import React from 'react'
import { Button, Typography } from '@mui/material'
import { Page } from '@griegconnect/krakentools-react-ui'

export const NotAuthorizedPage = () => {
  return (
    <Page.Wrapper title="Not authorized">
      <Typography gutterBottom>You are not authorized to view this page...</Typography>
      <Button href={process.env.PUBLIC_URL + '/'} variant="outlined" color="primary">
        Go to main page
      </Button>
    </Page.Wrapper>
  )
}
