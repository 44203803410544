import React, { useEffect } from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import FaceOutlined from '@mui/icons-material/FaceOutlined'
import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1),
      fontSize: 14,
    },
    inputfield: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
  })
)

type InputEmailsState = {
  values: string[]
  onChange: (emails: InputEmailsOnChange) => void
  className?: string
}
export type InputEmailsOnChange = {
  emails: string[]
  invalidEmails: string[]
  duplicateEmails: string[]
}

const InputEmail = (props: InputEmailsState) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState<string>('')
  const [focused, setFocused] = React.useState<boolean>(false)
  const [emails, setEmails] = React.useState<string[]>(props.values)
  const [invalidEmails, setInvalidEmails] = React.useState<string[]>([])
  const [duplicateEmails, setDuplicateEmails] = React.useState<string[]>([])
  const emailInputRef = React.createRef<HTMLInputElement>()

  const handleOnChange = (e: any) => {
    setInputValue(e.currentTarget.value)
  }

  const handleOnBlur = (e: any) => {
    setFocused(false)
    addEmail(e.currentTarget.value)
  }

  const handleOnFocus = () => setFocused(true)

  const isEmail = (email: string): boolean => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const addEmail = (value: string) => {
    if (value.length > 0) {
      const re = RegExp('[ ,;]', 'g')
      if (re.test(value)) {
        let splitData = new Set(value.split(re).filter((n) => n !== '' && n !== undefined && n !== null))
        const _emails = Array.from(splitData)
        const _valid = _emails.filter((email) => isEmail(email))
        const _invalid = _emails.filter((email) => !isEmail(email))
        const duplicates = emails.filter((email) => _valid.indexOf(email))
        setDuplicateEmails(duplicates)
        setInvalidEmails(_invalid)
        setEmails(Array.from(new Set([...emails, ..._valid])))
      } else {
        if (isEmail(value)) {
          const duplicates = emails.filter((email) => email === value)
          setDuplicateEmails(duplicates)
          setInvalidEmails([])
          setEmails(Array.from(new Set([...emails, value])))
        } else {
          setInvalidEmails([value])
        }
      }
      setInputValue('')
    }
  }
  const handleRemove = (val: string) => {
    const emailsCopy = emails.filter((email) => email !== val)
    setEmails(emailsCopy)
  }

  useEffect(() => {
    props.onChange({
      emails: emails,
      invalidEmails: invalidEmails,
      duplicateEmails: duplicateEmails,
    })
  }, [props, emails, invalidEmails, duplicateEmails])

  const handleOnKeydown = (e: any) => {
    switch (e.keyCode) {
      case 13:
        addEmail(e.target.value)
        break
      case 9:
        e.preventDefault()
        addEmail(e.target.value)
        break
      case 8:
        if (e.target.value.length === 0) {
          const emailsCopy = Array.from(emails)
          emailsCopy.pop()
          setEmails(emailsCopy)
        }
        break
      default:
    }
  }
  return (
    <div
      className={props.className}
      onClick={() => {
        if (emailInputRef.current) emailInputRef.current.focus()
      }}
    >
      {emails.map((email: string, index: number) => (
        <Chip
          key={index}
          icon={<FaceOutlined />}
          label={email}
          onDelete={() => handleRemove(email)}
          color="primary"
          className={classes.chip}
        />
      ))}
      <TextField
        ref={emailInputRef}
        className={classes.inputfield}
        type="text"
        value={inputValue}
        focused={focused}
        autoComplete={''}
        autoFocus={true}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeydown}
      />
    </div>
  )
}

export default InputEmail
