import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
  })
)

export type MapWrapperProps = {
  children: React.ReactNode | React.ReactNodeArray
}

export const MapWrapper = (props: MapWrapperProps) => {
  const classes = useStyles()

  return <div className={classes.wrapper}>{props.children}</div>
}

export default MapWrapper
