import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#fff',
  light: '#fff',
  dark: '#cccccc',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#000',
}

export default Color
