import { useRecoilValue } from 'recoil'
import { activeTenantSelector, Tenant } from '../atoms/tenantsAtom'
import {
  requiresTenant as requiresTenantCheck,
  requiresAllOfPermissions as requiresAllOfPermissionsCheck,
  requiresOneOfPermissions as requiresOneOfPermissionsCheck,
  requiresPermission as requiresPermissionCheck,
} from '../utils/access'

type UseAccessRequireTenantFuncType = {
  (
    /**
     * Defaults to `activeTenant`, no need to set this if your router always has a tenant context
     *
     * @default activeTenant
     */
    tenant?: Tenant.Type,
    /**
     * Set to true to check linked
     */
    checkLinked?: boolean
  ): boolean
}

type UseAccessPermissionType = {
  (
    permission: Tenant.Permission,
    /**
     * Defaults to `activeTenant`, no need to set this if your router always has a tenant context
     *
     * @default activeTenant
     * @deprecated Dgdhgsdhsd sdhs gdhsgd shdg
     */
    tenant?: Tenant.Type,
    /**
     * Set to true to check linked
     */
    checkLinked?: boolean
  ): boolean
}

type UseAccessPermissionsType = {
  (
    permissions: Tenant.Permission[],
    /**
     * Defaults to `activeTenant`, no need to set this if your router always has a tenant context
     *
     * @default activeTenant
     */
    tenant?: Tenant.Type,
    /**
     * Set to true to check linked
     */
    checkLinked?: boolean
  ): boolean
}

export type UseAccessType = {
  requiresAllOfPermissions: UseAccessPermissionsType
  requiresOneOfPermissions: UseAccessPermissionsType
  requiresPermission: UseAccessPermissionType
  requiresTenant: UseAccessRequireTenantFuncType
}

export const useAccess = (): UseAccessType => {
  const activeTenant = useRecoilValue(activeTenantSelector)

  const requiresAllOfPermissions: UseAccessPermissionsType = (
    permissions: Tenant.Permission[],
    tenant: Tenant.Type = activeTenant!,
    checkLinked?: boolean
  ) => {
    if (tenant) {
      return requiresAllOfPermissionsCheck(permissions, checkLinked)({ activeTenant: tenant })
    }
    return false
  }

  const requiresOneOfPermissions: UseAccessPermissionsType = (
    permissions: Tenant.Permission[],
    tenant: Tenant.Type = activeTenant!,
    checkLinked?: boolean
  ) => {
    if (tenant) {
      return requiresOneOfPermissionsCheck(permissions, checkLinked)({ activeTenant: tenant })
    }
    return false
  }

  const requiresPermission: UseAccessPermissionType = (
    permission: Tenant.Permission,
    tenant: Tenant.Type = activeTenant!,
    checkLinked?: boolean
  ) => {
    if (tenant) {
      return requiresPermissionCheck(permission, checkLinked)({ activeTenant: tenant })
    }
    return false
  }

  const requiresTenant: UseAccessRequireTenantFuncType = (tenant: Tenant.Type = activeTenant!) => {
    if (tenant) {
      return requiresTenantCheck({ activeTenant: tenant })
    }
    return false
  }

  return {
    requiresTenant,
    requiresAllOfPermissions,
    requiresOneOfPermissions,
    requiresPermission,
  }
}
