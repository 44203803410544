// export type AisCountry = {
//     number: number,
//     shortName: string,
//     fullName: string
// }
export type CountryDef = {
  code: string
  name: string
}

export const getAisCountry = (mmsi: number) => aisCountryMap.get(Number(String(mmsi).slice(0, 3)))

export const aisCountryMap = new Map<number, CountryDef>();

aisCountryMap.set(201, {code: "AL", name: "Albania"});
aisCountryMap.set(202, {code: "AD", name: "Andorra"});
aisCountryMap.set(203, {code: "AT", name: "Austria"});
aisCountryMap.set(204, {code: "PT", name: "Portugal"});
aisCountryMap.set(205, {code: "BE", name: "Belgium"});
aisCountryMap.set(206, {code: "BY", name: "Belarus"});
aisCountryMap.set(207, {code: "BG", name: "Bulgaria"});
aisCountryMap.set(208, {code: "VA", name: "Vatican City"});
aisCountryMap.set(209, {code: "CY", name: "Cyprus"});
aisCountryMap.set(210, {code: "CY", name: "Cyprus"});
aisCountryMap.set(211, {code: "DE", name: "Germany"});
aisCountryMap.set(212, {code: "CY", name: "Cyprus"});
aisCountryMap.set(213, {code: "GE", name: "Georgia"});
aisCountryMap.set(214, {code: "MD", name: "Moldova"});
aisCountryMap.set(215, {code: "MT", name: "Malta"});
aisCountryMap.set(216, {code: "ZZ", name: "Unknown"});
aisCountryMap.set(218, {code: "DE", name: "Germany"});
aisCountryMap.set(219, {code: "DK", name: "Denmark"});
aisCountryMap.set(220, {code: "DK", name: "Denmark"});
aisCountryMap.set(224, {code: "ES", name: "Spain"});
aisCountryMap.set(225, {code: "ES", name: "Spain"});
aisCountryMap.set(226, {code: "FR", name: "France"});
aisCountryMap.set(227, {code: "FR", name: "France"});
aisCountryMap.set(228, {code: "FR", name: "France"});
aisCountryMap.set(229, {code: "MT", name: "Malta"});
aisCountryMap.set(230, {code: "FI", name: "Finland"});
aisCountryMap.set(231, {code: "FO", name: "Faroe Islands"});
aisCountryMap.set(232, {code: "GB", name: "United Kingdom"});
aisCountryMap.set(233, {code: "GB", name: "United Kingdom"});
aisCountryMap.set(234, {code: "GB", name: "United Kingdom"});
aisCountryMap.set(235, {code: "GB", name: "United Kingdom"});
aisCountryMap.set(236, {code: "GI", name: "Gibraltar"});
aisCountryMap.set(237, {code: "GR", name: "Greece"});
aisCountryMap.set(238, {code: "HR", name: "Croatia"});
aisCountryMap.set(239, {code: "GR", name: "Greece"});
aisCountryMap.set(240, {code: "GR", name: "Greece"});
aisCountryMap.set(241, {code: "GR", name: "Greece"});
aisCountryMap.set(242, {code: "MA", name: "Morocco"});
aisCountryMap.set(243, {code: "HU", name: "Hungary"});
aisCountryMap.set(244, {code: "NL", name: "Netherlands"});
aisCountryMap.set(245, {code: "NL", name: "Netherlands"});
aisCountryMap.set(246, {code: "NL", name: "Netherlands"});
aisCountryMap.set(247, {code: "IT", name: "Italy"});
aisCountryMap.set(248, {code: "MT", name: "Malta"});
aisCountryMap.set(249, {code: "MT", name: "Malta"});
aisCountryMap.set(250, {code: "IE", name: "Ireland"});
aisCountryMap.set(251, {code: "IS", name: "Iceland"});
aisCountryMap.set(252, {code: "LI", name: "Liechtenstein"});
aisCountryMap.set(253, {code: "LU", name: "Luxembourg"});
aisCountryMap.set(254, {code: "MC", name: "Monaco"});
aisCountryMap.set(255, {code: "PT", name: "Portugal"});
aisCountryMap.set(256, {code: "MT", name: "Malta"});
aisCountryMap.set(257, {code: "NO", name: "Norway"});
aisCountryMap.set(258, {code: "NO", name: "Norway"});
aisCountryMap.set(259, {code: "NO", name: "Norway"});
aisCountryMap.set(261, {code: "PL", name: "Poland"});
aisCountryMap.set(262, {code: "ME", name: "Montenegro"});
aisCountryMap.set(263, {code: "PT", name: "Portugal"});
aisCountryMap.set(264, {code: "RO", name: "Romania"});
aisCountryMap.set(265, {code: "SE", name: "Sweden"});
aisCountryMap.set(266, {code: "SE", name: "Sweden"});
aisCountryMap.set(267, {code: "SK", name: "Slovakia"});
aisCountryMap.set(268, {code: "SM", name: "San Marino"});
aisCountryMap.set(269, {code: "CH", name: "Switzerland"});
aisCountryMap.set(270, {code: "CZ", name: "Czech Republic"});
aisCountryMap.set(271, {code: "TR", name: "Turkey"});
aisCountryMap.set(272, {code: "UA", name: "Ukraine"});
aisCountryMap.set(273, {code: "RU", name: "Russia"});
aisCountryMap.set(274, {code: "MK", name: "North Macedonia"});
aisCountryMap.set(275, {code: "LV", name: "Latvia"});
aisCountryMap.set(276, {code: "EE", name: "Estonia"});
aisCountryMap.set(277, {code: "LT", name: "Lithuania"});
aisCountryMap.set(278, {code: "SI", name: "Slovenia"});
aisCountryMap.set(279, {code: "RS", name: "Serbia"});
aisCountryMap.set(301, {code: "AI", name: "Anguilla"});
aisCountryMap.set(303, {code: "US", name: "United States"});
aisCountryMap.set(304, {code: "AG", name: "Antigua and Barbuda"});
aisCountryMap.set(305, {code: "AG", name: "Antigua and Barbuda"});
aisCountryMap.set(306, {code: "CW", name: "Curacao"});
aisCountryMap.set(307, {code: "AW", name: "Aruba"});
aisCountryMap.set(308, {code: "BS", name: "Bahamas"});
aisCountryMap.set(309, {code: "BS", name: "Bahamas"});
aisCountryMap.set(310, {code: "BM", name: "Bermuda"});
aisCountryMap.set(311, {code: "BS", name: "Bahamas"});
aisCountryMap.set(312, {code: "BZ", name: "Belize"});
aisCountryMap.set(314, {code: "BB", name: "Barbados"});
aisCountryMap.set(316, {code: "CA", name: "Canada"});
aisCountryMap.set(319, {code: "KY", name: "Cayman Islands"});
aisCountryMap.set(321, {code: "CR", name: "Costa Rica"});
aisCountryMap.set(323, {code: "CU", name: "Cuba"});
aisCountryMap.set(325, {code: "DM", name: "Dominica"});
aisCountryMap.set(327, {code: "DO", name: "Dominican Republic"});
aisCountryMap.set(329, {code: "GP", name: "Guadeloupe"});
aisCountryMap.set(330, {code: "GD", name: "Grenada"});
aisCountryMap.set(331, {code: "GL", name: "Greenland"});
aisCountryMap.set(332, {code: "GT", name: "Guatemala"});
aisCountryMap.set(334, {code: "HN", name: "Honduras"});
aisCountryMap.set(336, {code: "HT", name: "Haiti"});
aisCountryMap.set(338, {code: "US", name: "United States"});
aisCountryMap.set(339, {code: "JM", name: "Jamaica"});
aisCountryMap.set(341, {code: "KN", name: "Saint Kitts and Nevis"});
aisCountryMap.set(343, {code: "LC", name: "Saint Lucia"});
aisCountryMap.set(345, {code: "MX", name: "Mexico"});
aisCountryMap.set(347, {code: "MQ", name: "Martinique"});
aisCountryMap.set(348, {code: "MS", name: "Montserrat"});
aisCountryMap.set(350, {code: "NI", name: "Nicaragua"});
aisCountryMap.set(351, {code: "PA", name: "Panama"});
aisCountryMap.set(352, {code: "PA", name: "Panama"});
aisCountryMap.set(351, {code: "PA", name: "Panama"});
aisCountryMap.set(352, {code: "PA", name: "Panama"});
aisCountryMap.set(358, {code: "PR", name: "Puerto Rico"});
aisCountryMap.set(359, {code: "SV", name: "El Salvador"});
aisCountryMap.set(361, {code: "PM", name: "Saint Pierre and Miquelon"});
aisCountryMap.set(362, {code: "TT", name: "Trinidad and Tobago"});
aisCountryMap.set(364, {code: "TC", name: "Turks and Caicos Islands"});
aisCountryMap.set(366, {code: "US", name: "United States"});
aisCountryMap.set(367, {code: "US", name: "United States"});
aisCountryMap.set(368, {code: "US", name: "United States"});
aisCountryMap.set(369, {code: "US", name: "United States"});
aisCountryMap.set(370, {code: "PA", name: "Panama"});
aisCountryMap.set(371, {code: "PA", name: "Panama"});
aisCountryMap.set(372, {code: "PA", name: "Panama"});
aisCountryMap.set(373, {code: "PA", name: "Panama"});
aisCountryMap.set(374, {code: "PA", name: "Panama"});
aisCountryMap.set(375, {code: "VC", name: "Saint Vincent and the Grenadines"});
aisCountryMap.set(376, {code: "VC", name: "Saint Vincent and the Grenadines"});
aisCountryMap.set(377, {code: "VC", name: "Saint Vincent and the Grenadines"});
aisCountryMap.set(378, {code: "VG", name: "British Virgin Islands"});
aisCountryMap.set(379, {code: "VI", name: "United States Virgin Islands"});
aisCountryMap.set(401, {code: "AF", name: "Afghanistan"});
aisCountryMap.set(403, {code: "SA", name: "Saudi Arabia"});
aisCountryMap.set(405, {code: "BD", name: "Bangladesh"});
aisCountryMap.set(408, {code: "BH", name: "Bahrain"});
aisCountryMap.set(410, {code: "BT", name: "Bhutan"});
aisCountryMap.set(412, {code: "CN", name: "China"});
aisCountryMap.set(413, {code: "CN", name: "China"});
aisCountryMap.set(414, {code: "CN", name: "China"});
aisCountryMap.set(416, {code: "TW", name: "Taiwan"});
aisCountryMap.set(417, {code: "LK", name: "Sri Lanka"});
aisCountryMap.set(419, {code: "IN", name: "India"});
aisCountryMap.set(422, {code: "IR", name: "Iran"});
aisCountryMap.set(423, {code: "AZ", name: "Azerbaijan"});
aisCountryMap.set(425, {code: "IQ", name: "Iraq"});
aisCountryMap.set(428, {code: "IL", name: "Israel"});
aisCountryMap.set(431, {code: "JP", name: "Japan"});
aisCountryMap.set(432, {code: "JP", name: "Japan"});
aisCountryMap.set(434, {code: "TM", name: "Turkmenistan"});
aisCountryMap.set(436, {code: "KZ", name: "Kazakhstan"});
aisCountryMap.set(437, {code: "UZ", name: "Uzbekistan"});
aisCountryMap.set(438, {code: "JO", name: "Jordan"});
aisCountryMap.set(440, {code: "KR", name: "South Korea"});
aisCountryMap.set(441, {code: "KR", name: "South Korea"});
aisCountryMap.set(443, {code: "PS", name: "Palestine"});
aisCountryMap.set(445, {code: "KP", name: "North Korea"});
aisCountryMap.set(447, {code: "KW", name: "Kuwait"});
aisCountryMap.set(450, {code: "LB", name: "Lebanon"});
aisCountryMap.set(451, {code: "ZZ", name: "Unknown"});
aisCountryMap.set(453, {code: "ZZ", name: "Unknown"});
aisCountryMap.set(455, {code: "MV", name: "Maldives"});
aisCountryMap.set(457, {code: "MN", name: "Mongolia"});
aisCountryMap.set(459, {code: "NP", name: "Nepal"});
aisCountryMap.set(461, {code: "OM", name: "Oman"});
aisCountryMap.set(463, {code: "PK", name: "Pakistan"});
aisCountryMap.set(466, {code: "QA", name: "Qatar"});
aisCountryMap.set(468, {code: "SY", name: "Syria"});
aisCountryMap.set(470, {code: "AE", name: "United Arab Emirates"});
aisCountryMap.set(472, {code: "TJ", name: "Tajikistan"});
aisCountryMap.set(473, {code: "YE", name: "Yemen"});
aisCountryMap.set(475, {code: "YE", name: "Yemen"});
aisCountryMap.set(477, {code: "HK", name: "Hong Kong"});
aisCountryMap.set(478, {code: "BA", name: "Bosnia and Herzegovina"});
aisCountryMap.set(501, {code: "AQ", name: "Antarctica"});
aisCountryMap.set(503, {code: "AU", name: "Australia"});
aisCountryMap.set(506, {code: "MM", name: "Myanmar"});
aisCountryMap.set(508, {code: "BN", name: "Brunei"});
aisCountryMap.set(510, {code: "FM", name: "Micronesia"});
aisCountryMap.set(511, {code: "PW", name: "Palau"});
aisCountryMap.set(512, {code: "NZ", name: "New Zealand"});
aisCountryMap.set(514, {code: "KH", name: "Cambodia"});
aisCountryMap.set(515, {code: "KH", name: "Cambodia"});
aisCountryMap.set(516, {code: "CX", name: "Christmas Island"});
aisCountryMap.set(518, {code: "CK", name: "Cook Islands"});
aisCountryMap.set(520, {code: "FJ", name: "Fiji"});
aisCountryMap.set(523, {code: "CC", name: "Cocos (Keeling) Islands"});
aisCountryMap.set(525, {code: "ID", name: "Indonesia"});
aisCountryMap.set(529, {code: "KI", name: "Kiribati"});
aisCountryMap.set(531, {code: "LA", name: "Laos"});
aisCountryMap.set(533, {code: "MY", name: "Malaysia"});
aisCountryMap.set(536, {code: "MP", name: "Northern Mariana Islands"});
aisCountryMap.set(538, {code: "MH", name: "Marshall Islands"});
aisCountryMap.set(540, {code: "NC", name: "New Caledonia"});
aisCountryMap.set(542, {code: "NU", name: "Niue"});
aisCountryMap.set(544, {code: "NR", name: "Nauru"});
aisCountryMap.set(546, {code: "TF", name: "French Southern Territories"});
aisCountryMap.set(548, {code: "PH", name: "Philippines"});
aisCountryMap.set(553, {code: "PG", name: "Papua New Guinea"});
aisCountryMap.set(555, {code: "PN", name: "Pitcairn"});
aisCountryMap.set(557, {code: "SB", name: "Solomon Islands"});
aisCountryMap.set(559, {code: "AS", name: "American Samoa"});
aisCountryMap.set(561, {code: "WS", name: "Samoa"});
aisCountryMap.set(563, {code: "SG", name: "Singapore"});
aisCountryMap.set(564, {code: "SG", name: "Singapore"});
aisCountryMap.set(565, {code: "SG", name: "Singapore"});
aisCountryMap.set(566, {code: "SG", name: "Singapore"});
aisCountryMap.set(567, {code: "TH", name: "Thailand"});
aisCountryMap.set(570, {code: "TO", name: "Tonga"});
aisCountryMap.set(572, {code: "TV", name: "Tuvalu"});
aisCountryMap.set(574, {code: "VN", name: "Vietnam"});
aisCountryMap.set(576, {code: "VU", name: "Vanuatu"});
aisCountryMap.set(577, {code: "VU", name: "Vanuatu"});
aisCountryMap.set(578, {code: "WF", name: "Wallis and Futuna"});
aisCountryMap.set(601, {code: "ZA", name: "South Africa"});
aisCountryMap.set(603, {code: "AO", name: "Angola"});
aisCountryMap.set(605, {code: "DZ", name: "Algeria"});
aisCountryMap.set(607, {code: "XX", name: "Unknown"});
aisCountryMap.set(608, {code: "IO", name: "British Indian Ocean Territory"});
aisCountryMap.set(609, {code: "BI", name: "Burundi"});
aisCountryMap.set(610, {code: "BJ", name: "Benin"});
aisCountryMap.set(611, {code: "BW", name: "Botswana"});
aisCountryMap.set(612, {code: "CF", name: "Central African Republic"});
aisCountryMap.set(613, {code: "CM", name: "Cameroon"});
aisCountryMap.set(615, {code: "CG", name: "Congo - Brazzaville"});
aisCountryMap.set(616, {code: "KM", name: "Comoros"});
aisCountryMap.set(617, {code: "CV", name: "Cape Verde"});
aisCountryMap.set(618, {code: "AQ", name: "Antarctica"});
aisCountryMap.set(619, {code: "CI", name: "Côte d’Ivoire"});
aisCountryMap.set(620, {code: "KM", name: "Comoros"});
aisCountryMap.set(621, {code: "DJ", name: "Djibouti"});
aisCountryMap.set(622, {code: "EG", name: "Egypt"});
aisCountryMap.set(624, {code: "ET", name: "Ethiopia"});
aisCountryMap.set(625, {code: "ER", name: "Eritrea"});
aisCountryMap.set(626, {code: "GA", name: "Gabon"});
aisCountryMap.set(627, {code: "GH", name: "Ghana"});
aisCountryMap.set(629, {code: "GM", name: "Gambia"});
aisCountryMap.set(630, {code: "GW", name: "Guinea-Bissau"});
aisCountryMap.set(631, {code: "GQ", name: "Equatorial Guinea"});
aisCountryMap.set(632, {code: "GN", name: "Guinea"});
aisCountryMap.set(633, {code: "BF", name: "Burkina Faso"});
aisCountryMap.set(634, {code: "KE", name: "Kenya"});
aisCountryMap.set(635, {code: "AQ", name: "Antarctica"});
aisCountryMap.set(636, {code: "LR", name: "Liberia"});
aisCountryMap.set(637, {code: "LR", name: "Liberia"});
aisCountryMap.set(642, {code: "LY", name: "Libya"});
aisCountryMap.set(644, {code: "LS", name: "Lesotho"});
aisCountryMap.set(645, {code: "MU", name: "Mauritius"});
aisCountryMap.set(647, {code: "MG", name: "Madagascar"});
aisCountryMap.set(649, {code: "ML", name: "Mali"});
aisCountryMap.set(650, {code: "MZ", name: "Mozambique"});
aisCountryMap.set(654, {code: "MR", name: "Mauritania"});
aisCountryMap.set(655, {code: "MW", name: "Malawi"});
aisCountryMap.set(656, {code: "NE", name: "Niger"});
aisCountryMap.set(657, {code: "NG", name: "Nigeria"});
aisCountryMap.set(659, {code: "NA", name: "Namibia"});
aisCountryMap.set(660, {code: "RE", name: "Réunion"});
aisCountryMap.set(661, {code: "RW", name: "Rwanda"});
aisCountryMap.set(662, {code: "SD", name: "Sudan"});
aisCountryMap.set(663, {code: "SN", name: "Senegal"});
aisCountryMap.set(664, {code: "SC", name: "Seychelles"});
aisCountryMap.set(665, {code: "SH", name: "Saint Helena"});
aisCountryMap.set(666, {code: "SO", name: "Somalia"});
aisCountryMap.set(667, {code: "SL", name: "Sierra Leone"});
aisCountryMap.set(668, {code: "ST", name: "São Tomé and Príncipe"});
aisCountryMap.set(669, {code: "SZ", name: "Eswatini"});
aisCountryMap.set(670, {code: "TD", name: "Chad"});
aisCountryMap.set(671, {code: "TG", name: "Togo"});
aisCountryMap.set(672, {code: "TN", name: "Tunisia"});
aisCountryMap.set(674, {code: "TZ", name: "Tanzania"});
aisCountryMap.set(675, {code: "UG", name: "Uganda"});
aisCountryMap.set(676, {code: "CD", name: "Democratic Republic of the Congo"});
aisCountryMap.set(677, {code: "TZ", name: "Tanzania"});
aisCountryMap.set(678, {code: "ZM", name: "Zambia"});
aisCountryMap.set(679, {code: "ZW", name: "Zimbabwe"});
aisCountryMap.set(701, {code: "AR", name: "Argentina"});
aisCountryMap.set(710, {code: "BR", name: "Brazil"});
aisCountryMap.set(720, {code: "BO", name: "Bolivia"});
aisCountryMap.set(725, {code: "CL", name: "Chile"});
aisCountryMap.set(730, {code: "CO", name: "Colombia"});
aisCountryMap.set(735, {code: "EC", name: "Ecuador"});
aisCountryMap.set(740, {code: "UK", name: "United Kingdom"});
aisCountryMap.set(745, {code: "GF", name: "French Guiana"});
aisCountryMap.set(750, {code: "GY", name: "Guyana"});
aisCountryMap.set(755, {code: "PY", name: "Paraguay"});
aisCountryMap.set(760, {code: "PE", name: "Peru"});
aisCountryMap.set(765, {code: "SR", name: "Suriname"});
aisCountryMap.set(770, {code: "UY", name: "Uruguay"});
aisCountryMap.set(775, {code: "VE", name: "Venezuela"});
