import { Grid, Tooltip, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Vessel } from '@griegconnect/krakentools-kmap'

import { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import { Warning as WarningIcon } from '@griegconnect/krakentools-react-icons'
import { useTranslation } from 'react-i18next'

export type VesselInformationProps = {
  vessel: Vessel
  datatype: 'primary' | 'secondary'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    item: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xl')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
  })
)

export const VesselInformation = (props: VesselInformationProps) => {
  const classes = useStyles()
  const { t } = useTranslation('kmap', { keyPrefix: 'VesselInformation' })
  const createGridItem = (
    label: string,
    value: string,
    tooltipText?: string,
    tooltipPlacement?: MuiTooltipProps['placement']
  ) => {
    return (
      <>
        <Grid item xs={6} lg={3} className={classes.item}>
          <Tooltip title={tooltipText || ''} arrow placement={tooltipPlacement}>
            <Typography variant="body2" color="textSecondary">
              {label}:
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6} lg={3} className={classes.item}>
          <Typography variant="body2" color="textPrimary">
            {value}
          </Typography>
        </Grid>
      </>
    )
  }
  const source = props.vessel.source === 'ais' ? 'Live' : props.vessel.source === 'sat' ? 'Sat' : '-'

  return (
    <>
      <Grid container className={classes.container}>
        {props.datatype === 'primary' && (
          <>
            <Grid item xs={4} lg={3} className={classes.item}>
              <Typography variant="body2" color="textSecondary">
                {t('lastUpdate')}:
              </Typography>
            </Grid>
            <Grid item xs={7} lg={8} className={classes.item}>
              <Typography variant="body2" color="textPrimary">
                {props.vessel?.time ? new Date(props.vessel?.time.toString()).toLocaleString() : '-'}

                {props.vessel?.isGhost && (
                  <Tooltip title={t('over8hours')}>
                    <span>
                      <WarningIcon color="warning" sx={{ marginBottom: '-6px', paddingBottom: '0' }} />
                    </span>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            {createGridItem(t('mmsi'), props.vessel?.mmsi?.toString() || '-')}
            {createGridItem(t('imo'), props.vessel?.imo?.toString() || '-')}
          </>
        )}
        {props.datatype === 'secondary' && (
          <>
            {createGridItem(t('callsign'), props.vessel.callsign || '-')}
            {createGridItem(
              t('length'),
              (props.vessel?.dimensions && props.vessel?.dimensions?.bow + props.vessel?.dimensions?.stern + 'm') || '-'
            )}
            {createGridItem(
              t('beam'),
              (props.vessel?.dimensions &&
                props.vessel?.dimensions?.port + props.vessel?.dimensions?.starboard + 'm') ||
                '-'
            )}
            {createGridItem(t('draught'), (props.vessel?.draught && props.vessel?.draught + 'm') || '-')}
            {createGridItem(t('source'), source)}
            {createGridItem(t('aisClass'), props.vessel?.aisClass?.toUpperCase() || '-')}

            {createGridItem(
              t('sog'),
              (props.vessel?.speed && Math.round(Number(props.vessel?.speed || 0)).toFixed(0) + 'kn') || '-',
              t('speedOverGround'),
              'left'
            )}

            {createGridItem(
              t('trueHeading'),
              (props.vessel?.heading && Math.round(props.vessel?.heading).toFixed(0) + '°') || '-'
            )}

            {createGridItem(
              t('cog'),
              (props.vessel?.cog && Math.round(Number(props.vessel?.cog || 0)).toFixed(0) + '°') || '-',
              t('courseOverGround'),
              'left'
            )}
          </>
        )}
      </Grid>
    </>
  )
}

export default VesselInformation
