import kraken from './colors/kraken-color'
import port from './colors/port-color'
import predict from './colors/predict-color'
import explore from './colors/explore-color'
import plan from './colors/plan-color'
import security from './colors/security-color'
import terminal from './colors/terminal-color'
import traffic from './colors/traffic-color'
import info from './colors/info-color'
import success from './colors/success-color'
import warning from './colors/warn-color'
import error from './colors/error-color'
import grey from './colors/grey-color'
import { ThemeOptions } from '@mui/material/styles'
import linkColor from './colors/link-color'
import sharedThemeOptions from '../sharedThemeOptions'

const createDarkThemeOptions = (): ThemeOptions => {
  // Shared options
  const { components, ...topLevel } = sharedThemeOptions('dark', kraken.main, linkColor.main)

  // Dark options
  return {
    ...topLevel,
    palette: {
      mode: 'dark',
      primary: kraken,
      secondary: kraken,
      info,
      success,
      warning,
      error,
      action: {
        active: '#fff',
        hover: 'rgba(255, 255, 255, 0.08)',
        selected: 'rgba(255, 255, 255, 0.16)',
        disabled: 'rgba(255, 255, 255, 0.3)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
      },
      text: {
        primary: 'rgba(255, 255, 255, 0.87)',
        secondary: 'rgba(255, 255, 255, 0.6)',
        disabled: 'rgba(255, 255, 255, 0.38)',
      },
      background: {
        default: '#11181E',
        paper: '#22292F',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      grey,
    },
    components: {
      ...components,
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#242B31',
            color: 'rgba(255, 255, 255, 0.87)',
          },
          colorPrimary: {
            backgroundColor: '#242B31',
            color: 'rgba(255, 255, 255, 0.87)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation0: {
            backgroundColor: '#11181E',
            backgroundImage: 'none',
          },
          elevation1: {
            backgroundColor: '#1C2329',
            backgroundImage: 'none',
          },
          elevation2: {
            backgroundColor: '#20272D',
            backgroundImage: 'none',
          },
          elevation3: {
            backgroundColor: '#22292F',
            backgroundImage: 'none',
          },
          elevation4: {
            backgroundColor: '#242B31',
            backgroundImage: 'none',
          },
          elevation5: {
            backgroundColor: '#242B31',
            backgroundImage: 'none',
          },
          elevation6: {
            backgroundColor: '#293036',
            backgroundImage: 'none',
          },
          elevation7: {
            backgroundColor: '#293036',
            backgroundImage: 'none',
          },
          elevation8: {
            backgroundColor: '#2B3238',
            backgroundImage: 'none',
          },
          elevation9: {
            backgroundColor: '#2B3238',
            backgroundImage: 'none',
          },
          elevation10: {
            backgroundColor: '#2B3238',
            backgroundImage: 'none',
          },
          elevation11: {
            backgroundColor: '#2B3238',
            backgroundImage: 'none',
          },
          elevation12: {
            backgroundColor: '#2F363C',
            backgroundImage: 'none',
          },
          elevation13: {
            backgroundColor: '#2F363C',
            backgroundImage: 'none',
          },
          elevation14: {
            backgroundColor: '#2F363C',
            backgroundImage: 'none',
          },
          elevation15: {
            backgroundColor: '#2F363C',
            backgroundImage: 'none',
          },
          elevation16: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation17: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation18: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation19: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation20: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation21: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation22: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation23: {
            backgroundColor: '#31383E',
            backgroundImage: 'none',
          },
          elevation24: {
            backgroundColor: '#343B41',
            backgroundImage: 'none',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            alignItems: 'center',
          },
          action: {
            paddingTop: 0,
            'button:hover': {
              color: 'inherit',
              borderColor: 'inherit',
            },
          },
          filled: {
            color: '#000000DE',
            backgroundColor: '#FFFFFF',
          },
          filledInfo: {
            color: '#000000DE',
            backgroundColor: '#84D5FF',
          },
          filledError: {
            color: '#000000DE',
            backgroundColor: '#FFAB91',
          },
          filledSuccess: {
            color: '#000000DE',
            backgroundColor: '#A5D6A7',
          },
          filledWarning: {
            color: '#000000DE',
            backgroundColor: '#FFE082',
          },
        },
      },
    },
    kraken: {
      tools: {
        explore,
        kraken,
        plan,
        port,
        predict,
        security,
        terminal,
        traffic,
      },
    },
  }
}

const darkThemeOptions = createDarkThemeOptions()
export default darkThemeOptions
