import React, { useEffect, useMemo, useRef } from 'react'
import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DateTimeFormatOptions } from 'luxon'

export class Debouncer {
  timerId: number | null = null
  bounce(callback: () => any, delay: number = 850) {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
    this.timerId = window.setTimeout(callback, delay)
  }
}

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints.
 */
export function useWidth(): Breakpoint {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys]
  return (
    keys.reduce<Breakpoint>((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return matches ? key : output
    }, 'xs')
  )
}
 

/**
 * Async debouncer hook
 */

interface IRefType<TResult> {
  id: number
  theFunction?: (...args: any[]) => Promise<TResult>
  timeout?: number
  promise?: Promise<TResult>
  resolve?: (value: TResult | PromiseLike<TResult>) => void
  reject?: (reason?: any) => void
}

export function useAsyncDebouncer<T>(
  functionToDebounce: (...args: any[]) => Promise<T>,
  delay = 0
): (...args: any[]) => Promise<T> {
  const ref = useRef<IRefType<T>>({ id: 0 })

  ref.current.theFunction = functionToDebounce

  // Create the debounced function
  const bouncer = React.useCallback(
    (...args) => {
      // Create a new promise
      ref.current.promise = new Promise<T>((resolve, reject) => {
        // Keep track of resolve and reject
        ref.current.resolve = resolve
        ref.current.reject = reject
      })

      const id = ref.current.id + 1
      // Update the hook with the latest request ID
      ref.current.id = id

      // Clear the old timeout if one exists
      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout)
      }

      ref.current.timeout = window.setTimeout(async () => {
        ref.current.timeout = undefined

        const checkLatest = () => id === ref.current.id

        try {
          // Run the debounced function
          if (ref.current.theFunction) {
            const response = await ref.current.theFunction(...args)

            // If the request is latest, resolve
            if (checkLatest()) {
              if (ref.current.resolve) {
                ref.current.resolve(response)
              }
            }
          }
        } catch (error) {
          // If the request is latest, reject
          if (checkLatest()) {
            if (ref.current.reject) {
              ref.current.reject(error)
            }
          }
        }
      }, delay)

      return ref.current.promise
    },
    [delay]
  )

  return bouncer
}


/**
 * Cancellable promise
 */

type CancellablePromise<T> = {
	promise: Promise<T>
	cancel: () => void
}

export function makeCancelable<T>(promise: Promise<T>): CancellablePromise<T> {
	let isCanceled = false
	const wrappedPromise = new Promise<T>((resolve, reject) => {
		// Suppress resolution and rejection if canceled
		promise.then((val) => !isCanceled && resolve(val)).catch((error) => !isCanceled && reject(error))
	})
	return {
		promise: wrappedPromise,
		cancel() {
			isCanceled = true
		},
	}
}

export function useCancellablePromise() {
	const promises = useRef<CancellablePromise<unknown>[]>([])

	useEffect(() => {
		promises.current = promises.current || []
		return function cancel() {
			promises.current.forEach((p) => p.cancel())
			promises.current = []
		}
	}, [])

	return useMemo(
		() => ({
			cancellablePromise<T>(p: Promise<T>) {
				const cPromise = makeCancelable<T>(p)
				promises.current.push(cPromise)
				return cPromise.promise
			},
		}),
		[]
	)
}

type DateFormats = {
  readonly DATE_SHORT: DateTimeFormatOptions
}

export const DATE_FORMATS: DateFormats = {
  DATE_SHORT: { dateStyle: 'short' },
}
