import { ButtonGroup, Box } from '@mui/material'
import React from 'react'
import { MapTranslucentPaper } from './MapTranslucentPaper'

export type MapButtonGroupProps = {
  orientation?: 'vertical' | 'horizontal'
  top?: number
  bottom?: number
  left?: number
  right?: number
  children?: React.ReactNode | React.ReactNode[]
  position?: string
  align?: 'start' | 'end'
}

export const MapButtonGroup = (props: MapButtonGroupProps) => {
  return (
    <Box
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      left={props.left}
      sx={{
        transition: (theme) =>
          theme.transitions.create('bottom', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
      }}
      style={{ position: props.position || 'absolute' } as React.CSSProperties}
    >
      <ButtonGroup orientation={props.orientation ?? 'vertical'} component={MapTranslucentPaper} elevation={24}>
        {props.children}
      </ButtonGroup>
    </Box>
  )
}

export default MapButtonGroup
