import { IconButton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { ArrowDown, ArrowUp } from '@griegconnect/krakentools-react-icons'
import Paper from '@mui/material/Paper'
import React, { useState } from 'react'
import { BoardColumn, BoardColumnProps, DragProps } from './board-column'
import { BoardItemProps } from './board-item'
import { ColumnsContainer } from './columns-container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tracksRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    trackHeaderWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    toggleHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '100%',
    },
    trackHeader: {
      paddingRight: theme.spacing(1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    trackInfos: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.palette.action.disabled,
    },
    trackInfo: {
      marginRight: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    trackInfoHeader: {
      fontSize: '80%',
    },
    trackInfoColorHeader: {
      backgroundColor: theme.palette.primary.main,
      width: '1em',
      height: '1em',
      borderRadius: '1em',
      marginLeft: '1em',
    },
    trackInfoCount: {
      paddingLeft: '0.5em',
    },
  })
)

export type BoardTrackProps = {
  track: BoardTrackInfo
  columnsTracks: ColumnTracks[]
  dragProps: DragProps
}

export type BoardTrackInfo = {
  id: string
  headerProps: BoardTrackHeaderProps
  onlyAllowDropFromColumns?: string[]
  restrictDropFromColumns?: string[]
  onlyAllowDropFromTracks?: string[]
  restrictDropFromTracks?: string[]
}

export type ColumnTracks = {
  column: BoardColumnProps
  tracksItems: TrackItems[]
  isCollapsed: boolean
}

export type TrackItems = {
  trackId: string
  items: BoardItemProps[]
}

export type BoardTrackHeaderProps = {
  header: React.ReactNode
  isDisplayItemsInfoDisabled?: boolean
  isItemsInfoCountColors?: boolean
  itemsInfo?: React.ReactNode
}

export const BoardTrack: React.FC<React.PropsWithChildren<BoardTrackProps>> = React.memo((props: BoardTrackProps) => {
  const { track } = props
  const classes = useStyles()

  const [isCollapsed, setIsCollapsed] = useState(false)

  const onToggle = () => setIsCollapsed(!isCollapsed)

  const getItems = (columnId: string): BoardItemProps[] => {
    const trackCol = props.columnsTracks.find((t) => t.column.id == columnId)
    return trackCol?.tracksItems?.find((t) => t.trackId === props.track.id)?.items ?? []
  }

  const openColumns = props.columnsTracks.filter((c) => !c.isCollapsed).length

  const renderTrackInfoCountColumns = () => {
    return (
      <div className={classes.trackInfos}>
        {props.columnsTracks
          .map((trackCol) => {
            const col = trackCol.column
            if (track.headerProps.itemsInfo !== undefined) {
              return (
                <div className={classes.trackInfo} key={col.id}>
                  {track.headerProps.itemsInfo}
                </div>
              )
            }

            const count = trackCol.tracksItems.find((t) => t.trackId === track.id)?.items.length

            if (count == 0) {
              return undefined // Hide info about columns without items
            }

            return (
              <div className={classes.trackInfo} key={col.id}>
                <div className={classes.trackInfoHeader}>{col.headerProps.header}</div>
                <div className={classes.trackInfoCount}>{count}</div>
              </div>
            )
          })
          .filter((c) => c !== undefined)}
      </div>
    )
  }

  const renderTrackInfoCountColors = () => {
    const items: BoardItemProps[] = props.columnsTracks
      .flatMap((c: ColumnTracks) => c.column.items)
      .filter((i: BoardItemProps) => i.trackId === track.id)

    const colorsMap = new Map()
    items.forEach((item: BoardItemProps) => {
      const key = item.color ?? 'default'
      const collection = colorsMap.get(key)
      if (!collection) {
        colorsMap.set(key, [item])
      } else {
        collection.push(item)
      }
    })

    return (
      <div className={classes.trackInfos}>
        {Array.from(colorsMap).map((val) => {
          const color = val[0]
          const count = val[1].length
          const style: React.CSSProperties | undefined = color !== 'default' ? { backgroundColor: color } : undefined

          return (
            <div className={classes.trackInfo} key={color}>
              <div className={classes.trackInfoColorHeader} style={style}></div>
              <div className={classes.trackInfoCount}>{count}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      <Paper className={classes.trackHeaderWrap} elevation={2}>
        <div className={classes.toggleHeader}>
          <IconButton onClick={onToggle} size="large">
            {isCollapsed ? <ArrowDown /> : <ArrowUp />}
          </IconButton>
          <div className={classes.trackHeader}>{track.headerProps.header}</div>
        </div>
        {!track.headerProps.isDisplayItemsInfoDisabled &&
          openColumns > 0 &&
          (track.headerProps.isItemsInfoCountColors ? renderTrackInfoCountColors() : renderTrackInfoCountColumns())}
      </Paper>
      {!isCollapsed && (
        <ColumnsContainer>
          {props.columnsTracks.map((tracksCol: ColumnTracks, index: number) => {
            const col = tracksCol.column

            return (
              <BoardColumn
                key={col.id}
                {...col}
                id={col.id}
                track={track}
                headerProps={col.headerProps}
                items={getItems(col.id)}
                index={index}
                dragProps={props.dragProps}
                hideHeader={true}
                isOverrideCollapsed={tracksCol.isCollapsed}
              />
            )
          })}
        </ColumnsContainer>
      )}
    </>
  )
})
