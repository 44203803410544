import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type StyleProps = {
  orientation: 'vertical' | 'horizontal'
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    divider: {
      width: ({ orientation }) => (orientation === 'vertical' ? '30px' : '1px'),
      height: ({ orientation }) => (orientation === 'vertical' ? '1px' : '30px'),
      margin: ({ orientation }) => (orientation === 'vertical' ? '0px 5px 0px 5px' : '5px 0px 5px 0px'),
      alignSelf: 'center',
      backgroundColor: theme.palette.divider,
    },
  })
)

export type MapButtonDividerProps = {
  orientation?: 'vertical' | 'horizontal'
}

export const MapButtonDivider: React.FC<React.PropsWithChildren<MapButtonDividerProps>> = ({ orientation }) => {
  const classes = useStyles({ orientation: orientation ?? 'vertical' })

  return <Box className={classes.divider} />
}

export default MapButtonDivider
