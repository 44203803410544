import { AxiosInstance } from 'axios'
import { AssetType, AssetTypeStyleTemplate } from './dtos/KindsDto'

type BBox = {
  ll: [number, number]
  ur: [number, number]
}

export type Dataset = {
  access: string
  id: string
  name: string
  bbox: BBox
  crs_EPSG: string
} & DatasetDetails

export type DatasetDetails = {
  access: string
  bbox: BBox
  crs_EPSG: string
  dataset_last_modified: string
  dataset_version_copy: string
  id: string
  last_copy_transaction_number: string
  name: string
  resolution: number
  schema_url: string
}

export type DatasetConfig = {
  crs: string
  bbox: BBox
  dataset: string
  namespace: string
  tenant: string
}

export type SourceStyleTemplate = {
  [key: string]: AssetTypeStyleTemplate
}

export type CreateDatasetConfig = {
  tenant: string
  crs: string
  bbox: { llx: number; lly: number; urx: number; ury: number }
  dataset: string
  namespace: string
}
export class AssetsAdminApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async getDefaultTypes(source: string): Promise<AssetType[]> {
    return await this.httpClient.get('sources/' + source + '/defaults').then((response) => {
      return (response.data as AssetType[]).sort((a, b) => (a.name > b.name ? 1 : -1))
    })
  }

  async updateDefaultType(source: string, assetType: AssetType): Promise<null> {
    return await this.httpClient.put('sources/' + source + '/defaults/' + assetType.name, assetType, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  async getDatasets(): Promise<Partial<Dataset>[]> {
    return await this.httpClient.get('/sources/sfkb/datasets').then((response) => response.data)
  }

  async getDataset(id: string): Promise<DatasetDetails> {
    return await this.httpClient.get('/sources/sfkb/datasets/' + id).then((response) => response.data)
  }

  async getConfigs(): Promise<DatasetConfig[]> {
    return await this.httpClient.get('/sources/sfkb/datasets/configs').then((response) => response.data)
  }

  async createConfig(config: CreateDatasetConfig) {
    return await this.httpClient.post('/sources/sfkb/datasets/configs', config)
  }
}

export default AssetsAdminApi
