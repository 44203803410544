import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { Close } from '@griegconnect/krakentools-react-icons'
import { LanguageTag, Profile } from './profile-menu'
import ProfileCropper from './profile-cropper'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },

  topContainer: {
    position: 'fixed',
    top: 8,
    right: 8,
  },
  topButtonDialog: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(6),
  },
  title: {
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(4),
    width: theme.spacing(10),
    height: theme.spacing(10),
    maxWidth: '200px',
    maxHeight: '200px',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export const supportedLanguages: Record<LanguageTag, string> = {
  'en-GB': 'English',
  'nb-NO': 'Norwegian',
  'sv-SE': 'Swedish',
  'fi-FI': 'Finnish',
} as const

export const supportedLanguagesTranslated: Record<LanguageTag, string> = {
  'en-GB': 'English',
  'nb-NO': 'Norsk',
  'sv-SE': 'Svenska',
  'fi-FI': 'Suomi',
} as const

export interface ProfileFormProps {
  open: boolean
  onClose: () => void
  onFormSave: (profile: Profile) => Promise<void>
  onProfileImgSave: (profileImage: string) => void
  profile: Profile
  /**
   * One or more languages to display in the language selector.
   */
  languages?: LanguageTag[]
  onError: (errorMsg: string) => void
}

type FormErrors = Partial<Record<keyof Profile, string | undefined>>

export const validateProfileData = (form: Profile): FormErrors => {
  const newErrors: FormErrors = {}
  if (form.fullName === form.phone) {
    const errMsg = 'Your name and mobile number cannot be the same.'
    newErrors.fullName = errMsg
  }
  if (form.fullName === form.email) {
    const errMsg = 'Your name and email cannot be the same.'
    newErrors.fullName = errMsg
  }
  if (form.fullName === undefined || form.fullName.length < 4) {
    const errMsg = 'Your name needs to be at least 4 characters.'
    newErrors.fullName = errMsg
  }
  return newErrors
}

const ProfileForm = (props: ProfileFormProps) => {
  const classes = useStyles()
  const { onClose, onFormSave, onProfileImgSave, profile } = props
  const [form, setForm] = useState<Profile>({ ...profile })
  const [errors, setErrors] = useState<FormErrors>(validateProfileData(profile))
  const [showEditImg, setShowEditImg] = useState(false)
  const [image, setImage] = useState<string | ArrayBuffer | null>()
  const [loading, setLoading] = useState(false)
  const [errorOnInit] = useState(Object.keys(validateProfileData(profile)).length > 0)

  const handleProfileChange = (update: Object) => {
    const updated: Profile = { ...form, ...update }
    setForm(updated)
    validateData(updated)
  }

  const handleProfilePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (!files) {
      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
      setShowEditImg(true)
    }
    reader.readAsDataURL(files[0])
  }

  const validateData = (data: Profile): boolean => {
    const newErrors: FormErrors = validateProfileData(data)
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleProfileSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateData(form)) return
    setLoading(true)
    try {
      await onFormSave(form)
    } catch (error) {
      console.error('Error saving profile', error)
      props.onError('An error occurred when saving profile information. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handleProfileImgSave = (profileImage: string) => {
    setShowEditImg(false)
    onProfileImgSave(profileImage)
  }

  return !showEditImg ? (
    <>
      <div className={classes.topContainer}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <form onSubmit={handleProfileSave}>
        <div className={classes.contentContainer}>
          <div className={classes.form}>
            <Avatar className={classes.avatar} src={profile.picture} />
            <input
              onChange={handleProfilePictureChange}
              accept="image/*"
              className={classes.input}
              id="icon-button-file"
              type="file"
            />
            <label htmlFor="icon-button-file">
              <Button color="primary" variant="outlined" aria-label="upload picture" component="span">
                Edit Image
              </Button>
            </label>
            <Box m={2} />
            {errorOnInit && (
              <>
                <Alert severity="error" variant="standard">
                  It appears that some of your account details are incomplete. Please take a moment to update them.
                </Alert>
                <Box m={2} />
              </>
            )}
            <TextField
              name="fullName"
              label="Full name"
              variant="filled"
              placeholder="Firstname Middlename Lastname"
              required={true}
              defaultValue={profile.fullName}
              fullWidth
              onChange={(e) => handleProfileChange({ fullName: e.target.value })}
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            <Box m={2} />
            {/* TODO: remove when we have support for changing email */}
            {profile.email !== profile.phone + '@grieg.io' && (
              <>
                <TextField
                  name="userEmail"
                  label="E-mail"
                  variant="filled"
                  disabled
                  placeholder="E-mail"
                  required={true}
                  defaultValue={profile.email}
                  fullWidth
                  onChange={(e) => handleProfileChange({ userEmail: e.target.value })}
                />
                <Box m={2} />
              </>
            )}
            <TextField
              name="phone"
              label="Mobile number"
              variant="filled"
              placeholder="+4745678901"
              required={true}
              defaultValue={profile.phone}
              fullWidth
              disabled={profile.email === profile.phone + '@grieg.io'}
              onChange={(e) => handleProfileChange({ phone: e.target.value })}
            />
            {props.languages && (
              <>
                <Box m={2} />
                <FormControl required fullWidth variant="filled">
                  <InputLabel id="select-language-label">Language</InputLabel>
                  <Select
                    labelId="select-language-label"
                    id="select-language"
                    defaultValue={profile.locale}
                    label="Language"
                    required
                    onChange={(e) => handleProfileChange({ locale: e.target.value })}
                  >
                    {Object.entries(supportedLanguages).map(([langTag, langName]) => (
                      <MenuItem value={langTag} key={langTag}>
                        {langName}
                      </MenuItem>
                    ))}
                  </Select>
                  {form.locale && props.languages && props.languages.indexOf(form.locale as LanguageTag) === -1 ? (
                    <FormHelperText>
                      {supportedLanguages[form.locale]} is not supported in this application yet. If your preferred
                      language is not supported in the application we will fall back to using English.
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </FormControl>
              </>
            )}
          </div>
          <div className={classes.actions}>
            <Box display="flex" justifyContent="flex-end">
              <Box m={2} />
              <Stack direction="row" spacing={2}>
                <Button type="submit" color="primary" variant="contained" disabled={loading}>
                  {loading ? 'Please wait…' : 'Update profile'}
                </Button>
                <Button color="primary" onClick={props.onClose} variant="outlined" disabled={loading}>
                  Close
                </Button>
              </Stack>
            </Box>
          </div>
        </div>
      </form>
    </>
  ) : (
    <ProfileCropper
      selectedImage={image as string}
      onClose={() => setShowEditImg(false)}
      onSave={(profileImage) => handleProfileImgSave(profileImage)}
    />
  )
}

export default ProfileForm
