import { atom } from 'recoil'

export type Notification = {
  primary: string
  secondary?: string
  isRead?: boolean
  icon?: React.ReactNode
  to?: string
  callback?: () => void
}

export const notificationsAtom = atom<Notification[]>({
  key: 'krakenAppNotificationsState',
  default: [],
})
