import React, { ChangeEvent, useState } from 'react'

import { InputBase, InputBaseProps, styled } from '@mui/material'

const InputField = styled(InputBase)(() => ({
  padding: '0 9px',
  display: 'flex',
  fontSize: '14px',
  width: '100%',
}))

export type ILocalizedNumberInputProps = {
  value: number
  onChange: (value: number | null) => Promise<void>
  locale?: string
  precision?: number
  minValue?: number
  maxValue?: number

  autoFocus?: boolean
  label?: string
  error?: boolean
  required?: boolean
  disabled?: boolean

  className?: string
} & Pick<InputBaseProps, 'endAdornment'>

const NumberInput = ({
  value,
  onChange,
  locale = 'en-GB',
  precision = 5,
  minValue,
  maxValue,
  autoFocus,
  label,
  error,
  required,
  disabled,
  className,
  endAdornment,
}: ILocalizedNumberInputProps) => {
  const [internalValue, setInternalValue] = useState<string>(
    typeof value === 'number'
      ? value.toLocaleString(locale, { maximumFractionDigits: precision })
      : typeof value === 'string'
      ? Number(value).toLocaleString(locale, { maximumFractionDigits: precision })
      : ''
  )

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { number, sanitizedNumberString } = numberParser(event.target.value, locale, precision, minValue, maxValue)
    setInternalValue(sanitizedNumberString)
    onChange(number)
  }
  return (
    <InputField
      type="string"
      inputMode="decimal"
      onChange={handleOnChange}
      value={internalValue}
      error={error}
      required={required}
      placeholder={label}
      autoFocus={autoFocus}
      disabled={disabled}
      className={className}
      endAdornment={endAdornment}
    />
  )
}

interface INumberParser {
  number: number | null
  sanitizedNumberString: string // e.g. 1234.56 or 1..
  formattedNumberString: string // locale formatted number string e.g. 1 234,12
}

const numberParser = (
  input: number | string,
  locale: string,
  numberOfDecimals: number,
  minValue?: number,
  maxValue?: number
): INumberParser => {
  let sanitizedInput = sanitizeInput(input, numberOfDecimals, minValue !== undefined && minValue >= 0)
  const [integers, decimals] = sanitizedInput.split('.')

  const formattedNumberString =
    integers === '-'
      ? '-'
      : new Intl.NumberFormat(locale, {
          maximumSignificantDigits: 10 + numberOfDecimals,
          maximumFractionDigits: numberOfDecimals,
        }).format(decimals && decimals.length >= 0 ? Number(sanitizedInput) : Number(integers))

  const decimalSeparator = [',', '.'].includes((0.1).toLocaleString(locale)[1]) ? (0.1).toLocaleString(locale)[1] : ','
  let number = sanitizedInput === '-' || sanitizedInput === '' ? null : Number(sanitizedInput)
  if (number !== null) {
    if (maxValue !== undefined && number > maxValue) {
      number = maxValue
      sanitizedInput = number.toString()
    } else if (minValue !== undefined && number < minValue) {
      number = minValue
      sanitizedInput = number.toString()
    }
  }
  const sanitizedNumberString = sanitizedInput.replace('.', decimalSeparator)

  return { number, sanitizedNumberString, formattedNumberString }
}

const sanitizeInput = (input: number | string, precision?: number, noNegative?: boolean) => {
  const precitionRegex = new RegExp(`([,.]\\d{${precision}})\\d*`, 'g')
  let ret = String(input)
    .replace(/([^0-9,.-])/g, '') // removed all but [0-9], [,.] and [-]
    .replace(/(?!^)-/g, '') // removes all [-] that are not leading
    // Remove all but first occurence of decimal separator.
    .replace(',', '.')
    .replace('.', '%FD%') // replace first occurrence of decimal point (placeholder)
    .replace(/[,.]/g, '') // now replace all but first occurrence (refer to above)
    .replace('%FD%', '.') // otherwise, replace placeholder with period
    //
    .replace(precitionRegex, '$1')
  if (noNegative) ret = ret.replace('-', '')
  return ret
}

export default NumberInput
