import {
  Button,
  CardProps,
  CircularProgress,
  Container,
  Fade,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Card } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { ArrowLeft, ArrowRight } from '@griegconnect/krakentools-react-icons'
import { useNavigate } from 'react-router-dom'
import { Box, BoxProps } from '@mui/material'

type PageTitle = {
  title: string
  /**
   * Shows skeleton loading for title (use when title is not yet available)
   */
  loadingTitle?: boolean
  /** Back button (deprecated)
   * @deprecated since version 2.0
   *
   * @type {boolean}
   * @memberof PageTitle
   */
  showBackButton?: boolean
  backPath?: string
  buttons?: React.ReactNode
  /**
   * Use flexbox layout
   */
  flexbox?: boolean
}

type PageWrapper = PageTitle &
  Pick<BoxProps, 'className'> & {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    backPath?: string
    hideTitle?: boolean
    disablePadding?: boolean
    disablePaddingBottom?: boolean
    /**
     * Circular progress indicator: should be used when doing background processing like refetching etc (the user should still be able to use the page)
     * This progress indicator is faintly visible
     */
    refetching?: boolean
    refetchingOptions?: ProgressIndicatorProps['options']
    /**
     * Initial loading or something that needs to load before the user can do something useful.
     */
    loading?: boolean
    loadingOptions?: ProgressIndicatorProps['options']
    /**
     * Only supported if flexbox is true
     * @default sx: spacing(0.5), sm: spacing(1), md: spacing(2), lg: spacing(3)
     */
    gap?: number
  }

export const Wrapper = (props: PropsWithChildren<PageWrapper>) => {
  return (
    <Box className={props.className} display="flex" justifyContent="center" flex={1} minWidth={0} minHeight={0}>
      <Fade timeout={500} in={true}>
        <Container
          maxWidth={props.maxWidth ?? false}
          sx={{
            ...(props.disablePadding
              ? { padding: '0 !important' }
              : {
                  padding: (theme) => ({
                    xs: theme.spacing(2, 2, props.disablePaddingBottom ? 0 : 2),
                    sm: theme.spacing(4, 4, props.disablePaddingBottom ? 0 : 4),
                    md: theme.spacing(5, 5, props.disablePaddingBottom ? 0 : 5),
                    lg: theme.spacing(6, 6, props.disablePaddingBottom ? 0 : 6),
                  }),
                }),
            ...(props.flexbox
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  gap: (theme) => ({
                    xs: theme.spacing(1.5),
                    sm: theme.spacing(2),
                    md: theme.spacing(3),
                    lg: theme.spacing(3),
                  }),
                }
              : {}),
          }}
        >
          {!props.hideTitle && <Title {...props} flexbox={props.flexbox} />}
          {props.children}
        </Container>
      </Fade>
      {props.refetching !== undefined && (
        <ProgressIndicator loading={props.refetching} options={props.refetchingOptions} visibility="low" />
      )}
      {props.loading !== undefined && <ProgressIndicator loading={props.loading} options={props.loadingOptions} />}
    </Box>
  )
}

export const Title = (props: PageTitle) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(`(min-width: ${theme.breakpoints.values.lg}px)`)

  const onBack = () => {
    props.backPath ? navigate(props.backPath) : navigate(-1)
  }

  return (
    <Box display="flex" alignItems={'center'} flexDirection={'row'} mb={props.flexbox ? 0 : 3}>
      {props.showBackButton && (
        <ArrowLeft
          sx={{
            fontSize: 40,
            marginRight: (theme) => theme.spacing(1),
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={onBack}
        />
      )}
      <Box mr={2} maxWidth="100%">
        <Typography component="h1" variant={isMobile ? 'h1' : 'h2'}>
          {props.loadingTitle ? <Skeleton variant="text" sx={{ fontSize: '2.125rem' }} width={222} /> : props.title}
        </Typography>
      </Box>
      {props.buttons}
    </Box>
  )
}

interface ICardProps {
  children: React.ReactNode
  id?: string
  style?: React.CSSProperties
  className?: string
  padding?: number
  topLine?: boolean
  toplineColor?: string
  sx?: CardProps['sx']
}

export const Paper = (props: ICardProps) => {
  const theme = useTheme()
  const padding = props.padding ?? 3
  const topLine = props.topLine ?? false
  const topLineColor = props.toplineColor ?? theme.palette.primary.main

  return (
    <Card
      style={{
        padding: theme.spacing(padding),
        borderTop: !topLine ? 'none' : `2px solid ${topLineColor}`,
        ...props.style,
      }}
      sx={props.sx}
      id={props.id}
      elevation={1}
    >
      {props.children}
    </Card>
  )
}

type ErrorProps = {
  children?: React.ReactNode
  onRetry?: () => void
  retryText?: string
}

export const Error = (props: ErrorProps) => {
  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        {props.children ?? (
          <>
            <Typography gutterBottom>
              We're sorry, but we encountered an issue while trying to fetch the requested data. This may be due to a
              temporary problem with our servers or a connectivity issue on your end.
            </Typography>
            <Typography>
              If retrying doesn't work or if you continue to experience this issue, please contact our support team for
              further assistance.
            </Typography>
          </>
        )}
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            if (props.onRetry) {
              props.onRetry()
            } else {
              document.location.reload()
            }
          }}
        >
          {props.retryText ?? 'Retry'}
        </Button>
      </div>
    </>
  )
}

interface PaginationProps {
  page: number
  hasNext: boolean
  onChange: (page: number) => void
}

export const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = (props) => {
  if (!props.hasNext && props.page === 1) {
    return null
  }

  return (
    <Box display="flex" justifyContent="center" pt={2}>
      <Button
        variant="outlined"
        disabled={props.page <= 1}
        onClick={() => props.onChange(props.page - 1)}
        style={{ marginRight: 20 }}
        size="small"
        startIcon={<ArrowLeft />}
      >
        Previous page
      </Button>
      <Button
        size="small"
        variant="outlined"
        disabled={!props.hasNext}
        onClick={() => props.onChange(props.page + 1)}
        endIcon={<ArrowRight />}
      >
        Next page
      </Button>
    </Box>
  )
}

type ProgressIndicatorProps = {
  loading?: boolean
  options?: {
    offset: {
      x?: number
      y?: number
    }
  }
  visibility?: 'low' | 'high'
}

const ProgressIndicator = ({ loading, options, visibility = 'high' }: ProgressIndicatorProps) => {
  return (
    <CircularProgress
      sx={{
        position: 'fixed',
        bottom: (theme) => parseInt(theme.spacing(6)) + (options && options.offset.y ? options.offset.y : 0),
        right: (theme) => parseInt(theme.spacing(6)) + (options && options.offset.x ? options.offset.x : 0),
        zIndex: 9999,
        opacity: loading ? (visibility === 'high' ? 0.8 : 0.4) : 0,
        pointerEvents: 'none',
        transition: (t) =>
          t.transitions.create('all', {
            easing: loading ? t.transitions.easing.easeOut : t.transitions.easing.easeIn,
          }),
      }}
    />
  )
}
