import type { Color } from '@mui/material';

const color: Color = {
  50: '#E6EDF3',
  100: '#E1E9EE',
  200: '#DBE2E8',
  300: '#CED5DB',
  400: '#AEB5BB',
  500: '#91999E',
  600: '#6C7378',
  700: '#596066',
  800: '#3D444A',
  900: '#1E262B',
  A100: '#C4CBD1',
  A200: '#9CA4A9',
  A400: '#2C3339',
  A700: '#596066',
};

export default color;
