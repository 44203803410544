import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, ButtonProps } from '../button'
import { styled } from '@mui/material/styles'

const StyledDialog = styled(Dialog)<DialogProps>(() => ({
  '.MuiDialog-container': {
    alignItems: 'center',
    paddingTop: 0,
  },
}))

type AlertDialogProps = Pick<DialogProps, 'className' | 'maxWidth'> & {
  autoFocusNext?: boolean
  closeButtonColor?: ButtonProps['color']
  closeButtonName?: string
  disabled?: boolean
  disableNext?: boolean
  nextButtonColor?: ButtonProps['color']
  nextButtonName?: string
  open: boolean
  title: string
  children: React.ReactNode
  onClose?: () => void
  onComplete?: () => void
}

const AlertDialog = ({
  autoFocusNext,
  closeButtonColor = 'inherit',
  closeButtonName = 'Cancel',
  disabled,
  disableNext,
  nextButtonColor = 'primary',
  nextButtonName = 'OK',
  open,
  title,
  children,
  onClose,
  onComplete,
  className,
  maxWidth,
}: AlertDialogProps) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={maxWidth}
      className={className}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onClose && (
          <Button onClick={() => onClose()} color={closeButtonColor} disabled={disabled}>
            {closeButtonName}
          </Button>
        )}
        {onComplete && (
          <Button
            onClick={() => onComplete()}
            color={nextButtonColor}
            autoFocus={autoFocusNext}
            disabled={disabled || disableNext}
          >
            {nextButtonName}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  )
}

export default AlertDialog
