import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import React, { useCallback, useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'

import { useMapContext } from './MapContext'
import { fullscreenEnabledAtom } from './atoms/mapConfigAtoms'
import { FullscreenContext } from './FullscreenContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

export type FullscreenWrapper = {
  children?: React.ReactNode | React.ReactNodeArray
}

export const FullscreenWrapper = (props: FullscreenWrapper) => {
  const classes = useStyles()
  const { mapIdentifierSlug } = useMapContext()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [fullscreenEnabled, setFullscreenEnabled] = useRecoilState(fullscreenEnabledAtom(mapIdentifierSlug))

  useEffect(() => {
    const handler = (_event: Event) => {
      if (document?.fullscreenElement) {
        setFullscreenEnabled(true)
      } else {
        setFullscreenEnabled(false)
      }
    }
    document?.addEventListener('fullscreenchange', handler)
    return () => {
      document?.removeEventListener('fullscreenchange', handler)
    }
  }, [])

  const onFullscreenToggle = useCallback(() => {
    if (document?.fullscreenElement) {
      document?.exitFullscreen()
    } else {
      wrapperRef.current?.requestFullscreen().catch((e) => {
        console.error(e)
      })
    }
  }, [fullscreenEnabled])

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <FullscreenContext.Provider value={{ fullscreenToggle: onFullscreenToggle, isFullscreen: fullscreenEnabled }}>
        {props.children}
      </FullscreenContext.Provider>
    </div>
  )
}

export default FullscreenWrapper
