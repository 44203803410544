import { Box, Typography } from '@mui/material'
import { createRef, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

type TimeSliderProps = {
  time: number
  onChanged: (newDate: Date) => void
  onGoToTime?: (time: Date) => void
  onSkipToTime: (time: Date) => void
  onIsDragging: (isDragging: boolean) => void
  onRequestBufferFill: (time: number) => void
  multiplier: number
  bufferEndTime: Date | null
  bufferStartTime: Date | null
}
type TickProps = {
  x: number
  width: number
  label: string
  offset?: number
  type: 'label' | 'intermediate'
}
type BufferLineProps = {
  startX: number
  endX: number
  offset?: number
  potentialSize: number
}

// Define the range for the multiplier
const minMultiplier = 1
const maxMultiplier = 100

// Define the corresponding buffer threshold values
const minThreshold = 500
const maxThreshold = 200

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 62,
      marginBottom: 32,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.04)' : 'rgba(0,0,0,0.04)',
    },
    tick: {
      position: 'absolute',
      bottom: 0 + 8,
      width: 3,
      height: 16,
    },
    intermediateTick: {
      position: 'absolute',
      borderLeft: `2px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.16)' : 'rgba(0,0,0,0.16)'}`,
      height: 8,
      bottom: 0,
    },
    tickColumn: {
      position: 'relative',
      borderLeft: `2px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.16)' : 'rgba(0,0,0,0.16)'}`,
      height: 16,
    },
    tickLabel: {
      position: 'absolute',
      bottom: 24,
      width: 'auto',
      left: -15,
      color: theme.palette.text.disabled,
    },
    hrLine: {
      position: 'absolute',
      bottom: 34,
      left: 0,
      width: '100%',
      border: '1px dashed rgba(255,255,255,0.2)',
    },
    center: {
      position: 'absolute',
      width: 200,
      bottom: 50,
      left: 'calc(50% - 100px)',
      pointerEvents: 'none',
    },
    centerLine: {
      left: 'calc(50% - 1px)',
      pointerEvents: 'none',
      position: 'absolute',
      borderLeft: `2px solid ${theme.palette.mode === 'dark' ? 'white' : 'rgba(0,0,0,0.5)'}`,
      height: 20,
      maxWidth: 1,
      bottom: 8,
      zIndex: 2000,
    },
    bufferZone: {
      position: 'absolute',
      bottom: 14,
      left: 0,
      width: '100%',
    },
    bufferLine: {
      backgroundColor: '#84d5ff62',
      borderRadius: 2,
      height: 8,
    },
    bufferPotentialLine: {
      position: 'absolute',
      bottom: 14,
      height: 8,
      backgroundColor: 'rgba(255,255,255,0.08)',
      borderRadius: 2,
    },
    nopointerevents: {
      pointerEvents: 'none',
      userSelect: 'none',
    },
  })
)

const BufferLine = (props: BufferLineProps) => {
  const classes = useStyles()
  return (
    <>
      <div
        className={classes.bufferZone}
        style={{ left: props.startX + (props.offset || 0), width: props.endX - props.startX }}
      >
        <div className={classes.bufferLine}></div>
      </div>
      <div
        className={classes.bufferPotentialLine}
        style={{ left: props.startX - props.potentialSize / 2, width: props.endX - props.startX + props.potentialSize }}
      ></div>
    </>
  )
}

const Tick = (props: TickProps) => {
  const classes = useStyles()
  return (
    <div style={{ left: (props.x + (props.offset || 0)) % props.width }} className={classes.tick}>
      <Typography variant="caption" className={classes.tickLabel}>
        {props.label}
      </Typography>
      <div className={props.type === 'label' ? classes.tickColumn : classes.intermediateTick}></div>
    </div>
  )
}

const TimeSlider = (props: TimeSliderProps) => {
  const classes = useStyles()
  const isCapturingRef = useRef<boolean>(false)
  const [origin, setOrigin] = useState<[number, number]>([0, 0])
  const [currentTimeOffset, setCurrentTimeOffset] = useState<number>(0)
  const [ref] = useState<React.RefObject<HTMLDivElement>>(createRef<HTMLDivElement>())
  const [elementWidth, setElementWidth] = useState<number>(0)
  const timeRef = useRef<number>(props.time)
  const [isBufferRequested, setIsBufferRequested] = useState<boolean>(false)

  const baseTimeRange = 20 * 60 * 1000 * props.multiplier
  const msPerPixel = baseTimeRange / elementWidth
  const pixelsPerSecond = elementWidth / baseTimeRange
  const start = timeRef.current - baseTimeRange / 2
  const tickInterval = 60 * 4 * 1000 * props.multiplier
  const bufferStart = props.bufferStartTime ? pixelsPerSecond * (props.bufferStartTime.getTime() - start) : null
  const bufferEnd = props.bufferEndTime ? pixelsPerSecond * (props.bufferEndTime.getTime() - start) : null

  const bufferThresholdPx =
    maxThreshold +
    (minThreshold - maxThreshold) * ((maxMultiplier - props.multiplier) / (maxMultiplier - minMultiplier))

  const renderTicks = () => {
    const ticks: JSX.Element[] = []
    const tickRoundedStart = start - (start % tickInterval)

    for (var t = 0; t < baseTimeRange + tickInterval; t += tickInterval) {
      let tickTime = tickRoundedStart + t
      ticks.push(
        <Tick
          width={elementWidth}
          x={(t - (start % tickInterval)) * pixelsPerSecond}
          key={'tick_' + t}
          label={DateTime.fromMillis(tickTime).toFormat('HH:mm')}
          type="label"
        />
      )
    }
    for (var tt = 0; tt < baseTimeRange + tickInterval; tt += tickInterval / 10) {
      if (tt % tickInterval) {
        ticks.push(
          <Tick
            width={elementWidth}
            x={(tt - (start % tickInterval)) * pixelsPerSecond}
            key={'tick_' + tt}
            label={''}
            type="intermediate"
          />
        )
      }
    }
    return ticks
  }

  const handlePointerDown = (event: PointerEvent) => {
    if (!isBufferRequested) {
      setOrigin([event.clientX, event.clientY])
      setCurrentTimeOffset(timeRef.current)
      isCapturingRef.current = true
      props.onIsDragging(true)
    }
  }

  const handlePointerUp = (event: PointerEvent) => {
    if (isCapturingRef.current) {
      props.onIsDragging(false)
      props.onChanged(new Date(timeRef.current))
      const bufferThresholdMs = bufferThresholdPx * msPerPixel
      const timeIsBeforeBuffer = timeRef.current < (props.bufferStartTime?.getTime() ?? 0) - bufferThresholdMs / 2
      const timeIsAfterBuffer = timeRef.current > (props.bufferEndTime?.getTime() ?? 0) + bufferThresholdMs / 2

      if (timeIsBeforeBuffer || timeIsAfterBuffer) {
        props.onSkipToTime(new Date(timeRef.current))
      } else {
        setIsBufferRequested(true)
        props.onRequestBufferFill(timeRef.current)
      }
      isCapturingRef.current = false
    }
  }

  const handlePointerMove = (event: PointerEvent) => {
    if (isCapturingRef.current && ref.current) {
      const dX = origin[0] - event.clientX
      window.requestAnimationFrame(() => {
        const newTime = currentTimeOffset + msPerPixel * dX
        if (newTime < Date.now()) {
          timeRef.current = newTime
          props.onGoToTime?.(new Date(newTime))
        }
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (ref.current?.clientWidth) {
        setElementWidth(ref.current.clientWidth || 0)
      }
    }, 500)
  }, [ref])

  useEffect(() => {
    const element = ref.current
    if (element) {
      element.addEventListener('pointerdown', handlePointerDown)
    }

    return () => {
      if (element) {
        element.removeEventListener('pointerdown', handlePointerDown)
      }
    }
  }, [])

  useEffect(() => {
    if (isCapturingRef.current) {
      document.addEventListener('pointermove', handlePointerMove)
      document.addEventListener('pointerup', handlePointerUp)
      document.addEventListener('pointercancel', handlePointerUp)
    } else {
      document.removeEventListener('pointermove', handlePointerMove)
      document.removeEventListener('pointerup', handlePointerUp)
      document.removeEventListener('pointercancel', handlePointerUp)
    }

    return () => {
      document.removeEventListener('pointermove', handlePointerMove)
      document.removeEventListener('pointerup', handlePointerUp)
      document.removeEventListener('pointercancel', handlePointerUp)
    }
  }, [isCapturingRef.current])

  useEffect(() => {
    if (elementWidth) {
      // We dont want to update time while dragging.
      if (!isCapturingRef.current) {
        timeRef.current = props.time
      }
    }
  }, [props.time]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsBufferRequested(false)
  }, [props.bufferStartTime, props.bufferEndTime])

  return (
    <Box
      component="div"
      className={classes.root}
      ref={ref}
      sx={{ cursor: isCapturingRef.current ? 'grabbing' : 'grab' }}
    >
      <div className={classes.center}></div>
      <div className={classes.nopointerevents}>
        <div className={classes.centerLine}></div>
        {(bufferStart && bufferEnd && (
          <BufferLine potentialSize={bufferThresholdPx} startX={bufferStart} endX={bufferEnd} />
        )) ||
          null}

        {(elementWidth && renderTicks()) || null}
      </div>
    </Box>
  )
}
export default TimeSlider
