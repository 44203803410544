import { PaletteMode } from '@mui/material'

import { ReactComponent as AdminDark } from '../assets/Admin/Dark/Horizontal 1.svg'
import { ReactComponent as AdminLight } from '../assets/Admin/Light/Horizontal 1.svg'
import { ReactComponent as ExploreDark } from '../assets/Explore/Dark/Horizontal 1.svg'
import { ReactComponent as ExploreLight } from '../assets/Explore/Light/Horizontal 1.svg'
import { ReactComponent as GoDark } from '../assets/Port Go/Dark/Horizontal 1.svg'
import { ReactComponent as GoLight } from '../assets/Port Go/Light/Horizontal 1.svg'
import { ReactComponent as HaulierDark } from '../assets/Haulier/Dark/Horizontal 1.svg'
import { ReactComponent as HaulierLight } from '../assets/Haulier/Light/Horizontal 1.svg'
import { ReactComponent as KrakenDark } from '../assets/Kraken Tools/Dark/Horizontal 1.svg'
import { ReactComponent as KrakenLight } from '../assets/Kraken Tools/Light/Horizontal 1.svg'
import { ReactComponent as ManageDark } from '../assets/Manage/Dark/Horizontal 1.svg'
import { ReactComponent as ManageLight } from '../assets/Manage/Light/Horizontal 1.svg'
import { ReactComponent as MonitorDark } from '../assets/Monitor/Dark/Horizontal 1.svg'
import { ReactComponent as MonitorLight } from '../assets/Monitor/Light/Horizontal 1.svg'
import { ReactComponent as PlaceholderDark } from '../assets/Placeholder/Dark/Horizontal 1.svg'
import { ReactComponent as PlaceholderLight } from '../assets/Placeholder/Light/Horizontal 1.svg'
import { ReactComponent as PlanDark } from '../assets/Plan/Dark/Horizontal 1.svg'
import { ReactComponent as PlanLight } from '../assets/Plan/Light/Horizontal 1.svg'
import { ReactComponent as PortDark } from '../assets/Port/Dark/Horizontal 1.svg'
import { ReactComponent as PortLight } from '../assets/Port/Light/Horizontal 1.svg'
import { ReactComponent as PredictDark } from '../assets/Predict/Dark/Horizontal 1.svg'
import { ReactComponent as PredictLight } from '../assets/Predict/Light/Horizontal 1.svg'
import { ReactComponent as SecurityDark } from '../assets/Security/Dark/Horizontal 1.svg'
import { ReactComponent as SecurityLight } from '../assets/Security/Light/Horizontal 1.svg'
import { ReactComponent as ShiplogDark } from '../assets/Shiplog/Dark/Horizontal 1.svg'
import { ReactComponent as ShiplogLight } from '../assets/Shiplog/Light/Horizontal 1.svg'
import { ReactComponent as TerminalDark } from '../assets/Terminal/Dark/Horizontal 1.svg'
import { ReactComponent as TerminalLight } from '../assets/Terminal/Light/Horizontal 1.svg'
import { ReactComponent as TrafficDark } from '../assets/Traffic/Dark/Horizontal 1.svg'
import { ReactComponent as TrafficLight } from '../assets/Traffic/Light/Horizontal 1.svg'
import { ApplicationRef } from '../ApplicationOptionsContext'
import { CDN_CACHE_KILLER } from '../ui/SplashLoader'

export function getApplicationLogo(args: {
  appRef: ApplicationRef
  palette: PaletteMode
  type: 'component'
  designHostUrl?: string
  style?: React.CSSProperties
}): JSX.Element

export function getApplicationLogo(args: {
  appRef: ApplicationRef
  palette: PaletteMode
  type: 'url'
  designHostUrl?: string
}): string

export function getApplicationLogo({
  appRef,
  palette,
  type = 'component',
  designHostUrl,
  style,
}: {
  appRef: ApplicationRef
  palette: PaletteMode
  type: 'component' | 'url'
  designHostUrl?: string
  style?: React.CSSProperties
}) {
  const applicationLogoUrl = (pathPart: string) => {
    return `${designHostUrl}/design/default/bundle/logo/${pathPart}/${
      palette === 'dark' ? 'Dark' : 'Light'
    }/Symbol 1.svg?c=${CDN_CACHE_KILLER}`
  }
  switch (type) {
    case 'component':
      switch (appRef) {
        case 'admin':
          return palette === 'dark' ? <AdminDark style={style} /> : <AdminLight style={style} />
        case 'explore':
          return palette === 'dark' ? <ExploreDark style={style} /> : <ExploreLight style={style} />
        case 'go':
          return palette === 'dark' ? <GoDark style={style} /> : <GoLight style={style} />
        case 'haulier':
          return palette === 'dark' ? <HaulierDark style={style} /> : <HaulierLight style={style} />
        case 'manage':
          return palette === 'dark' ? <ManageDark style={style} /> : <ManageLight style={style} />
        case 'monitor':
          return palette === 'dark' ? <MonitorDark style={style} /> : <MonitorLight style={style} />
        case 'plan':
          return palette === 'dark' ? <PlanDark style={style} /> : <PlanLight style={style} />
        case 'port':
          return palette === 'dark' ? <PortDark style={style} /> : <PortLight style={style} />
        case 'predict':
          return palette === 'dark' ? <PredictDark style={style} /> : <PredictLight style={style} />
        case 'security':
          return palette === 'dark' ? <SecurityDark style={style} /> : <SecurityLight style={style} />
        case 'shiplog':
          return palette === 'dark' ? <ShiplogDark style={style} /> : <ShiplogLight style={style} />
        case 'terminal':
          return palette === 'dark' ? <TerminalDark style={style} /> : <TerminalLight style={style} />
        case 'traffic':
          return palette === 'dark' ? <TrafficDark style={style} /> : <TrafficLight style={style} />
        case 'kraken':
          return palette === 'dark' ? <KrakenDark style={style} /> : <KrakenLight style={style} />
        case 'placeholder':
        default:
          return palette === 'dark' ? <PlaceholderDark style={style} /> : <PlaceholderLight style={style} />
      }
    case 'url':
    default:
      switch (appRef) {
        case 'admin':
          return applicationLogoUrl('Admin')
        case 'explore':
          return applicationLogoUrl('Explore')
        case 'go':
          return applicationLogoUrl('Port Go')
        case 'haulier':
          return applicationLogoUrl('Haulier')
        case 'manage':
          return applicationLogoUrl('Manage')
        case 'monitor':
          return applicationLogoUrl('Monitor')
        case 'plan':
          return applicationLogoUrl('Plan')
        case 'port':
          return applicationLogoUrl('Port')
        case 'predict':
          return applicationLogoUrl('Predict')
        case 'security':
          return applicationLogoUrl('Security')
        case 'shiplog':
          return applicationLogoUrl('Shiplog')
        case 'terminal':
          return applicationLogoUrl('Terminal')
        case 'traffic':
          return applicationLogoUrl('Traffic')
        case 'kraken':
          return applicationLogoUrl('Grieg Connect')
        case 'placeholder':
        default:
          return applicationLogoUrl('Placeholder')
      }
  }
}
