import { ILonLat, IStyleTemplate, colorPalette, KMapLayer } from '@griegconnect/krakentools-kmap'
import { Geometry } from 'geojson'
import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil'
import { MonitorMap } from '../apis/MonitorApi'
import { CONSTANTS } from '../constants'

import { getFromLocalStorage, KrakenMapStorageState, setToLocalStorage } from '../utils/localStorageStore'
import { activeAssetLabelsSelector, activeAssetLayersSelector, assetLayersSelector } from './assetViewAtoms'
import { activeLiveLabelsAtom, activeLiveLayersSelector, liveLayersAtom } from './liveViewAtoms'
import { LayerMenuItem } from './types/LayerMenuItem'
import { MapIdentifierType } from './types/MapIdentifierType'
import { MeasureToolConfig } from './types/MeasureToolConfig'

/**
 *
 * Initialization flags
 *
 */

export const useLocalStorageAtom = atomFamily<boolean, MapIdentifierType>({
  key: 'useLocalStorageState',
  default: true,
})

/**
 *
 * Map states
 *
 */

export const mapBottomControlMargin = atomFamily<number, string>({
  key: 'mapBottomControlMargin',
  default: 0,
})

export const mapZoomLevelDefaultAtom = atom<number>({
  key: 'mapZoomLevelDefaultState',
  default: 4.905413880186123,
})

export const colorPaletteAtom = atomFamily<colorPalette, MapIdentifierType>({
  key: 'mapColorPaletteState',
  default: 'light',
})

export const mapRotationAtom = atomFamily<number, MapIdentifierType>({
  key: 'mapRotationState',
  default: 0,
})

export const mapRotationSelector = selectorFamily<number, MapIdentifierType>({
  key: 'mapRotationSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const rotation = get(mapRotationAtom(mapIdentifier))
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (useLocalStorage) {
      const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
      if (state?.rotation) {
        return state.rotation
      } else {
        return rotation
      }
    } else {
      return rotation
    }
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, newRotation) => {
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (newRotation instanceof DefaultValue) {
      if (useLocalStorage) {
        let existing = useLocalStorage ? getFromLocalStorage<KrakenMapStorageState>(mapIdentifier) : null
        if (existing?.rotation) {
          existing.rotation = undefined
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        }
      }
      reset(mapRotationAtom(mapIdentifier))
    } else {
      const normalizedRotation = ((newRotation % 360) + 360) % 360
      if (useLocalStorage) {
        let existing = useLocalStorage ? getFromLocalStorage<KrakenMapStorageState>(mapIdentifier) : null
        if (existing) {
          existing.rotation = normalizedRotation
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        } else {
          const newState: KrakenMapStorageState = {
            rotation: normalizedRotation,
          }
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
        }
      }
      set(mapRotationAtom(mapIdentifier), normalizedRotation)
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})


export const mapZoomLevelAtom = atomFamily<number, MapIdentifierType>({
  key: 'mapZoomLevelState',
  default: selector({
    key: 'mapZoomLevelDefaultSelector',
    get: ({ get }) => {
      return get(mapZoomLevelDefaultAtom)
    },
  }),
})

export const activeMapIdAtom = atomFamily<string | undefined, MapIdentifierType>({
  key: 'activeMapIdState',
  default: selector({
    key: 'activeMapIdDefaultSelector',
    get: ({ get }) => undefined,
  }),
})

export const activeMapIdSelector = selectorFamily<string | undefined, MapIdentifierType>({
  key: 'activeMapIdSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const activeMapId = get(activeMapIdAtom(mapIdentifier))
    const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
    if (state?.activeMapId) {
      return state.activeMapId
    } else {
      return activeMapId
    }
  },
  set: (mapIdentifier: MapIdentifierType) => ({ set }, newActiveMapId) => {
    const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
    if (!(newActiveMapId instanceof DefaultValue)) {
      if (state) {
        state.activeMapId = newActiveMapId
        setToLocalStorage<KrakenMapStorageState>(mapIdentifier, state)
      } else {
        setToLocalStorage<KrakenMapStorageState>(mapIdentifier, {
          activeMapId: newActiveMapId,
        })
      }
    }
    set(activeMapIdAtom(mapIdentifier), newActiveMapId)
  },
})

export const mapZoomLevelSelector = selectorFamily<number, MapIdentifierType>({
  key: 'mapZoomLevelSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const zoom = get(mapZoomLevelAtom(mapIdentifier))
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (useLocalStorage) {
      const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
      if (state?.zoomLevel) {
        return state.zoomLevel
      } else {
        return zoom
      }
    } else {
      return zoom
    }
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, newZoomLevel) => {
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (newZoomLevel instanceof DefaultValue) {
      if (useLocalStorage) {
        let existing = useLocalStorage ? getFromLocalStorage<KrakenMapStorageState>(mapIdentifier) : null
        if (existing?.zoomLevel) {
          existing.zoomLevel = undefined
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        }
      }
      reset(mapZoomLevelAtom(mapIdentifier))
    } else {
      const restrictedZoom = Math.min(Math.max(newZoomLevel, CONSTANTS.MIN_ZOOM), CONSTANTS.MAX_ZOOM)
      if (useLocalStorage) {
        let existing = useLocalStorage ? getFromLocalStorage<KrakenMapStorageState>(mapIdentifier) : null
        if (existing) {
          existing.zoomLevel = restrictedZoom
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        } else {
          const newState: KrakenMapStorageState = {
            zoomLevel: restrictedZoom,
          }
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
        }
      }
      set(mapZoomLevelAtom(mapIdentifier), restrictedZoom)
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const mapCenterDefaultAtom = atom<ILonLat>({
  key: 'mapCenterDefaultState',
  default: { lat: 59.09386537761213, lon: 8.077527771968162 },
})

export const mapCenterAtom = atomFamily<ILonLat, MapIdentifierType>({
  key: 'mapCenterState',
  default: selector({
    key: 'mapCenterDefaultSelector',
    get: ({ get }) => {
      return get(mapCenterDefaultAtom)
    },
  }),
})

export const mapCenterSelector = selectorFamily<ILonLat, MapIdentifierType>({
  key: 'mapCenterSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const center = get(mapCenterAtom(mapIdentifier))
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (useLocalStorage) {
      const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
      if (state?.center) {
        const lonlat: ILonLat = {
          lon: state.center.lon,
          lat: state.center.lat,
        }
        return lonlat
      } else {
        return center
      }
    } else {
      return center
    }
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, newCenter) => {
    const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
    if (newCenter instanceof DefaultValue) {
      if (useLocalStorage) {
        let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
        if (existing?.center) {
          existing.center = undefined
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        }
      }
      reset(mapCenterAtom(mapIdentifier))
    } else {
      if (useLocalStorage) {
        let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
        if (existing) {
          existing.center = {
            lon: newCenter.lon,
            lat: newCenter.lat,
          }
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
        } else {
          const newState: KrakenMapStorageState = {
            center: {
              lon: newCenter.lon,
              lat: newCenter.lat,
            },
          }
          setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
        }
      }
      set(mapCenterAtom(mapIdentifier), newCenter)
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const activeMapAtom = atomFamily<MonitorMap | null, MapIdentifierType>({
  key: 'activeBaseMaps',
  default: null,
})

export const activeBaseMapsAtom = atomFamily<KMapLayer[], MapIdentifierType>({
  key: 'activeBaseMaps',
  default: [],
})

export const mapBackgroundColorAtom = atomFamily<string | null, MapIdentifierType>({
  key: 'mapBackgroundColor',
  default: null,
})

export const basemapOrderAtom = atomFamily<string[], MapIdentifierType>({
  key: 'basemapOrderState',
  default: [],
})

export const styleTemplatesAtom = atomFamily<IStyleTemplate[], MapIdentifierType>({
  key: 'styleTemplatesState',
  default: (mapIdentifier: MapIdentifierType) => [],
})

export const fullscreenEnabledAtom = atomFamily<boolean, MapIdentifierType>({
  key: 'fullscreenEnabledState',
  default: false,
})

export const gpsPositionEnabledAtom = atomFamily<boolean, MapIdentifierType>({
  key: 'gpsPositionEnabledState',
  default: false,
})

export const focusedGeometryAtom = atomFamily<Geometry | null, MapIdentifierType>({
  key: 'focusedGeometryAtom',
  default: null,
})

export const measureToolAtom = atomFamily<MeasureToolConfig, MapIdentifierType>({
  key: 'measureToolAtom',
  default: {
    shouldClear: false,
    enableSnap: false,
  },
})

/* Layer state configuration */

export const visibleLayersSelector = selectorFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'visibleLayersSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const visibleAssetLayers = get(activeAssetLayersSelector(mapIdentifier))
    const visibleLiveLayers = get(activeLiveLayersSelector(mapIdentifier))
    return visibleAssetLayers.concat(visibleLiveLayers)
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, activeLayers) => {
    if (activeLayers instanceof DefaultValue) {
      reset(activeAssetLayersSelector(mapIdentifier))
      reset(activeLiveLayersSelector(mapIdentifier))
    } else {
      // Assets
      const availableAssetLayers = get(assetLayersSelector(mapIdentifier))
      const newAssetLayers = activeLayers.filter((al) =>
        availableAssetLayers.find((aal) => aal.key === al) ? true : false
      )
      set(activeAssetLayersSelector(mapIdentifier), newAssetLayers)
      // Live
      const availableLiveLayers = get(liveLayersAtom(mapIdentifier))
      const newLiveLayers = activeLayers.filter((al) =>
        availableLiveLayers.find((aal) => aal.key === al) ? true : false
      )
      set(activeLiveLayersSelector(mapIdentifier), newLiveLayers)
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const allLayersVisibleSelector = selectorFamily<boolean, MapIdentifierType>({
  key: 'allLayersVisibleSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const allVisibleLayers = get(visibleLayersSelector(mapIdentifier))
    // Assets
    const isAllAssetLayers = get(assetLayersSelector(mapIdentifier)).every((l) => allVisibleLayers.includes(l.key))
    // Live
    const isAllLiveLayers = get(liveLayersAtom(mapIdentifier)).every((l) => allVisibleLayers.includes(l.key))

    return isAllAssetLayers && isAllLiveLayers
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, allLayersVisible) => {
    if (allLayersVisible instanceof DefaultValue) {
      reset(activeAssetLayersSelector(mapIdentifier))
      reset(activeLiveLayersSelector(mapIdentifier))
    } else {
      if (allLayersVisible === false) {
        set(visibleLayersSelector(mapIdentifier), [])
      } else {
        // Assets
        const availableAssetLayers = get(assetLayersSelector(mapIdentifier)).map((l) => l.key)
        // Live
        const availableLiveLayers = get(liveLayersAtom(mapIdentifier)).map((l) => l.key)
        set(visibleLayersSelector(mapIdentifier), availableAssetLayers.concat(availableLiveLayers))
      }
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const visibleLabelsSelector = selectorFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'visibleLabelsSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const visibleAssetLabels = get(activeAssetLabelsSelector(mapIdentifier))
    const visibleLiveLabels = get(activeLiveLabelsAtom(mapIdentifier))
    return visibleAssetLabels.concat(visibleLiveLabels)
  },
  set: (mapIdentifier: MapIdentifierType) => ({ get, set, reset }, activeLabels) => {
    if (activeLabels instanceof DefaultValue) {
      reset(activeAssetLabelsSelector(mapIdentifier))
      reset(activeLiveLabelsAtom(mapIdentifier))
    } else {
      // Assets
      const availableAssetLabels = get(assetLayersSelector(mapIdentifier))
      const newAssetLabels = activeLabels.filter((al) =>
        availableAssetLabels.find((aal) => aal.key === al) ? true : false
      )
      set(activeAssetLabelsSelector(mapIdentifier), newAssetLabels)
      // Live
      const availableLiveLabels = get(liveLayersAtom(mapIdentifier))
      const newLiveLabels = activeLabels.filter((al) =>
        availableLiveLabels.find((aal) => aal.key === al) ? true : false
      )
      set(activeLiveLabelsAtom(mapIdentifier), newLiveLabels)
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const availableLayersSelector = selectorFamily<LayerMenuItem[], MapIdentifierType>({
  key: 'availableLayersSelector',
  get: (mapIdentifier: MapIdentifierType) => ({ get }) => {
    const assetLayers = get(assetLayersSelector(mapIdentifier))
    const liveLayers = get(liveLayersAtom(mapIdentifier))
    return assetLayers.concat(liveLayers)
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})
