import { IViewParameters } from '@griegconnect/krakentools-kmap'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { editAssetSelector } from './atoms/assetViewAtoms'

import {
  focusedGeometryAtom,
  gpsPositionEnabledAtom,
  mapBackgroundColorAtom,
  mapCenterSelector,
  mapRotationSelector,
  mapZoomLevelSelector,
  measureToolAtom,
  styleTemplatesAtom,
} from './atoms/mapConfigAtoms'
import { useMapContext } from './MapContext'
import { Debouncer } from './utils/Debouncer'
import { activeBaseMapsAtom, colorPaletteAtom } from './atoms/mapConfigAtoms'
import * as proj from 'ol/proj'

const resizeBouncer = new Debouncer()

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    map: {
      height: 'inherit',
      width: 'inherit',
      backgroundColor: theme.palette.background.default,
    },
    mapInner: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      '& > *': { pointerEvents: 'auto' },
    },
  })
)

export const Map: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { mapIdentifierSlug, kmapInstance: instance, colorPalette } = useMapContext()
  const { width, height, ref } = useResizeDetector()
  const theme = useTheme()
  const [zoom, setZoom] = useRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const [center, setCenter] = useRecoilState(mapCenterSelector(mapIdentifierSlug))
  const [rotation, setRotation] = useRecoilState(mapRotationSelector(mapIdentifierSlug))
  const [measureToolConfig, setMeasureToolConfig] = useRecoilState(measureToolAtom(mapIdentifierSlug))
  const gpsEnabled = useRecoilValue(gpsPositionEnabledAtom(mapIdentifierSlug))
  const focusedGeometry = useRecoilValue(focusedGeometryAtom(mapIdentifierSlug))
  const editAsset = useRecoilValue(editAssetSelector(mapIdentifierSlug))
  const classes = useStyles()
  const styleTemplates = useRecoilValue(styleTemplatesAtom(mapIdentifierSlug))
  const activeBaseMaps = useRecoilValue(activeBaseMapsAtom(mapIdentifierSlug))
  const mapBackgroundColor = useRecoilValue(mapBackgroundColorAtom(mapIdentifierSlug))
  const setMapColorPalette = useSetRecoilState(colorPaletteAtom(mapIdentifierSlug))

  // Initialize map
  useEffect(() => {
    instance.initMap()
    return () => {
      instance.destroy()
    }
  }, [instance])

  // Handle map events
  useEffect(() => {
    const moveListener = instance.on('moveend', (viewParams: IViewParameters) => {
      if (viewParams.zoom) {
        setZoom(viewParams.zoom)
      }
      if (viewParams.rotation !== undefined && viewParams.rotation !== null) {
        setRotation(viewParams.rotation)
      }
      if (viewParams.center) {
        if (viewParams.center.lon !== center.lon || viewParams.center.lat !== center.lat) {
          setCenter(viewParams.center)
        }
      }
    })
    return () => {
      instance.un(moveListener)
    }
  }, [instance, setZoom, setCenter, setRotation])

  useEffect(() => {
    resizeBouncer.bounce(() => instance.updateSize(), 100)
  }, [instance, width, height])

  useEffect(() => {
    if (editAsset === null) {
      // Prevent race-condition between editFeature panning & manual movements

      instance.setZoom(zoom)
    }
  }, [instance, editAsset, zoom])

  useEffect(() => {
    if (editAsset === null) {
      // Prevent race-condition between editFeature panning & manual movements
      const currentCenter = instance.getView().getCenter()
      if (currentCenter) {
        const currentCenterLonLat = proj.transform(currentCenter, 'EPSG:3857', 'EPSG:4326')
        if (currentCenterLonLat[0] !== center.lon || currentCenterLonLat[1] !== center.lat) {
          instance.setCenter(center)
        }
      }
    }
  }, [instance, editAsset, center])

  useEffect(() => {
    instance.enableNavigation(gpsEnabled)
  }, [instance, gpsEnabled])

  useEffect(() => {
    instance.setStyleTemplates(styleTemplates)
    setMapColorPalette(theme.palette.mode)
  }, [styleTemplates, instance, theme])

  useEffect(() => {
    if (instance && focusedGeometry) {
      instance.gotoGeometry(focusedGeometry)
    }
  }, [instance, focusedGeometry])

  useEffect(() => {
    const maps =
      mapBackgroundColor !== null
        ? activeBaseMaps.map((m) => ({ ...m, backgroundColor: 'rgba(0,0,0,0)' }))
        : activeBaseMaps
    instance.setActiveBaseMaps(maps)
    instance.setMapBackgroundColor(mapBackgroundColor || 'unset')
  }, [instance, activeBaseMaps, mapBackgroundColor])

  useEffect(() => {
    if (measureToolConfig.activeTool) {
      switch (measureToolConfig.activeTool) {
        case 'measureLength':
          instance.measureTool.startLengthMeasurement().then(() => {
            setMeasureToolConfig((prev) => ({ ...prev, activeTool: undefined }))
          })
          break
        case 'measureArea':
          instance.measureTool.startAreaMeasurement().then(() => {
            setMeasureToolConfig((prev) => ({ ...prev, activeTool: undefined }))
          })
          break
        case 'measureAngle':
          instance.measureTool.startAngleMeasurement().then(() => {
            setMeasureToolConfig((prev) => ({ ...prev, activeTool: undefined }))
          })
          break
        case 'measureCoordinate':
          instance.measureTool.startPointMeasurement().then(() => {
            setMeasureToolConfig((prev) => ({ ...prev, activeTool: undefined }))
          })
          break
      }
    }
  }, [instance, measureToolConfig.activeTool])

  useEffect(() => {
    if (measureToolConfig?.shouldClear) {
      instance.measureTool.stop()
      setMeasureToolConfig({ ...measureToolConfig, activeTool: undefined, shouldClear: false })
    }
  }, [measureToolConfig.shouldClear])

  useEffect(() => {
    instance.enableSnap(measureToolConfig.enableSnap)
  }, [measureToolConfig.enableSnap])

  useEffect(() => {
    if (instance) {
      setMapColorPalette(colorPalette)
      instance.setColorPalette(colorPalette)
    }
  }, [colorPalette, instance])

  return (
    <div data-testid="krakenmap-map" id={instance.mapElementId} ref={ref} className={classes.map}>
      <div className={classes.mapInner}>{children}</div>
    </div>
  )
}

export default Map
