import React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

type Props = {
  timestamp: string | Date
  variant?: 'exact' | 'relative' | 'distance'
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    name: {
      flex: '1 1 auto',
      marginRight: theme.spacing(1),
    },
    avatar: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  })
)

const CommentTimestamp = (props: Props) => {
  const { variant = 'relative', timestamp } = props
  const classes = useStyles()

  let dateObj = DateTime.fromJSDate(timestamp instanceof Date ? timestamp : DateTime.fromISO(timestamp).toJSDate())
  let formattedDate: string | null
  switch (variant) {
    case 'relative':
      formattedDate = dateObj.toRelativeCalendar()
      break
    case 'exact':
      formattedDate = dateObj.toLocaleString(DateTime.DATETIME_SHORT)
      break
    case 'distance':
      formattedDate = dateObj.toRelative()
      break
    default:
      formattedDate = dateObj.toLocaleString(DateTime.DATETIME_SHORT)
      break
  }
  return (
    <Typography variant="caption" component="time" dateTime={dateObj.toISO()} className={`${classes.root} ${props.className}`}>
      {formattedDate}
    </Typography>
  )
}

export default CommentTimestamp
