export const setToLocalStorage = <T extends object | string>(key: string, value: T) => {
  // Add expiration method
  const serializedString = JSON.stringify(value)
  localStorage.setItem(key, serializedString)
}

export const getFromLocalStorage = <T extends object | string>(key: string) => {
  // Add expiration method
  try {
    const unserializedString = localStorage.getItem(key)
    return unserializedString !== null ? (JSON.parse(unserializedString) as T) : null
  } catch (error) {
    return null
  }
}

export const removeFromLocalStorage = (key: string) => {
  // Add expiration method
  localStorage.removeItem(key)
}
