import { LineString, Point, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { createContext, useContext } from 'react'
import { KrakenMap } from '@griegconnect/krakentools-kmap'
import { Feature } from 'ol'

type MooringPlanContext = {
  kmapInstance: KrakenMap
  mooringLayer: VectorLayer<VectorSource<Feature<Polygon | LineString | Point>>>
}

export const MooringPlanContext = createContext<MooringPlanContext | null>(null)
export const useMooringContext = () => useContext(MooringPlanContext)!
