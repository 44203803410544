import axios, { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '@mui/material/Button'
import { configSelector, configPathAtom, ConfigType } from '../atoms/configAtom'
import { useAlerts } from '../hooks'

type AppNewVersionNotifierProps = {
  checkInterval: number
}

export const AppNewVersionNotifier = (props: AppNewVersionNotifierProps) => {
  const config = useRecoilValue(configSelector)
  const configPath = useRecoilValue(configPathAtom)
  const [newVersion, setNewVersion] = useState<boolean>(false)
  const { setGlobal: globalNotification } = useAlerts()

  useEffect(() => {
    if (!newVersion) {
      const interval = setInterval(() => {
        return axios
          .get(configPath, {
            baseURL: window.location.origin,
          })
          .then((response: AxiosResponse<ConfigType>) => {
            if (response.data.version !== config.version) {
              setNewVersion(true)
              globalNotification(
                `An updated version is available. Click 'Upgrade' to start using the latest version. Please be aware that some services may not function properly if you do not upgrade.`,
                'info',
                false,
                <Button
                  onClick={() => window.location.reload()}
                  variant="outlined"
                  color="inherit"
                  sx={{ color: 'inherit', borderColor: 'inherit' }}
                >
                  Upgrade
                </Button>
              )
              clearInterval(interval)
            }
          })
          .catch((e: Error | AxiosError) => {
            console.error(`[react-kraken-app] Can't fetch config`, e)
          })
      }, props.checkInterval * 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [configPathAtom, configSelector, newVersion])

  return null
}

export default AppNewVersionNotifier
