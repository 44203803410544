import { Notification as NotificationIcon, Checkmark as CheckmarkIcon } from '@griegconnect/krakentools-react-icons'
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useApplicationOptions } from '../ApplicationOptionsContext'

import { notificationsAtom } from '../atoms/notificationsAtom'

export type NotificationsGlobalProps = {
  showMoreLinkTo?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(0.8125),
    },
    item: {
      padding: theme.spacing(2),
    },
    isRead: {
      color: theme.palette.text.disabled,
    },
    text: {
      color: theme.palette.text.secondary,
    },
  })
)

export const NotificationsButton = () => {
  const { notifications } = useApplicationOptions()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const notificationItems = useRecoilValue(notificationsAtom)

  const onToggleHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseHandler = () => {
    setAnchorEl(null)
  }

  const menuItems = useMemo(() => {
    return notificationItems.slice(0, 10).map((notification, index) => {
      const callbackWithClose = () => {
        onCloseHandler()
        notification.callback?.()
      }

      if (notification.to) {
        return (
          <MenuItem
            className={clsx(classes.item, notification.isRead ? classes.isRead : undefined)}
            component={Link}
            onClick={callbackWithClose}
            to={notification.to}
            key={`menu-item-${index}`}
          >
            {notification.icon && <ListItemIcon>{notification.icon}</ListItemIcon>}
            <ListItemText primary={notification.primary} secondary={notification.secondary} />
          </MenuItem>
        )
      } else {
        return (
          <MenuItem
            className={clsx(classes.item, notification.isRead ? classes.isRead : undefined)}
            onClick={callbackWithClose}
            key={`menu-item-${index}`}
          >
            {notification.icon && <ListItemIcon>{notification.icon}</ListItemIcon>}
            <ListItemText primary={notification.primary} secondary={notification.secondary} />
          </MenuItem>
        )
      }
    })
  }, [notificationItems])

  return (
    <div>
      <IconButton className={classes.button} size="large" onClick={onToggleHandler}>
        <NotificationIcon />
      </IconButton>
      <Menu
        BackdropProps={{
          invisible: true,
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseHandler}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.length > 0 ? (
          menuItems
        ) : (
          <Box px={2} py={1}>
            <Typography className={classes.text} variant="subtitle1">
              No notifications...
            </Typography>
          </Box>
        )}
        {notificationItems.length > 10 && notifications?.showMoreLinkTo && (
          <MenuItem
            component={Link}
            to={notifications.showMoreLinkTo}
            onClick={onCloseHandler}
            sx={{ justifyContent: 'flex-end' }}
          >
            Show more...
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default NotificationsButton
