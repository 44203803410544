import { useRecoilValue } from 'recoil'
import { activeTenantSelector, tenantsAtom } from '../atoms/tenantsAtom'

export const useTenants = () => {
  const tenants = useRecoilValue(tenantsAtom)
  const activeTenant = useRecoilValue(activeTenantSelector)

  return {
    tenants,
    activeTenant,
  }
}
