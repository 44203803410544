import React from 'react'
import { atom } from 'recoil'

export type RightDrawerAtomType = {
  isOpen: boolean
  content?: React.ReactNode
}

export const rightDrawerAtom = atom<RightDrawerAtomType>({
  key: 'rightDrawerState',
  default: {
    isOpen: false,
    content: undefined
  },
})
