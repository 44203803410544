import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#FFDA6A',
  light: '#ffff9b',
  dark: '#c9a93a',
  contrastText: 'rgba(0, 0, 0, 0.87)',
}

export default Color
