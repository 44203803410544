import { PaletteMode } from '@mui/material'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { themeConfigurationAtom } from '../atoms/themeAtom'
import { HeaderAtomType, headerAtom } from '../atoms/headerAtom'

type UseUserInterfaceType = {
  appBar: (visible: boolean) => void
  headerState: HeaderAtomType
  leftDrawer: (visible: boolean) => void
  /**
   * @deprecated use `leftDrawer(false)`
   */
  hideLeftDrawer: () => void
  /**
   * @deprecated use `leftDrawer(true)`
   */
  showLeftDrawer: () => void
  notifications: (visible: boolean) => void
  palette: PaletteMode
  setPalette: (palette: PaletteMode) => void
  search: (visible: boolean) => void
  tabsBar: (visible: boolean) => void
  tenantSelector: (visible: boolean) => void
  applicationSwitcher: (visible: boolean) => void
  applicationLogo: (visible: boolean) => void
}

export const useUserInterface = (): UseUserInterfaceType => {
  const setLeftDrawerState = useSetRecoilState(leftDrawerAtom)
  const [themeConfiguration, setThemeConfiguration] = useRecoilState(themeConfigurationAtom)
  const [headerState, setHeaderState] = useRecoilState(headerAtom)

  const appBar = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hide: !visible }))
  }

  const leftDrawer = (visible: boolean) => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: !visible }))
  }
  /**
   * @deprecated use `leftDrawer(false)`
   */
  const hideLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: true }))
  }
  /**
   * @deprecated use `leftDrawer(true)`
   */
  const showLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: false }))
  }

  const notifications = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideNotifications: !visible }))
  }

  const setPalette = (palette: PaletteMode) => {
    setThemeConfiguration((currVal) => ({ ...currVal, palette }))
  }

  const search = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideSearch: !visible }))
  }

  const tabsBar = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideTabsBar: !visible }))
  }

  const tenantSelector = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideTenantSelector: !visible }))
  }

  const applicationSwitcher = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideApplicationSwitcher: !visible }))
  }

  const applicationLogo = (visible: boolean) => {
    setHeaderState((currVal) => ({ ...currVal, hideApplicationLogo: !visible }))
  }

  return {
    appBar,
    headerState,
    leftDrawer,
    hideLeftDrawer,
    showLeftDrawer,
    notifications,
    palette: themeConfiguration.palette,
    setPalette,
    search,
    tabsBar,
    tenantSelector,
    applicationSwitcher,
    applicationLogo,
  }
}
