import { LiveMessage, VesselFilter } from '@griegconnect/krakentools-kmap'
import { AxiosInstance } from 'axios'

export type Observer = {
  id: number
  ref: string
}

type AndFilter = {
  and: (Partial<VesselFilter> | AndFilter)[]
}
// bbox, inside, mmsis, source, faster(sog), observers, still
const possibleFilters = ['mmsis', 'bbox', 'sources', 'inside', 'still', 'faster']

export const buildFilter = (conditions: Partial<VesselFilter>): AndFilter | Partial<VesselFilter> => {
  const elements = Object.entries(conditions).filter(([key]) => possibleFilters.includes(key))
  if (elements.length === 1) {
    return { [elements[0][0]]: elements[0][1] }
  } else if (elements.length === 2) {
    return { and: [{ [elements[0][0]]: elements[0][1] }, { [elements[1][0]]: elements[1][1] }] }
  } else {
    const first = elements.shift()!
    return { and: [{ [first[0]]: first[1] }, buildFilter(Object.fromEntries(elements))] }
  }
}

export class WhereApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async query(term: string, results?: number, offset?: number) {
    return await this.httpClient
      .get(`search?term=` + term, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data)
  }

  async getObservers(): Promise<Observer[]> {
    return await this.httpClient.get('/observers').then((response) => response.data as Observer[])
  }

  /*
   *
   * Vessels
   *
   */

  async getVesselSnapshot(mmsi: string | number, time?: Date) {
    return this.httpClient
      .get(`/snapshot?mmsis=${mmsi}${time ? '&instant=' + time.toISOString() : ''}`)
      .then((response) => response.data as LiveMessage[])
  }

  async getSnapshot(filter: VesselFilter, time?: Date) {
    return this.httpClient
      .post(`/snapshot?${time ? '&instant=' + time.toISOString() : ''}`, buildFilter(filter))
      .then((response) => response.data as LiveMessage[])
  }
}
