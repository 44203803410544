import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  LabelOutlined as LabelOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material'
import { useRecoilState } from 'recoil'
import { useMemo } from 'react'
import clsx from 'clsx'

import { useMapContext } from '../MapContext'
import { visibleLayersSelector, visibleLabelsSelector } from '../atoms/mapConfigAtoms'
import { LayerMenuItem } from '../atoms/types/LayerMenuItem'
import Highlighter from 'react-highlight-words'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      paddingLeft: theme.spacing(1.5),
      opacity: 0.6,
    },
    button: {
      padding: theme.spacing(0.5),
    },
    actions: {
      right: theme.spacing(1),
    },
    active: {
      opacity: 0.87,
    },
  })
)

export type LayerListItemProps = {
  layer: LayerMenuItem
  filterTerm?: string
}

export const LayerListItem = (props: LayerListItemProps) => {
  const { mapIdentifierSlug } = useMapContext()
  const classes = useStyles()
  const [visibleLayers, setVisibleLayers] = useRecoilState(visibleLayersSelector(mapIdentifierSlug))
  const [visibleLabels, setVisibleLabels] = useRecoilState(visibleLabelsSelector(mapIdentifierSlug))
  const theme = useTheme()
  const onCheckVisibilityHandler = () => {
    setVisibleLayers((prev) => {
      if (prev.find((l) => l === props.layer.key)) {
        return prev.filter((l) => l !== props.layer.key)
      } else {
        return prev.concat(props.layer.key)
      }
    })
  }

  const onCheckLabelHandler = () => {
    setVisibleLabels((prev) => {
      if (prev.find((l) => l === props.layer.key)) {
        return prev.filter((l) => l !== props.layer.key)
      } else {
        return prev.concat(props.layer.key)
      }
    })
  }
  const isVisible = useMemo(() => {
    return visibleLayers.find((l) => l === props.layer.key) ? true : false
  }, [visibleLayers])

  const hasVisibleLabel = useMemo(() => {
    return visibleLabels.find((l) => l === props.layer.key) ? true : false
  }, [visibleLabels])

  return (
    <ListItem key={props.layer.key}>
      <ListItemText
        className={clsx(classes.text, isVisible ? classes.active : undefined)}
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textPrimary',
        }}
        primary={
          <Highlighter
            highlightStyle={{
              color: 'inherit',
              background: 'none',
              textDecoration: 'underline',
            }}
            searchWords={[props.filterTerm || '']}
            autoEscape={true}
            textToHighlight={props.layer.name}
          />
        }
      />
      <ListItemSecondaryAction className={classes.actions}>
        {props.layer.hasLabels && (
          <IconButton className={classes.button} onClick={onCheckLabelHandler} size="large">
            {hasVisibleLabel ? <LabelOutlinedIcon color="primary" /> : <LabelOutlinedIcon color="disabled" />}
          </IconButton>
        )}
        <IconButton className={classes.button} onClick={onCheckVisibilityHandler} size="large">
          {isVisible ? <VisibilityOutlinedIcon color="primary" /> : <VisibilityOffOutlinedIcon color="disabled" />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default LayerListItem
