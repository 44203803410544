import React from 'react'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  profile: {
    padding: `0px !important`,
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface IProfileIconProps {
  fullName?: string
  imageUrl?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  size?: 'small' | 'large'
  style?: React.CSSProperties
}

const ProfileIcon: React.FC<React.PropsWithChildren<IProfileIconProps>> = ({ fullName, imageUrl, size, onClick: handleClick }) => {
  const classes = useStyles()

  const renderAvatar = () => {
    return (
      <Avatar
        alt={fullName ?? 'Unknown user'}
        src={imageUrl}
        className={size === 'large' ? classes.avatarLarge : classes.avatarSmall}
      >
        {fullName ? getInitials(fullName) : '??'}
      </Avatar>
    )
  }

  if (handleClick) {
    return (
      <IconButton
        color="inherit"
        className={classes.profile}
        onClick={handleClick}
        size="large">
        {renderAvatar()}
      </IconButton>
    );
  }

  return <div className={classes.avatarWrapper}>{renderAvatar()}</div>
}

// flex: 0 0 auto;
// color: #fff;
// padding: 12px;
// overflow: visible;
// font-size: 1.5rem;
// text-align: center;
// transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// border-radius: 50%;

const getInitials = (fullName: string) => {
  const fullNameParts = fullName.split(' ')
  if (fullNameParts.length > 1) {
    return fullNameParts[0].charAt(0) + fullNameParts[fullNameParts.length - 1].charAt(0)
  }
  return 'XX'
}

export default ProfileIcon
