import { alpha, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    fullWidth: {
      display: 'block',
      minWidth: '100%',
    },
    dropZone: {
      color: 'transparent',
      overflow: 'hidden',
      height: 0,
      position: 'absolute',
      transition: 'all 0.4s, height 0s, color 0.4s ease-out 0.2s',
      // backdropFilter: 'blur(0px)',
      backgroundColor: alpha(theme.palette.background.default, 0),
    },
    showDropZone: {
      color: 'inherit',
      overflow: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // backdropFilter: 'blur(3px)',
      boxShadow: `inset 0px 0px 10px ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.background.default, 0.75),
      height: '100%',
      width: '100%',
      top: 0,
      right: 0,
      zIndex: 1,
    },
    dropZoneHover: {
      color: theme.palette.primary.main,
      // backdropFilter: 'blur(5px)',
      boxShadow: `inset 0px 0px 80px ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.background.default, 0.95),
    },
  })
)

type FileDropZoneProps = {
  onFilesDropped: (files: FileList) => void
  onDragStart?: () => void
  onDragEnd?: () => void
  children: React.ReactNode
  dropText?: string
  fullWidth?: boolean
  className?: string
}

const FileDropZone = (props: FileDropZoneProps) => {
  const classes = useStyles()
  const [showOverlay, setShowOverlay] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    document.addEventListener('dragenter', handleDocumentDragEnter)
    document.addEventListener('dragleave', handleDocumentDragLeave)
    document.addEventListener('dragend', handleDocumentDragEnd)
    document.addEventListener('drop', handleDocumentDrop)

    // Clean up
    return () => {
      document.removeEventListener('dragenter', handleDocumentDragEnter)
      document.removeEventListener('dragleave', handleDocumentDragLeave)
      document.addEventListener('dragend', handleDocumentDragEnd)
      document.removeEventListener('drop', handleDocumentDrop)
    }
  }, [])

  const handleDocumentDragEnter = useCallback((event: DragEvent) => {
    const dataTransfer = event && event.dataTransfer
    const isFile =
      dataTransfer && dataTransfer.types && dataTransfer.types.length == 1 && dataTransfer.types[0] == 'Files'
    if (isFile) {
      setShowOverlay(true)
      if (props.onDragStart) props.onDragStart()
    }
  }, [])

  const handleDocumentDragLeave = useCallback((event: DragEvent) => {
    if (event.clientX <= 0 || event.clientY <= 0) {
      setShowOverlay(false)
      if (props.onDragEnd) props.onDragEnd()
    }
  }, [])

  const handleDocumentDragEnd = useCallback((_event: DragEvent) => {
    setShowOverlay(false)
    if (props.onDragEnd) props.onDragEnd()
  }, [])

  const handleDocumentDrop = useCallback((_event: DragEvent) => {
    setShowOverlay(false)
    if (props.onDragEnd) props.onDragEnd()
  }, [])

  const dragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setActive(true)
  }

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    props.onFilesDropped(event.dataTransfer.files)
    setShowOverlay(false)
    setActive(false)
  }

  const dragLeaveHandler = (_event: React.DragEvent<HTMLDivElement>) => {
    setActive(false)
  }

  return (
    <div className={`${clsx(classes.root, props.fullWidth ? classes.fullWidth : undefined)} ${props.className}`}>
      <div
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        // className={`${classes.dropZone} ${showOverlay ? classes.dropZoneActive : ''}`}
        className={clsx(
          classes.dropZone,
          showOverlay ? classes.showDropZone : undefined,
          active ? classes.dropZoneHover : undefined
        )}
      >
        {props.dropText ?? 'Drop your file(s) here…'}
      </div>
      {props.children}
    </div>
  )
}

export default FileDropZone
