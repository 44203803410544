import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { generatePath, useNavigate } from 'react-router-dom'
import { activeTenantSelector, tenantsAtom } from './atoms/tenantsAtom'
import { getFromLocalStorage, setToLocalStorage } from './utils/localStorage'
import { useApplicationOptions } from './ApplicationOptionsContext'
import NoActiveTenant, { INoActiveTenantProps } from './ui/NoActiveTenant'

interface ISelectTenantProps {
  /**
   * Will auto select/redirect if user only have one tenant, or if persisted tenant ref in localStorage exist.
   * Only triggerd if document.location.pathname === process.env.PUBLIC_URL + '/'
   *
   * @default true
   */
  autoSelect?: boolean
  /**
   * Force active tenant on all routes.
   *
   * @default false
   */
  force?: boolean
  selectTenantType?: INoActiveTenantProps['type']
  children: React.ReactNode
}

/**
 * Component for keeping application in tenant context (`/companies/${ref}`), with options to force context.
 *
 * @example
 * <TenantContextWrapper>
 *   <SomeOtherComponent />
 * </SelectTenant>
 */
export const TenantContextWrapper: React.FC<React.PropsWithChildren<ISelectTenantProps>> = (props) => {
  const [initPathName] = useState(document.location.pathname)
  const [initQueryParam] = useState(location.search)
  const [persistedActiveTenantRef, setPersistedActiveTenantRef] = useState(getFromLocalStorage<string>('tenantRef'))
  const [initialized, setInitialized] = useState(false)
  const [showSelectTenantUI, setShowSelectTenantUI] = useState(false)
  const tenantAccess = useRecoilValue(tenantsAtom)
  const activeTenant = useRecoilValue(activeTenantSelector)
  const navigate = useNavigate()
  const { tenantsUrlMatchPathParam, tenantsUrlMatchPathPrefix, reloadOnTenantsChange } = useApplicationOptions()

  useEffect(() => {
    const isRoot = initPathName === process.env.PUBLIC_URL || initPathName === `${process.env.PUBLIC_URL}/`
    const autoSelect = () => {
      // We should not auto select if path or search is defined
      if (tenantAccess && props.autoSelect && isRoot && initQueryParam === '') {
        let autoSelectRef
        if (persistedActiveTenantRef && tenantAccess.some((tenant) => tenant.ref === persistedActiveTenantRef)) {
          autoSelectRef = persistedActiveTenantRef
        }
        if (props.autoSelect && tenantAccess.length === 1) {
          autoSelectRef = tenantAccess[0].ref
        }
        if (autoSelectRef) {
          const tenantUrl = generatePath(tenantsUrlMatchPathPrefix, {
            [tenantsUrlMatchPathParam]: autoSelectRef,
          })
          navigate(tenantUrl, { replace: true })
        } else {
          setShowSelectTenantUI(true)
        }
      }
      setInitialized(true)
    }

    if (!initialized) {
      if (
        props.force &&
        tenantAccess.length > 0 &&
        activeTenant === null &&
        initPathName !== `${process.env.PUBLIC_URL}/`
      ) {
        setInitialized(true)
        setShowSelectTenantUI(true)
      } else {
        autoSelect()
      }
    }
  }, [
    tenantAccess,
    activeTenant,
    history,
    tenantsUrlMatchPathPrefix,
    tenantsUrlMatchPathParam,
    initialized,
    initPathName,
    props.autoSelect,
    props.force,
  ])

  useEffect(() => {
    if (activeTenant !== null && activeTenant.ref !== persistedActiveTenantRef) {
      setToLocalStorage('tenantRef', activeTenant.ref)
      setPersistedActiveTenantRef(activeTenant.ref)
    }
  }, [activeTenant])

  const selectTenantHandler = (ref: string) => {
    const tenantUrl = generatePath(tenantsUrlMatchPathPrefix, {
      [tenantsUrlMatchPathParam]: ref,
    })
    if (reloadOnTenantsChange) {
      window.document.location.href = tenantUrl
    } else {
      navigate(tenantUrl)
      setShowSelectTenantUI(false)
    }
  }

  if (initialized && showSelectTenantUI) {
    return <NoActiveTenant onSelect={selectTenantHandler} type={props.selectTenantType} />
  }

  if (initialized && !showSelectTenantUI) {
    return <>{props.children}</>
  }

  return null
}

TenantContextWrapper.defaultProps = {
  autoSelect: true,
  force: false,
  selectTenantType: 'page',
}
