import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#36B278',
  light: '#6ee5a7',
  dark: '#00814c',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#90D0AE',
}

export default Color
