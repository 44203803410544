import { useAuth } from '@griegconnect/krakentools-react-auth'
import { Person as PersonIcon } from '@griegconnect/krakentools-react-icons'
import {
  Profile,
  ProfileDialog,
  ProfileMenu,
  useTheme as useGriegTheme,
  validateProfileData,
} from '@griegconnect/krakentools-react-ui'
import { Button, Hidden, PaletteMode } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { generatePath, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { configSelector } from '../atoms/configAtom'
import { headerAtom } from '../atoms/headerAtom'
import { activeTenantSelector, tenantsAtom } from '../atoms/tenantsAtom'
import { themeConfigurationSelector, ThemeConfigurationType } from '../atoms/themeAtom'
import { userAtom } from '../atoms/userAtom'
import { useAlerts, useIdentityApi, useUsersApi } from '../hooks'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    profile: {
      margin: theme.spacing(-0.5),
      padding: theme.spacing(0.5),
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    paper: {
      minWidth: 200,
      maxHeight: '80vh',
      overflow: 'auto',
    },
  })
)

export type ProfileButtonProps = {
  profileMenuChildren?: React.ReactNode
  /**
   * Only used to disable palette switch in order to default to a specific palette mode.
   * @deprecated
   */
  disablePaletteSwitch: boolean
}

export const ProfileButton = (props: ProfileButtonProps) => {
  const classes = useStyles()
  const { loginWithRedirect, logout } = useAuth()
  const { paletteType } = useGriegTheme()
  const {
    tenantsUrlMatchPathPrefix,
    tenantsUrlMatchPathParam,
    reloadOnTenantsChange,
    beforeLogout,
    i18n,
  } = useApplicationOptions()
  const navigate = useNavigate()
  const { enqueue: enqueueSnackbar } = useAlerts()
  const { disableProfileCompletion } = useApplicationOptions()
  const config = useRecoilValue(configSelector)
  const tenants = useRecoilValue(tenantsAtom)
  const activeTenant = useRecoilValue(activeTenantSelector)
  const headerState = useRecoilValue(headerAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const setThemeConfig = useSetRecoilState(themeConfigurationSelector)
  const usersApi = useUsersApi()
  const identityApi = useIdentityApi()

  const changeTenantHandler = (tenantId: string) => {
    const newActiveTenant = tenants.find((tenant) => tenant.id === tenantId) ?? null
    if (newActiveTenant) {
      navigate(
        generatePath(tenantsUrlMatchPathPrefix, {
          [tenantsUrlMatchPathParam]: newActiveTenant?.ref,
        })
      )
      if (reloadOnTenantsChange) {
        document.location.reload()
      }
    }
  }

  const logoutHandler = async () => {
    if (beforeLogout) {
      await beforeLogout()
    }
    logout({
      client_id: config.auth.clientId,
      federated: true,
      returnTo: window.location.origin + (config.auth.redirectPath ? config.auth.redirectPath : '/'),
    })
  }

  const loginHandler = () => {
    const redirectOptions = {
      appState: {
        targetUrl: window.location.pathname + window.location.search + window.location.hash,
      },
    }
    loginWithRedirect(redirectOptions)
  }

  const profileSaveHandler = async (profile: Profile) => {
    await usersApi
      .updateCurrentUser(profile.fullName ?? profile.email, profile.phone ? profile.phone.toString() : '')
      .then(() => {
        setUser((prevValue) => {
          return {
            ...prevValue!,
            name: profile.fullName ?? profile.email,
            mobile: profile.phone ? profile.phone.toString() : '',
          }
        })
      })
    if (profile.locale && profile.locale !== user?.metadata.locale) {
      await usersApi.updateCurrentUserMetadata({ theme: user?.metadata.theme ?? '', locale: profile.locale })
      window.location.reload()
    }
  }

  const avatarSaveHandler = async (imageString: string) => {
    try {
      identityApi.updateProfileImage(imageString).then((response) => {
        setUser((prevValue) => {
          return {
            ...prevValue!,
            picture: response.picture ?? '',
          }
        })
      })
    } catch (error) {
      console.error('Error saving profile image', error)
      alert('An error occurred when saving profile image. Please try again')
    }
  }

  const onPaletteChangeHandler = (palette: PaletteMode) => {
    setThemeConfig((prev: ThemeConfigurationType) => {
      return { ...prev, palette: palette }
    })
  }

  const userMustCompleteProfile = React.useMemo(() => {
    if (user && !disableProfileCompletion) {
      const validationErrors = validateProfileData({
        email: user.email,
        fullName: user.name,
        phone: user.mobile,
        locale: user.metadata.locale,
      })
      return Object.keys(validationErrors).length > 0
    }
    return false
  }, [disableProfileCompletion, user])

  return (
    <div className={classes.wrapper}>
      {user ? (
        <>
          <Hidden mdDown>
            <ProfileMenu
              profile={{
                email: user.email,
                picture: user.picture,
                fullName: user.name,
                phone: user.mobile !== null ? user.mobile : undefined,
                locale: user.metadata.locale,
              }}
              initOpenProfileForm={userMustCompleteProfile}
              languages={i18n?.languages}
              onProfileSave={profileSaveHandler}
              onProfileImageSave={avatarSaveHandler}
              onLogin={loginHandler}
              onLogout={logoutHandler}
              paletteType={props.disablePaletteSwitch ? undefined : paletteType}
              onChangePaletteMode={props.disablePaletteSwitch ? undefined : onPaletteChangeHandler}
              onError={(e) => enqueueSnackbar(e, 'error')}
            >
              {props.profileMenuChildren}
            </ProfileMenu>
          </Hidden>
          <Hidden mdUp>
            <ProfileDialog
              profile={{
                email: user.email,
                picture: user.picture,
                fullName: user.name,
                phone: user.mobile !== null ? user.mobile : undefined,
                locale: user.metadata.locale,
              }}
              initOpenProfileForm={userMustCompleteProfile}
              onError={(e) => enqueueSnackbar(e, 'error')}
              languages={i18n?.languages}
              onProfileSave={profileSaveHandler}
              onProfileImageSave={avatarSaveHandler}
              onLogin={loginHandler}
              onLogout={logoutHandler}
              paletteType={props.disablePaletteSwitch ? undefined : paletteType}
              onChangePaletteMode={props.disablePaletteSwitch ? undefined : onPaletteChangeHandler}
              showTenantSwitcher={!headerState.hideTenantSelector}
              activeTenantRef={activeTenant?.id ?? undefined}
              onChangeTenant={changeTenantHandler}
              tenants={tenants.map((tenant) => ({ ref: tenant.id, name: tenant.name }))}
            >
              {props.profileMenuChildren}
            </ProfileDialog>
          </Hidden>
        </>
      ) : (
        <Button onClick={loginHandler} variant="outlined" startIcon={<PersonIcon />}>
          Login
        </Button>
      )}
    </div>
  )
}

export default ProfileButton
