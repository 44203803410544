import { Collection, Feature } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { EventsKey } from 'ol/events'
import { LineString, MultiPoint, Point } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { fromLonLat, toLonLat } from 'ol/proj'
import { getLength } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo, useState } from 'react'

import { assertIsStyle, getAdordnmentLineStyles, ILonLat, labelStyle, Spatial } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { useMeasuringContext } from '../../MeasuringContext'
import { formatDistanceInMeters } from '../../utils/formatDistanceInMeters'
import { midPoint } from '../../utils/geometry'

export type MeasureLineDef = {
  type: 'distance'
  id: string
  from: ILonLat
  to: ILonLat
}

type MeasureLineProps = {
  line: MeasureLineDef
  selected: boolean
  onChange: (line: MeasureLineDef) => void
}

export const editMeasureLineStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
  geometry: (feature) => {
    const coords = (feature.getGeometry() as LineString)?.getCoordinates?.()
    return coords ? new MultiPoint(coords) : undefined
  },
  zIndex: 2000,
})

export const measureLineStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 255, 0.64)',
    lineDash: [5, 5],
    width: 2,
  }),
  zIndex: 150,
})

export const MeasureLine: FC<React.PropsWithChildren<MeasureLineProps>> = ({ line, selected, onChange }) => {
  const { measuringLayer } = useMeasuringContext()
  const { kmapInstance, colorPalette } = useMapContext()
  const [viewParams, setViewParams] = useState(kmapInstance.getViewParams())
  const moveEndEventKey = useMemo(
    () =>
      kmapInstance.on('moveend', () => {
        setViewParams(kmapInstance.getViewParams())
      }),
    []
  )

  const measureLineFeature = useMemo(() => {
    const feature = new Feature({
      geometry: new LineString([]),
    })
    feature.set('type', 'measureLine')
    feature.set('selectable', true)
    feature.set('measureId', line.id)
    feature.set('snappable', true)
    feature.setStyle(measureLineStyle)
    measuringLayer.getSource()?.addFeature(feature)
    return feature
  }, [])

  const labelFeature = useMemo(() => {
    const feature = new Feature({
      geometry: new Point([]),
    })
    feature.set('type', 'measureLineLabel')
    feature.set('measureId', line.id)
    feature.set('snappable', true)
    measuringLayer.getSource()?.addFeature(feature)
    const style = labelStyle(colorPalette)
    const text = style.getText()
    if (text) {
      text.setRotateWithView(true)
      text.setOffsetY(16)
      text.setJustify('center')
      text.setTextAlign('center')
      text.setTextBaseline('middle')
    }
    feature.setStyle(style)
    return feature
  }, [])

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([measureLineFeature]),
      insertVertexCondition: () => false,
      deleteCondition: () => false,
      style: labelStyle(colorPalette),
    })
    return modify
  }, [])

  let changeEventKey: EventsKey | undefined
  let modifyEndEventKey: EventsKey | undefined
  const renderAdordnments = (edit: boolean = false) => {
    if (!edit) {
      const geometry = measureLineFeature.getGeometry()
      if (geometry?.getCoordinates().length === 2) {
        const adordnmentStyles = getAdordnmentLineStyles(
          geometry as LineString,
          kmapInstance.getViewParams()?.viewResolution,
          measureLineStyle.clone()
        )
        if (adordnmentStyles) {
          measureLineFeature.setStyle([measureLineStyle, ...adordnmentStyles])
        }
      }
    } else {
      const style = measureLineStyle.clone()
      const stroke = style.getStroke()
      if (stroke) {
        stroke.setColor('rgba(24, 160, 251, 1)')
      } else {
        console.warn('measureLineStyle stroke is not a Stroke')
      }
      measureLineFeature.setStyle([style, editMeasureLineStyle])
    }
  }
  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.addToSnapCollection(measureLineFeature)
    kmapInstance.resetSnapCollection()
    measuringLayer.setZIndex(3000)
    renderAdordnments(true)
    changeEventKey = measureLineFeature.getGeometry()?.on('change', (event) => {
      const lineStringGeometry = event.target as LineString
      const coords = lineStringGeometry.getCoordinates()
      renderAdordnments(true)
      updateLabel(coords[0], coords[1])
    })
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === line.id)
      ?.forEach((f) => {
        const style = f.getStyle()
        if (style instanceof Style) {
          style.setZIndex(5000)
        }
      })
    modifyEndEventKey = modifyInteraction.on('modifyend', () => {
      const geom = measureLineFeature.getGeometry()
      const coords = geom?.getCoordinates()
      if (coords) {
        const from = toLonLat(coords[0])
        const to = toLonLat(coords[1])
        onChange({ ...line, from: { lon: from[0], lat: from[1] }, to: { lon: to[0], lat: to[1] } })
      }
    })

    kmapInstance.addInteraction(modifyInteraction)
  }

  const disableEdit = () => {
    measureLineFeature.setStyle(measureLineStyle)
    renderAdordnments()
    kmapInstance.removeInteraction(modifyInteraction)
    measuringLayer.setZIndex(100)
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === line.id)
      ?.forEach((f) => {
        const style = f.getStyle()
        if (style instanceof Style) {
          style.setZIndex(100)
        }
      })

    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = measureLineFeature.getGeometry() as LineString
    const geom = labelFeature.getGeometry()
    if (geom instanceof Point) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('labelFeature geometry is not a Point')
    }
    const style = assertIsStyle(labelFeature.getStyle())
    const text = style?.getText()

    const angle = Math.atan2(to[1] - from[1], to[0] - from[0])
    if (text) {
      text.setJustify('center')
      text.setTextBaseline('middle')
      text.setRotateWithView(true)
      const deg = Spatial.toDegrees(angle)
      text.setRotation(deg > 90 || deg < -90 ? -angle + Math.PI : -angle)
      text.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
    }
  }

  useEffect(() => {
    const from = fromLonLat([line.from.lon, line.from.lat])
    const to = fromLonLat([line.to.lon, line.to.lat])
    const lineGeometry = measureLineFeature.getGeometry() as LineString

    if (from && to && lineGeometry) {
      measureLineFeature.getGeometry()?.setCoordinates([from, to])
      renderAdordnments(selected)
      updateLabel(from, to)
    }
  }, [line, viewParams])

  useEffect(() => {
    return () => {
      disableEdit()
      measuringLayer.getSource()?.removeFeature(measureLineFeature)
      measuringLayer.getSource()?.removeFeature(labelFeature)
      kmapInstance.un(moveEndEventKey)
    }
  }, [])

  useEffect(() => {
    const style = labelStyle(colorPalette)
    const labelText = assertIsStyle(labelFeature.getStyle()).getText()?.getText()
    const text = style.getText()
    if (text) {
      text.setOffsetY(16)
      text.setJustify('center')
      text.setTextBaseline('middle')
      text.setText(labelText)
      text.setRotateWithView(true)
    }
    labelFeature.setStyle(style)
    const lineStringGeometry = measureLineFeature.getGeometry() as LineString
    const coords = lineStringGeometry.getCoordinates()
    renderAdordnments(true)
    updateLabel(coords[0], coords[1])
  }, [colorPalette, labelFeature])

  useEffect(() => {
    if (selected) {
      enableEdit()
    } else {
      disableEdit()
    }
  }, [selected, modifyInteraction])

  return null
}

export default MeasureLine
