import { atom } from 'recoil'

export type SearchAtomType = {
  term: string
  placeholder: string
  miniSearchOpen: boolean
  searchVisible: boolean
  clearOnOpen: boolean
  popdownContentComponent?: JSX.Element
}

export const searchAtom = atom<SearchAtomType>({
  key: 'searchState',
  default: {
    term: '',
    placeholder: 'Search…',
    clearOnOpen: true,
    miniSearchOpen: false,
    searchVisible: false,
  },
})
