import { Box, IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material'
import React from 'react'
import { MapTranslucentPaper } from './MapTranslucentPaper'

export type MapButtonProps = Pick<IconButtonProps, 'onClick'> & {
  icon?: React.ReactNode
  isActive?: boolean
  disabled?: boolean
  toolTipText?: string
  toolTipPlacement?: TooltipProps['placement']
  iconButtonOnly?: boolean
  /**
   * If true, the button will not loose focus when clicked.
   *
   * @default false
   */
  keepFocus?: boolean
}

export const MapButton = React.forwardRef<HTMLButtonElement, MapButtonProps>(({ keepFocus = false, ...props }, ref) => {
  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick?.(event)
    if (!keepFocus) event.currentTarget.blur()
  }

  const renderButton = () => {
    const iconButton = (
      <IconButton
        color={props.isActive ? 'primary' : 'inherit'}
        onClick={onClickHandler}
        size="large"
        ref={ref}
        disabled={props.disabled}
      >
        {props.icon}
      </IconButton>
    )

    if (props.iconButtonOnly) {
      return iconButton
    }
    return <MapTranslucentPaper>{iconButton}</MapTranslucentPaper>
  }

  return (
    <Tooltip title={props.toolTipText} placement={props.toolTipPlacement} arrow={!!props.toolTipText}>
      {renderButton()}
    </Tooltip>
  )
})

export default MapButton
