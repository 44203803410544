import { Chip, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { ResultItem } from '.'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '25px auto min-content',
      gridTemplateRows: '48px',
      alignItems: 'center',
      width: '100%',
      gap: 16,
      // paddingBottom: 4,
    },
    containerNoIcon: {
      display: 'grid',
      gridTemplateColumns: 'auto min-content',
      gridTemplateRows: '48px',
      alignItems: 'center',
      width: '100%',
      gap: 16,
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    icon: {
      justifySelf: 'center',
    },
    chip: {
      justifySelf: 'center',
    },
    greenChip: {
      backgroundColor:
        (theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark) + '9A',
      color: theme.palette.mode === 'light' ? theme.palette.success.dark : theme.palette.success.light,
    },
    redChip: {
      backgroundColor: (theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark) + '9A',
      color: theme.palette.mode === 'light' ? theme.palette.error.dark : theme.palette.error.light,
    },
    yellowChip: {
      backgroundColor:
        (theme.palette.mode === 'light' ? theme.palette.warning.light : theme.palette.warning.dark) + '9A',
      color: theme.palette.mode === 'light' ? theme.palette.warning.dark : theme.palette.warning.light,
    },
    greyChip: {
      backgroundColor: (theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[500]) + '9A',
      color: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.grey[50],
    },
  })
)

type SearchBarResultItemProps = React.HTMLAttributes<HTMLLIElement> & {
  resultItem: ResultItem
}

export const SearchBarResultItem = (props: SearchBarResultItemProps) => {
  const { resultItem, ...liProps } = props
  const classes = useStyles()

  return (
    <li {...liProps}>
      <div className={resultItem.icon ? classes.container : classes.containerNoIcon} data-testid="searchbar-resultitem">
        {resultItem.icon && <resultItem.icon className={classes.icon} />}
        <div>
          <div>
            <Typography variant="button">{resultItem.title}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {resultItem.subtitle}
            </Typography>
          </div>
        </div>
        <div className={classes.chip}>
          {resultItem.chip && (
            <Chip
              className={
                (resultItem.chip.color === 'green' && classes.greenChip) ||
                (resultItem.chip.color === 'yellow' && classes.yellowChip) ||
                (resultItem.chip.color === 'red' && classes.redChip) ||
                (resultItem.chip.color === 'grey' && classes.greyChip) ||
                ''
              }
              label={resultItem.chip.text}
            />
          )}
        </div>
      </div>
    </li>
  )
}

export default SearchBarResultItem
