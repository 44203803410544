import { LayersOutlined as LayersOutlinedIcon } from '@mui/icons-material'
import { FC, ReactNode, useState } from 'react'
import { Box, Paper, Theme, Typography, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@griegconnect/krakentools-react-icons'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import { LiveLayerControl } from './LiveLayerControl'
import AssetLayerControl from './AssetLayerControl'
import { MapButtonPanelRegion } from '../../shared/MapButtonPanel'
import { MapPopperButton } from '../../shared/MapPopperButton'
import { MapLayerControl } from './MapLayerControl'
import { useMapContext } from '../../MapContext'
import React from 'react'
import { MapTranslucentPaper } from '../../shared/MapTranslucentPaper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      width: 420,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      paddingBottom: theme.spacing(2),
    },
    closeButton: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    list: {},
  })
)

type LayerControlProps = {
  region?: MapButtonPanelRegion
  controls?: ('live' | 'assets' | 'maps')[]
  customControls?: ReactNode[]
  icon?: ReactNode
  title?: ReactNode
  tooltipText?: string
  bottomoffset?: number
}

export const LayerControl: FC<React.PropsWithChildren<LayerControlProps>> = ({
  region,
  controls,
  customControls,
  icon,
  title,
  tooltipText,
  bottomoffset,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { mapIdentifierSlug } = useMapContext()
  const handleToggle = () => {
    setOpen(!open)
  }
  const renderContent = () => (
    <>
      <Box overflow="auto" className={classes.list}>
        {controls?.includes('live') && <LiveLayerControl />}
        {controls?.includes('assets') && <AssetLayerControl startCollapsed />}
        {controls?.includes('maps') && <MapLayerControl />}
        {customControls?.map((customControl, index) => (
          <React.Fragment key={mapIdentifierSlug + '_customControl_' + index}>{customControl}</React.Fragment>
        ))}
      </Box>
    </>
  )
  return (
    <MapPopperButton
      icon={icon || <LayersOutlinedIcon />}
      open={open}
      onClose={handleToggle}
      region={region}
      tooltipText={tooltipText}
      title={title}
      content={
        <MapTranslucentPaper
          styleVariant="static"
          className={classes.paper}
          elevation={24}
          sx={{ maxHeight: `calc(85vh - ${64 + (bottomoffset || 0)}px)` }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <>
              {title ? (
                typeof title === 'string' ? (
                  <Typography variant="h6">{title}</Typography>
                ) : (
                  title
                )
              ) : (
                <Typography variant="h6">Layers</Typography>
              )}
            </>
            <IconButton onClick={() => setOpen(false)} className={classes.closeButton} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          {renderContent()}
        </MapTranslucentPaper>
      }
      mobileContent={<Box padding={4}>{renderContent()}</Box>}
    />
  )
}

export default LayerControl
