import { Box, Hidden, Skeleton } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { MapDialog } from '../dialogs/MapDialog'
import MapDialogMobile from '../dialogs/MapDialogMobile'
import AssetInformation from './AssetInformation'
import AssetsApi from '../apis/AssetsApi'
import { Asset, AssetType } from '../apis/dtos/KindsDto'
import { mapAssetToDisplayValues } from './mapAssetPropsToDisplayValues'
import { useTranslation } from 'react-i18next'

type AssetInfoBoxProps = {
  onClose: () => void
  assetId: string | number
  api: AssetsApi
  assetTypes: AssetType[]
}

const AssetInfoBox = forwardRef<HTMLDivElement, AssetInfoBoxProps>((props, ref) => {
  const [asset, setAsset] = useState<Asset>()
  const { onClose, assetId, api, assetTypes } = props
  const { t } = useTranslation('kmap')
  useEffect(() => {
    api.getAsset(String(assetId)).then((asset) => setAsset(asset))
  }, [assetId])

  const assetSource = Object.keys(asset?.data || {})[0]
  const assetProps = Object.keys(asset?.data || {}).reduce(
    (acc, key) => ({ ...acc, ...asset!.data[key].properties }),
    {}
  ) as Record<string, unknown>

  const assetType = asset ? assetTypes.find((type) => type.meta.id === asset.data[assetSource].assetType) : undefined
  const displayValues =
    asset && assetProps && assetType ? mapAssetToDisplayValues(asset, assetProps, assetType) : undefined
  const subtype = displayValues?.subtype ? ' (' + displayValues.subtype + ')' : ''
  return (
    <>
      <Box display="none">
        {!asset ? (
          <MapDialog
            title={t('AssetInfoBox.titleLoading')}
            onClose={onClose}
            arrow="bottom"
            primary={
              <>
                <Box display="flex" justifyContent="space-between">
                  <Skeleton width="45%" />
                  <Skeleton width="45%" />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Skeleton width="45%" />
                  <Skeleton width="45%" />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Skeleton width="45%" />
                  <Skeleton width="45%" />
                </Box>
              </>
            }
            ref={ref}
          />
        ) : (
          <MapDialog
            title={(displayValues?.assetName || '') + subtype}
            subtitle={undefined}
            onClose={onClose}
            arrow="bottom"
            primary={displayValues?.primary ? <AssetInformation values={displayValues?.primary} /> : null}
            secondary={displayValues?.secondary ? <AssetInformation values={displayValues?.secondary} /> : null}
            ref={ref}
          />
        )}
      </Box>
      <Hidden lgUp>
        <MapDialogMobile
          title={(displayValues?.assetName || '') + subtype}
          subtitle={undefined}
          onClose={onClose}
          primary={displayValues?.primary ? <AssetInformation values={displayValues?.primary} /> : null}
          secondary={displayValues?.secondary ? <AssetInformation values={displayValues?.secondary} /> : null}
        />
      </Hidden>
    </>
  )
})
export default AssetInfoBox
