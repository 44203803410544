import React, { ReactChild } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  children: ReactChild[] | ReactChild
  className: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    comment: {
      alignSelf: 'flex-start',
    },
  })
)

const CommentList = (props: Props) => {
  const { children } = props
  const classes = useStyles()

  return <div className={`${classes.root} ${props.className}`}>{children}</div>
}

export default CommentList
