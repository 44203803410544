import { Box, Collapse, Grid, Link, Theme, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { FC, useState } from 'react'
import { AssetDisplayValue } from './mapAssetPropsToDisplayValues'

const isValidUrl = (urlStr?: string | number) => {
  if (!urlStr) {
    return false
  }
  let url
  try {
    url = new URL(String(urlStr))
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

type AssetInformationProps = {
  values: AssetDisplayValue[]
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {},
  },
  item: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('xl')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  itemGroupHeader: {
    cursor: 'pointer',
  },
}))

const AssetItemGroup: FC<React.PropsWithChildren<{ items: AssetDisplayValue[]; title: string }>> = ({ items, title }) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  return (
    <>
      <Box
        width="100%"
        display="flex"
        onClick={() => {
          setExpanded(!expanded)
        }}
        className={classes.itemGroupHeader}
      >
        <Tooltip arrow title={title} placement="left-end">
          <Grid item xs={6} className={classes.item}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {title}:
            </Typography>
          </Grid>
        </Tooltip>
        <Grid item xs={6} className={classes.item} justifyContent="end" display="flex">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </Grid>
      </Box>
      <Collapse in={expanded} unmountOnExit sx={{ width: '100%' }}>
        <Box sx={{ padding: 2 }}>
          {items.map((item, index) => (
            <AssetInformationGridItem gridItem={item} key={title + index} />
          ))}
        </Box>
      </Collapse>
    </>
  )
}

const AssetInformationGridItem: FC<React.PropsWithChildren<{ gridItem: AssetDisplayValue }>> = ({ gridItem }) => {
  const classes = useStyles()
  return (
    <Box width="100%" display="flex">
      <Tooltip arrow title={gridItem.keyDescription || ''} placement="left-end">
        <Grid item xs={6} className={classes.item}>
          <Typography variant="body2" color="textSecondary" noWrap>
            {gridItem.key}:
          </Typography>
        </Grid>
      </Tooltip>
      <Grid item xs={6} className={classes.item}>
        <AssetListItemValue item={gridItem} />
      </Grid>
    </Box>
  )
}
const AssetInformation: FC<React.PropsWithChildren<AssetInformationProps>> = ({ values }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      {values.map((item, index) => {
        if (item.items) {
          if (item.items.length > 0) {
            if (Array.isArray(item.items[0])) {
              return item.items.map((itemitem, index) => (
                <AssetItemGroup items={itemitem as AssetDisplayValue[]} title={item.key} key={item.key + index} />
              ))
            } else {
              return item.items.length === 1 ? (
                <AssetInformationGridItem
                  gridItem={{
                    key: item.key,
                    keyDescription: item.keyDescription,
                    value: item.items[0].value,
                    valueDescription: item.items[0].keyDescription,
                  }}
                  key={item.key + index}
                />
              ) : (
                <AssetItemGroup items={item.items as AssetDisplayValue[]} title={item.key} key={item.key + index} />
              )
            }
          }
        } else {
          return <AssetInformationGridItem gridItem={item} key={'root' + index} />
        }
      })}
    </Grid>
  )
}

const AssetListItemValue: React.FC<React.PropsWithChildren<{ item: AssetDisplayValue }>> = ({ item }) => {
  return isValidUrl(item.value) ? (
    <Tooltip arrow title={item.valueDescription || item.keyDescription} placement="right-end">
      <Typography variant="body2" color="textPrimary" noWrap>
        <Link href={String(item.value)}>{item.value}</Link>
      </Typography>
    </Tooltip>
  ) : (
    <>
      {item.items ? (
        item.items.map((i, index) =>
          !Array.isArray(i) ? (
            <Tooltip arrow title={i.keyDescription} key={i.key + index} placement="right-end">
              <Typography variant="body2" color="textPrimary" sx={{ overflowWrap: 'break-word' }}>
                {i.value || i.key || ''}
              </Typography>
            </Tooltip>
          ) : null
        )
      ) : (
        <Tooltip arrow title={item.valueDescription || item.keyDescription} placement="right-end">
          <Typography variant="body2" color="textPrimary" sx={{ overflowWrap: 'break-word' }}>
            {item.value || '-'}
          </Typography>
        </Tooltip>
      )}
    </>
  )
}

export default AssetInformation
