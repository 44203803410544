import { MoreVertical } from '@griegconnect/krakentools-react-icons'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useMemo, useState } from 'react'

export type Action = {
  label: string
  callback: () => void
}

export type SnackbarMenuProps = {
  actions: Action[]
}

export const SnackbarMenu: React.FC<React.PropsWithChildren<SnackbarMenuProps>> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onActionHandler = (callback: Action['callback']) => {
    setAnchorEl(null)
    return callback
  }

  const onCloseHandler = () => {
    setAnchorEl(null)
  }

  const menuItems = useMemo(() => {
    return props.actions.map((action) => {
      return (
        <MenuItem key={action.label} onClick={onActionHandler(action.callback)}>
          {action.label}
        </MenuItem>
      )
    })
  }, [setAnchorEl, props.actions])

  return (
    <>
      <IconButton onClick={onClickHandler} color="primary">
        <MoreVertical />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={onCloseHandler}
        anchorEl={anchorEl}
        sx={{ zIndex: 1400 }}
        BackdropProps={{ invisible: true }}
      >
        {menuItems}
      </Menu>
    </>
  )
}

export default SnackbarMenu
