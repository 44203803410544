import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#e4de94',
  light: '#f3efc7',
  dark: '#d1c546',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#d3c52c',
}

export default Color
