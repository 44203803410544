import { alpha } from '@mui/material/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Droppable } from '@hello-pangea/dnd'
import { boardColumnBackgroundColor, BoardColumnComponentProps } from './board-column'
import { BoardItemDraggable, BoardItemProps } from './board-item'

type Props = {
  isDragHighlighted: boolean
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    items: {
      backgroundColor: (props) =>
        props.isDragHighlighted ? alpha(theme.palette.primary.main, 0.16) : boardColumnBackgroundColor, //TODO Use theme when palette is updated
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      paddingBottom: 0,
      minHeight: '3em',
      flexGrow: 1,
      borderRadius: theme.spacing(0.5),
    },
  })
)

export type DroppableIdProps = {
  columnId: string
  trackId?: string
}

export const ColumnsDroppable = React.memo((columnProps: BoardColumnComponentProps) => {
  const draggingTargetColumnId = columnProps.dragProps.draggingTarget?.columnId
  const draggingTargetTrackId = columnProps.dragProps.draggingTarget?.trackId
  const trackId = columnProps.track?.id

  const isDragHighlighted: boolean =
    draggingTargetColumnId !== undefined &&
    draggingTargetColumnId == columnProps.id &&
    draggingTargetTrackId === trackId
  const classes = useStyles({ isDragHighlighted })
  const items = columnProps.items!

  const droppableId: DroppableIdProps = {
    columnId: columnProps.id,
    trackId: trackId,
  }
  const dropId = JSON.stringify(droppableId)

  return (
    <Droppable droppableId={dropId} isDropDisabled={columnProps.isDropDisabled}>
      {(provided: any) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className={classes.items}>
          {items.map((item: BoardItemProps, index: number) => (
            <BoardItemDraggable
              key={item.id}
              index={index}
              columnId={columnProps.id}
              isDragDisabled={columnProps.dragProps.isDragDisabled || item.isDragRestricted}
              {...item}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
})
