import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
    },
  })
);

export type ColumnsContainerProps = {
  children?: React.ReactNode;
};

export const ColumnsContainer: React.FC<React.PropsWithChildren<ColumnsContainerProps>> = React.memo((props: ColumnsContainerProps) => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
});
