import { Chip, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from "@mui/material"
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Vessel as VesselIcon, Port as PortIcon } from "@griegconnect/krakentools-react-icons"

import { SearchResultItem } from "../atoms/types/SearchResultItem"

export type SpotlightResultItemProps = {
    item: SearchResultItem
} & React.HTMLAttributes<HTMLLIElement>;

const useStyles = makeStyles((_theme: Theme) => createStyles({
    listItemContainer: {
        width: '100%'
    },
    secondaryAction: {
        right: 0
    }
}))

export const SpotlightResultItem = (props: SpotlightResultItemProps) => {

    const classes = useStyles()

    const typeToIcon = (type: SearchResultItem['type']) => {
        switch(type) {
            case "vessel": 
                return <VesselIcon />
            case "port": 
                return <PortIcon />
            case "asset":
            default:
                return null
        }
    }

    return (
        <ListItem ContainerComponent="div" dense={true} ContainerProps={{ className: classes.listItemContainer }} {...props} >
            {props.item.type && (<ListItemIcon>{typeToIcon(props.item.type)}</ListItemIcon>)}
            <ListItemText primary={props.item.title} secondary={props.item.subtitle} />
            {props.item.chip && (
                <ListItemSecondaryAction className={classes.secondaryAction}>
                    <Chip label={props.item.chip.text} />
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

export default SpotlightResultItem