import { createRoot } from 'react-dom/client'
import { ApplicationProvider } from '@griegconnect/krakentools-react-kraken-app'

import * as serviceWorker from './serviceWorker'
import { AdminRouter } from './AdminRouter'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(
  '3c92ac2a13f79038b2fca55b538af70bTz03MzQ0MyxFPTE3MjU3NTAwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ApplicationProvider
    tool="port"
    name="Assets Admin"
    reloadOnTenantsChange
    filterUserAccess={(tenant) => {
      const hasGriegConnectConfigPermission = tenant.some(
        (t) =>
          t.tenant.ref === 'griegconnect' &&
          t.permissions!.some((p) => p.domain === 'assets' && p.name === 'config:read') &&
          t.permissions!.some((p) => p.domain === 'assets' && p.name === 'config:write')
      )

      if (!hasGriegConnectConfigPermission) {
        return []
      }

      return tenant
        .filter(
          (t) =>
            t.permissions!.some((p) => p.domain === 'assets' && p.name === 'assets:read') &&
            t.permissions!.some((p) => p.domain === 'assets' && p.name === 'assets:write')
        )
        .map((taccess) => ({
          id: taccess.tenant.id,
          name: taccess.tenant.name,
          permissions: taccess.permissions ?? [],
          ref: taccess.tenant.ref,
          companies: [],
        }))
    }}
  >
    {/* <Navigation navigation={[...navigation]} /> */}
    <AdminRouter />
  </ApplicationProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
