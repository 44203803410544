import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#9629CC',
  light: '#cb5eff',
  dark: '#62009a',
  contrastText: 'rgba(255, 255, 255, 0.87)',
  contrastPrimary: '#CB95E7',
}

export default Color
