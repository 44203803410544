import { Coordinate } from 'ol/coordinate'
import Feature from 'ol/Feature'
import { LineString, Point } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { toLonLat } from 'ol/proj'
import { getLength } from 'ol/sphere'
import { Fill, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { assertIsStyle, GeometryType, labelStyle, Spatial } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { useMeasuringContext } from '../../MeasuringContext'
import { formatDistanceInMeters } from '../../utils/formatDistanceInMeters'
import { midPoint } from '../../utils/geometry'
import { editMeasureLineStyle, MeasureLineDef, measureLineStyle } from './MeasureLine'

type DrawMeasureLineInteractionProps = {
  onMeasureLineCreated: (line: MeasureLineDef) => void
}
const dotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
  }),
})
const lineStyle = measureLineStyle.clone()
lineStyle.getStroke()?.setColor('rgba(24, 160, 251, 1)')
export const DrawMeasureLineInteraction: FC<React.PropsWithChildren<DrawMeasureLineInteractionProps>> = ({
  onMeasureLineCreated,
}) => {
  const { kmapInstance: kmap } = useMapContext()
  const { measuringLayer } = useMeasuringContext()
  const [isMounted, setIsMounted] = useState(false)
  const [id] = useState(v4())

  const labelFeature = useMemo(() => {
    const label = new Feature({
      geometry: new Point([]),
    })
    const style = labelStyle(kmap.getCurrentColorPalette())
    style.getText()?.setOffsetY(16)
    label.setStyle(style)
    measuringLayer.getSource()?.addFeature(label)
    return label
  }, [])

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = new LineString([from, to])
    const geom = labelFeature.getGeometry()
    if (geom instanceof Point) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('labelFeature geometry is not a Point')
    }
    const angle = Math.atan2(to[1] - from[1], to[0] - from[0])
    const text = assertIsStyle(labelFeature.getStyle()).getText()
    if (text) {
      text.setJustify('center')
      text.setTextBaseline('middle')
      text.setRotateWithView(true)
      const deg = Spatial.toDegrees(angle)
      text.setRotation(deg > 90 || deg < -90 ? -angle + Math.PI : -angle)
      text.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
    }
  }

  const drawInteraction = useMemo(() => {
    if (!isMounted) return null
    const interaction = new Draw({
      type: GeometryType.LINE_STRING,
      maxPoints: 2,
      stopClick: true,
      style: [editMeasureLineStyle, lineStyle, dotStyle],
    })

    kmap.enableSnap(true)
    interaction.on('drawstart', (e: DrawEvent) => {
      // shouldAddNewPoint = false
      kmap.addToSnapCollection(e.feature)
      kmap.resetSnapCollection()

      e.feature.getGeometry()?.on('change', (event) => {
        const line = event.target as LineString
        const coords = line.getCoordinates()
        updateLabel(coords[0], coords[1])
      })
    })
    interaction.on('drawend', (e: DrawEvent) => {
      const coords = (e.feature.getGeometry() as LineString).getCoordinates()
      const from = toLonLat(coords[0])
      const to = toLonLat(coords[1])
      onMeasureLineCreated({
        id,
        from: { lon: from[0], lat: from[1] },
        to: { lon: to[0], lat: to[1] },
        type: 'distance',
      })
      stop()
    })
    return interaction
  }, [kmap, isMounted])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    return () => {
      stop()
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [isMounted])

  const stop = () => {
    if (kmap && drawInteraction) {
      drawInteraction.abortDrawing()
      kmap.removeInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'auto'
      measuringLayer.getSource()?.removeFeature(labelFeature)
    }
  }

  return null
}

export default DrawMeasureLineInteraction
