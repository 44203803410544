import { IconButton, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowLeft, ArrowRight } from '@griegconnect/krakentools-react-icons';
import React from 'react';
import { BoardColumnHeaderProps } from './board-column';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrap: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '3.5em',
      width: '100%',
      alignItems: 'center',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    headerContentOpen: {
      flexGrow: 1,
      display: 'flex',
    },
    toggleWrap: {
      flexShrink: 0,
    },
    itemsInfo: {
      color: theme.palette.action.disabled,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  })
);

export type ColumnHeaderProps = {
  isCollapsible: boolean | undefined;
  isCollapsed: boolean;
  headerProps: BoardColumnHeaderProps;
  customCountCollapsed?: React.ReactNode;
  getItemsInfo: () => React.ReactNode | undefined;

  handleToggleCollapse: () => void;
};

export const ColumnHeader: React.FC<React.PropsWithChildren<ColumnHeaderProps>> = React.memo((props: ColumnHeaderProps) => {
  const { isCollapsible, isCollapsed, getItemsInfo, handleToggleCollapse, headerProps } = props;
  const { header } = headerProps;
  const classes = useStyles();

  const itemsInfo = getItemsInfo();

  return (
    <div className={classes.headerWrap}>
      {!isCollapsed && (
        <div className={classes.headerContentOpen}>
          <Typography variant="subtitle1" component="h1" color="inherit" noWrap={true}>
            {header}
          </Typography>
          {itemsInfo !== undefined && (
            <Typography variant="subtitle1" component="h1" color="inherit" noWrap={true} className={classes.itemsInfo}>
              {itemsInfo}
            </Typography>
          )}
        </div>
      )}
      {isCollapsible && (
        <Typography variant="subtitle1" component="h1" color="inherit" noWrap={true} className={classes.toggleWrap}>
          <IconButton onClick={handleToggleCollapse} size="large">{isCollapsed ? <ArrowRight /> : <ArrowLeft />}</IconButton>
        </Typography>
      )}
    </div>
  );
});
