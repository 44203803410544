import { Select, MenuItem, IconButton, Hidden, SelectChangeEvent } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Sorting as SortingIcon } from '@griegconnect/krakentools-react-icons'
import React, { PropsWithChildren, useEffect, useMemo, useState, useRef } from 'react'

export type SortOption<D> = {
  label: string
  key: string | number
  sortFn?: (data: D[]) => D[]
}

export type Props<D> = {
  placeholder?: string
  options: SortOption<D>[]
  onClientSideSortChange?: (sortFn: (data: D[]) => D[]) => void
  onServerSideSortChange?: (key: SortOption<D>['key']) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: theme.spacing(20),
      textOverflow: 'ellipsis',
      backgroundColor: theme.palette.action.hover,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    selectInput: {
      textOverflow: 'ellipsis',
      padding: theme.spacing(1),
    },
    iconButton: {},
  })
)

export function SortDropdown<D>({
  options,
  onClientSideSortChange,
  onServerSideSortChange,
  placeholder,
}: PropsWithChildren<Props<D>>): React.ReactElement {
  const [option, setOption] = useState<SortOption<D> | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const onChangeHandler = (event: SelectChangeEvent<string | number>, _child: React.ReactNode) => {
    const option = options.find((o) => o.key === event.target.value)
    setOption(option ?? null)
  }

  useEffect(() => {
    if (option) {
      if (option.sortFn) {
        onClientSideSortChange?.(option.sortFn)
      }
      onServerSideSortChange?.(option.key)
    }
  }, [option])

  const renderOptions = useMemo(
    () =>
      options.map((o) => {
        return (
          <MenuItem value={o.key} key={`select-option-${o.key}`}>
            {o.label}
          </MenuItem>
        )
      }),
    [options]
  )

  return (
    <div ref={wrapperRef}>
      <Hidden mdUp>
        <IconButton onClick={() => setOpen(true)} className={classes.iconButton} size="large">
          <SortingIcon />
        </IconButton>
      </Hidden>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={option?.key ?? ''}
        onChange={onChangeHandler}
        variant="filled"
        displayEmpty
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        MenuProps={{
          anchorEl: wrapperRef.current,
        }}
        inputProps={{
          className: classes.selectInput,
        }}
        className={classes.select}
      >
        <MenuItem value="" disabled>
          {placeholder ?? 'Sorting'}
        </MenuItem>
        {renderOptions}
      </Select>
    </div>
  )
}

export default SortDropdown
