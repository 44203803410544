import { Step as MuiStep, StepProps } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { StepClasskey } from '@mui/material/Step'
import clsx from 'clsx'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepClasskey>((theme) =>
  createStyles<StepClasskey, StepProps>({
    root: {
      boxShadow: theme.shadows[1],
    },
    horizontal: {},
    vertical: {},
    alternativeLabel: {},
    completed: {},
  })
)

function VerticalStep(props: StepProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiStep
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        horizontal: clsx(innerClasses.horizontal, classes?.horizontal),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
        completed: clsx(innerClasses.completed, classes?.completed),
      }}
    />
  )
}

export default VerticalStep
