import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useWidth } from '@griegconnect/krakentools-react-ui'

import { menuItemsAtom } from '../atoms/menuAtom'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { menuParentOpenAtom } from '../atoms/menuParentOpenAtom'

const MenuController = () => {
  const menuItems = useRecoilValue(menuItemsAtom)
  const [menuParentOpenState, setMenuParentOpenState] = useRecoilState(menuParentOpenAtom)
  const [leftDrawerState, setLeftDrawerState] = useRecoilState(leftDrawerAtom)
  const { pathname: activePath } = useLocation()
  const width = useWidth()

  // Handle menu when opening or closing the drawer
  useEffect(() => {
    if (!leftDrawerState.isOpen) {
      setTimeout(() => {
        setMenuParentOpenState('')
      }, 200)
    }
    if (leftDrawerState.isOpen && menuParentOpenState === '') {
      // If a top level with sub menu, or a sub menu is active, open the top level/parent
      setTimeout(() => {
        const activeItem = menuItems.find(
          (menuItem) =>
            menuItem.items &&
            ((menuItem.absolutePath !== undefined && activePath.indexOf(menuItem.absolutePath) === 0) ||
              menuItem.items?.find((childItem) => activePath === childItem.absolutePath))
        )
        if (activeItem) {
          setMenuParentOpenState(`${activeItem.absolutePath}-${activeItem.name}`)
        }
      }, 200)
    }
  }, [leftDrawerState])

  // Handle menu when navigating
  useEffect(() => {
    if (leftDrawerState.isOpen && menuParentOpenState !== '') {
      // If a top level with sub menu, or a sub menu is active, open the top level/parent
      setTimeout(() => {
        const activeItem = menuItems.find(
          (menuItem) =>
            menuItem.items &&
            ((menuItem.absolutePath !== undefined && activePath.indexOf(menuItem.absolutePath) === 0) ||
              menuItem.items?.find((childItem) => activePath === childItem.absolutePath))
        )
        if (activeItem) {
          setMenuParentOpenState(`${activeItem.absolutePath}-${activeItem.name}`)
        }
      }, 200)
    }
  }, [activePath])

  // Handle menu on small devices
  useEffect(() => {
    if (leftDrawerState.isOpen && width === 'sm') {
      setTimeout(() => {
        setLeftDrawerState({ ...leftDrawerState, isOpen: false })
      }, 200)
    }
  }, [activePath, width])

  return null
}

export default MenuController
