import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { BoardColumnProps, DragProps, getColumnItemsInfo } from './board-column';
import { BoardTrack, BoardTrackInfo, TrackItems, ColumnTracks } from './board-track';
import { ColumnHeader } from './column-header';
import { ColumnLayout } from './column-layout';
import { ColumnsContainer } from './columns-container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tracksContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    track: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  })
);

export type BoardTracksProps = {
  id: string;
  tracks: BoardTrackInfo[];
  columns: BoardColumnProps[];
};

export type BoardTracksComponentProps = BoardTracksProps & {
  dragProps: DragProps;
};

const getTracksColumns = (
  props: BoardTracksComponentProps,
  columnsCollapsed: { [key: string]: boolean }
): ColumnTracks[] =>
  props.columns.map((col) => {
    const tracksItems = props.tracks.map(
      (track): TrackItems => {
        return {
          trackId: track.id,
          items: col.items.filter((i) => i.trackId === track.id),
        };
      }
    );

    return {
      column: col,
      tracksItems: tracksItems,
      isCollapsed: columnsCollapsed[col.id],
    };
  });

export const BoardTracks = React.memo((props: BoardTracksComponentProps) => {
  const classes = useStyles();

  const [tracksColumns, setTracksColumns] = useState<ColumnTracks[]>([]);

  const getInitialColumnsCollapsed = (): { [key: string]: boolean } => {
    let result: { [key: string]: boolean } = {};
    props.columns.forEach((c) => (result[c.id] = c.isStartCollapsed === true));
    return result;
  };

  const [columnsCollapsed, setColumnsCollapsed] = useState<{
    [key: string]: boolean;
  }>(getInitialColumnsCollapsed());
  const onToggleCollapseColumn = (colId: string) => {
    const newColumnsCollapsed: { [key: string]: boolean } = {
      ...columnsCollapsed,
    };
    newColumnsCollapsed[colId] = !newColumnsCollapsed[colId];

    setColumnsCollapsed(newColumnsCollapsed);
  };

  useEffect(() => {
    setTracksColumns(getTracksColumns(props, columnsCollapsed));
  }, [props, columnsCollapsed]);

  const countItems = (columnId: string) => props.columns.find((c) => c.id === columnId)?.items?.length ?? 0;

  const openCount = Object.keys(columnsCollapsed).filter((id) => !columnsCollapsed[id]).length;
  const collapsedCount = props.columns.length - openCount;

  return (
    <ColumnLayout
      openColumnsCount={openCount}
      columnsCount={props.columns.length}
      disableSpacing={true}
      isCollapsed={openCount === 0}
      collapsedChildColumns={collapsedCount}
    >
      <div className={classes.tracksContent}>
        {/* Headers */}
        <ColumnsContainer>
          {props.columns.map((columnProps) => (
            <ColumnLayout
              key={columnProps.id}
              openColumnsCount={1}
              columnsCount={1}
              isCollapsed={columnsCollapsed[columnProps.id]}
            >
              <ColumnHeader
                isCollapsible={true}
                isCollapsed={columnsCollapsed[columnProps.id]}
                handleToggleCollapse={() => onToggleCollapseColumn(columnProps.id)}
                headerProps={columnProps.headerProps}
                getItemsInfo={() =>
                  getColumnItemsInfo(false, columnProps.headerProps, () => countItems(columnProps.id))
                }
              />
            </ColumnLayout>
          ))}
        </ColumnsContainer>

        {/* Tracks */}
        {props.tracks.map((track) => (
          <div key={track.id} className={classes.track}>
            <BoardTrack track={track} columnsTracks={tracksColumns} dragProps={props.dragProps} />
          </div>
        ))}
      </div>
    </ColumnLayout>
  );
});
