import { StepLabel as MuiStepLabel, StepLabelClasskey } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { StepLabelProps } from '@mui/material/StepLabel'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepLabelClasskey>(() =>
  createStyles<StepLabelClasskey, StyleProps>({
    root: {},
    error: {},
    active: {},
    alternativeLabel: {},
    completed: {},
    disabled: {},
    horizontal: {},
    iconContainer: {},
    label: {},
    labelContainer: {},
    vertical: {},
  })
)

function StepLabel(props: StepLabelProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiStepLabel
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        labelContainer: clsx(innerClasses.labelContainer, classes?.labelContainer),
        label: clsx(innerClasses.label, classes?.label),
        iconContainer: clsx(innerClasses.iconContainer, classes?.iconContainer),
        horizontal: clsx(innerClasses.horizontal, classes?.horizontal),
        disabled: clsx(innerClasses.disabled, classes?.disabled),
        completed: clsx(innerClasses.completed, classes?.completed),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
        active: clsx(innerClasses.active, classes?.active),
        error: clsx(innerClasses.error, classes?.error),
      }}
    />
  )
}

export default StepLabel
