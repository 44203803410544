import { Checkbox } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react'

import { SelectedContext, StringKeyOf } from './KrakenTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      padding: theme.spacing(1.5),
    },
  })
)

export type Props<D> = {
  data: D
  dataKey: StringKeyOf<D>
  onToggle: (selected: D) => void
}

export function CheckboxCell<D>({ data, onToggle, dataKey }: PropsWithChildren<Props<D>>): React.ReactElement {
  const selected = useContext(SelectedContext)
  const classes = useStyles()

  const isSelected = useMemo(() => {
    return selected.some((s) => s[dataKey] === data[dataKey])
  }, [data, selected])

  const onClickHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      onToggle(data)
    },
    [data, onToggle]
  )

  return <Checkbox onClick={onClickHandler} checked={isSelected} className={classes.checkbox} />
}

export default CheckboxCell
