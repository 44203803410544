import { createContext, useContext } from 'react'
import { KrakenMap, KMapLayer, colorPalette } from '@griegconnect/krakentools-kmap'
import { MonitorApi, MonitorMap } from './apis/MonitorApi'
import { NotificationHandlerParams } from './MapProvider'

type MapContext = {
  mapIdentifierSlug: string
  kmapInstance: KrakenMap
  maps: MonitorMap[]
  layers: KMapLayer[]
  notify?: (params: NotificationHandlerParams) => void
  refetchMapsAndLayers: () => void
  applyMap: (id: string) => void
  getToken: () => Promise<string | undefined>
  colorPalette: colorPalette
  monitorApi?: MonitorApi
}

export const MapContext = createContext<MapContext | null>(null)
export const useMapContext = () => useContext(MapContext)!
