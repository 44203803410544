import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useApplicationContext } from '..'
import { useConfig } from '../hooks'

const ConfigError = () => {
  const application = useApplicationContext()
  const config = useConfig()

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ mr: 'auto', ml: 'auto', minHeight: '100vh', maxWidth: 600 }}
      >
        <Grid sx={{ width: '100%', maxWidth: 600 }}>
          <Typography variant="h1">{application.name} can't start</Typography>
          <div style={{ marginBottom: 24 }}>
            <Typography>
              The application was unable to start correctly because of an error occurred, please try again or contact
              support.
            </Typography>
            {config.initErrorMessage && (
              <Typography variant="body2" color="error.light">
                Error message: {config.initErrorMessage}
              </Typography>
            )}
          </div>
          <Button variant="contained" onClick={() => (window.location.href = '/')}>
            Restart application
          </Button>

          {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && (
            <Paper sx={{ p: 3, mt: 3 }}>
              <Typography sx={{ color: 'warning.main' }} gutterBottom>
                Please check that your config file (configurationFilePath) can be reached.
              </Typography>
              <Typography variant="body2" component="div">
                <pre style={{ overflow: 'auto' }}>{JSON.stringify(application, null, 2)}</pre>
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ConfigError
