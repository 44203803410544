import { MoreVertical as MoreVerticalIcon } from '@griegconnect/krakentools-react-icons'
import { Box, IconButton, Hidden, Fab } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, { useRef } from 'react'

export type Props = {
  onActionsClick: (refElement: HTMLButtonElement) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(0.75),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(6),
      right: theme.spacing(3),
    },
  })
)

export const ActionsButton: React.FC<React.PropsWithChildren<Props>> = ({ onActionsClick }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const fabRef = useRef<HTMLButtonElement>(null)
  const classes = useStyles()

  const onClickHandler = () => {
    if (buttonRef.current) {
      onActionsClick(buttonRef.current)
    } else if (fabRef.current) {
      onActionsClick(fabRef.current)
    }
  }

  return (
    <Box>
      <Hidden mdDown>
        <Box marginLeft={1}>
          <IconButton
            onClick={onClickHandler}
            ref={buttonRef}
            className={classes.button}
            size="large">
            <MoreVerticalIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Fab className={classes.fab} onClick={onClickHandler} ref={fabRef} color="primary">
          <MoreVerticalIcon />
        </Fab>
      </Hidden>
    </Box>
  );
}

export default ActionsButton
