import React, { ChangeEvent, HTMLAttributes, ReactNode, useEffect, useState } from 'react'

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Skeleton,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { useAsyncDebouncer } from '../utils'
import { DelayedSpinner } from '../delayed-spinner/DelayedSpinner'
import StyledSelectPopper from './StyledPaper'
import ReadOnlySelect from './ReadOnlySelect'

export type AsyncSelectOption = {
  [x: string]: unknown
  value: string | number
  label: string
}

export type AsyncSelectFetch = Required<Pick<AutocompleteAsyncProps, 'fetchOptions' | 'fetchOptionById'>>
export interface AutocompleteAsyncProps {
  name?: string
  label?: string
  value: string | number | null
  disabled?: boolean
  required?: boolean
  isLoading?: boolean
  autoFocus?: boolean
  styles?: {
    root?: string
  }
  variant?: 'filled' | 'outlined'
  readOnly?: boolean
  error?: boolean
  onChange: (value: AsyncSelectOption | null) => void
  endAdornment?: (option: AsyncSelectOption | null) => React.ReactNode
  startAdornment?: (option: AsyncSelectOption | null) => React.ReactNode
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: AsyncSelectOption,
    state: AutocompleteRenderOptionState
  ) => ReactNode
  fetchOptions: (input: string) => Promise<AsyncSelectOption[]>
  fetchOptionById?: (id: string | number) => Promise<AsyncSelectOption>
  onInputChanged?: (value: string) => void
  onOpen?: () => void
  onClose?: () => void
  sx?: SxProps
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      width: '20px !important',
      height: '20px !important',
      marginLeft: theme.spacing(2),
    },
  })
)

export const AutocompleteAsync = ({
  autoFocus,
  disabled,
  error,
  isLoading,
  label,
  name,
  readOnly,
  required,
  styles,
  value,
  endAdornment,
  fetchOptionById,
  fetchOptions,
  onChange,
  onInputChanged,
  renderOption,
  startAdornment,
  variant = 'filled',
  sx,
  ...other
}: AutocompleteAsyncProps): JSX.Element => {
  const classes = useStyles()

  const [isFetchingOptions, setIsFetchingOptions] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  const [optionValues, setOptionValues] = useState<AsyncSelectOption[]>([])
  const [currentOption, setCurrentOption] = useState<AsyncSelectOption | null>(null)

  useEffect(() => {
    if (value === null) {
      setCurrentOption(null)
      setIsInitialized(true)
    } else if (value && currentOption?.value !== value) {
      setIsFetchingOptions(true)
      setCurrentOption(null)
      fetchOptionById?.(value)
        .then((option) => {
          setOptionValues([option])
          setCurrentOption(option)
        })
        .catch((error) => {
          setCurrentOption(currentOption)
          console.error(`Error fetching initial option for ${name}, value: ${value}`, error)
        })
        .finally(() => {
          setIsFetchingOptions(false)
          setIsInitialized(true)
        })
    } else setIsInitialized(true)
  }, [currentOption, fetchOptionById, name, value])

  const debouncedFetch = useAsyncDebouncer(fetchOptions, 500)

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newInput = event.target.value
    setIsFetchingOptions(true)

    debouncedFetch(newInput)
      .then((result) => {
        // Make sure current option is included in result
        if (currentOption && !result.some((o) => o.value === currentOption.value)) {
          result.splice(0, 0, currentOption)
        }

        setOptionValues(result)
        setIsFetchingOptions(false)
      })
      .catch((error) => {
        console.error(`${name} - Fetch failed`, error)
      })
  }

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, value: AsyncSelectOption | null) => {
    setCurrentOption(value)
    onChange(value)
  }

  const getStartAdornment = (autoCompleteParams: AutocompleteRenderInputParams): React.ReactNode | undefined => {
    const propsAdornment = startAdornment
    const inputAdornment = autoCompleteParams.InputProps.startAdornment

    return !propsAdornment ? (
      inputAdornment
    ) : !inputAdornment ? (
      propsAdornment(currentOption)
    ) : (
      <>
        {propsAdornment(currentOption)}
        {inputAdornment}
      </>
    )
  }

  const getEndAdornment = (autoCompleteParams: AutocompleteRenderInputParams): React.ReactNode | undefined => {
    const propsAdornment = endAdornment
    const inputAdornment = autoCompleteParams.InputProps.endAdornment

    const adornment = !propsAdornment ? (
      inputAdornment
    ) : !inputAdornment ? (
      propsAdornment
    ) : (
      <>
        {propsAdornment}
        {inputAdornment}
      </>
    )

    return isFetchingOptions ? (
      <>
        {adornment}
        <DelayedSpinner className={classes.spinner} />
      </>
    ) : (
      <>{adornment}</>
    )
  }

  if (!isInitialized) return <Skeleton width={'100%'} height={'50%'} />

  if (readOnly)
    return <ReadOnlySelect startAdornment={startAdornment} item={currentOption} label={currentOption?.label} />

  return (
    <Autocomplete
      options={optionValues}
      filterOptions={(x) => x}
      getOptionLabel={(option: AsyncSelectOption) => option.label}
      renderOption={renderOption}
      isOptionEqualToValue={(option: AsyncSelectOption, value: AsyncSelectOption) => option.value === value?.value}
      value={currentOption}
      loading={isFetchingOptions}
      disabled={disabled || isLoading}
      onChange={handleChange}
      PopperComponent={StyledSelectPopper}
      classes={{
        root: clsx(styles?.root),
      }}
      sx={sx}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            {...params}
            name={name}
            variant={variant}
            label={label ?? undefined}
            onChange={handleInputChange}
            required={required}
            autoFocus={autoFocus}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: getEndAdornment(params),
              startAdornment: getStartAdornment(params),
            }}
          />
        )
      }}
      {...other}
    />
  )
}

export default AutocompleteAsync
