import { Share } from '@mui/icons-material'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/defaultstyles'

export const routes = [
  {
    name: 'Default Styles',
    page: React.createElement(React.lazy(() => import('./DefaultStyles'))),
    menu: {
      icon: <Share />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
]
