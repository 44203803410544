import { Box, Button, IconButton, Hidden } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Filter as FilterIcon } from '@griegconnect/krakentools-react-icons'
import React, { useRef } from 'react'

export type Props = {
  label?: string
  onFilterClick: (refElement: HTMLButtonElement) => void
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    iconButton: {},
  })
)

export const FilterButton: React.FC<React.PropsWithChildren<Props>> = ({ label, onFilterClick }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const iconButtonRef = useRef<HTMLButtonElement>(null)
  const classes = useStyles()
  const onClickHandler = () => {
    if (buttonRef.current) {
      onFilterClick(buttonRef.current)
    } else if (iconButtonRef.current) {
      onFilterClick(iconButtonRef.current)
    }
  }

  return (
    <Box marginLeft={2}>
      <Hidden mdDown>
        <Button variant="outlined" onClick={onClickHandler} ref={buttonRef}>
          {label ?? 'Filter'}
        </Button>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          onClick={onClickHandler}
          ref={iconButtonRef}
          className={classes.iconButton}
          size="large">
          <FilterIcon />
        </IconButton>
      </Hidden>
    </Box>
  );
}

export default FilterButton
