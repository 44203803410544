import { Alert, Box, Button, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import useTheme from '@mui/styles/useTheme'
import { SnackbarKey } from 'notistack'
import React, { useMemo } from 'react'
import { Action, SnackbarMenu } from './SnackbarMenu'

export type DefaultSnackbarProps = {
  alertKey: SnackbarKey
  message: string
  size: 'small' | 'normal'
  primaryAction: Action
  secondaryActions?: Action[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      paddingTop: theme.spacing(1.125),
      paddingBottom: theme.spacing(1.125),
      display: 'flex',
      alignItems: 'center',
    },
    action: {
      padding: 0,
      alignItems: 'center',
    },
  })
)

export const DefaultSnackbar = React.forwardRef<HTMLDivElement, DefaultSnackbarProps>((props, ref) => {
  const classes = useStyles()
  const theme = useTheme()

  const secondaryActions = useMemo(() => {
    if (props.secondaryActions) {
      if (props.secondaryActions.length > 1 || props.size === 'small') {
        return <SnackbarMenu actions={props.secondaryActions} />
      } else {
        return (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={props.secondaryActions[0].callback}
            sx={{ marginX: 1, marginY: 1 }}
          >
            {props.secondaryActions[0].label}
          </Button>
        )
      }
    }
  }, [props.size, props.secondaryActions])

  return (
    <Alert
      variant="filled"
      icon={false}
      ref={ref}
      classes={classes}
      sx={{ boxShadow: theme.shadows[6] }}
      action={
        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" alignItems="center">
          {props.secondaryActions === undefined ||
            (props.secondaryActions.length === 1 && props.size !== 'small' && secondaryActions)}
          <Button
            variant={
              props.size === 'small' || (props.secondaryActions && props.secondaryActions.length > 1)
                ? 'outlined'
                : 'contained'
            }
            sx={{ marginX: 1, marginY: props.size === 'small' ? 0 : 1 }}
            color={'primary'}
            size={props.size === 'small' ? 'small' : 'large'}
            onClick={props.primaryAction.callback}
          >
            {props.primaryAction.label}
          </Button>
          {props.secondaryActions &&
            (props.secondaryActions.length > 1 || (props.secondaryActions.length === 1 && props.size === 'small')) &&
            secondaryActions}
        </Box>
      }
    >
      <Typography variant="body2">{props.message}</Typography>
    </Alert>
  )
})

export default DefaultSnackbar
