import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#2f8ea2',
  light: '#67bed3',
  dark: '#006073',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#85d9ea',
}

export default Color
