import { StepIcon as MuiStepIcon, StepIconClasskey, StepIconProps } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps, StepIconClasskey>((theme: Theme) =>
  createStyles<StepIconClasskey, StyleProps>({
    root: {},
    text: {},
    active: {},
    completed: {
      color: theme.palette.secondary.main,
    },
    error: {},
  })
);

function VerticalStepIcon(props: StepIconProps) {
  const { classes, ...other } = props;
  const innerClasses = useStyles();
  return (
    <MuiStepIcon
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        text: clsx(innerClasses.text, classes?.text),
        active: clsx(innerClasses.active, classes?.active),
        completed: clsx(innerClasses.completed, classes?.completed),
        error: clsx(innerClasses.error, classes?.error),
      }}
    />
  );
}

export default VerticalStepIcon;
