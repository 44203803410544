import { atomFamily, DefaultValue, selectorFamily } from 'recoil'
import { Vessel } from '@griegconnect/krakentools-kmap'
import { LayerMenuItem } from './types/LayerMenuItem'
import { MapIdentifierType } from './types/MapIdentifierType'
import { selectedAssetAtom } from './assetViewAtoms'
import { getFromLocalStorage, KrakenMapStorageState, setToLocalStorage } from '../utils/localStorageStore'
import { useLocalStorageAtom } from './mapConfigAtoms'
import { VesselFilter } from '@griegconnect/krakentools-kmap'
/* Live View Layers */

export const enableLiveViewAtom = atomFamily<boolean, MapIdentifierType>({
  key: 'enableLiveView',
  default: true,
})

export const vesselOpacityAtom = atomFamily<number, MapIdentifierType>({
  key: 'vesselOpacity',
  default: 1,
})

export const activeLiveLayersAtom = atomFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeLiveLayersState',
  default: [],
})

export const activeLiveLayersSelector = selectorFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeLiveLayersSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const layers = get(activeLiveLayersAtom(mapIdentifier))
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (useLocalStorage) {
        const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
        if (state?.layers?.liveView) {
          return state.layers.liveView
        } else {
          return layers
        }
      } else {
        return layers
      }
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, newActiveLayers) => {
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (newActiveLayers instanceof DefaultValue) {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.layers?.liveView) {
            existing.layers.liveView = []
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          }
        }
        reset(activeLiveLayersAtom(mapIdentifier))
      } else {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.layers) {
            existing.layers.liveView = newActiveLayers
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          } else {
            const newState: KrakenMapStorageState = {
              ...existing,
              layers: {
                ...existing?.layers,
                liveView: newActiveLayers,
              },
            }
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
          }
        }
        set(activeLiveLayersAtom(mapIdentifier), newActiveLayers)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const activeLiveLabelsAtom = atomFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeLiveLabelsState',
  default: [],
})

export const liveLayersAtom = atomFamily<LayerMenuItem[], MapIdentifierType>({
  key: 'liveLayersState',
  default: (mapIdentifier: MapIdentifierType) => [],
})

export const allLiveViewLayersVisibleSelector = selectorFamily<boolean, MapIdentifierType>({
  key: 'allLiveViewLayersVisibleSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const allVisibleLayers = get(activeLiveLayersSelector(mapIdentifier))
      const isAllLiveLayers = get(liveLayersAtom(mapIdentifier)).every((l) => allVisibleLayers.includes(l.key))
      return isAllLiveLayers
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, allLiveLayersVisible) => {
      if (allLiveLayersVisible instanceof DefaultValue) {
        reset(activeLiveLayersSelector(mapIdentifier))
      } else {
        if (allLiveLayersVisible === false) {
          set(activeLiveLayersSelector(mapIdentifier), [])
        } else {
          // Live
          const availableLiveLayers = get(liveLayersAtom(mapIdentifier)).map((l) => l.key)
          set(activeLiveLayersSelector(mapIdentifier), availableLiveLayers)
        }
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

/* Live View Actions */

export const selectedVesselAtom = atomFamily<Vessel | null, MapIdentifierType>({
  key: 'selectedVesselState',
  default: (mapIdentifier: MapIdentifierType) => null,
})

export const selectedVesselSelector = selectorFamily<Vessel | null, MapIdentifierType>({
  key: 'selectedVesselSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      return get(selectedVesselAtom(mapIdentifier))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, selectedVessel) => {
      if (selectedVessel instanceof DefaultValue) {
        reset(selectedVesselAtom(mapIdentifier))
      } else {
        reset(selectedAssetAtom(mapIdentifier))
        set(selectedVesselAtom(mapIdentifier), selectedVessel)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const followedVesselAtom = atomFamily<Vessel | null, MapIdentifierType>({
  key: 'followedVesselState',
  default: null,
})

export const followedVesselSelector = selectorFamily<Vessel | null, MapIdentifierType>({
  key: 'followedVesselSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      return get(followedVesselAtom(mapIdentifier))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, followedVessel) => {
      if (followedVessel instanceof DefaultValue) {
        reset(followedVesselAtom(mapIdentifier))
      } else {
        reset(selectedAssetAtom(mapIdentifier))
        reset(selectedVesselAtom(mapIdentifier))
        set(followedVesselAtom(mapIdentifier), followedVessel)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const displayVesselTrailsAtom = atomFamily<Vessel[], MapIdentifierType>({
  key: 'displayVesselTrailsState',
  default: [],
})

export const visibleVesselsAtom = atomFamily<Vessel[], MapIdentifierType>({
  key: 'visibleVesselsState',
  default: (mapIdentifier: MapIdentifierType) => [],
})

export const vesselFilterAtom = atomFamily<VesselFilter, MapIdentifierType>({
  key: 'vesselFilterState',
  default: (mapIdentifier: MapIdentifierType) => ({source: 'ais'}),
})
