import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import React, { FC } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '../text-field'
import FieldError from '../../form/field-error'
import { FilledTextFieldProps } from '@mui/material/TextField'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    inputContainer: {
      marginBottom: theme.spacing(2),
    },
  })
)

type TextInputStyleProps = {
  classes?: {
    inputContainer?: string
  }
}

export type TextInputProps = {
  errorMessage?: string
} & TextInputStyleProps &
  Omit<FilledTextFieldProps, 'variant'>

const CoreTextInput = (props: TextInputProps) => {
  const { id, name, value, label, disabled, errorMessage, ...other } = props

  return (
    <>
      <TextField
        fullWidth
        id={id ?? name}
        name={name}
        value={value || ''}
        disabled={disabled}
        label={label}
        error={!!errorMessage}
        {...other}
        variant="filled"
      />
      {errorMessage && <FieldError errorMessage={errorMessage} />}
    </>
  )
}

const TextInput: FC<React.PropsWithChildren<TextInputProps>> = (props: TextInputProps) => {
  const innerClasses = useStyles()
  const { classes, className, ...other } = props
  return (
    <div className={clsx(innerClasses.inputContainer, classes?.inputContainer, className)}>
      <CoreTextInput classes={classes} {...other} />
    </div>
  )
}

export default TextInput
