import { Popper, PopperProps } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'

const StyledPopper = styled(Popper)({
  minWidth: 400,
})

const StyledSelectPopper = (props: PopperProps) => {
  return <StyledPopper {...props} placement={'bottom-start'} />
}
export default StyledSelectPopper
