import React, { useState } from 'react'
import { Popper, Paper, ClickAwayListener, IconButton, Typography } from '@mui/material'
import { Applications } from '@griegconnect/krakentools-react-icons'
import { Close } from '@griegconnect/krakentools-react-icons'

import AppSelection, { Application } from './components/app-selection'
import TenantSelection, { Tenant } from './components/tenant-selection'

import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => ({
  appSelectionWrapper: {
    display: 'flex',
  },
  activeTenant: {
    padding: theme.spacing(0.5),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appSelection: {
    padding: theme.spacing(1),
  },
  appSelectionWithTenant: {
    padding: theme.spacing(1),
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: theme.spacing(3),
    width: '328px',
  },
}))

export type GlobalContextNavProps = {
  enableAppSelection?: boolean
  applications?: Application[]
  enableTenantSelection?: boolean
  activeTenant?: Tenant
  tenants?: Tenant[]
  onTenantChange?: (tenant: Tenant) => void
  className?: string
}

export const GlobalContextNav = (props: GlobalContextNavProps) => {
  const { enableAppSelection, applications, enableTenantSelection, activeTenant, tenants, onTenantChange, className } =
    props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return
    } else {
      setAnchorEl(null)
    }
  }

  const open = Boolean(anchorEl)

  return (
    <>
      {(enableTenantSelection || enableAppSelection) && (
        <>
          <div className={`${classes.appSelectionWrapper} ${className}`}>
            {enableTenantSelection && activeTenant && (
              <Typography color="textSecondary" variant="body2" className={classes.activeTenant}>
                {activeTenant.name}
              </Typography>
            )}
            <IconButton
              color="inherit"
              className={classes.appSelection}
              onClick={handleClick}
              id="toggle-global-nav"
              size="large"
            >
              {open ? <Close /> : <Applications />}
            </IconButton>
          </div>
          <Popper
            open={open}
            anchorEl={anchorEl}
            disablePortal={true}
            modifiers={[
              {
                name: 'flip',
              },
              {
                name: 'preventOverflow',
              },
              {
                name: 'arrow',
              },
            ]}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper elevation={3} className={classes.paper}>
                {enableTenantSelection && tenants && tenants.length > 0 && (
                  <TenantSelection tenants={tenants} activeTenant={activeTenant} onTenantChange={onTenantChange} />
                )}
                {enableAppSelection && applications && applications.length > 0 && (
                  <AppSelection applications={applications} />
                )}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    </>
  )
}

export default GlobalContextNav
