import { Box, IconButton, Card, CardActions, CardHeader, CardContent, Collapse } from '@mui/material'
import { Theme, CSSObject } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import React, { forwardRef, useMemo, useState } from 'react'
import {
  Close as CloseIcon,
  ArrowDown as ArrowDownIcon,
  ArrowUp as ArrowUpIcon,
} from '@griegconnect/krakentools-react-icons'

export type MapDialogProps = {
  title: string
  subtitle?: React.ReactNode
  arrow?: 'top' | 'right' | 'bottom' | 'left'
  onClose: () => void
  primary?: React.ReactNode | React.ReactNode[]
  secondary?: React.ReactNode | React.ReactNode[]
  children?: React.ReactNode | React.ReactNode[]
  actions?: React.ReactNode | React.ReactNode[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    arrowDown: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderTop:
        '22px solid ' + (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor,
      borderRadius: 4,
      bottom: -16,
      left: 'calc(50% - 16px)',
    },
    arrowUp: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderBottom:
        '22px solid ' + (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor,
      borderRadius: 4,
      top: -20,
      left: 'calc(50% - 16px)',
    },
    arrowLeft: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderRight:
        '22px solid ' + (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor,
      borderRadius: 4,
      bottom: 'calc(50% - 16px)',
      left: -16,
    },
    arrowRight: {
      position: 'absolute',
      width: 0,
      height: 0,
      borderTop: '16px solid transparent',
      borderBottom: '16px solid transparent',
      borderLeft:
        '22px solid ' + (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor,
      borderRadius: 4,
      bottom: 'calc(50% - 16px)',
      right: -20,
    },
    arrowNone: {},
    paper: {
      borderRadius: theme.shape.borderRadius,
      width: 400,
    },
    actions: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export const MapDialog = forwardRef<HTMLDivElement, MapDialogProps>((props, ref) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const classes = useStyles()
  const getArrowClass = useMemo(() => {
    switch (props.arrow) {
      case 'top':
        return classes.arrowUp
      case 'right':
        return classes.arrowRight
      case 'bottom':
        return classes.arrowDown
      case 'left':
        return classes.arrowLeft
      default:
        return classes.arrowNone
    }
  }, [props.arrow])

  const onToggleCollapseHandler = () => {
    setCollapsed((prev) => !prev)
  }

  return (
    <div className={classes.wrapper} ref={ref}>
      <Box className={getArrowClass} />
      <Card className={classes.paper} elevation={24}>
        <CardHeader
          title={props.title}
          subheader={props.subtitle}
          action={
            <IconButton onClick={props.onClose} size="small">
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent classes={{ root: classes.content }}>
          {props.primary ?? props.children}
          <Collapse in={collapsed} timeout="auto" unmountOnExit>
            {props.secondary}
          </Collapse>
        </CardContent>
        {props.secondary && (
          <Box className={classes.actions}>
            <IconButton size="small" onClick={onToggleCollapseHandler}>
              {collapsed ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          </Box>
        )}
        <Box className={classes.actions}>{props.actions && <CardActions>{props.actions}</CardActions>}</Box>
      </Card>
    </div>
  )
})
