import { Error as ErrorIcon } from '@griegconnect/krakentools-react-icons'
import { SnackbarKey, useSnackbar as useNotistackHook } from 'notistack'
import { Alert } from '@mui/material'
import React, { useCallback } from 'react'
import useTheme from '@mui/styles/useTheme'
import { AlertMessage } from '../../hooks/useAlerts'

export type ErrorAlertProps = {
  alertKey: SnackbarKey
  message: AlertMessage
}

export const ErrorAlert = React.forwardRef<HTMLDivElement, ErrorAlertProps>((props, ref) => {
  const { closeSnackbar } = useNotistackHook()
  const theme = useTheme()

  const onCloseHandler = useCallback(() => {
    closeSnackbar(props.alertKey)
  }, [props.alertKey])

  return (
    <Alert
      variant="filled"
      severity="error"
      icon={<ErrorIcon />}
      ref={ref}
      onClose={onCloseHandler}
      sx={{ boxShadow: theme.shadows[6] }}
    >
      {props.message}
    </Alert>
  )
})

export default ErrorAlert
