import { Box, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import WidgetCard from './widget-card'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { ArrowCircleUp, ArrowCircleDown } from '@griegconnect/krakentools-react-icons'
import React from 'react'
import { IWidgetCardProps } from './widget-card'

const useStyles = makeStyles((theme: Theme) => ({
  positive: {
    color: theme.palette.success.main,
    paddingLeft: '5px',
  },
  negative: {
    color: theme.palette.error.main,
    paddingLeft: '5px',
  },
}))

type Direction = 'up' | 'down'

interface ITrendWidgetProps extends IWidgetCardProps {
  value: number | string
  percentage: number
  direction: Direction
  type: 'trend' | 'arrow'
  positiveValues?: Direction
}

export const TrendWidget: React.VFC<ITrendWidgetProps> = ({
  value,
  percentage,
  direction,
  type,
  positiveValues = 'up',
  ...props
}) => {
  const classes = useStyles()

  return (
    <WidgetCard {...props}>
      <Box display="flex" flex={1} alignItems="flex-end" justifyContent="flex-start" height="100%">
        <Typography variant="h1" component="h1">
          {value}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {type === 'trend' && direction === 'up' && <TrendingUpIcon className={classes.positive} />}
        {type === 'trend' && direction === 'down' && <TrendingDownIcon className={classes.negative} />}
        {type === 'arrow' && direction === 'up' && <ArrowCircleUp />}
        {type === 'arrow' && direction === 'down' && <ArrowCircleDown />}
        <Typography
          variant="body1"
          className={percentage === 0 || direction === positiveValues ? classes.positive : classes.negative}
        >
          {direction === 'up' ? '+' : '-'}
          {percentage} %
        </Typography>
      </Box>
    </WidgetCard>
  )
}

export default TrendWidget
