import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import type { Tool } from '../themes/createKrakenTheme'
import Fade from '@mui/material/Fade'

const PREFIX = 'Splash'

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  progress: `${PREFIX}-progress`,
  progressRoot: `${PREFIX}-progressRoot`,
  progressBar: `${PREFIX}-progressBar`,
  logoContainer: `${PREFIX}-logoContainer`,
  logo: `${PREFIX}-logo`,
  message: `${PREFIX}-message`,
  copyright: `${PREFIX}-copyright`,
  kraken: `${PREFIX}-kraken`,
  griegConnectLogo: `${PREFIX}-krakenLogo`,
  image: `${PREFIX}-image`,
}

const StyledPaper = styled(Paper)<StyleProps>(({ theme, applicationimageurl }) => {
  return {
    [`&.${classes.root}`]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      zIndex: theme.zIndex.modal,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
      },
      [theme.breakpoints.up('md')]: {
        boxShadow: theme.shadows[4],
      },
    },
    [`& .${classes.content}`]: {
      display: 'grid',
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        gridTemplateColumns:
          '[progress-column-start logo-column-start copyright-column-start kraken-column-start image-column-start] 100% [progress-column-end logo-column-end copyright-column-end kraken-column-end image-column-end]',
        gridTemplateRows:
          '[progress-row-start logo-row-start image-row-start] 60% [progress-row-end logo-row-end copyright-row-start] 1fr [copyright-row-end kraken-row-start] auto [kraken-row-end image-row-end] 0',
        height: '100%',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns:
          '[progress-column-start logo-column-start kraken-column-start copyright-column-start] 1fr [progress-column-end  logo-column-end kraken-column-end copyright-column-end image-column-start] 33% [image-column-end]',
        gridTemplateRows:
          '[progress-row-start logo-row-start image-row-start] 50% [progress-row-end logo-row-end copyright-row-start] 1fr [copyright-row-end kraken-row-start] 25% [kraken-row-end image-row-end]',
        width: '960px',
        height: '540px',
      },
    },
    [`& .${classes.progress}`]: {
      gridArea: 'progress-row-start / progress-column-start / progress-row-end / progress-column-end',
      borderTopLeftRadius: 4,
    },
    [`& .${classes.progressRoot}`]: {
      backgroundColor: theme.palette.primary.light,
    },
    [`& .${classes.progressBar}`]: {
      backgroundColor: theme.palette.primary.main,
    },
    [`& .${classes.logoContainer}`]: {
      display: 'flex',
      position: 'relative',
      gridArea: 'logo-row-start / logo-column-start / logo-row-end / logo-column-end',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
      },
    },
    [`& .${classes.logo}`]: {
      alignSelf: 'center',
      [theme.breakpoints.down('md')]: {
        height: 88,
      },
      overflow: 'visible',
    },
    [`& .${classes.message}`]: {
      position: 'absolute',
      bottom: theme.spacing(2),
      maxWidth: '100%',
    },
    [`& .${classes.copyright}`]: {
      gridArea: 'copyright-row-start / copyright-column-start / copyright-row-end / copyright-column-end',
      fontFamily: 'Roboto Mono',
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        fontSize: 10,
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(6),
        fontSize: 12,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
      },
    },
    [`& .${classes.kraken}`]: {
      gridArea: 'kraken-row-start / kraken-column-start / kraken-row-end / kraken-column-end',
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(10),
        paddingTop: theme.spacing(4),
      },
    },
    [`& .${classes.griegConnectLogo}`]: {
      height: 48,
      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
      overflow: 'visible',
    },
    [`& .${classes.image}`]: {
      gridArea: 'image-row-start / image-column-start / image-row-end / image-column-end',
      backgroundImage: `url('${applicationimageurl}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      [theme.breakpoints.down('md')]: {
        opacity: 0.1,
      },
      [theme.breakpoints.up('md')]: {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  }
})

type Props = {
  applicationName: string
  applicationTool: Tool
  applicationLogoLightUrl: string
  applicationLogoDarkUrl: string
  applicationImageUrl: string
  griegConnectLogoLightUrl?: string
  griegConnectLogoDarkUrl?: string
  progressValue?: number
  progressType?: 'determinate' | 'indeterminate'
  message?: string
  version?: string
  copyrightFromYear?: string
  className?: string
}

type StyleProps = {
  applicationimageurl?: string
}

export const Splash = (props: Props) => {
  const {
    applicationName,
    applicationImageUrl,
    applicationLogoLightUrl,
    applicationLogoDarkUrl,
    copyrightFromYear,
    griegConnectLogoDarkUrl,
    griegConnectLogoLightUrl,
    version,
    message,
    progressValue,
    progressType,
    className,
  } = props

  const theme = useTheme()
  const applicationLogoUrl = theme.palette.mode === 'light' ? applicationLogoLightUrl : applicationLogoDarkUrl
  const griegConnectLogoUrl = theme.palette.mode === 'light' ? griegConnectLogoLightUrl : griegConnectLogoDarkUrl

  const [isLogoLoaded, setIsLogoLoaded] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(griegConnectLogoUrl === undefined ? true : false)

  const handleLogoLoaded = () => setIsLogoLoaded(true)
  const handleKrakenToolsLogoLoaded = () => setIsImageLoaded(true)

  const date = new Date()
  const currentYear = date.getFullYear().toString()

  return (
    <StyledPaper className={`${classes.root} ${className}`} applicationimageurl={applicationImageUrl}>
      <Fade in={isLogoLoaded && isImageLoaded}>
        <Paper className={classes.content} elevation={24}>
          <Fade in={progressType === 'indeterminate' || progressValue != null}>
            <LinearProgress
              className={classes.progress}
              variant={progressType ?? 'determinate'}
              value={progressValue ?? 0}
              classes={{ root: classes.progressRoot, bar: classes.progressBar }}
            ></LinearProgress>
          </Fade>
          <div className={classes.image} />
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src={applicationLogoUrl}
              alt={applicationName}
              onLoad={handleLogoLoaded}
              onError={handleLogoLoaded}
            />
            <Typography className={classes.message}>{message}</Typography>
          </div>
          <div className={classes.copyright}>
            {version && <div>Version {version}</div>}
            <div>
              ©{copyrightFromYear ? copyrightFromYear + '-' : ''}
              {currentYear} Grieg Connect AS. All rights reserved.
            </div>
            <div>Grieg Connect is owned by Grieg Logistics, a part of the family-owned Grieg Group.</div>
          </div>
          {griegConnectLogoUrl && (
            <div className={classes.kraken}>
              <img
                className={classes.griegConnectLogo}
                src={griegConnectLogoUrl}
                alt="Kraken Tools logo"
                onLoad={handleKrakenToolsLogoLoaded}
                onError={handleKrakenToolsLogoLoaded}
              />
            </div>
          )}
        </Paper>
      </Fade>
    </StyledPaper>
  )
}

export default Splash
