import DatasetIcon from '@mui/icons-material/Dataset'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/sfkb'

export const routes = [
  {
    name: 'SFKB Datasets',
    page: React.createElement(React.lazy(() => import('./SFKB'))),
    menu: {
      icon: <DatasetIcon />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
]
