import { Error as ErrorIcon } from '@griegconnect/krakentools-react-icons'
import { Alert, styled } from '@mui/material'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { globalAlertAtom } from '../../atoms/globalInfoAtom'

const CustomAlert = styled(Alert)(({ theme, severity }) => ({
  '& .MuiLink-root': {
    color: `${theme.palette.getContrastText(theme.palette.text.primary)} !important`,
    '&:hover': {
      opacity: 0.8,
    },
  },
}))

export const GlobalAlert = () => {
  const [globalAlert, setGlobalAlert] = useRecoilState(globalAlertAtom)

  const onCloseHandler = useCallback(() => {
    setGlobalAlert(null)
  }, [setGlobalAlert])

  return globalAlert ? (
    <CustomAlert
      variant="filled"
      severity={globalAlert.type}
      icon={<ErrorIcon />}
      onClose={globalAlert.closeable ? onCloseHandler : undefined}
      sx={{ borderRadius: 0 }}
      action={globalAlert.action}
    >
      {globalAlert.message}
    </CustomAlert>
  ) : null
}

export default GlobalAlert
