import { Box, Button } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Vessel } from '@griegconnect/krakentools-kmap'
import { NearMeOutlined as NearMeOutlinedIcon, TimelineOutlined as TimelineOutlinedIcon } from '@mui/icons-material'
import { useRecoilState } from 'recoil'
import { useMemo } from 'react'

import { followedVesselSelector, displayVesselTrailsAtom } from '../atoms/liveViewAtoms'
import { useMapContext } from '../MapContext'
import { useTranslation } from 'react-i18next'

export type VesselActionsProps = {
  vessel: Vessel
  disableFollow?: boolean
  disableTrail?: boolean
  onFollowVessel?: (vessel: Vessel) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'auto',
      },
    },
    button: {
      flexShrink: 0,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(2),
      },
    },
  })
)

export const VesselActions = (props: VesselActionsProps) => {
  const { mapIdentifierSlug } = useMapContext()
  const classes = useStyles()
  const [followedVessel, setFollowedVessel] = useRecoilState(followedVesselSelector(mapIdentifierSlug))
  const [trails, setTrails] = useRecoilState(displayVesselTrailsAtom(mapIdentifierSlug))
  const { t } = useTranslation('kmap')
  const toggleFollowHandler = () => {
    setFollowedVessel((prev) => (prev && prev.mmsi === props.vessel.mmsi ? null : props.vessel))
    props.onFollowVessel?.(props.vessel)
  }

  const toggleTrailHandler = () => {
    setTrails((prev) => {
      if (prev.find((prev) => prev.mmsi === props.vessel.mmsi)) {
        return prev.filter((prev) => prev.mmsi !== props.vessel.mmsi)
      } else {
        return prev.concat(props.vessel)
      }
    })
  }

  const isFollowing = useMemo(() => {
    return followedVessel ? followedVessel.mmsi === props.vessel.mmsi : false
  }, [followedVessel, props.vessel.mmsi])

  const hasTrail = useMemo(() => {
    return trails ? (trails.find((t) => t.mmsi === props.vessel.mmsi) ? true : false) : false
  }, [trails, props.vessel.mmsi])

  return (
    <Box className={classes.wrapper}>
      {!props.disableFollow && (
        <Button
          variant="outlined"
          color={isFollowing ? 'primary' : undefined}
          className={classes.button}
          startIcon={<NearMeOutlinedIcon />}
          onClick={toggleFollowHandler}
        >
          {t('VesselActions.followVessel')}
        </Button>
      )}
      {!props.disableTrail && (
        <Button
          variant="outlined"
          color={hasTrail ? 'primary' : undefined}
          className={classes.button}
          startIcon={<TimelineOutlinedIcon />}
          onClick={toggleTrailHandler}
        >
          {hasTrail ? t('VesselActions.hideTrail') : t('VesselActions.showTrail')}
        </Button>
      )}
    </Box>
  )
}

export default VesselActions
