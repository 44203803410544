import { Grid, IconButton, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import {} from '@mui/material/Paper'
import { Theme, CSSObject } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Cross } from '@griegconnect/krakentools-react-icons'
import React from 'react'
import clsx from 'clsx'

interface AdditionalStyleProps {
  footerIncluded: boolean
}

const useStyles = makeStyles<Theme, AdditionalStyleProps>((theme) => ({
  root: {
    outline: 'none !important',
  },
  rootWithoutFooter: {
    paddingBottom: theme.spacing(6),
  },
  header: {
    height: theme.spacing(9),
    padding: theme.spacing(0, 3),
    backgroundColor: alpha(
      (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor as string,
      0.6
    ),

    backdropFilter: 'blur(8px)',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.up('md')]: {
      backdropFilter: 'initial',
      position: 'initial',
      top: 'initial',
      backgroundColor: 'initial',
    },
  },
  headerContainer: {
    height: theme.spacing(9),
  },
  headerActions: {
    '& > button': {
      // marginLeft: theme.spacing(2),
      marginLeft: 10,
    },
  },
  content: {
    padding: theme.spacing(0, 3),
  },
  contentContainer: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  contentColumn: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      maxHeight: ({ footerIncluded }) => (footerIncluded ? 'calc(100vh - 242px)' : 'calc(100vh - 222px)'),
    },
  },
  mainContent: {
    // backgroundColor: 'rgba(0, 255, 71, 1)',
  },
  secondaryContent: {
    // backgroundColor: 'rgba(219, 255, 0, 1)',
  },
  footer: {
    backgroundColor: alpha(
      (theme.components!.MuiPaper!.styleOverrides!.elevation24! as CSSObject).backgroundColor as string,
      0.6
    ),
    backdropFilter: 'blur(8px)',
    position: 'sticky',
    bottom: 0,
    [theme.breakpoints.up('md')]: {
      backdropFilter: 'initial',
      position: 'initial',
      bottom: 'initial',
      backgroundColor: 'initial',
    },
  },
  footerContainer: {
    padding: theme.spacing(0, 3),
    height: theme.spacing(8.5),
    borderTopWidth: 1,
    borderTopColor: theme.palette.divider,
    borderTopStyle: 'solid',
  },
  footerActions: {
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export interface IDialogAdvancedLayoutProps {
  title: string | React.ReactNode
  header: {
    left?: React.ReactNode
    right?: React.ReactNode
    /**
     * Not implemented
     *
     * map is probably not the best variable name for this,
     * we need to know more about this before implementing it
     */
    map?: React.ReactNode
  }
  mainContent: React.ReactNode
  secondaryContent: React.ReactNode
  footer?: {
    buttons?: React.ReactNode
    status?: string
  }
  onClose: (reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => void
}

const ModalLayout: React.FC<React.PropsWithChildren<IDialogAdvancedLayoutProps>> = ({
  title,
  header,
  mainContent,
  secondaryContent,
  footer,
  onClose,
}) => {
  const styleProps: AdditionalStyleProps = {
    footerIncluded: !!footer,
  }
  const classes = useStyles(styleProps)
  const headerLeftComponent = header && header.left !== undefined

  return (
    <Grid
      container
      className={`
        ${classes.root}${footer ? '' : ` ${classes.rootWithoutFooter}`}
      `}
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      <Grid item className={classes.header}>
        <Grid
          container
          className={classes.headerContainer}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{headerLeftComponent ? header.left : <Typography variant="h6">{title}</Typography>}</Grid>
          <Grid item className={classes.headerActions}>
            {header && header.right}
            <IconButton size="small" onClick={() => onClose('closeButton')}>
              <Cross />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        <Grid container className={classes.contentContainer} direction="row" spacing={4}>
          <Grid item className={clsx(classes.mainContent, classes.contentColumn)} xs={12} md={7}>
            {headerLeftComponent && <Typography variant="h6">{title}</Typography>}
            {mainContent}
          </Grid>
          <Grid item className={clsx(classes.secondaryContent, classes.contentColumn)} xs={12} md={5}>
            {secondaryContent}
          </Grid>
        </Grid>
      </Grid>
      {footer && (
        <Grid item className={classes.footer}>
          <Grid
            container
            className={classes.footerContainer}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography color="textSecondary">{footer.status}</Typography>
            </Grid>
            <Grid item className={classes.footerActions}>
              {footer.buttons}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
export default ModalLayout
