import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#84D5FF',
  light: '#b9ffff',
  dark: '#4ea4cc',
  contrastText: 'rgba(0, 0, 0, 0.87)',
}

export default Color
