import React, { Suspense } from 'react'
import { ConfirmInvitationProps } from './ui/ConfirmInvitation'
import SuspenseLoader from './ui/SuspenseLoader'

const LazyConfirmInvitation = React.lazy(() => import('./ui/ConfirmInvitation'))

export type ConfirmInvitationOptionsType = undefined | boolean | ConfirmInvitationProps

const ConfirmInvitationWrapper: React.FC<React.PropsWithChildren<{ options: ConfirmInvitationOptionsType }>> = ({ options, children }) => {
  const showInvitePage = document.location.pathname === `${process.env.PUBLIC_URL}/invite` && options !== undefined
  if (showInvitePage) {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <LazyConfirmInvitation
          redirectUrl={typeof options === 'object' ? options.redirectUrl : undefined}
          redirectType={typeof options === 'object' ? options.redirectType : undefined}
          confirmBtnText={typeof options === 'object' ? options.confirmBtnText : undefined}
        />
      </Suspense>
    )
  } else {
    return <>{children}</>
  }
}

export default ConfirmInvitationWrapper
