import { Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { HTMLProps } from 'react'
import WidgetCard from './widget-card'
import { IWidgetCardProps } from './widget-card'

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    transition: 'stroke-dashoffset 1s',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  },
  circleContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  iconContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    '& svg': {
      fontSize: '3rem',
      padding: theme.spacing(1),
    },
  },
  progressRingContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '48px',
    textAlign: 'center',
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%',
  },
}))

interface IProgressRingProps extends HTMLProps<HTMLDivElement> {
  radius: number
  strokeWidth: number
  progress: number
  color?: string
  opacity?: number
}

interface IProgressIconProps {
  progress: number
  color?: string
  icon?: React.ReactNode
}

interface IProgressWidgetProps extends IWidgetCardProps {
  value: number
  resolved?: boolean
  progressIcon?: React.ReactNode
}

const ProgressRing: React.FC<React.PropsWithChildren<IProgressRingProps>> = (props) => {
  const classes = useStyles()
  const { radius, strokeWidth: stroke, progress, color, opacity } = props
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference

  return (
    <div {...props}>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className={classes.circle}
          stroke={color || 'white'}
          opacity={opacity}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  )
}

const ProgressIcon: React.FC<React.PropsWithChildren<IProgressIconProps>> = (props) => {
  const classes = useStyles()
  const { progress, color, icon } = props
  const radius = 50
  const strokeWidth = 4

  return (
    <div className={classes.progressRingContainer}>
      <ProgressRing
        className={classes.circleContainer}
        style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}
        radius={radius}
        strokeWidth={strokeWidth}
        progress={progress >= 100 ? 100 : progress}
        color={color}
      />
      {progress < 100 && (
        <ProgressRing
          className={classes.circleContainer}
          style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}
          radius={radius}
          strokeWidth={strokeWidth}
          progress={100 + progress}
          color={color}
          opacity={0.2}
        />
      )}
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  )
}

export const ProgressWidget: React.VFC<IProgressWidgetProps> = ({ value, resolved, progressIcon, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()

  const color = !resolved ? theme.palette.error.main : theme.palette.success.main

  const [percent, setPercent] = React.useState(0)

  React.useEffect(() => {
    setTimeout(() => {
      setPercent(value)
    }, 100)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <WidgetCard {...props}>
        <div className={classes.contentContainer}>
          <ProgressIcon progress={percent} color={color} icon={progressIcon} />

          <Typography variant="h1" component="p">
            {Math.round(value)} %
          </Typography>
        </div>
      </WidgetCard>
    </>
  )
}
export default ProgressWidget
