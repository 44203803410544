import { useEffect, useMemo, useRef, useState } from 'react'
import { useMapContext } from '../../MapContext'
import { aisShipType, HistoryView as HistoryViewClass, VesselModel } from '@griegconnect/krakentools-kmap'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import Container from '@mui/material/Container'
import PlayerControls from './PlayerControls'
import { Debouncer } from '../../utils/Debouncer'
import { mapCenterSelector, mapZoomLevelSelector } from '../../atoms/mapConfigAtoms'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Backdrop from '@mui/material/Backdrop'
import { selectedSearchResultAtom } from '../../atoms/controlAtoms'
import { displayVesselTrailsAtom, selectedVesselSelector, vesselFilterAtom } from '../../atoms/liveViewAtoms'
import Box from '@mui/material/Box'
import { MapDialog } from '../../dialogs/MapDialog'
import { aisNavStatuses } from '../../utils/aisNavStatuses'
import { aisCountryMap } from '../../utils/aisCountryMap'
import VesselInformation from '../../shared/VesselInformation'
import VesselActions from '../../shared/VesselActions'
import MapSnackBarControl from '../../shared/MapSnackBarControl'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@mui/material'

export type HistoryViewProps = {
  liveApiBaseUrl: string
  tenantId: string | null
  paused?: boolean
  controlsHeight: number
  currentTimeComponent: React.ReactNode
  time?: string
  highlightedVessel?: string
  onChangeTime?: (time: Date) => void
  onCancelHighlight?: () => void
}

type PendingBufferState = {
  isReverse: boolean
  originTime: Date
}

const bufferDebouncer = new Debouncer()

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.72)' : 'rgba(255,255,255,0.72)',
      backdropFilter: 'blur(4px)',
    },
  })
)

export const HistoryView = (props: HistoryViewProps) => {
  const { kmapInstance: instance, mapIdentifierSlug, getToken } = useMapContext()
  const [historyViewInstance, setHistoryViewInstance] = useState<HistoryViewClass | null>(null)
  const [dateTickInterval, setDateTickInterval] = useState<number>(0)
  const [isPaused, setIsPaused] = useState<boolean>(true)
  const [multiplier, setMultiplier] = useState<number>(10)
  const [bufferRange, setBufferRange] = useState<[Date | null, Date | null]>([null, null])
  const [isBuffering, setIsBuffering] = useState<boolean>(false)
  const [enableInterpolation, setEnableInterpolation] = useState(true)
  const [pendingBufferState, setPendingBufferState] = useState<PendingBufferState | null>(null)
  const [pendingPlay, setPendingPlay] = useState<boolean>(false)
  const [isCurrentTime, setIsCurrentTime] = useState<boolean>(false)
  const classes = useStyles()
  const selectedSearchResult = useRecoilValue(selectedSearchResultAtom(mapIdentifierSlug))
  const setCenter = useSetRecoilState(mapCenterSelector(mapIdentifierSlug))
  const setZoomLevel = useSetRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const [selectedVessel, setSelectedVessel] = useRecoilState(selectedVesselSelector(mapIdentifierSlug))
  const vesselFilter = useRecoilValue(vesselFilterAtom(mapIdentifierSlug))
  const trails = useRecoilValue(displayVesselTrailsAtom(mapIdentifierSlug))
  const vesselDialog = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('kmap', { keyPrefix: 'History' })
  const initTime = props.time && !isNaN(new Date(props.time).getTime()) ? new Date(props.time) : null

  const [timeshiftTime, setTimeshiftTime] = useState<Date>(initTime || new Date(Date.now() - 60 * 1000))

  useEffect(() => {
    if (props.tenantId === null) {
      console.debug('HistoryView: No active tenant, e.g. no tenant filtering enabled')
    }
    const initializedView = new HistoryViewClass(
      instance,
      getToken,
      props.liveApiBaseUrl,
      undefined,
      props.tenantId ?? undefined
    )
    initializedView.enableInterpolation(true)
    initializedView.setVesselFilter(vesselFilter)
    initializedView.replayState.multiplier = multiplier
    setTimeout(() => setHistoryViewInstance(initializedView), 400)

    return () => {
      initializedView.destroy()
    }
  }, [instance, props.liveApiBaseUrl, props.tenantId])

  useEffect(() => {
    historyViewInstance?.removeTrails()
    if (trails.length > 0) {
      trails.forEach((trailVessel) => trailVessel.mmsi && historyViewInstance?.showTrail(trailVessel.mmsi))
    }
  }, [trails])

  useEffect(() => {
    historyViewInstance?.setVesselFilter(vesselFilter)
  }, [vesselFilter, historyViewInstance])

  useEffect(() => {
    if (historyViewInstance) {
      historyViewInstance?.setHighlightedVessel(props.highlightedVessel ? Number(props.highlightedVessel) : undefined)
    }
  }, [props.highlightedVessel, historyViewInstance])

  useEffect(() => {
    if (historyViewInstance && selectedVessel && selectedVessel.mmsi && vesselDialog.current) {
      const position = 'bottom-center'
      const offset: [number, number] = [0, -35]
      const vesselDialogId = historyViewInstance.addVesselOverlay(
        selectedVessel.mmsi,
        vesselDialog.current,
        selectedVessel.gpsPosition,
        true,
        position,
        offset
      )
      const vesselUpdateListenerId = historyViewInstance.on(
        'vessel-update:' + selectedVessel.mmsi,
        (vessel: VesselModel) => {
          if (selectedVessel.mmsi) {
            setSelectedVessel({ ...selectedVessel, gpsPosition: vessel.gpsPosition })
          }
        }
      )
      return () => {
        if (vesselDialogId) {
          historyViewInstance.removeVesselOverlay(vesselDialogId)
        }
        if (vesselUpdateListenerId) {
          historyViewInstance.un(vesselUpdateListenerId)
        }
      }
    }
  }, [historyViewInstance, selectedVessel])

  useEffect(() => {
    if (historyViewInstance) {
      requestBuffer(timeshiftTime).finally(() => setIsBuffering(false))
    }
  }, [historyViewInstance])

  const requestBuffer = (time: Date) => {
    setIsBuffering(true)
    return new Promise((resolve, reject) => {
      if (historyViewInstance && !pendingBufferState && !isBuffering) {
        setBufferRange([null, null])
        const fromTime = new Date(time.getTime() - 120 * 1000 * historyViewInstance.replayState.multiplier)
        const toTime = new Date(time.getTime() + 120 * 1000 * historyViewInstance.replayState.multiplier)
        historyViewInstance?.reset()
        historyViewInstance?.showSnapshot(time).then(() => {
          historyViewInstance?.requestBufferHTTP(fromTime, toTime).then(resolve).catch(reject)
        })
      } else {
        console.error(
          'HistoryView: requestBuffer called while already buffering or pending buffer state is not null',
          pendingBufferState
        )
      }
    })
  }

  useEffect(() => {
    props.onChangeTime?.(timeshiftTime)
  }, [props.onChangeTime, timeshiftTime])

  useEffect(() => {
    if (selectedSearchResult && (selectedSearchResult.type === 'vessel' || selectedSearchResult.type === 'port')) {
      if (selectedSearchResult.type === 'port') {
        setCenter({
          lat: selectedSearchResult.value.coordinate[1],
          lon: selectedSearchResult.value.coordinate[0],
        })
        setZoomLevel(14)
      }
    }
  }, [selectedSearchResult])

  useEffect(() => {
    if (historyViewInstance) {
      historyViewInstance.replayState.multiplier = multiplier
      historyViewInstance.replayState.timeshiftTime = timeshiftTime.getTime()
    }

    const trailClickId = historyViewInstance?.on('trail-point-click', (trailPointEvent: any) => {
      const time = new Date(trailPointEvent.time)
      historyViewInstance?.gotoTime(time)
      setTimeshiftTime(time)
    })

    const vesselClickId = historyViewInstance?.on('vessel-click', (vessel: VesselModel) => {
      setSelectedVessel(vessel)
      // historyViewInstance?.removeTrails()
      // historyViewInstance?.showTrail(vessel.mmsi)
    })

    const bufferEndEvent = historyViewInstance?.on('buffer-end', (bufferStartEnd: [Date, Date]) => {
      setPendingBufferState(null)

      historyViewInstance?.renderTrails()
      setIsBuffering(false)
      if (historyViewInstance) {
        if (pendingBufferState) {
          // historyViewInstance?.emptyView()
          historyViewInstance?.gotoTime(pendingBufferState.originTime)

          pendingBufferState.isReverse
            ? historyViewInstance?.gotoStartOfBuffer()
            : historyViewInstance?.gotoEndOfBuffer()
        }
        setBufferRange(bufferStartEnd)
        if (pendingPlay) {
          handlePlayPause()
          setPendingPlay(false)
        }
      }
    })

    const moveEndEvent = historyViewInstance?.on('moveend', () => {
      if (isBuffering) return
      if (!isPaused) {
        setPendingPlay(true)
        handlePlayPause()
      }
      bufferDebouncer.bounce(() => {
        setIsBuffering(true)
        requestBuffer(timeshiftTime).finally(() => setIsBuffering(false))
      }, 1000)
    })
    if (timeshiftTime > new Date(Date.now() - 5 * 1000)) {
      setIsPaused(true)
      setIsCurrentTime(true)
      window.clearInterval(dateTickInterval)
      setDateTickInterval(0)
      historyViewInstance?.pause()
    } else {
      setIsCurrentTime(false)
    }

    return () => {
      historyViewInstance?.un(vesselClickId || '')
      historyViewInstance?.un(bufferEndEvent || '')
      historyViewInstance?.un(moveEndEvent || '')
      historyViewInstance?.un(trailClickId || '')
    }
  }, [
    historyViewInstance,
    requestBuffer,
    timeshiftTime,
    isPaused,
    multiplier,
    pendingBufferState,
    selectedVessel,
    isBuffering,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedVessel) {
      setSelectedVessel(null)
    }
  }, [timeshiftTime])

  const handleTimeChanged = (time: Date) => {
    setTimeshiftTime(time)
    if (!pendingBufferState) {
      handleSkipToTime(time)
      handleGoToTime(time)
    }

    if (!isPaused) {
      setPendingPlay(true)
      handlePlayPause()
    }
  }
  const handlePlayPause = () => {
    if (historyViewInstance) {
      setIsPaused(!isPaused)
      if (isPaused) {
        historyViewInstance.replay(
          historyViewInstance.replayState.timeshiftTime > 0
            ? new Date(historyViewInstance.replayState.timeshiftTime)
            : timeshiftTime,
          multiplier
        )
        if (!dateTickInterval) {
          setDateTickInterval(
            window.setInterval(() => {
              if (historyViewInstance) {
                setTimeshiftTime(new Date(historyViewInstance.replayState.timeshiftTime))
              }
            }, 1000)
          )
        }
      } else {
        window.clearInterval(dateTickInterval)
        setDateTickInterval(0)
        historyViewInstance?.pause()
      }
    }
  }

  const handleMultiplierChanged = (newMultiplier: number) => {
    if (historyViewInstance) {
      setMultiplier(newMultiplier)
      historyViewInstance.replayState.multiplier = newMultiplier
      requestBuffer(timeshiftTime).finally(() => setIsBuffering(false))
    }
  }
  const handleGoToTime = (time: Date) => {
    setTimeshiftTime(time)
    historyViewInstance?.gotoTime(time)
  }

  const handleRequestBufferFill = (time: number) => {
    if (bufferRange[0] && bufferRange[1]) {
      if (new Date(time) > bufferRange[1]) {
        setPendingBufferState({
          isReverse: false,
          originTime: new Date(timeshiftTime.getTime() - 1000 * 60 * multiplier),
        })
        historyViewInstance?.requestBufferHTTP(bufferRange[1], new Date(time)).then(() => {
          historyViewInstance?.showSnapshot(new Date(time))
        })
      } else if (new Date(time) < bufferRange[0]) {
        setPendingBufferState({
          isReverse: true,
          originTime: new Date(timeshiftTime.getTime() + 1000 * 60 * multiplier),
        })
        historyViewInstance?.requestBufferHTTP(new Date(time), bufferRange[0], true).then(() => {
          historyViewInstance?.showSnapshot(new Date(time))
        })
      }
    }
  }
  const handleSkipToTime = (time: Date) => {
    if (bufferRange[0] && bufferRange[1]) {
      if (
        time > bufferRange[1] ||
        time < bufferRange[0] ||
        isNaN(bufferRange[0].getTime()) ||
        isNaN(bufferRange[1].getTime())
      ) {
        requestBuffer(time).finally(() => setIsBuffering(false))
      }
    }
  }
  const handleToggleInterpolation = (enable: boolean) => {
    setEnableInterpolation(enable)
    historyViewInstance?.enableInterpolation(enable)
  }
  const country = selectedVessel ? aisCountryMap.get(Number(String(selectedVessel.mmsi).substring(0, 3))) : ''
  const flag = country ? (
    <img
      width="20"
      height="15"
      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
      title={country.name}
    />
  ) : null
  const shipType = aisShipType(selectedVessel?.shiptype || 0) || ''
  const navStatus = selectedVessel?.navStatus ? aisNavStatuses.get(selectedVessel.navStatus)?.displayName : ''

  const snackbar = useMemo(() => <MapSnackBarControl />, [trails])

  return (
    (historyViewInstance && (
      <>
        {isCurrentTime && <Backdrop open={true}>{props.currentTimeComponent}</Backdrop>}
        {snackbar}
        <Container className={classes.container} disableGutters={true} maxWidth={false}>
          <div style={{ padding: 0 }}>
            <PlayerControls
              isCurrentTime={isCurrentTime}
              bufferEndTime={bufferRange[1]}
              bufferStartTime={bufferRange[0]}
              time={timeshiftTime}
              isPaused={isPaused}
              multiplier={multiplier}
              onMultiplierChanged={handleMultiplierChanged}
              onTimeChanged={handleTimeChanged}
              onTogglePlayPause={handlePlayPause}
              onGoToTime={handleGoToTime}
              onRequestBufferFill={handleRequestBufferFill}
              onSkipToTime={handleSkipToTime}
              height={props.controlsHeight}
              enableInterpolation={enableInterpolation}
              onToggleInterpolation={handleToggleInterpolation}
            />
            {(isBuffering || pendingBufferState !== null) && (
              <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} />
            )}
          </div>
          {selectedVessel && isPaused && (
            <Box display="none">
              <MapDialog
                title={selectedVessel.name || selectedVessel.mmsi?.toString() || t('unknownVessel')}
                subtitle={
                  <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                    {flag}
                    <span>{shipType + ', ' + (navStatus ? navStatus : '')}</span>
                  </Stack>
                }
                onClose={() => setSelectedVessel(null)}
                arrow="bottom"
                ref={vesselDialog}
                primary={
                  <>
                    <VesselInformation datatype="primary" vessel={selectedVessel} />
                    <VesselInformation datatype="secondary" vessel={selectedVessel} />
                  </>
                }
                actions={<VesselActions vessel={selectedVessel} disableFollow />}
              ></MapDialog>
            </Box>
          )}
        </Container>
        <Snackbar
          open={props.highlightedVessel !== undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ marginBottom: '140px' }}
          message={t('highlightingVessel')}
          action={
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => {
                props.onCancelHighlight?.()
              }}
              sx={{ mr: 1 }}
            >
              {t('stop')}
            </Button>
          }
        />
      </>
    )) ||
    null
  )
}

export default HistoryView
