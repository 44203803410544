import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#A3D6A6',
  light: '#d5ffd8',
  dark: '#73a477',
  contrastText: 'rgba(0, 0, 0, 0.87)',
}

export default Color
