import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { Checkmark as CheckmarkIcon } from '@griegconnect/krakentools-react-icons'

import Logo from './griegid.svg'
import styles from '../../grieg-onboarding.module.scss'
import SignUpForm, { SignUpFormState } from './sign-up-form'
import { Alert, TextField } from '@mui/material'
import { SignupFormFooter, SignupFormFooterProps } from './sign-up-form-footer'

export type SignUpCallbackType = (formState: SignUpFormState) => Promise<boolean>

export type SignUpProps<T = 'default' | 'verifyEmail'> = Pick<
  SignupFormFooterProps,
  'privacyPolicyUrl' | 'termsOfServiceUrl'
> & {
  display: boolean
  onClose: () => void
  signUpFields: string[]
  onSignUp: SignUpCallbackType
  flow: T
} & (T extends 'default' ? { accountExists?: never } : Pick<VerifyEmailFirstProps, 'accountExists'>)

export const signUpFieldNames = {
  EMAIL: 'email',
  NAME: 'name',
  PASSWORD: 'password',
  PASSWORD_REPEAT: 'repeat_password',
  PHONE_NUMBER: 'phone_number,',
}

type ValidateFieldResult = {
  status: boolean
  text: string
}

export function SignUp<T>(props: SignUpProps<T>) {
  const flow = props.flow ?? 'default'

  const initialFormState: SignUpFormState = {
    email: {
      name: signUpFieldNames.EMAIL,
      value: '',
      required: props.signUpFields.includes(signUpFieldNames.EMAIL),
      display: props.signUpFields.includes(signUpFieldNames.EMAIL),
      error: false,
      helperText: '',
    },
    name: {
      name: signUpFieldNames.NAME,
      value: '',
      required: props.signUpFields.includes(signUpFieldNames.NAME),
      display: props.signUpFields.includes(signUpFieldNames.NAME),
      error: false,
      helperText: '',
    },
    password: {
      name: signUpFieldNames.PASSWORD,
      value: '',
      required: props.signUpFields.includes(signUpFieldNames.PASSWORD),
      display: props.signUpFields.includes(signUpFieldNames.PASSWORD),
      error: false,
      helperText: 'At least one upper and lowercase character, a number and minimum 8 characters long.',
    },
    repeat_password: {
      name: signUpFieldNames.PASSWORD_REPEAT,
      value: '',
      required: props.signUpFields.includes(signUpFieldNames.PASSWORD_REPEAT),
      display: props.signUpFields.includes(signUpFieldNames.PASSWORD_REPEAT),
      error: false,
      helperText: '',
    },
    phone_number: {
      name: signUpFieldNames.PHONE_NUMBER,
      value: '',
      required: props.signUpFields.includes(signUpFieldNames.PHONE_NUMBER),
      display: props.signUpFields.includes(signUpFieldNames.PHONE_NUMBER),
      error: false,
      helperText: '',
      placeholder: '+47XXXXXXXX',
    },
  }

  const signUpContentRef = useRef<HTMLDivElement>(null)
  const [showVerifyEmail, setShowVerifyEmail] = useState(props.flow === 'verifyEmail')
  const [formValidated, setFormValidated] = useState(false)
  const [formSaving, setFormSaving] = useState(false)
  const [formSavingError, setFormSavingError] = useState<string | null>(null)
  const [accountCreated, setAccountCreated] = useState(false)
  const [formState, setFormState] = useState<SignUpFormState>(initialFormState)

  useEffect(() => {
    if (props.display === true) {
      if (signUpContentRef) {
        if (signUpContentRef.current) {
          signUpContentRef.current.scrollTo(0, 0)
        }
      }
      setFormState(initialFormState)
      setFormValidated(false)
      setFormSaving(false)
      setFormSavingError(null)
      setAccountCreated(false)
    }
  }, [props.display])

  const formFieldMapper = (name: string, stateObject: SignUpFormState) => {
    switch (name) {
      case signUpFieldNames.EMAIL:
        return stateObject.email
      case signUpFieldNames.NAME:
        return stateObject.name
      case signUpFieldNames.PASSWORD:
        return stateObject.password
      case signUpFieldNames.PASSWORD_REPEAT:
        return stateObject.repeat_password
      case signUpFieldNames.PHONE_NUMBER:
        return stateObject.phone_number
      default:
        return null
    }
  }

  const validateField = (name: string, value: string): ValidateFieldResult => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    const phoneNumberRegex = /^\+?[0-9 ]{4,}$/

    switch (name) {
      case signUpFieldNames.EMAIL:
        if (formState.email.required) {
          return {
            status: emailRegex.test(value),
            text: 'Invalid formatted email address.',
          }
        } else {
          return {
            status: value.length > 0 ? emailRegex.test(value) : true,
            text: 'Invalid formatted email address.',
          }
        }
      case signUpFieldNames.NAME:
        if (formState.name.required) {
          return {
            status: value.length > 0 ? true : false,
            text: 'Full name is required.',
          }
        } else {
          return { status: true, text: '' }
        }
      case signUpFieldNames.PASSWORD:
        if (formState.password.required) {
          return {
            status: passwordRegex.test(value),
            text:
              'Password must consist of at least one upper and lowercase character (A-Z), a number and be minimum 8 characters long.',
          }
        } else {
          return {
            status: value.length > 0 ? passwordRegex.test(value) : true,
            text:
              'Password must consist of at least one upper and lowercase character (A-Z), a number and be minimum 8 characters long.',
          }
        }
      case signUpFieldNames.PASSWORD_REPEAT:
        if (formState.repeat_password.required) {
          if (formState.password.value !== value) {
            return {
              status: false,
              text: 'Passwords are not equal. Please try again.',
            }
          } else {
            return { status: true, text: '' }
          }
        } else {
          if (value.length > 0 && formState.password.value !== value) {
            return {
              status: false,
              text: 'Passwords are not equal. Please try again.',
            }
          } else {
            return { status: true, text: '' }
          }
        }
      case signUpFieldNames.PHONE_NUMBER:
        if (formState.phone_number.required) {
          return {
            status: phoneNumberRegex.test(value),
            text: 'Phone number may only contain + and digits.',
          }
        } else {
          return {
            status: value.length > 0 ? phoneNumberRegex.test(value) : true,
            text: 'Phone number may only contain + and digits.',
          }
        }
      default:
        return { status: true, text: '' }
    }
  }

  const validateForm = () => {
    let copiedFormState = { ...formState }
    setFormValidated(true)
    const validForm = Object.keys(formState).reduce((acc, key) => {
      const fieldObj = copiedFormState[key as keyof typeof copiedFormState]
      const { status, text } = validateField(fieldObj.name, fieldObj.value.trim())
      if (status) {
        return acc
      } else {
        fieldObj.helperText = text
        fieldObj.error = true
        return false
      }
    }, true)

    if (validForm) {
      return true
    } else {
      setFormState(copiedFormState)
      return false
    }
  }

  const handleFormFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedFormState = { ...formState }
    let field = formFieldMapper(event.target.name, updatedFormState)
    if (field) {
      const { status, text } = validateField(event.target.name, event.target.value.trim())
      if (status || !formValidated) {
        field.value = event.target.value
        field.helperText = ''
        field.error = false
      } else {
        field.value = event.target.value
        field.helperText = text
        field.error = true
      }
    }
    setFormState(updatedFormState)
  }

  const onFormSubmit = () => {
    setFormSaving(true)
    if (validateForm()) {
      formState.email.value = formState.email.value.trim()
      props
        .onSignUp(formState)
        .then((success: boolean) => {
          if (success) {
            setAccountCreated(true)
            setFormSaving(false)
          } else {
            setFormSavingError('Unknown error occured. Try again, and if error persists please let us know.')
            setFormSaving(false)
          }
        })
        .catch((error: string) => {
          setFormSavingError(error)
          setFormSaving(false)
        })
    } else {
      setFormSaving(false)
    }
  }

  const handleClose = () => {
    props.onClose()
    setShowVerifyEmail(props.flow === 'verifyEmail')
  }

  return (
    <div
      className={styles.signUpWrapper}
      style={{ top: props.display ? '0vh' : '100vh', opacity: props.display ? 1 : 0 }}
    >
      <div ref={signUpContentRef} className={styles.signUpContent}>
        <div className={styles.signUpLogo}>
          <img src={Logo} alt="Grieg ID Logo" />
        </div>
        <div className={styles.signUpForm}>
          <Grid container={true} spacing={4} justifyContent="center">
            {showVerifyEmail ? (
              <Grid item={true} xs={12}>
                <VerifyEmailFirst
                  accountExists={props.accountExists!}
                  onClose={handleClose}
                  formState={formState}
                  onFormFieldChange={handleFormFieldChange}
                  onValidateEmail={validateField}
                  onShowSignup={() => setShowVerifyEmail(false)}
                  privacyPolicyUrl={props.privacyPolicyUrl}
                  termsOfServiceUrl={props.termsOfServiceUrl}
                />
              </Grid>
            ) : (
              <>
                {accountCreated === false && (
                  <>
                    <Grid item={true} xs={12}>
                      <SignUpForm onValueChange={handleFormFieldChange} formState={formState} />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <SignupFormFooter
                        privacyPolicyUrl={props.privacyPolicyUrl}
                        termsOfServiceUrl={props.termsOfServiceUrl}
                        disabled={formSaving}
                      />
                    </Grid>
                    {formSavingError && (
                      <Grid item={true} xs={12}>
                        <Typography color="error">{formSavingError}</Typography>
                      </Grid>
                    )}
                    <Grid item={true} xs={12} sm={5} md={4}>
                      <Button
                        onClick={onFormSubmit}
                        variant="contained"
                        color="primary"
                        disabled={formSaving}
                        sx={{ mr: 2 }}
                        startIcon={formSaving ? <CircularProgress size={20} color="inherit" /> : undefined}
                      >
                        Create account
                      </Button>
                      <Button onClick={handleClose} disabled={formSaving}>
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}
                {accountCreated === true && (
                  <Grid item={true} xs={12}>
                    <CheckmarkIcon color="primary" style={{ fontSize: '7rem' }} />
                    <Typography align="center" sx={{ color: 'text.secondary' }} gutterBottom>
                      You're account has been created.
                      <br />
                      Please check your e-mail inbox to verify your account.
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} align="center" gutterBottom>
                      If you can't find your confirmation email in your inbox, please check your spam/junk folder.
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  )
}

type VerifyEmailFirstProps = Pick<SignupFormFooterProps, 'privacyPolicyUrl' | 'termsOfServiceUrl'> & {
  formState: SignUpFormState
  onFormFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onShowSignup: () => void
  onClose: () => void
  onValidateEmail: (name: 'email', value: string) => ValidateFieldResult
  accountExists: (email: string) => boolean | Promise<boolean>
}

const VerifyEmailFirst = (props: VerifyEmailFirstProps) => {
  const [loading, setLoading] = useState(false)
  const [emailValid, setEmailValid] = useState<ValidateFieldResult>({ status: true, text: '' })
  const [checkAccountError, setCheckAccountError] = useState(false)

  const handleStart = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const emailValid = props.onValidateEmail('email', props.formState.email.value)
    setEmailValid(emailValid)
    if (!emailValid.status) {
      return
    }
    setLoading(true)
    try {
      const accountExists = await props.accountExists(props.formState.email.value)
      setCheckAccountError(false)
      setLoading(false)
      if (!accountExists) {
        props.onShowSignup()
      } else {
        setTimeout(() => {
          props.onClose()
        }, 2000)
      }
    } catch (error) {
      console.error(`[react-onboarding] can't check if account exists`, error)
      setCheckAccountError(true)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleStart} style={{ maxWidth: 560, marginRight: 'auto', marginLeft: 'auto' }}>
      <Grid item={true} xs={12}>
        <TextField
          name={props.formState.email.name}
          fullWidth={true}
          margin="normal"
          required={props.formState.email.required}
          label="E-mail address"
          value={props.formState.email.value}
          error={!emailValid.status}
          helperText={!emailValid.status ? emailValid.text : 'Please provide your email address to get started.'}
          placeholder={props.formState.email.placeholder}
          sx={{ mb: 3 }}
          onChange={props.onFormFieldChange}
        />
      </Grid>
      {checkAccountError && (
        <Grid item={true} xs={12} sx={{ mb: 3 }}>
          <Alert severity="error" sx={{ textAlign: 'left' }}>
            An unknown error occurred while checking if account exists, please try again, or contact your administrator.
          </Alert>
        </Grid>
      )}
      <Grid item={true} xs={12} sx={{ mb: 3 }}>
        <Button sx={{ mr: 2 }} onClick={props.onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading || props.formState.email.error}
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : undefined}
        >
          {loading ? 'Please wait…' : 'Next'}
        </Button>
      </Grid>
    </form>
  )
}

export default SignUp
