import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#94CEFF',
  dark: '#619DCC',
  light: '#C8FFFF',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#296ACC',
}

export default Color
