import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono'

import React, { Suspense, createContext, useContext } from 'react'
import { MutableSnapshot, RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'
import Button from '@mui/material/Button'
import { StyledEngineProvider } from '@mui/material/styles'

import { FrameWrapper } from './FrameWrapper'
import { ApplicationWrapper } from './ApplicationWrapper'
import { AuthWrapper } from './wrappers/AuthWrapper'
import { HttpWrapper } from './wrappers/HttpWrapper'
import { TenantsWrapper, TenantsWrapperProps } from './wrappers/TenantsWrapper'
import { ThemeWrapper } from './wrappers/ThemeWrapper'
import { SuspenseLoader } from './ui/SuspenseLoader'
import { ApplicationOptionsContext, ApplicationOptionsContextType } from './ApplicationOptionsContext'
import { configPathAtom } from './atoms/configAtom'
import OnboardingWrapper, { OnboardingWrapperProps } from './wrappers/OnboardingWrapper'
import ConfirmInvitationWrapper, { ConfirmInvitationOptionsType } from './ConfirmInvitationWrapper'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'
import ErrorBoundary from './ErrorBoundary'
import { NotificationsGlobalProps } from './ui/NotificationsButton'
import { headerAtom } from './atoms/headerAtom'
import { PaletteMode } from '@mui/material'
import { themeConfigurationAtom } from './atoms/themeAtom'
import SnackbarWrapper from './wrappers/SnackbarWrapper'

type AppBarOptions = {
  hideApplicationLogo?: boolean
  hideApplicationSwitcher?: boolean
}

export type ApplicationProviderProps = Omit<ApplicationOptionsContextType, 'changeProfileModal'> &
  Pick<TenantsWrapperProps, 'filterUserAccess'> & {
    appBar?: AppBarOptions
    confirmInvitation?: ConfirmInvitationOptionsType
    children?: React.ReactNode
    /**
     * Temporary solution, will be reimplemented.
     *
     * @deprecated
     */
    profileMenuButtons?: string[]
    onboarding?: Omit<OnboardingWrapperProps, 'children'>
    showNotifications?: boolean
    notifications?: NotificationsGlobalProps
    /**
     * Temporary solution used by Port until all components support dark/light mode
     * @deprecated
     */
    paletteMode?: PaletteMode
  }

const KrakenAppContext = createContext({ logout: async () => {} })
export const useKrakenApp = () => useContext(KrakenAppContext)!

export const ApplicationProvider = (props: ApplicationProviderProps) => {
  const {
    name,
    tool,
    appRef,
    tenantsUrlMatchPathPrefix,
    tenantsUrlMatchPathParam,
    reloadOnTenantsChange,
    beforeLogout,
    disableProfileCompletion,
    notAuthenticated,
    configurationFilePath,
    appBar,
    filterUserAccess,
    children,
    profileMenuButtons,
    onboarding,
    confirmInvitation,
    showNotifications,
    notifications,
    i18n,
    paletteMode,
  } = props
  const [profileModalOpen, setProfileModalOpen] = React.useState<string | undefined>(undefined)

  const initializeRecoil = (mutableSnapshot: MutableSnapshot) => {
    if (configurationFilePath) {
      mutableSnapshot.set(configPathAtom, configurationFilePath)
    }
    mutableSnapshot.set(themeConfigurationAtom, (fallback) => {
      return {
        palette: paletteMode ?? fallback.palette,
      }
    })
    mutableSnapshot.set(headerAtom, (fallback) => {
      return {
        hide: fallback.hide,
        hideTabsBar: fallback.hideTabsBar,
        hideTenantSelector: fallback.hideTenantSelector,
        hideSearch: fallback.hideSearch,
        hideApplicationLogo: appBar?.hideApplicationLogo ?? fallback.hideApplicationLogo,
        hideApplicationSwitcher: appBar?.hideApplicationSwitcher ?? fallback.hideApplicationSwitcher,
        hideNotifications: showNotifications === true ? false : fallback.hideNotifications,
      }
    })
  }

  const profileMenuChildren = () => (
    <>
      {(profileMenuButtons || []).map((label) => (
        <Button
          key={`profile-button-${label}`}
          variant="outlined"
          color="primary"
          fullWidth={true}
          disableElevation={true}
          onClick={() => setProfileModalOpen(label)}
          style={{ marginBottom: '16px', display: 'block' }}
        >
          {label}
        </Button>
      ))}
    </>
  )

  const logout = async () => {
    if (beforeLogout) {
      await beforeLogout()
    }
  }

  return (
    <KrakenAppContext.Provider
      value={{
        logout,
      }}
    >
      <ApplicationOptionsContext.Provider
        value={{
          tool,
          appRef,
          tenantsUrlMatchPathPrefix,
          tenantsUrlMatchPathParam,
          reloadOnTenantsChange,
          disableProfileCompletion,
          beforeLogout,
          notAuthenticated,
          configurationFilePath,
          name,
          menu: props.menu,
          profileModalOpen,
          changeProfileModal: (value) => setProfileModalOpen(value),
          i18n,
          notifications,
        }}
      >
        <StyledEngineProvider injectFirst={true}>
          <RecoilRoot initializeState={initializeRecoil}>
            <ThemeWrapper tool={tool}>
              <Suspense fallback={<SuspenseLoader />}>
                <BrowserRouter>
                  <SnackbarWrapper>
                    <ErrorBoundaryProvider>
                      <ErrorBoundary reloadPage margins>
                        <AuthWrapper>
                          <HttpWrapper>
                            <ConfirmInvitationWrapper options={confirmInvitation}>
                              <OnboardingWrapper {...onboarding}>
                                <ApplicationWrapper>
                                  <TenantsWrapper filterUserAccess={filterUserAccess}>
                                    <FrameWrapper
                                      disableFooter={true}
                                      content={children}
                                      profileMenuChildren={profileMenuChildren()}
                                      disablePaletteSwitch={paletteMode ? true : false}
                                    />
                                  </TenantsWrapper>
                                </ApplicationWrapper>
                              </OnboardingWrapper>
                            </ConfirmInvitationWrapper>
                          </HttpWrapper>
                        </AuthWrapper>
                      </ErrorBoundary>
                    </ErrorBoundaryProvider>
                  </SnackbarWrapper>
                </BrowserRouter>
              </Suspense>
            </ThemeWrapper>
          </RecoilRoot>
        </StyledEngineProvider>
      </ApplicationOptionsContext.Provider>
    </KrakenAppContext.Provider>
  )
}

ApplicationProvider.defaultProps = {
  tool: 'kraken',
  tenantsUrlMatchPathPrefix: '/companies/:tenantRef',
  tenantsUrlMatchPathParam: 'tenantRef',
  reloadOnTenantsChange: false,
  configurationFilePath: '/config/config.json',
  profileModalOpen: undefined,
}
export default ApplicationProvider
