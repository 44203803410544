import { Coordinate } from 'ol/coordinate'
import { EventsKey } from 'ol/events'
import Feature from 'ol/Feature'
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { fromLonLat, toLonLat } from 'ol/proj'
import { getLength, offset } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo, useState } from 'react'

import {
  assertIsStyle,
  Collection,
  ILonLat,
  labelStyleDark,
  labelStyleLight,
  Spatial,
} from '@griegconnect/krakentools-kmap'

import { useMooringContext } from '../MooringPlanContext'
import { formatDistanceInMeters } from '../utils/formatDistanceInMeters'
import { midPoint } from '../utils/geometry'
import { selectedMooringLineStyle } from './DrawMooringLineInteraction'
import { useMapContext } from '../MapContext'

type MooringLineProps = {
  from: ILonLat
  to: ILonLat
  vesselId: string | number
  selected: boolean
  id: string
  editable?: boolean
  onChange: (from: ILonLat, to: number) => void
}

const mooringLineStyleLight = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
})
const mooringLineStyleDark = new Style({
  stroke: new Stroke({ color: '#DDD', width: 4 }),
})

const mooringLineDisabledStyleLight = new Style({
  stroke: new Stroke({ color: 'rgba(0,0,0,0.38)', width: 4 }),
})
const mooringLineDisabledStyleDark = new Style({
  stroke: new Stroke({ color: 'rgba(255,255,255,0.38)', width: 4 }),
})

const mooringLineAdornmentStyleLight = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
  geometry: (feature) => {
    const coords = (feature.getGeometry() as LineString)?.getCoordinates?.()
    return coords ? new MultiPoint(coords) : undefined
  },
  zIndex: 2000,
})
const mooringLineAdornmentStyleDark = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
  geometry: (feature) => {
    const coords = (feature.getGeometry() as LineString)?.getCoordinates?.()
    return coords ? new MultiPoint(coords) : undefined
  },
  zIndex: 2000,
})

const MooringLine: FC<React.PropsWithChildren<MooringLineProps>> = ({
  from,
  to,
  vesselId,
  selected,
  id,
  editable,
  onChange,
}) => {
  const { mooringLayer, kmapInstance } = useMooringContext()
  const { colorPalette } = useMapContext()
  const originalGeometry = new LineString([fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])])
  const [changeEventKey, setChangeEventKey] = useState<EventsKey | undefined>()
  const [modifyEndEventKey, setModifyEndEventKey] = useState<EventsKey | undefined>()

  const lineStyle = useMemo(() => {
    if (colorPalette === 'dark') {
      return editable ? [mooringLineAdornmentStyleDark, mooringLineStyleDark] : mooringLineDisabledStyleDark
    } else {
      return editable ? [mooringLineAdornmentStyleLight, mooringLineStyleLight] : mooringLineDisabledStyleLight
    }
  }, [editable, colorPalette])

  const labelStyle = colorPalette === 'dark' ? labelStyleDark : labelStyleLight

  const feature = useMemo(() => {
    const line = new LineString([fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])])
    const lineFeature = new Feature({
      geometry: line,
    })
    lineFeature.set('mooringLineForVessel', vesselId)
    lineFeature.set('id', id)
    lineFeature.set('type', 'mooringLine')
    lineFeature.set('selectable', !!editable)
    lineFeature.setStyle(lineStyle)
    mooringLayer.getSource()?.addFeature(lineFeature)
    return lineFeature
  }, [])

  const labelFeature = useMemo(() => {
    const label = new Feature({
      geometry: new Point(fromLonLat(midPoint([from.lon, from.lat], [to.lon, to.lat]))),
    })
    label.set('mooringLineForVessel', vesselId)
    label.set('id', id)
    const style = labelStyle.clone()
    style.setZIndex(200)
    label.setStyle(style)
    mooringLayer.getSource()?.addFeature(label)
    return label
  }, [])

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = feature.getGeometry() as LineString
    const geom = labelFeature.getGeometry()
    if (geom instanceof Point) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('labelFeature geometry is not a Point')
    }
    const style = assertIsStyle(labelFeature.getStyle())
    const text = style.getText()
    const angle = Math.atan2(to[1] - from[1], to[0] - from[0])
    if (text) {
      const deg = Spatial.toDegrees(angle)
      text.setRotation(deg > 90 || deg < -90 ? -angle + Math.PI : -angle)
    }
    style.getText()?.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
  }

  useEffect(() => {
    const lineStringGeometry = feature.getGeometry() as LineString
    const coords = [fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])]
    lineStringGeometry.setCoordinates(coords)
    updateLabel(coords[0], coords[1])
  }, [from, to])

  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.resetSnapCollection()
    kmapInstance.addToSnapCollection(feature)
    mooringLayer.setZIndex(3000)
    const f = mooringLayer.getSource()?.getFeatureById(vesselId)
    if (f && f instanceof Feature) {
      const vesselCoords = (f.getGeometry() as Polygon)?.getCoordinates()[0]
      let snappedToVertexIndex: number | false = false
      setChangeEventKey(
        feature.getGeometry()?.on('change', (event) => {
          const lineStringGeometry = event.target as LineString
          const coords = lineStringGeometry.getCoordinates()
          const snapPointCursor = coords[coords.length - 1]

          vesselCoords?.forEach((vertex, index) => {
            if (Math.abs(vertex[0] - snapPointCursor[0]) < 0.1 && Math.abs(vertex[1] - snapPointCursor[1]) < 0.1) {
              snappedToVertexIndex = index
              originalGeometry.setCoordinates(coords)
            }
          })

          updateLabel(coords[0], coords[1])
        })
      )

      setModifyEndEventKey(
        modifyInteraction.on('modifyend', () => {
          if (snappedToVertexIndex) {
            const geom = feature.getGeometry()
            if (geom) {
              const first = toLonLat(geom.getFirstCoordinate())
              onChange({ lon: first[0], lat: first[1] }, snappedToVertexIndex)
            }
          } else {
            feature.getGeometry()?.setCoordinates(originalGeometry.getCoordinates())
          }
        })
      )
      feature.setStyle([selectedMooringLineStyle, mooringLineAdornmentStyleLight])
      kmapInstance.addInteraction(modifyInteraction)
    } else {
      console.warn('MooringLine: vessel feature not found, or not a Feature')
    }
  }

  const disableEdit = () => {
    feature.setStyle(lineStyle)
    kmapInstance.removeInteraction(modifyInteraction)
    kmapInstance.removeFromSnapCollection(feature)
    mooringLayer.setZIndex(100)
    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([feature]),
      insertVertexCondition: () => false,
      style: selectedMooringLineStyle,
    })
    return modify
  }, [])

  useEffect(() => {
    if (modifyInteraction && editable) {
      if (!selected) {
        disableEdit()
      } else {
        enableEdit()
      }
    }
  }, [selected, modifyInteraction, kmapInstance])

  useEffect(
    () => () => {
      disableEdit()
      mooringLayer.getSource()?.removeFeature(feature)
      mooringLayer.getSource()?.removeFeature(labelFeature)
      if (modifyInteraction) {
        kmapInstance.removeInteraction(modifyInteraction)
      }
    },
    []
  )

  useEffect(() => {
    feature.setStyle(lineStyle)
    const style = labelStyle.clone()
    const text = style.getText()
    if (text) {
      text.setOffsetY(16)
      text.setJustify('center')
      text.setTextAlign('center')
      text.setTextBaseline('middle')
      text.setRotateWithView(true)
    }
    labelFeature.setStyle(style)
    const coords = [fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])]
    updateLabel(coords[0], coords[1])
  }, [colorPalette, labelFeature, feature])

  return null
}

export default MooringLine
