import { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios'
import { ExtendedFeature } from '../atoms/types/ExtendedFeature'
import { Asset, AssetType, AssetTypeDto, AssetTypeStyleTemplate } from './dtos/KindsDto'

export class AssetsApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance, getToken: () => Promise<string | undefined>) {
    this.httpClient = httpClient
    this.httpClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
      const token = await getToken()
      if (!token) {
        console.warn('No accesstoken provided for AssetsApi')
      }
      if (config.headers) {
        config.headers.Authorization = 'Bearer ' + (token || '')
      } else {
        config.headers = { Authorization: 'Bearer ' + (token || '') }
      }
      return config
    })
  }

  // Types
  async getKinds(signal?: CancelToken): Promise<AssetTypeDto> {
    return await this.httpClient
      .get(`types`, {
        cancelToken: signal,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse<AssetTypeDto>) => {
        return response.data
      })
  }

  async getType(id: string): Promise<AssetType> {
    return await this.httpClient
      .get('types/' + id, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data)
  }

  // Assets
  async getAsset(assetId: string, signal?: CancelToken): Promise<Asset> {
    return await this.httpClient
      .get(`assets/${assetId}`, {
        cancelToken: signal,
        headers: {
          Accept: 'application/geo+json',
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse<Asset>) => {
        return response.data
      })
  }

  async getSources(): Promise<string[]> {
    return await this.httpClient.get('sources').then((response) => response.data)
  }

  async updateAsset(id: string, patch: any): Promise<null> {
    return await this.httpClient.patch('assets/' + id, patch, { headers: { 'Content-Type': 'application/json' } })
  }

  async updateTypeStyle(typeRef: string, style: AssetTypeStyleTemplate): Promise<null> {
    return await this.httpClient.put('types/' + typeRef + '/styles', style, {
      headers: { 'Content-Type': 'application/json' },
    })
  }

  async updateType(typeRef: string, kind: AssetType): Promise<null> {
    return await this.httpClient.put('types/' + typeRef, kind, { headers: { 'Content-Type': 'application/json' } })
  }

  async createType(kind: AssetType): Promise<null> {
    return await this.httpClient.post('types', kind, { headers: { 'Content-Type': 'application/json' } })
  }

  async uploadCollection(typeId: string, epsg: number, namePointer: string, collection: string) {
    return await this.httpClient.post(
      'types/' + typeId + '/assets?epsg=' + epsg + '&namePointer=' + namePointer,
      collection
    )
  }
}

export default AssetsApi
