import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#000',
  light: '#2c2c2c',
  dark: '#000',
  contrastText: 'rgba(255, 255, 255, 0.87)',
  contrastPrimary: '#fff',
}

export default Color
