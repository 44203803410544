import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogAdvancedLayout, { IDialogAdvancedLayoutProps } from './dialog-advanced-layout'
import { useTheme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 1152,
  },
}))

type DialogAdvancedProps = IDialogAdvancedLayoutProps &
  Pick<DialogProps, 'disableAutoFocus' | 'className'> & {
    open: boolean
    enableBackdropClose?: boolean
    enableEscKeyDownClose?: boolean
  }

const DialogAdvanced: React.FC<React.PropsWithChildren<DialogAdvancedProps>> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && props.enableBackdropClose) {
      props.onClose('backdropClick')
    }

    if (reason === 'escapeKeyDown' && props.enableEscKeyDownClose) {
      props.onClose('escapeKeyDown')
    }
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        disableAutoFocus={props.disableAutoFocus}
        className={props.className}
        classes={{ paper: classes.paper }}
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            position: 'fixed',
            top: 32,
            maxHeight: 'calc(100vh - 42px)',
          },
        }}
      >
        <DialogAdvancedLayout {...props} />
      </Dialog>
    </>
  )
}
export default DialogAdvanced
