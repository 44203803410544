import { atom } from 'recoil'

/**
 * Severity types:
 * - Info (used to display important information, e.g. user has been added)
 * - Warning (used to display warnings, e.g. side-effects of data entered)
 * - Error (used to display invalid data errors, e.g. required fields)
 * - Critical (used to trigger application error boundary, e.g. required API could not be loaded such as tenants)
 */
export type ApplicationErrorType = {
  message: string
  severity: 'info' | 'warning' | 'error' | 'critical'
}

export const applicationErrorAtom = atom<ApplicationErrorType[]>({
  key: 'applicationErrorState',
  default: [],
})
