import React from 'react'
import { Theme } from '@mui/material/styles'
import { ClassNameMap } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Draggable } from '@hello-pangea/dnd'
import { Card } from '@mui/material'

export type BoardItemProps = {
  id: string
  trackId?: string
  title?: React.ReactNode
  content?: React.ReactNode
  contentClassName?: string
  footerContent?: React.ReactNode
  color?: string
  isDragRestricted?: boolean
  onClick?: (id: string, columnId: string, trackId?: string) => void
  className?: string
}

export type BoardItemComponentProps = BoardItemProps & {
  index: number
  isDragDisabled?: boolean
  columnId: string
}

const useStyles = makeStyles<Theme, BoardItemProps>((theme: Theme) =>
  createStyles({
    cardRoot: {
      borderLeftStyle: 'solid',
      borderLeftWidth: theme.spacing(0.5),
      borderLeftColor: theme.palette.primary.main,
    },
    cardContent: {
      padding: 0,
    },
    item: {
      marginBottom: theme.spacing(2),
    },
    contentContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: (props) => (props.footerContent ? theme.spacing(1) : theme.spacing(2)),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
    },
  })
)

const getItemStyle = (props: BoardItemComponentProps): React.CSSProperties => {
  return props.color ? { borderLeftColor: props.color } : {}
}

export const BoardItemDraggable = React.memo((props: BoardItemComponentProps) => {
  const classes = useStyles(props)

  return (
    <Draggable
      draggableId={props.id}
      index={props.index}
      isDragDisabled={props.isDragDisabled || props.isDragRestricted}
    >
      {(provided: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${classes.item} ${props.className}`}
          onClick={props.onClick ? () => props.onClick!(props.id, props.columnId, props.trackId) : undefined}
        >
          {renderItemContent(props, classes)}
        </div>
      )}
    </Draggable>
  )
})

export const BoardItem = React.memo((props: BoardItemComponentProps) => {
  const classes = useStyles(props)

  return <div className={`${classes.item} ${props.className}`}>{renderItemContent(props, classes)}</div>
})

const renderItemContent = (props: BoardItemComponentProps, classes: ClassNameMap<string>) => {
  return (
    <Card className={classes.cardRoot} style={getItemStyle(props)} elevation={2}>
      <div className={props.contentClassName ?? classes.contentContainer}>
        <div>{props.title}</div>
        {props.content}
      </div>
      {props.footerContent}
    </Card>
  )
}
