import { useAuth } from '@griegconnect/krakentools-react-auth'
import {
  CarouselElement,
  GriegOnboardingProps,
  GriegOnboardingProvider,
  PasswordlessSignInType,
  signUpFieldNames,
  SignUpFormState,
} from '@griegconnect/krakentools-react-onboarding'
import React from 'react'
import { useUsersApi } from '../hooks'
import { httpErrorsAsString } from '../utils/krakenHttpProblem'
import { AlertDialog } from '@griegconnect/krakentools-react-ui'
import { useAuthConnectionsApi } from '../hooks/useAuthConnectionsApi'

export type OnboardingWrapperProps = {
  children: React.ReactNode
  carouselElements?: CarouselElement[]
  termsOfServiceUrl?: string
  privacyPolicyUrl?: string
  disableCreateAccount?: boolean
} & Pick<GriegOnboardingProps, 'signInMethods' | 'passwordlessDescription' | 'loginRedirectOnSingleSignInMethod'>

const signUpPath = '/sign-up'
const forceSignup = document.location.pathname === signUpPath
const searchParams = new URLSearchParams(document.location.search)
/** Used by external services/applications like the ISPS course, need to be included in REDIRECT_WHITELIST */
const redirectUrl = searchParams.get('redirectUrl')
const externalSignUp = forceSignup && redirectUrl !== null

export const OnboardingWrapper = (props: OnboardingWrapperProps) => {
  const { loginWithRedirect, isAuthenticated, isInitializing } = useAuth()
  const usersApi = useUsersApi(false)
  const authConnectionsApi = useAuthConnectionsApi(false)
  const [accountExistOptions, setAccountExistOptions] = React.useState({ dialogOpen: false, connection: '', email: '' })

  const onLogin = (email?: string) => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        login_hint: email,
        appState: {
          targetUrl: redirectUrl
            ? redirectUrl
            : window.location.pathname + window.location.search + window.location.hash,
        },
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onPasswordlessLogin = (type: PasswordlessSignInType) => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        appState: {
          targetUrl: redirectUrl
            ? redirectUrl
            : window.location.pathname + window.location.search + window.location.hash,
        },
        connection: type,
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onCustomConnectionLogin = (connection: string, email?: string) => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        login_hint: email,
        appState: {
          targetUrl: redirectUrl
            ? redirectUrl
            : window.location.pathname + window.location.search + window.location.hash,
        },
        connection,
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onSignUp = (formState: SignUpFormState) => {
    const returnToUrl = externalSignUp
      ? redirectUrl
      : `${window.location.origin}/?redirectUrl=${encodeURIComponent(redirectUrl ?? window.location.pathname)}`

    return new Promise<boolean>((resolve, reject) => {
      usersApi
        .signup(
          formState.email.value,
          formState.name.value,
          formState.password.value,
          formState.phone_number.value,
          returnToUrl
        )
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          let errorMessage = 'An unknown error occurred, please try again, or contact your administrator.'
          if (error?.response?.data) {
            const krakenError = httpErrorsAsString(error.response.data)
            if (krakenError) {
              errorMessage = krakenError
            }
          }
          reject(errorMessage)
        })
    })
  }

  if (isAuthenticated || !props.carouselElements) {
    return <>{props.children}</>
  }

  if (isInitializing && props.carouselElements) {
    return null
  }

  const passwordlessSiginEnabled =
    props.signInMethods?.includes('sms') || props.signInMethods?.includes('email') ? true : false
  const defaultSignInEnabled =
    props.signInMethods === undefined ||
    props.signInMethods.length === 0 ||
    props.signInMethods?.includes('username-password')
      ? true
      : false

  if (props.signInMethods?.length === 1 && props.loginRedirectOnSingleSignInMethod) {
    if (props.signInMethods[0] === 'sms') {
      onPasswordlessLogin('sms')
      return null
    } else if (props.signInMethods[0] === 'email') {
      onPasswordlessLogin('email')
      return null
    } else if (props.signInMethods[0] === 'username-password') {
      onLogin()
      return null
    }
  }

  return (
    <>
      <AlertDialog
        open={accountExistOptions.dialogOpen}
        onClose={() => setAccountExistOptions({ connection: '', dialogOpen: false, email: '' })}
        title="Account already exists"
        onComplete={() => {
          if (accountExistOptions.connection === 'Username-Password-Authentication') {
            onLogin(accountExistOptions.email)
          } else {
            onCustomConnectionLogin(accountExistOptions.connection, accountExistOptions.email)
          }
        }}
        nextButtonName="Sign in"
      >
        An account with this email address already exists. Please sign in or reset your password if you've forgotten it.
      </AlertDialog>
      <GriegOnboardingProvider
        carouselElements={props.carouselElements}
        termsOfServiceUrl={props.termsOfServiceUrl ?? 'https://krakentools.com/latest-kraken-app-terms-of-service/'}
        privacyPolicyUrl={props.privacyPolicyUrl ?? 'https://krakentools.com/latest-kraken-app-privacy-policy/'}
        signUpFields={[
          signUpFieldNames.EMAIL,
          signUpFieldNames.NAME,
          signUpFieldNames.PASSWORD,
          signUpFieldNames.PASSWORD_REPEAT,
          signUpFieldNames.PHONE_NUMBER,
        ]}
        onLogin={defaultSignInEnabled ? onLogin : undefined}
        onSignUp={onSignUp}
        signInMethods={props.signInMethods}
        onPasswordlessLogin={passwordlessSiginEnabled ? onPasswordlessLogin : undefined}
        passwordlessDescription={props.passwordlessDescription}
        onClose={
          externalSignUp
            ? () => {
                document.location.href = redirectUrl
              }
            : undefined
        }
        initVisible={true}
        disableCreateAccount={props.disableCreateAccount}
        forceSignup={forceSignup}
        flow="verifyEmail"
        accountExists={async (email) => {
          const authConnection = await authConnectionsApi.authConnections(email)
          const accountExist =
            authConnection.exists ||
            (!authConnection.exists && authConnection.connection.id !== 'Username-Password-Authentication')
          setAccountExistOptions({ dialogOpen: accountExist, connection: authConnection.connection.id, email })
          return accountExist
        }}
      >
        {props.children}
      </GriegOnboardingProvider>
    </>
  )
}

export default OnboardingWrapper
