import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { displayVesselTrailsAtom, followedVesselSelector } from '../atoms/liveViewAtoms'
import { mapBottomControlMargin } from '../atoms/mapConfigAtoms'
import { useMapContext } from '../MapContext'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { VesselModel } from '@griegconnect/krakentools-kmap'
import { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      width: '100%',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    small: {
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
      maxWidth: 'calc(100vw - 96px)',
    },
  })
)

const MapSnackBarControl = () => {
  const classes = useStyles()
  const { mapIdentifierSlug } = useMapContext()
  const mapBottomMargin = useRecoilValue(mapBottomControlMargin(mapIdentifierSlug))
  const [trails, setTrails] = useRecoilState(displayVesselTrailsAtom(mapIdentifierSlug))
  const followedVessel = useRecoilValue(followedVesselSelector(mapIdentifierSlug))
  const resetFollow = useResetRecoilState(followedVesselSelector(mapIdentifierSlug))
  const resetTrails = useResetRecoilState(displayVesselTrailsAtom(mapIdentifierSlug))
  const theme = useTheme()
  const { t } = useTranslation('kmap')
  const mqSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const followedAndTrails = () => (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={mqSmall ? classes.small : ''}
      sx={{ marginBottom: mapBottomMargin + 'px' }}
      ContentProps={{ style: { paddingLeft: 0, marginLeft: 0, width: '100%' }, classes: { action: classes.action } }}
      action={
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Button variant="outlined" color="inherit" size="small" onClick={resetFollow} sx={{ mr: 1 }}>
            {t('SnackbarControl.stopFollow')}
          </Button>
          <Button variant="outlined" color="inherit" size="small" onClick={resetTrails} sx={{ mr: 1 }}>
            {t('SnackbarControl.hideTracks')}
          </Button>
        </Box>
      }
    />
  )

  const trailsOnly = () => (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={mqSmall ? classes.small : ''}
      sx={{ marginBottom: mapBottomMargin + 'px' }}
      message={
        trails.length === 1
          ? t('SnackbarControl.singleTrackMessage', { name: trails[0].name })
          : t('SnackbarControl.multipleTracksMessage', { count: trails.length })
      }
      action={
        <Button variant="outlined" color="inherit" size="small" onClick={resetTrails} sx={{ mr: 1 }}>
          {trails.length === 1 ? t('SnackbarControl.hideTrack') : t('SnackbarControl.hideAll')}
        </Button>
      }
    />
  )

  const followedOnly = (vessel: VesselModel) => (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      className={mqSmall ? classes.small : ''}
      sx={{ marginBottom: mapBottomMargin + 'px' }}
      message={t('SnackbarControl.followingMessage', { name: vessel.name })}
      action={
        <Button variant="outlined" color="inherit" size="small" onClick={resetFollow} sx={{ mr: 1 }}>
          {t('SnackbarControl.cancel')}
        </Button>
      }
    />
  )

  useEffect(() => {
    return () => {
      setTrails([])
    }
  }, [])

  return followedVessel && trails.length > 0
    ? followedAndTrails()
    : followedVessel
    ? followedOnly(followedVessel)
    : trails.length > 0
    ? trailsOnly()
    : null
}

export default MapSnackBarControl
