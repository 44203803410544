import { PaperProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactChild, ReactNode } from 'react'
import { Paper } from '../paper'

type Props = & Pick<PaperProps, 'className'> & {
  author?: ReactChild
  elevation?: number
  timestamp?: ReactChild
  internal?: boolean
  internalTagName: string
  isHandled?: boolean
  actions?: ReactNode
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      // color: theme.palette.text.hint,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.5),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    headerDetails: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    actions: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    authorTags: {
      display: 'flex',
      width: '100%',
    },
    internal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    },
    tag: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: theme.palette.warning.main,
    },
    timestamp: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    body: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
)

const Comment: React.FC<React.PropsWithChildren<Props>> = ({ children, className, author, elevation, timestamp, internal, internalTagName, actions }) => {
  const classes = useStyles()

  return (
    <Paper variant="elevation" elevation={elevation} className={`${classes.root} ${className}`}>
      <article>
        <header className={classes.header}>
          <div className={classes.authorTags}>{author}</div>
          <div className={classes.headerDetails}>
            {internal && (
              <div className={classes.internal}>
                <Typography variant="caption" className={classes.tag}>
                  {internalTagName}
                </Typography>
              </div>
            )}
            <div className={classes.timestamp}>{timestamp}</div>
            <div className={classes.actions}>{actions}</div>
          </div>
        </header>
      </article>
      <div className={classes.body}>{children}</div>
    </Paper>
  )
}

export default Comment
