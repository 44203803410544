import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#EF8FA8',
  light: '#ffc0d9',
  dark: '#ba6079',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#CC2954',
}

export default Color
