import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { Responsive, ResponsiveProps, WidthProvider } from 'react-grid-layout'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

const useStyles = makeStyles((_theme: Theme) => ({
  resizeIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
    transform: 'rotate(45deg)',
    opacity: 0.2,
    cursor: 'nwse-resize',
    display: 'none',
  },
  layoutStatic: {
    '& > div': {
      cursor: 'grab',
    },
  },
  layoutMoving: {
    '& > div': {
      cursor: 'grabbing',
    },
  },
  showResize: {
    '& .nonDraggable': {
      display: 'initial',
    },
  },
  nonDraggable: {
    '& > div': {
      cursor: 'initial',
    },
  },
  layoutContainer: {
    margin: '-10px',
  },
}))

interface IPosition {
  x: number
  y: number
}

type NewPosition = IPosition | undefined

export interface IWidget {
  component: JSX.Element
  isResizable?: boolean
  nonDraggable?: boolean
  /**
   * Exact columns span for medium and large breakpoints. E.g. [1,12]
   *
   * @type {([number, number])}
   * @memberof IWidget
   */
  exactWidth?: [number, number]
  /**
   * Columns span for medium and large breakpoints. E.g. [2,3]
   *
   * @type {([1 | 2, 1 | 2 | 3])}
   * @memberof IWidget
   */
  width?: [1 | 2, 1 | 2 | 3]
  /**
   * If resizable, can't be larger than selected screen size on medium and large screens.
   *
   * @type {('half' | 'third')}
   * @memberof IWidget
   */
  maxWidth?: 'half' | 'third'
  /**
   * One value for each breakpoint, or one universal value for all breakpoints. E.g. [5,5,5,6] or [4]
   *
   * @type {([number, number, number, number] | [number])}
   * @memberof IWidget
   */
  height?: [number, number, number, number] | [number]
  /**
   * If resizeable, cant't be larger than selected height.
   *
   * @type {number}
   * @memberof IWidget
   */
  maxHeight?: number
  /**
   * One value for each breakpoint, add undefined to keep original value. E.g. [undefined, undefined, { x: 0, y: 11 }, { x: 8, y: 6 }]
   *
   * @type {[NewPosition, NewPosition, NewPosition, NewPosition]}
   * @memberof IWidget
   */
  position?: [NewPosition, NewPosition, NewPosition, NewPosition]
  /**
   * Needed if widgets are going to change. Otherwise problems with the layout or widget sizes may occur.
   *
   * @type {string}
   * @memberof IWidget
   */
  id?: string
}

interface IGridItem {
  i: string
  x: number
  y: number
  w: number
  h: number
  isResizable?: boolean
  isDraggable?: boolean
  maxW?: number
  maxH?: number
}

type ILayoutKeys = 'xs' | 'sm' | 'md' | 'lg'

type ILayouts = {
  [key in ILayoutKeys]: IGridItem[]
}

interface IDashboardProps {
  widgets: IWidget[]
  locked?: boolean
  storageKey: string
  className?: string
}

const ResponsiveGridLayout = WidthProvider<React.PropsWithChildren<ResponsiveProps>>(Responsive)

export const Dashboard: React.FC<React.PropsWithChildren<IDashboardProps>> = ({ widgets, locked, storageKey, className }) => {
  const classes = useStyles()

  const [currentWidgets, setCurrentWidgets] = React.useState<IWidget[]>([])

  const getFromLS = (key: any) => {
    const ls = JSON.parse(localStorage.getItem(storageKey) ?? JSON.stringify(defaultLayoutsCurrent))
    return ls[key]
  }

  const saveToLS = (key: any, value: any) => {
    localStorage.setItem(
      storageKey,
      JSON.stringify({
        [key]: value,
      })
    )
  }

  const createDefaultLayouts = (widgets: IWidget[]): ILayouts => {
    return {
      xs: widgets.map((w, i) => ({
        i: `${i + 1}`,
        x: 0,
        y: i * 3,
        w: 1,
        h: w.height ? w.height[0] : 4,
        isResizable: !!w.isResizable,
        isDraggable: !w.nonDraggable,
        maxH: w.maxHeight,
      })),
      sm: widgets.map((w, i) => ({
        i: `${i + 1}`,
        x: 0,
        y: i * 2,
        w: 2,
        h: w.height && w.height.length !== 1 ? w.height[1] : w.height ? w.height[0] : 4,
        isResizable: !!w.isResizable,
        isDraggable: !w.nonDraggable,
        maxH: w.maxHeight,
      })),
      md: widgets.map((w, i) => ({
        i: `${i + 1}`,
        x: i === 0 || i % 2 === 0 ? 0 : 4,
        y: Math.floor(i / 2),
        w: w.exactWidth ? w.exactWidth[0] : w.width ? w.width[0] * 3 : 3,
        h: w.height && w.height.length !== 1 ? w.height[2] : w.height ? w.height[0] : 4,
        isResizable: !!w.isResizable,
        isDraggable: !w.nonDraggable,
        maxH: w.maxHeight,
        maxW: w.maxWidth === 'half' ? 3 : w.maxWidth === 'third' ? 2 : undefined,
      })),
      lg: widgets.map((w, i) => ({
        i: `${i + 1}`,
        x: i === 0 || i % 3 === 0 ? 0 : i === 1 || i === 4 || (i - 4) % 3 === 0 ? 4 : 8,
        y: Math.floor(i / 3),
        w: w.exactWidth ? w.exactWidth[1] : w.width ? w.width[1] * 4 : 4,
        h: w.height && w.height.length !== 1 ? w.height[3] : w.height ? w.height[0] : 4,
        isResizable: !!w.isResizable,
        isDraggable: !w.nonDraggable,
        maxH: w.maxHeight,
        maxW: w.maxWidth === 'half' ? 6 : w.maxWidth === 'third' ? 4 : undefined,
      })),
    }
  }

  const defaultLayoutsCurrent = createDefaultLayouts(currentWidgets)

  const defaultLayoutsNew = createDefaultLayouts(widgets)

  const originalLayouts: ILayouts = getFromLS('layouts') || {}

  const [layouts, setLayouts] = React.useState<ILayouts>(JSON.parse(JSON.stringify(originalLayouts)))
  const [isMoving, setIsMoving] = React.useState(false)

  React.useEffect(() => {
    if (currentWidgets.length === 0) {
      setCurrentWidgets(widgets)
    }
    const layouts = getFromLS('layouts') || {}
    onLayoutChange(undefined, layouts)
  }, []) // eslint-disable-line

  const onLayoutChange = (_layout: any, layouts: ILayouts) => {
    let newLayouts: ILayouts = { ...defaultLayoutsNew }
    let indexToRemove: undefined | number = undefined
    let widgetToAdd: undefined | IWidget = undefined
    if (currentWidgets.length > 0 && currentWidgets.length > widgets.length) {
      indexToRemove = currentWidgets.findIndex((cw) => widgets.find((w) => w.id === cw.id) === undefined)
      newLayouts = { ...defaultLayoutsCurrent }
    }
    if (currentWidgets.length > 0 && widgets.length > currentWidgets.length) {
      widgetToAdd = widgets.find((w) => currentWidgets.find((cw) => cw.id === w.id) === undefined)
      newLayouts = { ...defaultLayoutsCurrent }
    }
    Object.entries(newLayouts).forEach(([name, newLayout]) => {
      if (layouts[name as ILayoutKeys]?.length > 0) {
        for (const item of newLayout) {
          const itemIndex = newLayout.indexOf(item)
          const newItem = layouts[name as ILayoutKeys][itemIndex]
          if (item.isResizable && newItem) {
            item.w = newItem.w
            item.h = newItem.h
          }
          if (item.isDraggable && newItem) {
            item.x = newItem.x
            item.y = newItem.y
          }
        }
        if (indexToRemove !== undefined) {
          newLayout.splice(indexToRemove, 1)
          for (const item of newLayout) {
            const itemIndex2 = newLayout.indexOf(item)
            item.i = `${itemIndex2 + 1}`
          }
        }
        if (widgetToAdd) {
          const lastWidgetIndex = currentWidgets.length - 1
          const layoutIndex = Object.entries(newLayouts).findIndex((obj) => obj[0] === name)
          newLayout.push({
            i: `${lastWidgetIndex + 2}`,
            x: 0,
            y: -1,
            w: !widgetToAdd.isResizable
              ? layoutIndex < 2
                ? layoutIndex + 1
                : widgetToAdd.exactWidth
                ? widgetToAdd.exactWidth[layoutIndex - 2]
                : widgetToAdd.width
                ? widgetToAdd.width[layoutIndex - 2] * (layoutIndex + 1)
                : layoutIndex + 1
              : layoutIndex + 1,
            h:
              widgetToAdd.height && widgetToAdd.height.length !== 1
                ? widgetToAdd.height[layoutIndex]
                : widgetToAdd.height
                ? widgetToAdd.height[0]
                : 4,
            isResizable: !!widgetToAdd.isResizable,
            isDraggable: !widgetToAdd.nonDraggable,
          })
        }
      }
      if (!layouts['xs']) {
        for (const widget of widgets) {
          if (widget.position) {
            const widgetIndex = widgets.indexOf(widget)
            const pos =
              name === 'xs'
                ? widget.position[0]
                : name === 'sm'
                ? widget.position[1]
                : name === 'md'
                ? widget.position[2]
                : widget.position[3]
            if (pos) {
              newLayout[widgetIndex].x = pos.x
              newLayout[widgetIndex].y = pos.y
            }
          }
        }
      }
    })
    saveToLS('layouts', newLayouts)
    setLayouts(newLayouts)
    setCurrentWidgets(widgets)
  }

  const onDragStart = () => {
    setIsMoving(true)
  }

  const onDragStop = () => {
    setIsMoving(false)
  }

  return (
    <div className={`${classes.layoutContainer} ${className}`}>
      <Box position="relative">
        <ResponsiveGridLayout
          className={`layout ${locked ? '' : isMoving ? classes.layoutMoving : classes.layoutStatic}`}
          layouts={layouts as any}
          rowHeight={50}
          breakpoints={{ lg: 1150, md: 890, sm: 560, xs: 0 }}
          cols={{ lg: 12, md: 6, sm: 2, xs: 1 }}
          resizeHandle={
            <span>
              <ArrowForwardIos className={`${classes.resizeIcon} nonDraggable`} />
            </span>
          }
          draggableCancel=" .nonDraggable"
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts as any)}
          onDragStart={onDragStart}
          onDragStop={onDragStop}
        >
          {widgets.length > 0 &&
            widgets.map((widget, i) => {
              return (
                <div
                  key={`${i + 1}`}
                  data-grid={defaultLayoutsNew['lg'][i]}
                  className={`${
                    locked || widget.nonDraggable
                      ? `nonDraggable ${classes.nonDraggable}`
                      : widget.isResizable
                      ? classes.showResize
                      : ''
                  }`}
                >
                  {widget.component}
                </div>
              )
            })}
        </ResponsiveGridLayout>
      </Box>
    </div>
  )
}
export default Dashboard
