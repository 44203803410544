import { MobileStepper as MuiMobileStepper, MobileStepperProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { MobileStepperClassKey } from '@mui/material/MobileStepper'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, MobileStepperClassKey>(() =>
  createStyles<MobileStepperClassKey, StyleProps>({
    root: {},
    positionBottom: {},
    positionTop: {},
    positionStatic: {},
    dots: {},
    dot: {},
    dotActive: {},
    progress: {},
  })
)

function MobileStepper(props: MobileStepperProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiMobileStepper
      className={props.className}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        positionBottom: clsx(innerClasses.positionBottom, classes?.positionBottom),
        positionTop: clsx(innerClasses.positionTop, classes?.positionTop),
        positionStatic: clsx(innerClasses.positionStatic, classes?.positionStatic),
        dots: clsx(innerClasses.dots, classes?.dots),
        dot: clsx(innerClasses.dot, classes?.dot),
        dotActive: clsx(innerClasses.dotActive, classes?.dotActive),
        progress: clsx(innerClasses.progress, classes?.progress),
      }}
      {...other}
    />
  )
}

export default MobileStepper
