import { Checkbox } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import 'react-virtualized/styles.css' // only needs to be imported once
import './scrollfix.css' // only needs to be imported once
import React from 'react'
import {
  Table,
  Column,
  TableHeaderProps,
  SortIndicator,
  SortDirection,
  AutoSizer,
  InfiniteLoader,
  IndexRange,
  Index,
} from 'react-virtualized'
import _ from 'lodash'
import TableCell from '@mui/material/TableCell'

const useStyles = makeStyles((theme: Theme) => ({
  checkboxCell: {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
  },
  headerRow: {
    padding: 0,
    margin: 0,
    background: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.divider}`,
    outline: 'none',
  },
  headerCell: {},
  row: {},
  noOutline: {
    outline: 'none',
  },
  header: {
    position: 'sticky',
    outline: 'none',
  },
  grid: {
    outline: 'none',
  },
  rowEven: {
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
  },
  rowOdd: {
    outline: 'none',
    backgroundColor: theme.palette.grey['900'],
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

export interface HeaderItem {
  dataKey: string
  label: string
  width?: number
  autoAdjustWidth?: boolean
  align?: 'right' | 'left' | 'center'
  disableSort?: boolean
  renderRow?: (col: any) => React.ReactNode | undefined
}

interface TableProps {
  headerColumns: HeaderItem[]
  data: Array<any>
  height: number
  headerHeight?: number
  rowHeight?: number
  disableSort: boolean
  useCheckboxes: boolean
  onCheckboxChange: (values: any[]) => void
  rowCount?: number
  rowClassName: string | ((info: Index) => string)
  loadMoreRows?: (params: IndexRange) => any
  renderColumns?: (params: IndexRange) => any
  className?: string
}

let warnDeprecated = false

/**
 * @deprecated GCTable is deprecated, please upgrade to DataGridPro
 */
export const GCTable: React.FC<React.PropsWithChildren<TableProps>> = (props) => {
  const { headerColumns, disableSort, useCheckboxes, loadMoreRows, rowCount, headerHeight, rowHeight } = props
  const [sortBy, setSortBy] = React.useState<string>(disableSort ? '' : headerColumns[0].dataKey)
  const [sortDirection, setSortDirection] = React.useState<'ASC' | 'DESC'>(SortDirection.ASC)
  const [selected, setSelected] = React.useState<number[]>([])
  const [items, setItems] = React.useState<any[]>(props.data)
  const classes = useStyles()

  if (!warnDeprecated) {
    console.warn('[react-ui]: GCTable is deprecated, please upgrade to DataGridPro')
    warnDeprecated = true
  }

  React.useEffect(() => {
    setItems(props.data)
  }, [props.data])

  const headerRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps, _index: number) => {
    return (
      <React.Fragment key={dataKey}>
        <div className="ReactVirtualized__Table__headerTruncatedText">{label}</div>
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </React.Fragment>
    )
  }
  const _sortList = ({ sortBy, sortDirection }: { sortBy: any; sortDirection: any }) => {
    let newList = _.sortBy(items, [sortBy])
    if (sortDirection === SortDirection.DESC) {
      newList.reverse()
    }
    return newList
  }

  const _sort = ({ sortBy, sortDirection }: { sortBy: any; sortDirection: any }) => {
    setSortBy(sortBy)
    setSortDirection(sortDirection)
    const sortedList = props.disableSort ? items : _sortList({ sortBy, sortDirection })
    setItems(sortedList)
  }

  const getTotalWidth = () => {
    const colSpecifiedWidthTotal =
      headerColumns
        .filter((col) => typeof col.width !== 'undefined')
        .map((col) => col.width)
        .reduce((a, b) => a! + b!, 0) ?? 0

    const hWithoutLength = headerColumns.filter((col) => typeof col.width === 'undefined').length

    const remainingColWidth = hWithoutLength > 0 ? hWithoutLength * 100 : 0
    return colSpecifiedWidthTotal + remainingColWidth
  }

  //fix sorting

  const cellCheckboxRender: ({ rowIndex }: { rowIndex: any }) => React.ReactNode = ({ rowIndex }) => (
    <TableCell key={`checkbox-${rowIndex}`} className={classes.checkboxCell}>
      <Checkbox
        checked={selected.filter((s) => s === rowIndex).length > 0}
        onClick={() => onSelect(rowIndex)}
        inputProps={{ 'aria-label': 'toggle' }}
      />
    </TableCell>
  )

  const headerCheckboxRenderer = () => (
    <TableCell className={classes.checkboxCell}>
      <Checkbox
        checked={selected.length === items.length}
        onClick={onSelectAll}
        inputProps={{ 'aria-label': 'toggle all' }}
      />
    </TableCell>
  )

  const onSelect = (rowIndex: any) => {
    let newSelected: any[] = []
    const exists = selected.filter((s: number) => s === rowIndex)
    if (!exists || exists.length === 0) {
      newSelected.push(...selected)
      newSelected.push(rowIndex)
    } else {
      newSelected = selected.filter((s: number) => s !== rowIndex)
    }
    setSelected(newSelected)
    props.onCheckboxChange(newSelected.map((s) => items[s]))
  }

  const onSelectAll = () => {
    selected.length > 0 ? setSelected([]) : setSelected(items.map((_d, i) => i))
    props.onCheckboxChange(selected.length > 0 ? [] : items)
  }

  const localLoad = async () => {}
  const totalWidth = getTotalWidth()

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !!items[index]}
      loadMoreRows={loadMoreRows ?? localLoad}
      rowCount={rowCount ?? items.length}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className={props.className}
              width={width > totalWidth ? width : totalWidth}
              ref={registerChild}
              style={{ outline: 'none' }}
              onRowsRendered={onRowsRendered}
              height={height}
              headerHeight={headerHeight ?? 40}
              rowHeight={rowHeight ?? 35}
              rowCount={items.length}
              rowGetter={({ index }) => items[index]}
              sort={_sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              gridClassName={classes.grid}
              rowClassName={
                props.rowClassName
                  ? props.rowClassName
                  : ({ index }) => {
                      return index === -1 ? classes.headerRow : index % 2 === 0 ? classes.rowEven : classes.rowOdd
                    }
              }
            >
              {useCheckboxes && (
                <Column
                  dataKey={'checkbox'}
                  cellRenderer={cellCheckboxRender}
                  headerRenderer={headerCheckboxRenderer}
                  width={40}
                  disableSort={true}
                />
              )}
              {headerColumns.map((col, index) =>
                col.renderRow ? (
                  col.renderRow(col)
                ) : (
                  <Column
                    headerClassName={classes.header}
                    headerRenderer={(r: any) => headerRenderer(r, index)}
                    dataKey={col.dataKey}
                    label={col.label}
                    flexGrow={typeof col.autoAdjustWidth === 'undefined' ? 1 : col.autoAdjustWidth ? 1 : 0}
                    width={col.width ? col.width! : 10}
                    disableSort={disableSort || (col.disableSort ?? false)}
                  />
                )
              )}
            </Table>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  )
}

export default GCTable
GCTable.defaultProps = {
  disableSort: false,
  useCheckboxes: false,
}
