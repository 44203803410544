import React, { Suspense } from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { SuspenseLoader } from '../ui/SuspenseLoader'
import ErrorBoundary from '../ErrorBoundary'
import { useLocation } from 'react-router-dom'

export type ContentProps = {
  component?: React.ReactNode
  disableBottomSafeArea?: boolean
}

type useStylesProps = {
  disableBottomSafeArea: boolean
}

const useStyles = makeStyles<Theme, useStylesProps>((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      overflow: 'auto',
      overscrollBehavior: 'none',
      'touch-action': 'auto',
      '-ms-touch-action': 'auto',
      marginRight: 'env(safe-area-inset-right)',
      marginLeft: 'env(safe-area-inset-left)',
      boxSizing: 'border-box',
      height: '100%',
      width: 'calc(100% - env(safe-area-inset-right) - env(safe-area-inset-left))',
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.up('md')]: {
        paddingBottom: (props) => (props.disableBottomSafeArea ? 0 : 'env(safe-area-inset-bottom)'),
      },
    },
  })
)

export const Content = (props: ContentProps) => {
  const { component, disableBottomSafeArea } = props
  const classes = useStyles({
    disableBottomSafeArea: disableBottomSafeArea ?? false,
  })

  return (
    <div className={classes.content}>
      {/* We need to use key here to reset error in ErrorBoundary/rerender the ErrorBoundary component */}
      <ErrorBoundary reloadPage margins>
        <Suspense fallback={<SuspenseLoader />}>{component}</Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default Content
