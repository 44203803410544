import { useAuth } from '@griegconnect/krakentools-react-auth'
import { useHttp } from '@griegconnect/krakentools-react-http'
import { ITenantAccess, Application } from '@griegconnect/krakentools-react-tenants'
import { AxiosInstance } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { applicationErrorAtom } from '../atoms/applicationErrorAtom'
import { configSelector, ConfigType } from '../atoms/configAtom'
import { activeTenantSelector, Tenant, tenantsAtom } from '../atoms/tenantsAtom'
import { userAccessAtom } from '../atoms/userAccessAtom'
import { userAtom } from '../atoms/userAtom'
import { useUsersApi } from '../hooks/useUsersApi'
import SplashLoader from '../ui/SplashLoader'
import { Button, Typography } from '@mui/material'
import { Page } from '@griegconnect/krakentools-react-ui'

type FilterTenantType = (tenants: ITenantAccess[]) => Promise<Tenant.Type[]> | Tenant.Type[]
type FilterTenantTypeWithConfig = {
  type: 'config'
  callback: (tenants: ITenantAccess[], config: ConfigType) => Promise<Tenant.Type[]> | Tenant.Type[]
}
type FilterTenantTypeWithConfigAndAppSpec = {
  type: 'app-spec'
  callback: (
    tenants: ITenantAccess[],
    config: ConfigType,
    appSpec: Application.Data[]
  ) => Promise<Tenant.Type[]> | Tenant.Type[]
}
type FilterTenantTypeWithConfigAndHttpClient = {
  type: 'http-client'
  callback: (
    tenants: ITenantAccess[],
    config: ConfigType,
    httpClient: (baseUrl: string, useBearerToken?: boolean | undefined) => AxiosInstance
  ) => Promise<Tenant.Type[]> | Tenant.Type[]
}

export type TenantsWrapperProps = {
  children?: React.ReactNode
  filterUserAccess?:
    | FilterTenantType
    | FilterTenantTypeWithConfig
    | FilterTenantTypeWithConfigAndHttpClient
    | FilterTenantTypeWithConfigAndAppSpec
}

export type ParamsType = {
  [key: string]: string
}

export const TenantsWrapper: React.FC<React.PropsWithChildren<TenantsWrapperProps>> = ({
  children,
  filterUserAccess: filterUserAccessCallback,
}) => {
  const { tenantsUrlMatchPathPrefix, tenantsUrlMatchPathParam } = useApplicationOptions()
  const { httpClient } = useHttp()
  const { isAuthenticated, logout } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>()

  const config = useRecoilValue(configSelector)
  const setUser = useSetRecoilState(userAtom)
  const setUserAccess = useSetRecoilState(userAccessAtom)
  const [tenants, setTenants] = useRecoilState(tenantsAtom)
  const [activeTenant, setActiveTenant] = useRecoilState(activeTenantSelector)
  const setErrors = useSetRecoilState(applicationErrorAtom)
  const usersApi = useUsersApi()

  const match = useMatch({
    path: tenantsUrlMatchPathPrefix ?? '/companies/:tenantRef',
    end: false,
  })

  useEffect(() => {
    if (match) {
      const matchedTenantParam = match.params[tenantsUrlMatchPathParam ?? 'tenantRef']
      if (matchedTenantParam) {
        if (activeTenant?.ref !== matchedTenantParam) {
          setActiveTenant(tenants.find((tenant) => tenant.ref === matchedTenantParam) ?? null)
        }
      } else {
        setActiveTenant(null)
      }
    } else {
      setActiveTenant(null)
    }
  }, [match, activeTenant])

  const getTenants = useCallback(async () => {
    try {
      const data = await usersApi.currentUserAccess()
      setUser(data.user)
      setUserAccess(data.access)
      let userAccess: Tenant.Type[]
      if (filterUserAccessCallback) {
        if (typeof filterUserAccessCallback === 'function') {
          userAccess = await filterUserAccessCallback(data.access)
        } else {
          if (filterUserAccessCallback.type === 'config') {
            userAccess = await filterUserAccessCallback.callback(data.access, config)
          } else if (filterUserAccessCallback.type === 'http-client') {
            userAccess = await filterUserAccessCallback.callback(data.access, config, httpClient)
          } else if (filterUserAccessCallback.type === 'app-spec') {
            const appSpec = await usersApi.getCurrentUserApplications(data.access)
            userAccess = await filterUserAccessCallback.callback(data.access, config, appSpec)
          } else {
            userAccess = []
          }
        }
      } else {
        userAccess = data.access.map((access) => {
          return {
            id: access.tenant.id,
            name: access.tenant.name,
            ref: access.tenant.ref,
            permissions: (access.permissions as Tenant.Permission[]) ?? [],
            companies: access.companies.map((c) => ({
              ...c.company,
              permissions: c.permissions ?? [],
            })),
          } as Tenant.Type
        })
      }
      return userAccess.sort((t1, t2) => (t1.name > t2.name ? 1 : -1))
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('An unknown error occurred')
      }
      return []
    }
  }, [usersApi])

  const fetchTenants = () => {
    if (isAuthenticated) {
      setIsLoading(true)
      getTenants()
        .then((data) => {
          setTenants(data)
        })
        .catch((e) => {
          setErrors((errors) => errors.concat({ message: e.message, severity: 'critical' }))
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchTenants()
  }, [isAuthenticated])

  if (isLoading) {
    return <SplashLoader message="Fetching user information" />
  }

  if (errorMessage !== undefined) {
    return (
      <Page.Wrapper title="Error fetching your details">
        <Typography>{errorMessage}</Typography>
        <Typography sx={{ mb: 2 }}>If the problem persists, please contact your administrator.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            fetchTenants()
          }}
        >
          Retry
        </Button>
        {isAuthenticated && <Button onClick={() => logout()}>Sign out</Button>}
      </Page.Wrapper>
    )
  }

  return children
}

export default TenantsWrapper
