import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMobile = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M7 2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H7Zm1 18V4h2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1h2v16H8Z"
      clipRule="evenodd"
    />
  )
export default SvgMobile
