import { ConfigType } from "../atoms/configAtom"

export const getEnvironmentName = (configEnv: ConfigType['environment']) => {
    if (process.env.NODE_ENV !== 'production' || configEnv === 'dev') {
      return 'dev'
    }
    if (configEnv === undefined) {
      return 'unknown'
    }
    return configEnv
  }