import { Application } from '@griegconnect/krakentools-react-tenants'
import { Grid } from '@mui/material'
import AppSwitcherGridItem from './AppSwitcherGridItem'

export type AppSwitcherGridProps = {
  applications?: Application.Data[]
  children?: React.ReactNode
}

const AppSwitcherGrid = (props: AppSwitcherGridProps) => {
  return (
    <>
      <Grid container>
        {props.applications &&
          props.applications.map((application) => (
            <Grid item xs={4} key={application.ref}>
              <AppSwitcherGridItem
                title={application.title}
                applicationUrl={application.applicationUrl}
                designBundle={application.designBundle}
                description={application.description}
              />
            </Grid>
          ))}
      </Grid>
      {props.children}
    </>
  )
}

export default AppSwitcherGrid
