import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 180,
  },
  formControlLabel: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: `translate(${theme.spacing(1)}, 12px) scale(1)`,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
  },
  selectIcon: {
    right: theme.spacing(0.5),
    fill: theme.palette.text.secondary,
  },
}))

const TenantsStyledSelect = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: theme.spacing(0.5),
      position: 'relative',
      backgroundColor: theme.palette.action.hover,
      border: `1px solid transparent`,
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(1),
      '&:focus': {
        borderRadius: theme.spacing(0.5),
        borderColor: theme.palette.primary.main,
      },
    },
  })
)(InputBase)

export interface ISelectTenantItem {
  name: string
  ref: string
}

interface ITenantSelectProps {
  activeRef?: string
  tenants: ISelectTenantItem[]
  onChange: (tenantRef: string) => void
  style?: React.CSSProperties
  className?: string
}

const TenantSelect: React.FC<React.PropsWithChildren<ITenantSelectProps>> = ({
  onChange,
  tenants,
  activeRef,
  style,
  className,
}) => {
  const classes = useStyles()

  const onChangeHandler = (event: any) => {
    onChange(event.target.value as string)
  }

  return (
    <FormControl fullWidth={true} variant="filled" className={`${classes.formControl} ${className}`} style={style}>
      <InputLabel id="tenant-select-label" className={classes.formControlLabel}>
        {activeRef ? '' : tenants.length === 0 ? 'No companies' : 'Select company'}
      </InputLabel>
      <Select
        labelId="tenant-select-label"
        id="tenant-select"
        classes={{ icon: classes.selectIcon }}
        value={activeRef ?? ''}
        disabled={tenants.length === 0}
        // native={true}
        autoWidth={false}
        onChange={onChangeHandler}
        input={<TenantsStyledSelect />}
      >
        {activeRef === undefined && tenants.length < 0 && (
          <MenuItem value="" disabled>
            Select company
          </MenuItem>
        )}

        {tenants.map((tenant) => {
          return (
            <MenuItem key={tenant.ref} value={tenant.ref}>
              {tenant.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default TenantSelect
