import { ILayerListItem } from '@griegconnect/krakentools-kmap'
import { atomFamily, DefaultValue, selectorFamily } from 'recoil'

import { getFromLocalStorage, KrakenMapStorageState, setToLocalStorage } from '../utils/localStorageStore'
import { selectedVesselAtom } from './liveViewAtoms'
import { LayerMenuItem } from './types/LayerMenuItem'
import { MapIdentifierType } from './types/MapIdentifierType'
import { AssetType } from '../apis/dtos/KindsDto'
import { ExtendedFeature } from './types/ExtendedFeature'
import { CreateAsset } from './types/CreateAsset'
import { EditAsset } from './types/EditAsset'
import { useLocalStorageAtom } from './mapConfigAtoms'

/**
 * Asset View Layers Logicview
 * */

// Asset Layers Menu State
export const activeAssetLayersAtom = atomFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeAssetLayersState',
  default: [],
})

export const activeAssetLayersSelector = selectorFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeAssetLayersSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const layers = get(activeAssetLayersAtom(mapIdentifier))
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (useLocalStorage) {
        const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
        if (state?.layers?.assetView) {
          return state.layers.assetView
        } else {
          return layers
        }
      } else {
        return layers
      }
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, newActiveLayers) => {
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (newActiveLayers instanceof DefaultValue) {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.layers?.assetView) {
            existing.layers.assetView = []
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          }
        }
        reset(activeAssetLayersAtom(mapIdentifier))
      } else {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.layers) {
            existing.layers.assetView = newActiveLayers
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          } else {
            const newState: KrakenMapStorageState = {
              ...existing,
              layers: {
                ...existing?.layers,
                assetView: newActiveLayers,
              },
            }
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
          }
        }
        set(activeAssetLayersAtom(mapIdentifier), newActiveLayers)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const activeAssetLabelsAtom = atomFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeAssetLabelsState',
  default: [],
})

export const activeAssetLabelsSelector = selectorFamily<LayerMenuItem['key'][], MapIdentifierType>({
  key: 'activeAssetLabelsSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const labels = get(activeAssetLabelsAtom(mapIdentifier))
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (useLocalStorage) {
        const state = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
        if (state?.labels?.assetView) {
          return state.labels.assetView
        } else {
          return labels
        }
      } else {
        return labels
      }
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, newActiveLabels) => {
      const useLocalStorage = get(useLocalStorageAtom(mapIdentifier))
      if (newActiveLabels instanceof DefaultValue) {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.labels?.assetView) {
            existing.labels.assetView = []
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          }
        }
        reset(activeAssetLabelsAtom(mapIdentifier))
      } else {
        if (useLocalStorage) {
          let existing = getFromLocalStorage<KrakenMapStorageState>(mapIdentifier)
          if (existing?.labels) {
            existing.labels.assetView = newActiveLabels
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, existing)
          } else {
            const newState: KrakenMapStorageState = {
              ...existing,
              labels: {
                assetView: newActiveLabels,
              },
            }
            setToLocalStorage<KrakenMapStorageState>(mapIdentifier, newState)
          }
        }
        set(activeAssetLabelsAtom(mapIdentifier), newActiveLabels)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

// All available asset layers (e.g. kinds)
export const kindsAtom = atomFamily<AssetType[], MapIdentifierType>({
  key: 'kindsState',
  default: (mapIdentifier: MapIdentifierType) => [],
})

export const assetLayersSelector = selectorFamily<LayerMenuItem[], MapIdentifierType>({
  key: 'assetLayersSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const kinds = get(kindsAtom(mapIdentifier))
      // const distinctKinds = kinds.reduce<Kind[]>((prev, curr) => {
      //   return prev.find(p => p.ref === curr.ref) ? prev : [...prev, curr]
      // }, [])
      return kinds.map((l) => {
        return {
          key: l.meta.id,
          name: l.name || l.meta.id,
          hasLabels: true,
        }
      }).sort((a, b) => a.name > b.name?1:-1)
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const allAssetLayersVisibleSelector = selectorFamily<boolean, MapIdentifierType>({
  key: 'allAssetLayersVisibleSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const allShownAssetLayers = get(activeAssetLayersSelector(mapIdentifier))
      return get(assetLayersSelector(mapIdentifier)).every((l) => allShownAssetLayers.includes(l.key))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, allLayersVisible) => {
      if (allLayersVisible instanceof DefaultValue) {
        reset(activeAssetLayersSelector(mapIdentifier))
      } else {
        if (allLayersVisible === false) {
          set(activeAssetLayersSelector(mapIdentifier), [])
        } else {
          const availableAssetLayers = get(assetLayersSelector(mapIdentifier)).map((l) => l.key)
          set(activeAssetLayersSelector(mapIdentifier), availableAssetLayers)
        }
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

// All active layers formatted to kmap format
export const visibleAssetLayersSelector = selectorFamily<ILayerListItem[], MapIdentifierType>({
  key: 'visibleAssetLayersSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      const layers = get(assetLayersSelector(mapIdentifier))
      const activeLayers = get(activeAssetLayersSelector(mapIdentifier))
      const activeLabels = get(activeAssetLabelsSelector(mapIdentifier))
      const layerListItems = layers
        .filter((l) => activeLayers.includes(l.key))
        .map((l) => {
          return {
            layerName: l.name,
            type: l.key,
            visible: true,
            showLabels: activeLabels.includes(l.key),
          } as ILayerListItem
        })
      return layerListItems
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

/* Asset view actions */

export const selectedAssetAtom = atomFamily<ExtendedFeature | null, MapIdentifierType>({
  key: 'selectedAssetState',
  default: null,
})

export const selectedAssetSelector = selectorFamily<ExtendedFeature | null, MapIdentifierType>({
  key: 'selectedAssetSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      return get(selectedAssetAtom(mapIdentifier))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ get, set, reset }, selectedAsset) => {
      if (selectedAsset instanceof DefaultValue) {
        reset(selectedAssetAtom(mapIdentifier))
      } else {
        reset(selectedVesselAtom(mapIdentifier))
        set(selectedAssetAtom(mapIdentifier), selectedAsset)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const hightlightedAssetAtom = atomFamily<ExtendedFeature | null, MapIdentifierType>({
  key: 'hightlightedAssetState',
  default: null,
})

export const editAssetAtom = atomFamily<EditAsset | null, MapIdentifierType>({
  key: 'editAssetState',
  default: null,
})

export const editAssetSelector = selectorFamily<EditAsset | null, MapIdentifierType>({
  key: 'editAssetSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      return get(editAssetAtom(mapIdentifier))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ set, reset }, editAsset) => {
      if (editAsset instanceof DefaultValue) {
        reset(editAssetAtom(mapIdentifier))
      } else {
        reset(hightlightedAssetAtom(mapIdentifier))
        reset(selectedAssetAtom(mapIdentifier))
        reset(selectedVesselAtom(mapIdentifier))
        reset(createAssetAtom(mapIdentifier))
        set(editAssetAtom(mapIdentifier), editAsset)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const createAssetAtom = atomFamily<CreateAsset | null, MapIdentifierType>({
  key: 'createAssetState',
  default: null,
})

export const createAssetSelector = selectorFamily<CreateAsset | null, MapIdentifierType>({
  key: 'createAssetSelector',
  get:
    (mapIdentifier: MapIdentifierType) =>
    ({ get }) => {
      return get(createAssetAtom(mapIdentifier))
    },
  set:
    (mapIdentifier: MapIdentifierType) =>
    ({ set, reset }, createAsset) => {
      if (createAsset instanceof DefaultValue) {
        reset(createAssetAtom(mapIdentifier))
      } else {
        reset(hightlightedAssetAtom(mapIdentifier))
        reset(selectedAssetAtom(mapIdentifier))
        reset(selectedVesselAtom(mapIdentifier))
        reset(editAssetSelector(mapIdentifier))
        set(createAssetAtom(mapIdentifier), createAsset)
      }
    },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
})

export const refreshAssetViewAtom = atomFamily<number, MapIdentifierType>({
  key: 'refreshAssetViewStateAtom',
  default: 0
})