import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export type Application = {
  name: string;
  logo: React.ReactElement;
  url: string;
};

export type AppSelectionProps = {
  applications: Application[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      paddingTop: theme.spacing(2),
    },
    label: {
      textAlign: 'center',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    appButton: {
      width: '80px',
      padding: theme.spacing(1),
    },
  })
);

export const AppSelection = (props: AppSelectionProps) => {
  const { applications } = props;
  const classes = useStyles();

  const handleApplicationChange = (url: string) => {
    window.location.href = url;
  };

  const applicationItems = applications.map((app: Application) => {
    return (
      <Button
        key={app.name}
        aria-label={app.name}
        className={classes.appButton}
        onClick={() => handleApplicationChange(app.url)}
      >
        <span>
          <div>{app.logo}</div>
          <Typography align="center" color="inherit" variant="caption">
            {app.name}
          </Typography>
        </span>
      </Button>
    );
  });

  return <div className={classes.appsWrapper}>{applicationItems}</div>;
};

export default AppSelection;
