import { atom } from 'recoil'

export type FooterAtomType = {
  hide: boolean
}

export const footerAtom = atom<FooterAtomType>({
  key: 'footerState',
  default: {
    hide: true,
  },
})
