import { Typography, Button } from '@mui/material'

export type SignupFormFooterProps = {
  termsOfServiceUrl: string
  privacyPolicyUrl: string
  disabled: boolean
}

export const SignupFormFooter = (props: SignupFormFooterProps) => (
  <Typography sx={{ color: 'text.secondary' }}>
    By creating a Grieg ID you accept the
    <Button size="small" href={props.termsOfServiceUrl} disabled={props.disabled} color="primary" target="_blank">
      Terms of service
    </Button>
    and
    <Button size="small" href={props.privacyPolicyUrl} disabled={props.disabled} color="primary" target="_blank">
      Privacy Policy
    </Button>
  </Typography>
)
