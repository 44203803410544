import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#66BB6A',
  light: '#98ee99',
  dark: '#338a3e',
  contrastText: 'rgba(0, 0, 0, 0.87)',
}

export default Color
