import { Palette } from '@mui/icons-material'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/styles'

export const routes = [
  {
    name: 'Styles',
    page: React.createElement(React.lazy(() => import('./Styles'))),
    menu: {
      icon: <Palette />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
]
