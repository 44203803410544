import { Popper, Paper, Grow, ClickAwayListener } from '@mui/material'
import { IconButtonToInputField } from '@griegconnect/krakentools-react-ui'
import { useRecoilState } from 'recoil'
import { searchAtom } from '../atoms/searchAtom'
import { useEffect, useRef, useState } from 'react'
import { Search as SearchIcon } from '@griegconnect/krakentools-react-icons'

const Search = () => {
  const [searchState, setSearchState] = useRecoilState(searchAtom)
  const [openPopper, setOpenPopper] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (searchState.miniSearchOpen) {
      openPopdown()
    }
  }, [searchState.miniSearchOpen])

  const openPopdown = () => {
    setTimeout(() => {
      setOpenPopper(true)
    }, 400)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSearchState((currVal) => ({ ...currVal, miniSearchOpen: true, term: currVal.clearOnOpen ? '' : currVal.term }))
    openPopdown()
  }

  const handleBlur = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.relatedTarget) === false &&
      anchorRef.current.contains(event.target) === false
    ) {
      setSearchState((currVal) => ({ ...currVal, miniSearchOpen: false }))
      setOpenPopper(false)
    }
  }

  const handleChange = (term: string) => {
    setSearchState((currVal) => {
      return { ...currVal, term }
    })
  }

  const handleSubmit = () => {
    setSearchState((currVal) => ({ ...currVal, searchVisible: true, miniSearchOpen: false }))
    setOpenPopper(false)
  }

  const handlePopoverClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target) === false) {
      setSearchState((currVal) => ({ ...currVal, miniSearchOpen: false }))
      setOpenPopper(false)
    }
  }

  return (
    <>
      <div ref={anchorRef}>
        <IconButtonToInputField
          autoFocus
          open={searchState.miniSearchOpen}
          icon={<SearchIcon />}
          value={searchState.term}
          placeholder={searchState.placeholder}
          onChange={handleChange}
          onOpen={handleOpen}
          onSubmit={handleSubmit}
          onBlur={handleBlur}
        />
      </div>
      {searchState.miniSearchOpen && !searchState.searchVisible && openPopper && (
        <Popper open={openPopper} anchorEl={anchorRef.current} role="search" transition style={{ zIndex: 99999 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handlePopoverClose}>
                  <Paper style={{ padding: 16, width: 250 }}>{searchState.popdownContentComponent}</Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  )
}

export default Search
