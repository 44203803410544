import { Application } from '@griegconnect/krakentools-react-tenants'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useUsersApi } from '..'
import { userAccessAtom } from '../atoms/userAccessAtom'
import axios from 'axios'
import { isValidUrl } from '../utils/url'

type UseQueryApplicationsOptions = {
  enabled?: boolean
}
export const useApplicationsQuery = (options?: UseQueryApplicationsOptions) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const abortControllerRef = useRef<AbortController>()
  const [data, setData] = useState<Application.Data[]>()
  const usersApi = useUsersApi()
  const userAccess = useRecoilValue(userAccessAtom)

  const queryApi = async () => {
    setIsLoading(true)
    setIsError(false)
    const newAbortController = new AbortController()
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = newAbortController
      const applications = await usersApi.getCurrentUserApplications(
        userAccess ?? [],
        abortControllerRef.current.signal
      )
      setData(applications.filter((app) => isValidUrl(app.applicationUrl)))
    } catch (error) {
      if (error instanceof axios.Cancel === false) {
        if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
          console.error('[kraken-app::useApplicationsQuery]', error)
          setIsError(true)
        }
      }
    }
    if (
      abortControllerRef.current &&
      abortControllerRef.current === newAbortController &&
      !abortControllerRef.current.signal.aborted
    ) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isInit && (options?.enabled === undefined || options.enabled)) {
      setIsInit(true)
      queryApi()
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [options?.enabled])

  return { data, isLoading, isError, refetch: queryApi }
}
