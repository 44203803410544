import { IconButton } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon } from '@griegconnect/krakentools-react-icons'
import { useRecoilState } from 'recoil'

import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(1.5),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(1),
      },
    },
  })
)

export const LeftDrawerButton = () => {
  const classes = useStyles()
  const [leftDrawerState, setLeftDrawerState] = useRecoilState(leftDrawerAtom)

  const toggleLeftDrawerHandler = () => {
    setLeftDrawerState({ ...leftDrawerState, isOpen: !leftDrawerState.isOpen })
  }

  return leftDrawerState.disable ? null : (
    <IconButton className={classes.button} onClick={toggleLeftDrawerHandler} size="large">
      {leftDrawerState.isOpen ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );
}

export default LeftDrawerButton
