import React, { useState } from 'react'
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 36,
  },
  tabRoot: {
    minWidth: 0,
    paddingBottom: 2,
    minHeight: 36,
  },
  scrollButtons: {
    width: theme.spacing(3),
  },
}))

export type TabComponentType = {
  title: string
  component: JSX.Element
  disabledTab?: boolean
  icon?: string | React.ReactElement
  /**
   * Can be used if you need to bind your tabs to navigation, or if you need an identifier other than index
   *
   * @type {string}
   */
  slug?: string
}

interface TabsProps {
  items: TabComponentType[]
  /**
   * The index of the tab to be selected by default
   * @example If you want to select the second tab by default, set this to 1
   * @default 0
   */
  defaultSelectedIndex?: number
  /**
   * If tab panels should unmount when they are not active. If unmount is used,
   * the component's internal state will reset.
   *
   * @default false
   */
  unmount?: boolean
  className?: string
  onChange?: (next: TabComponentType, prev: TabComponentType) => void
  /**
   * IMPORTANT: You may not wish to use this component as controlled, so consider
   * whether you need to use `value` and `onChange` or not.
   *
   * @type {number}
   * @memberof TabsProps
   */
  value?: number
}

export const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = ({
  items,
  unmount,
  defaultSelectedIndex,
  className,
  onChange,
  value: controlledValue,
}) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultSelectedIndex ?? 0)
  const classes = useStyles()
  const controlled = onChange !== undefined && controlledValue !== undefined

  const handleTabChange = (_e: React.ChangeEvent<{}>, index: number) => {
    if (controlled && onChange) {
      const prev = items[uncontrolledValue]
      const next = items[index]
      onChange(next, prev)
    } else {
      setUncontrolledValue(index)
    }
  }

  return (
    <div>
      <MuiTabs
        variant="scrollable"
        scrollButtons="auto"
        value={controlled ? controlledValue : uncontrolledValue}
        className={className}
        classes={{ root: classes.tabsRoot, scrollButtons: classes.scrollButtons }}
        onChange={handleTabChange}
      >
        {items.map((tabItem) => (
          <MuiTab
            key={`tab-${tabItem.title}`}
            label={tabItem.title}
            classes={{ root: classes.tabRoot }}
            disabled={tabItem.disabledTab}
            icon={tabItem.icon}
          />
        ))}
      </MuiTabs>
      <div style={{ padding: '24px 16px' }}>
        {items.map((tabItem, index) => (
          <TabPanel
            key={`panel-${tabItem.title}`}
            unmount={unmount}
            hidden={controlled ? index !== controlledValue : index !== uncontrolledValue}
          >
            {tabItem.component}
          </TabPanel>
        ))}
      </div>
    </div>
  )
}

const useTabPanelStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}))

type TabPanelType = Pick<TabsProps, 'unmount'> & {
  hidden: boolean
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelType>> = ({ hidden, children, unmount }) => {
  const classes = useTabPanelStyles()

  if (unmount && hidden) {
    return null
  }

  return <div className={hidden ? classes.hidden : undefined}>{children}</div>
}

Tabs.defaultProps = {
  unmount: false,
}

export default Tabs
