import { Box, InputAdornment, InputBase, useMediaQuery, CircularProgress, Stack, Popper } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete'
import { Search as SearchIcon } from '@griegconnect/krakentools-react-icons'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useMapContext } from '../MapContext'
import { searchResultAtom, searchStringAtom, selectedSearchResultAtom } from '../atoms/controlAtoms'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SearchResultItem } from '../atoms/types/SearchResultItem'
import { MapButtonGroup } from '../shared/MapButtonGroup'
import { MapButton } from '../shared/MapButton'
import { SpotlightResultItem } from './SpotlightResultItem'
import { followedVesselSelector } from '../atoms/liveViewAtoms'
import { useHttp } from '@griegconnect/krakentools-react-http'
import { aisShipType } from '@griegconnect/krakentools-kmap'
import { aisCountryMap } from '../utils/aisCountryMap'
import { SearchCategory, SearchitApi } from '../apis/SearchitApi'
import { useQuery } from '@tanstack/react-query'
import { MapTranslucentPaper } from '../shared/MapTranslucentPaper'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundBlur: {
      maxWidth: 320 + 16 + 48 + 'px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: 'none',
        width: `calc(100% - ${24 + 24}px)`,
      },
      zIndex: 10,
    },
    inputField: {
      userSelect: 'none',
      paddingRight: theme.spacing(1),
    },
    input: {
      border: 'none',
      padding: theme.spacing(1.8125),
    },
  })
)

type SpotlightControlProps = {
  autoFocus?: boolean
  activeTenantId: string
  searchitApiUrl: string
  placeholder?: string
  isAbsolute?: boolean
  width?: number
  searchCategories?: SearchCategory[]
  onError?: (error: Error) => void
  onOptionSelect?: (value: SearchResultItem | null) => void
}

export const SpotlightControl = React.forwardRef<HTMLInputElement, SpotlightControlProps>((props, ref) => {
  const absolute = props.isAbsolute === false ? false : true
  const classes = useStyles()
  const { mapIdentifierSlug } = useMapContext()
  const theme = useTheme()
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [searchString, setSearchString] = useRecoilState(searchStringAtom(mapIdentifierSlug))
  const [searchResults, setSearchResults] = useRecoilState(searchResultAtom(mapIdentifierSlug))
  const followedVessel = useRecoilValue(followedVesselSelector(mapIdentifierSlug))
  const setSelectedSearchResult = useSetRecoilState(selectedSearchResultAtom(mapIdentifierSlug))
  const selectedSearchRef = useRef<SearchResultItem | null>(null)
  const { httpClient } = useHttp()
  const { t } = useTranslation('kmap')
  const searchitApi = useMemo(
    () => new SearchitApi(httpClient(props.searchitApiUrl + '/tenants/' + props.activeTenantId, true)),
    [props.activeTenantId, httpClient, props.searchitApiUrl]
  )
  const cats = props.searchCategories || ['ports', 'vessels']

  useEffect(() => {
    if (searchString.length < 3) {
      setSearchResults([])
    }
  }, [searchString])

  const { isLoading } = useQuery(
    ['search-' + props.searchCategories?.join('-'), searchString.toLowerCase()],
    ({ signal }) => searchitApi.query(searchString.toLowerCase(), cats, signal),
    {
      enabled: searchString.length >= 2,
      cacheTime: 0,
      onError: props.onError,
      onSuccess: (data) => {
        const searchResults: SearchResultItem[] = data.map((current) => {
          switch (current._index) {
            case 'searchit_ports':
              const portResult = current._source
              return {
                value: portResult,
                title: (
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <span>{portResult.name + (portResult.locode ? ' [' + portResult.locode + ']' : '')}</span>
                    {portResult.locode && (
                      <img
                        width="20"
                        height="15"
                        src={`https://flagcdn.com/w20/${portResult.locode.substring(0, 2).toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${portResult.locode.substring(0, 2).toLowerCase()}.png 2x`}
                        title={portResult.country}
                      />
                    )}
                  </Stack>
                ),
                optionLabel: portResult.name,
                subtitle: portResult.country,
                type: 'port',
              }
            case 'ais-statics_statics':
              const staticResult = current._source
              const country = aisCountryMap.get(Number(String(staticResult.payload.mmsi).slice(0, 3)))
              const shiptype = aisShipType(staticResult.payload.shipType) || 'Unknown'
              const callsign = staticResult.payload.callsign ? staticResult.payload.callsign + ' - ' : ''
              const name = staticResult.name || staticResult.payload.mmsi
              const flag = country ? (
                <img
                  width="20"
                  height="15"
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                  title={country.name}
                />
              ) : null
              return {
                value: staticResult,
                title: (
                  <Stack direction={'row'} alignItems={'center'} spacing={1} justifyContent={'space-between'}>
                    <span>{name}</span> {flag || (country?.code ? <span>[{country.code}]</span> : '')}
                  </Stack>
                ),
                subtitle: callsign + shiptype,
                type: 'vessel',
                optionLabel: String(name),
              }
          }
        })
        setSearchResults(searchResults)
      },
    }
  )

  const onInputChangeHandler = (_event: React.ChangeEvent<{}>, value: string) => {
    setSearchString(value)
  }

  const onChangeSelectHandler = (event: React.ChangeEvent<{}>, value: SearchResultItem | string | null) => {
    if (typeof value !== 'string') {
      selectedSearchRef.current = value
      if (props.onOptionSelect) props.onOptionSelect(value)
    }
  }

  const onSearchToggle = () => {
    setShowSearch((prev) => !prev)
  }

  const onBlurHandler = () => {
    setSearchString('')
    setSearchResults([])
    setShowSearch(false)
  }

  const filterOptions = (options: SearchResultItem[]) => options

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SearchResultItem,
    state: AutocompleteRenderOptionState
  ) => {
    return <SpotlightResultItem {...props} key={`${option?.title}-${props.id}`} item={option} />
  }

  const uiIsLoading = searchString.length >= 2 && isLoading
  return followedVessel === null || isDesktopScreen ? (
    <Box position={absolute ? 'absolute' : 'unset'} className={classes.backgroundBlur} width={props.width || '100%'}>
      {!isDesktopScreen && !showSearch && (
        <MapButtonGroup left={absolute ? 0 : undefined} top={absolute ? 0 : undefined}>
          <MapButton onClick={onSearchToggle} icon={<SearchIcon />} isActive={showSearch} iconButtonOnly />
        </MapButtonGroup>
      )}
      {(isDesktopScreen || showSearch) && (
        <Autocomplete
          onInputChange={onInputChangeHandler}
          onChange={onChangeSelectHandler}
          inputValue={searchString}
          autoHighlight={true}
          clearOnEscape={true}
          onBlur={!isDesktopScreen ? onBlurHandler : undefined}
          disableClearable={false}
          fullWidth={true}
          onClose={(_evt, reason) => {
            if (reason === 'selectOption' && selectedSearchRef.current !== null) {
              setSelectedSearchResult(selectedSearchRef.current)
              if (props.onOptionSelect) props.onOptionSelect(selectedSearchRef.current)
            }
          }}
          PopperComponent={(props) => (
            <Popper
              {...props}
              popperOptions={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      mainAxis: false,
                    },
                  },
                ],
              }}
            />
          )}
          PaperComponent={(props) => (
            <MapTranslucentPaper {...props} elevation={24} sx={{ marginTop: 1 }} styleVariant="static" />
          )}
          renderInput={(params) => (
            <MapTranslucentPaper>
              <InputBase
                {...params.InputProps}
                className={classes.inputField}
                fullWidth={true}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                inputRef={ref}
                endAdornment={
                  <InputAdornment position="end">
                    {(uiIsLoading && <CircularProgress size={24} />) || <SearchIcon />}
                  </InputAdornment>
                }
                inputProps={{
                  ...params.inputProps,
                  className: classes.input,
                }}
              />
            </MapTranslucentPaper>
          )}
          options={searchResults}
          getOptionLabel={(option) => {
            return typeof option === 'string' ? option : option.optionLabel
          }}
          filterOptions={filterOptions}
          renderOption={renderOption}
          loading={isLoading}
          loadingText={uiIsLoading ? t('SpotlightControl.loadingText') : t('SpotlightControl.atleastTwoCharsText')}
          noOptionsText={t('SpotlightControl.noHits')}
          isOptionEqualToValue={(option, value) => option.optionLabel === value.optionLabel}
        />
      )}
    </Box>
  ) : null
})

export default SpotlightControl
