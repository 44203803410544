import { StepContent as MuiStepContent, StepContentProps } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { StepContentClasskey } from '@mui/material/StepContent'
import clsx from 'clsx'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepContentClasskey>(() =>
  createStyles<StepContentClasskey, StyleProps>({
    root: {},
    last: {},
    transition: {},
  })
)

function StepContent(props: StepContentProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiStepContent
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        last: clsx(innerClasses.last, classes?.last),
        transition: clsx(innerClasses.transition, classes?.transition),
      }}
    />
  )
}

export default StepContent
