import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ArrowUp as ArrowUpIcon } from '@griegconnect/krakentools-react-icons'
import { ArrowDown as ArrowDownIcon } from '@griegconnect/krakentools-react-icons'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { ReactNode, useState } from 'react'
import Box from '@mui/material/Box'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingBottom: theme.spacing(1),
    },
    listHeader: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    listIcon: {
      minWidth: theme.spacing(3.5),
    },
    actions: {
      right: theme.spacing(1),
    },
  })
)

type LayerControlCategoryProps = {
  title: ReactNode
  secondaryAction?: React.ReactNode
  startCollapsed?: boolean
}

export const LayerControlCategory: React.FC<React.PropsWithChildren<LayerControlCategoryProps>> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(!props.startCollapsed)
  const onToggleList = () => {
    setOpen((prev) => !prev)
  }
  return (
    <List disablePadding={true} className={classes.list}>
      <ListItem disableGutters={true} onClick={onToggleList} className={classes.listHeader}>
        <ListItemIcon className={classes.listIcon}>
          {open ? <ArrowUpIcon fontSize="small" /> : <ArrowDownIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2',
            color: 'textPrimary',
          }}
          primary={props.title}
        />
        {props.secondaryAction && (
          <ListItemSecondaryAction className={classes.actions}>{props.secondaryAction}</ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>{props.children}</Box>
      </Collapse>
    </List>
  )
}
