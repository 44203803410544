import { ArrowLeft, Close, Menu } from '@griegconnect/krakentools-react-icons';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      paddingTop: `env(safe-area-inset-top)`,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      zIndex: theme.zIndex.drawer + 1,
    },
    backButton: {
      padding: theme.spacing(1),
    },
    drawerButton: {
      padding: theme.spacing(1),
    },
    toolbar: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    title: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexGrow: 1,
    },
    profile: {
      padding: theme.spacing(1),
    },
  })
);

export type AppBarProps = {
  enableBackButton?: boolean;
  onBackButtonClick?: () => void;
  enableDrawer?: boolean;
  drawerOpen?: boolean;
  onDrawerToggle?: (state: boolean) => void;
  applicationName?: string;
  className?: string
  children?: React.ReactNode;
};

export const AppBar = (props: AppBarProps) => {
  const {
    enableBackButton,
    onBackButtonClick,
    enableDrawer,
    drawerOpen,
    onDrawerToggle,
    applicationName,
    className,
    children
  } = props;

  const classes = useStyles();

  const toggleDrawer = (state: boolean) => {
    if (onDrawerToggle !== undefined) {
      onDrawerToggle(state);
    }
  };

  return (
    <MuiAppBar position="fixed" className={`${classes.appBar} ${className}`} elevation={3}>
      <Toolbar className={classes.toolbar}>
        {enableBackButton && (
          <IconButton
            color="inherit"
            onClick={onBackButtonClick}
            className={classes.backButton}
            data-testid="appbar-backbutton"
            size="large">
            <ArrowLeft />
          </IconButton>
        )}
        {enableDrawer && (
          <IconButton
            color="inherit"
            onClick={() => toggleDrawer(!drawerOpen)}
            className={classes.drawerButton}
            data-testid="appbar-menubutton"
            size="large">
            {drawerOpen ? <Close /> : <Menu />}
          </IconButton>
        )}
        <Typography variant="h6" component="h1" color="inherit" noWrap={true} className={classes.title}>
          <span>{applicationName ? applicationName : 'Grieg Connect'}</span>
        </Typography>
        {children}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
