import React, { useState } from 'react';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { BoardItemProps } from './board-item';
import { ColumnsDroppable, DroppableIdProps } from './columns-droppable';
import { ColumnHeader } from './column-header';
import { BoardTrackInfo } from './board-track';
import { ColumnLayout } from './column-layout';

export const boardColumnMinimumWidth = (columnsCount: number) => {
  return `${columnsCount * 15}em`;
};
export const boardColumnBackgroundColor = 'rgba(0, 0, 0, 0.04)';//TODO Use theme palette is updated

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnCollapsed: {
      display: 'flex',
      flexDirection: 'column',
    },
    topBottomContent: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    itemsCollapsed: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      flexGrow: 1,
      textAlign: 'center',

      backgroundColor: boardColumnBackgroundColor,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    collapsedWrap: {
      // transform: 'rotate(90deg) translateX(27px)',
      writingMode: 'vertical-rl',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    collapsedCount: {
      color: theme.palette.action.disabled,
      paddingTop: theme.spacing(2),
    },
  })
);

export type BoardColumnProps = {
  id: string;
  headerProps: BoardColumnHeaderProps;
  topContent?: React.ReactNode;
  topContentTrack?: BoardColumnTrackContent[];
  bottomContent?: React.ReactNode;
  bottomContentTrack?: BoardColumnTrackContent[];
  isCollapsible?: boolean;
  items: BoardItemProps[];
  isDropDisabled?: boolean;
  onlyAllowDropFromColumns?: string[];
  restrictDropFromColumns?: string[];
  onlyAllowDropFromTracks?: string[];
  restrictDropFromTracks?: string[];
  isStartCollapsed?: boolean;
  isOverrideCollapsed?: boolean;
  tracks?: BoardTrackInfo[];
};

export type BoardColumnComponentProps = BoardColumnProps & {
  index: number;
  track?: BoardTrackInfo;
  dragProps: DragProps;
  hideHeader?: boolean;
};

export type BoardColumnTrackContent = {
  trackId: string;
  content: React.ReactNode;
};

export type DragProps = {
  isDragDisabled: boolean;
  draggingSource?: DroppableIdProps;
  draggingTarget?: DroppableIdProps;
};

export type BoardColumnHeaderProps = {
  header: React.ReactNode;
  headerCollapsed?: React.ReactNode;
  isDisplayItemsInfoDisabled?: boolean;
  itemsInfo?: (itemsCount: number) => React.ReactNode;
  itemsInfoCollapsed?: (itemsCount: number) => React.ReactNode;
};

export const BoardColumn = React.memo((props: BoardColumnComponentProps) => {
  const classes = useStyles(props);
  const [isCollapsedState, setIsCollapsed] = useState(props.isStartCollapsed ?? false);
  const isCollapsed = props.isOverrideCollapsed !== undefined ? props.isOverrideCollapsed : isCollapsedState;

  const { header, headerCollapsed } = props.headerProps;

  const onToggleCollapse = () => {
    setIsCollapsed(!isCollapsedState);
  };

  const draggingFromColumnId = props.dragProps.draggingSource?.columnId;
  const draggingFromTrackId = props.dragProps.draggingSource?.trackId;

  const isDropDisabled =
    props.isDropDisabled ||
    // Verify allow/restrict column drops
    isDragFromDisabled(draggingFromColumnId, props.id, props.onlyAllowDropFromColumns, props.restrictDropFromColumns) ||
    // Verify allow/restrict column drops from track props
    isDragFromDisabled(
      draggingFromColumnId,
      props.id,
      props.track?.onlyAllowDropFromColumns,
      props.track?.restrictDropFromColumns
    ) ||
    // Verify allow/restrict track drops
    isDragFromDisabled(
      draggingFromTrackId,
      props.track?.id,
      props.onlyAllowDropFromTracks,
      props.restrictDropFromTracks
    ) ||
    // Verify allow/restrict track drops from track props
    isDragFromDisabled(
      draggingFromTrackId,
      props.track?.id,
      props.track?.onlyAllowDropFromTracks,
      props.track?.restrictDropFromTracks
    );

  const RenderTopContent = () => {
    return (
      <>
        {props.topContent && <div className={classes.topBottomContent}>{props.topContent}</div>}
        {props.track &&
          props.topContentTrack &&
          props.topContentTrack
            .filter((t) => t.trackId === props.track?.id)
            .map((t: BoardColumnTrackContent) => (
              <div key={t.trackId} className={classes.topBottomContent}>
                {t.content}
              </div>
            ))}
      </>
    );
  };

  const RenderBottomContent = () => {
    return (
      <>
        {props.bottomContent && <div className={classes.topBottomContent}>{props.bottomContent}</div>}
        {props.track &&
          props.bottomContentTrack &&
          props.bottomContentTrack
            .filter((t) => t.trackId === props.track?.id)
            .map((t: BoardColumnTrackContent) => (
              <div key={t.trackId} className={classes.topBottomContent}>
                {t.content}
              </div>
            ))}
      </>
    );
  };

  const countItems = () => {
    return props.items?.length ?? 0;
  };

  const renderItemsInfoCollapsed = () => {
    const itemsInfo = getColumnItemsInfo(isCollapsed, props.headerProps, countItems);
    return itemsInfo !== undefined ? <span className={classes.collapsedCount}>{itemsInfo}</span> : <></>;
  };

  return (
    <ColumnLayout key={props.id} openColumnsCount={1} columnsCount={1} isCollapsed={isCollapsed}>
      {!props.hideHeader && (
        <ColumnHeader
          isCollapsible={props.isCollapsible}
          isCollapsed={isCollapsed}
          headerProps={props.headerProps}
          handleToggleCollapse={onToggleCollapse}
          getItemsInfo={() => getColumnItemsInfo(isCollapsed, props.headerProps, countItems)}
        ></ColumnHeader>
      )}
      {!isCollapsed && (
        <>
          <RenderTopContent />
          <ColumnsDroppable isDropDisabled={isDropDisabled} {...props} />
          <RenderBottomContent />
        </>
      )}
      {isCollapsed && (
        <div className={classes.itemsCollapsed}>
          <span className={classes.collapsedWrap}>
            {headerCollapsed ?? header}
            {renderItemsInfoCollapsed()}
          </span>
        </div>
      )}
    </ColumnLayout>
  );
});

const isDragFromDisabled = (
  draggingFromId: string | undefined,
  currentId: string | undefined,
  onlyAllowFromIds: string[] | undefined,
  restrictFromIds: string[] | undefined
): boolean => {
  return (
    draggingFromId !== undefined &&
    draggingFromId !== currentId &&
    ((onlyAllowFromIds !== undefined && !onlyAllowFromIds.some((id) => id === draggingFromId)) ||
      (restrictFromIds !== undefined && restrictFromIds.some((id) => id === draggingFromId)))
  );
};

export const getColumnItemsInfo = (
  isCollapsed: boolean,
  headerProps: BoardColumnHeaderProps,
  countItems: () => number | undefined
) => {
  const {
    isDisplayItemsInfoDisabled: isDisplayCountDisabled,
    itemsInfo: customCount,
    itemsInfoCollapsed: customCountCollapsed,
  } = headerProps;

  const count = () => countItems() ?? 0;

  return !isDisplayCountDisabled
    ? isCollapsed && customCountCollapsed
      ? customCountCollapsed(count())
      : customCount
      ? customCount(count())
      : `(${count()})`
    : undefined;
};
