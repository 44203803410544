import { StepConnector as MuiStepConnector, StepConnectorClasskey } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StepConnectorProps } from '@mui/material/StepConnector'
import clsx from 'clsx'
import React from 'react'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepConnectorClasskey>(() =>
  createStyles<StepConnectorClasskey, StyleProps>({
    root: {},
    completed: {},
    alternativeLabel: {},
    horizontal: {},
    active: {},
    disabled: {},
    line: {},
    lineHorizontal: {},
    lineVertical: {},
    vertical: {},
  })
)

function StepConnector(props: StepConnectorProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiStepConnector
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        lineHorizontal: clsx(innerClasses.lineHorizontal, classes?.lineHorizontal),
        line: clsx(innerClasses.line, classes?.line),
        disabled: clsx(innerClasses.disabled, classes?.disabled),
        active: clsx(innerClasses.active, classes?.active),
        horizontal: clsx(innerClasses.horizontal, classes?.horizontal),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
        completed: clsx(innerClasses.completed, classes?.completed),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        lineVertical: clsx(innerClasses.lineVertical, classes?.lineVertical),
      }}
    />
  )
}

export default StepConnector
