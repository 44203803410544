import { AddToPhotos } from '@mui/icons-material'
import React from 'react'
import { Navigation } from '@griegconnect/krakentools-react-kraken-app'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/types'

export const routes: Navigation.Item[] = [
  {
    name: 'Manage Asset Types',
    page: React.createElement(React.lazy(() => import('./Types'))),
    menu: {
      icon: <AddToPhotos />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
  {
    name: 'AssetType',
    page: React.createElement(React.lazy(() => import('./Type'))),
    path: tenantsUrlMatchPathPrefix + ROUTEPATH + '/:idornew',
  },
]
