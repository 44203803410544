import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono'

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import { GriegThemeContext } from './theme-context'
import { ThemeProvider, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import createKrakenTheme, { Tool } from './createKrakenTheme'
import { PaletteMode } from '@mui/material'
import lightThemeOptions from './light/theme'
import darkThemeOptions from './dark/theme'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type Props = {
  children: React.ReactNode
  useScopedCssBaseline?: boolean
  tool: Tool
  paletteType?: PaletteMode
  overrideGlobalStyles?: boolean
}

type UseGlobalStylesProps = {
  useHtmlTagGlobalStyle: boolean
  useBodyTagGlobalStyle: boolean
  useRootTagGlobalStyle: boolean
  useOverscrollBehavior: boolean
  paletteType: PaletteMode
}
const useGlobalStyles = makeStyles<Theme, UseGlobalStylesProps>(() =>
  createStyles({
    '@global': {
      html: {
        width: (props) => (props.useHtmlTagGlobalStyle ? '100vw' : undefined),
        height: (props) => (props.useHtmlTagGlobalStyle ? 'calc(100% + env(safe-area-inset-top))' : undefined),
        overflow: (props) => (props.useHtmlTagGlobalStyle ? 'hidden' : undefined),
        'touch-action': (props) => (props.useHtmlTagGlobalStyle ? 'none' : undefined),
        '-ms-touch-action': (props) => (props.useHtmlTagGlobalStyle ? 'none' : undefined),
        overscrollBehavior: (props) => (props.useOverscrollBehavior ? 'none' : undefined),
        fallbacks: (props) => ({
          height: props.useHtmlTagGlobalStyle ? '100vh' : undefined,
        }),
      },
      body: {
        backgroundColor: (props) =>
          `${
            props.paletteType === 'light'
              ? lightThemeOptions.palette!.background!.default
              : darkThemeOptions.palette!.background!.default
          } !important`,
        width: (props) => (props.useBodyTagGlobalStyle ? '100%' : undefined),
        height: (props) => (props.useBodyTagGlobalStyle ? '100%' : undefined),
        overflow: (props) => (props.useBodyTagGlobalStyle ? 'hidden' : undefined),
        'touch-action': (props) => (props.useBodyTagGlobalStyle ? 'none' : undefined),
        '-ms-touch-action': (props) => (props.useBodyTagGlobalStyle ? 'none' : undefined),
        overscrollBehavior: (props) => (props.useOverscrollBehavior ? 'none' : undefined),
        '& #root': {
          width: (props) => (props.useRootTagGlobalStyle ? '100%' : undefined),
          height: (props) => (props.useRootTagGlobalStyle ? '100%' : undefined),
          overflow: (props) => (props.useRootTagGlobalStyle ? 'hidden' : undefined),
          'touch-action': (props) => (props.useRootTagGlobalStyle ? 'none' : undefined),
          '-ms-touch-action': (props) => (props.useRootTagGlobalStyle ? 'none' : undefined),
          overscrollBehavior: (props) => (props.useOverscrollBehavior ? 'none' : undefined),
        },
      },
    },
  })
)

export const GriegThemeProvider = (props: Props) => {
  const {
    children,
    useScopedCssBaseline,
    tool: preferredTool,
    paletteType: preferredPaletteMode,
    overrideGlobalStyles,
  } = props

  const [tool, setTool] = useState<Tool>(preferredTool)
  const [paletteType, setPaletteMode] = useState<PaletteMode>('dark')

  useGlobalStyles({
    useHtmlTagGlobalStyle: overrideGlobalStyles ?? false,
    useBodyTagGlobalStyle: overrideGlobalStyles ?? false,
    useRootTagGlobalStyle: overrideGlobalStyles ?? false,
    useOverscrollBehavior: overrideGlobalStyles ?? false,
    paletteType: preferredPaletteMode ?? 'dark',
  })

  const changeTool = useCallback((tool: Tool) => setTool(tool), [])
  const changePaletteMode = useCallback((paletteType: PaletteMode) => setPaletteMode(paletteType), [])

  useEffect(() => {
    setTool(preferredTool)
    setPaletteMode(preferredPaletteMode ?? 'dark')
  }, [preferredTool, preferredPaletteMode])

  const theme = useMemo(() => createKrakenTheme(tool, paletteType), [tool, paletteType])

  return (
    <GriegThemeContext.Provider
      value={{
        tool,
        paletteType,
        changeTool,
        changePaletteMode,
      }}
    >
      <ThemeProvider theme={theme}>
        {useScopedCssBaseline ? <ScopedCssBaseline enableColorScheme /> : <CssBaseline enableColorScheme />}
        {children}
      </ThemeProvider>
    </GriegThemeContext.Provider>
  )
}

export default GriegThemeProvider
