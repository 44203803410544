import { useHttp } from '@griegconnect/krakentools-react-http'
import { InviteApi } from '@griegconnect/krakentools-react-tenants'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'

export const useInviteApi = () => {
  const { httpClient } = useHttp()
  const config = useRecoilValue(configSelector)

  const inviteApi = useMemo(() => new InviteApi(httpClient, config.api.tenantApiUrl), [httpClient, config])
  return inviteApi
}
