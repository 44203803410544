import { Box, Card, CardHeader, IconButton, CardContent, Collapse, CardActions, ClickAwayListener } from '@mui/material'
import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon } from '@griegconnect/krakentools-react-icons'
import { Close as CloseIcon } from '@griegconnect/krakentools-react-icons'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactNode, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

export type MapDialogMobileProps = {
  title?: ReactNode
  onClose: () => void
  maxHeight?: string
  subtitle?: React.ReactNode
  actions?: React.ReactNode | React.ReactNode[]
  primary?: React.ReactNode | React.ReactNode[]
  children?: React.ReactNode | React.ReactNode[]
  secondary?: React.ReactNode | React.ReactNode[]
}

type StyleProps = {
  maxHeight?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    card: {
      borderTopRightRadius: theme.spacing(2),
      borderTopLeftRadius: theme.spacing(2),
      padding: theme.spacing(0),
      position: 'absolute',
      width: '100%',
      bottom: 0,
      maxHeight: ({ maxHeight }) => maxHeight ?? '75%',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1500,
    },
    headerWrapper: {
      paddingTop: theme.spacing(1.5),
    },
    header: {
      marginTop: theme.spacing(1.5),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      padding: theme.spacing(0),
      [theme.breakpoints.down('xl')]: {
        marginBottom: theme.spacing(0),
      },
    },
    content: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0),
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&:last-child': {
        padding: theme.spacing(0),
      },
    },
    primaryActions: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
    },
    actions: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    swipeWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    swipeIcon: {
      backgroundColor: theme.palette.action.selected,
      width: '10%',
      height: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
    },
  })
)

export const MapDialogMobile = (props: MapDialogMobileProps) => {
  const classes = useStyles({ maxHeight: props.maxHeight ?? undefined })
  const [expanded, setExpanded] = useState<boolean>(false)

  const onExpandHandler = () => {
    setExpanded(true)
  }

  const onShrinkHandler = () => {
    setExpanded(false)
  }

  const onToggleExpandHandler = () => {
    setExpanded((prev) => !prev)
  }

  const handlers = useSwipeable({
    onSwipedUp: onExpandHandler,
    onSwipedDown: onShrinkHandler,
    trackMouse: true,
    preventDefaultTouchmoveEvent: true,
  })

  return (
    <ClickAwayListener onClickAway={props.onClose} mouseEvent="onClick">
      <Card className={classes.card} elevation={24}>
        <Box {...handlers} className={classes.headerWrapper}>
          {props.secondary && (
            <Box className={classes.swipeWrapper}>
              <Box className={classes.swipeIcon} />
            </Box>
          )}
          <CardHeader
            title={props.title}
            subheader={props.subtitle}
            action={
              <IconButton onClick={props.onClose} size="small">
                <CloseIcon />
              </IconButton>
            }
            className={classes.header}
          />
        </Box>
        {props.actions && <CardActions className={classes.primaryActions}>{props.actions}</CardActions>}
        <CardContent classes={{ root: classes.content }}>
          {props.primary ?? props.children}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {props.secondary}
          </Collapse>
        </CardContent>
        <CardActions className={classes.actions}>
          {props.secondary && (
            <IconButton size="small" onClick={onToggleExpandHandler}>
              {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          )}
        </CardActions>
      </Card>
    </ClickAwayListener>
  )
}

export default MapDialogMobile
