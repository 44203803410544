import { NavStatus, NavStatusCode } from "@griegconnect/krakentools-kmap";

export const aisNavStatuses = new Map<NavStatusCode, NavStatus>();



aisNavStatuses.set("under_way_using_engine", {
    statusCode: "under_way_using_engine",
    displayName: "Under way",
    color: "green",
});

aisNavStatuses.set("at_anchor", {
    statusCode: "at_anchor",
    displayName: "At anchor",
    color: "grey",
});

aisNavStatuses.set("not_under_command", {
    statusCode: "not_under_command",
    displayName: "Not under command",
    color: "grey",
});
aisNavStatuses.set("restricted_maneuverability", {
    statusCode: "restricted_maneuverability",
    displayName: "Restricted maneuverability",
    color: "yellow",
});
aisNavStatuses.set("constrained_by_draught", {
    statusCode: "constrained_by_draught",
    displayName: "Constrained by draught",
    color: "yellow",
});
aisNavStatuses.set("moored", {
    statusCode: "moored",
    displayName: "Moored",
    color: "grey",
});
aisNavStatuses.set("aground", {
    statusCode: "aground",
    displayName: "Aground",
    color: "red",
});
aisNavStatuses.set("fishing", {
    statusCode: "fishing",
    displayName: "Fishing",
    color: "green",
});

aisNavStatuses.set("under_way_sailing", {
    statusCode: "under_way_sailing",
    displayName: "Under way sailing",
    color: "green",
});

aisNavStatuses.set("fishing", {
    statusCode: "fishing",
    displayName: "Fishing",
    color: "green",
});

aisNavStatuses.set("towing", {
    statusCode: "towing",
    displayName: "Towing",
    color: "green",
});

aisNavStatuses.set("ais_sart", {
    statusCode: "ais_sart",
    displayName: "AIS-SART",
    color: "red",
});

aisNavStatuses.set("unknown", {
    statusCode: "unknown", 
    displayName: "Unknown",
    color: 'grey'
})