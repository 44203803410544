import React, { ReactNode, useEffect, useState } from 'react'

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  CircularProgress,
  FilterOptionsState,
  TextField,
} from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import StyledSelectPopper from '../autocomplete/StyledPaper'
import FieldError from '../form/field-error'
import ReadOnlySelect from './ReadOnlySelect'

export type AutocompleteProps<Item extends Record<string, unknown>> = {
  items: Item[]
  valueField: string
  displayField: string
  name: string
  placeholder?: string
  label?: string
  selectedItem: string | number | null
  className?: string
  isLoading: boolean
  isDisabled?: boolean
  required?: boolean
  errorMessage?: string | string[]
  InputProps?: Partial<StandardInputProps>
  styles?: {
    root?: string
  }
  variant?: 'filled' | 'outlined'
  showErrorMessage?: boolean
  autoFocus?: boolean
  readOnly?: boolean
  startAdornment?: (item: Item | null) => ReactNode
  onChange: (value: Item | null) => void
  getOptionLabel?: (item: Item, displayField?: string, valueField?: string) => string
  onInputChanged?: (value: string) => void
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Item,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode
  filterOptions?: (options: Item[], state: FilterOptionsState<Item>) => Item[]
  getOptionDisabled?: (option: Item) => boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: `flex`,
      flexWrap: `wrap`,
      alignItems: `center`,
      marginBottom: theme.spacing(2),
    },
  })
)

export const AutocompleteComponent = <Item extends Record<string, unknown>>(
  props: AutocompleteProps<Item>
): React.ReactElement => {
  const {
    label,
    placeholder,
    items,
    name,
    selectedItem,
    valueField,
    displayField,
    isLoading,
    errorMessage,
    InputProps,
    styles,
    isDisabled,
    required,
    autoFocus,
    readOnly,
    startAdornment,
    getOptionLabel,
    onChange,
    onInputChanged,
    renderOption,
    filterOptions,
    getOptionDisabled,
    showErrorMessage = true,
    variant = 'filled',
  } = props
  const [innerSelectedItem, setInnerSelectedItem] = useState<Item | null>(null)
  const [options, setOptions] = useState<Item[]>([])
  const classes = useStyles({ error: !!errorMessage })

  const onHandleChange = (_event: any, value: any | null) => {
    onChange(value)

    if (items && items.length > 0 && value) {
      const foundElement = items.find((x) => x[valueField] === value[valueField]) || null
      setInnerSelectedItem(foundElement)
      return
    }
    setInnerSelectedItem(null)
  }

  useEffect(() => {
    if (items && items.length > 0) {
      setOptions(items)
      if (selectedItem) {
        const foundElement = items.find((x) => x[valueField] === selectedItem)
        if (foundElement) {
          setInnerSelectedItem(foundElement)
        }
      }
    } else if (options.length > 0 && items.length === 0) {
      setOptions([])
      setInnerSelectedItem(null)
    }
  }, [items, selectedItem, valueField, options.length])

  useEffect(() => {
    if (items && items.length > 0 && selectedItem) {
      const foundElement = items.find((item) => item[valueField] === selectedItem)
      if (!foundElement) {
        return
      }
      setInnerSelectedItem(foundElement)
      return
    }
    setInnerSelectedItem(null)
  }, [selectedItem, items, valueField])

  const getRenderInput = (params: AutocompleteRenderInputParams) => (
    <>
      <TextField
        {...params}
        variant={variant}
        key={name}
        label={label}
        name={name}
        autoFocus={autoFocus}
        required={required}
        placeholder={placeholder}
        disabled={isDisabled}
        error={!!errorMessage}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading ? <CircularProgress color="inherit" size={24} /> : null}
              {InputProps?.endAdornment}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
          startAdornment: startAdornment ? startAdornment(innerSelectedItem) : undefined,
        }}
      />
      {showErrorMessage && <FieldError errorMessage={errorMessage} />}
    </>
  )

  const optionLabel = getOptionLabel
    ? (option: Item) => getOptionLabel(option, displayField, valueField)
    : (option: Item) => (option ? (option[displayField] as string) : '')

  if (readOnly)
    return (
      <ReadOnlySelect
        startAdornment={startAdornment}
        item={innerSelectedItem}
        label={innerSelectedItem?.[displayField] as any}
      />
    )

  return (
    <Autocomplete<Item, false, false, false>
      options={options}
      key={`autocomplete-${name}`}
      onChange={onHandleChange}
      classes={{
        root: clsx(classes.root, styles?.root),
      }}
      disabled={isDisabled}
      value={innerSelectedItem}
      loading={isLoading}
      renderOption={renderOption}
      getOptionLabel={optionLabel}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      renderInput={getRenderInput}
      PopperComponent={StyledSelectPopper}
      onInputChange={(_event, value: string, reason: string) => {
        if (reason === 'input') {
          if (onInputChanged) {
            onInputChanged(value)
          }
        }
      }}
    />
  )
}

export default AutocompleteComponent
