import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#d3c52c',
  light: '#dad16c',
  dark: '#998c00',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#e4de94',
}

export default Color
