import { SnackbarProvider } from 'notistack'

type SnackbarWrapperProps = {
  maxSnack?: number
  children: React.ReactNode
}

export const SnackbarWrapper = ({ maxSnack = 3, children }: SnackbarWrapperProps) => {
  return <SnackbarProvider maxSnack={maxSnack}>{children}</SnackbarProvider>
}

export default SnackbarWrapper
