import { generatePath } from 'react-router-dom'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { useRecoilValue } from 'recoil'
import { activeTenantSelector } from '../atoms/tenantsAtom'

export const useTenantUrl = () => {
  const { tenantsUrlMatchPathPrefix, tenantsUrlMatchPathParam } = useApplicationOptions()

  /**
   * @example
   * tenantsUrl.baseUrl('griegconnect), will return /companies/griegconnect
   */
  const baseUrl = (ref: string) =>
    generatePath(tenantsUrlMatchPathPrefix, {
      [tenantsUrlMatchPathParam]: ref,
    })

  /**
   * @example
   * tenantsUrl.forPath('/users'), will return /companies/griegconnect/users
   */
  function forPath<T extends {}>(path: string, ref: string, params?: { [key in T]: string }) {
    return generatePath(tenantsUrlMatchPathPrefix + path, {
      [tenantsUrlMatchPathParam]: ref,
      ...params,
    })
  }

  return {
    baseUrl,
    forPath,
  }
}

export const useActiveTenantUrl = () => {
  const { tenantsUrlMatchPathPrefix, tenantsUrlMatchPathParam } = useApplicationOptions()
  const activeTenant = useRecoilValue(activeTenantSelector)

  /**
   * @example
   * useActiveTenantUrl.baseUrl, will return /companies/griegconnect
   */
  const baseUrl = generatePath(tenantsUrlMatchPathPrefix, {
    [tenantsUrlMatchPathParam]: activeTenant ? activeTenant.ref : '',
  })

  /**
   * @example
   * tenantsUrl.forPath('/users'), will return /companies/griegconnect/users
   * @description If you have a path that is already prepended, or that should not have anything prepended, use:
   * @example
   * tenantsUrl.forPath('/companies/:tenantRef/settings', false), will return /companies/griegconnect/settings
   * @example
   * tenantsUrl.forPath('/settings', false), will return /settings
   */
  function forPath<T extends {}>(path: string, prependTenantBaseUrl: boolean = true, params?: { [key in T]: string }) {
    return generatePath(prependTenantBaseUrl ? tenantsUrlMatchPathPrefix + path : path, {
      [tenantsUrlMatchPathParam]: activeTenant ? activeTenant.ref : '',
      ...params,
    })
  }

  return {
    baseUrl,
    forPath,
  }
}
