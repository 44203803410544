import { KMapLayer, colorPalette } from '@griegconnect/krakentools-kmap'

const mapBoxToken = 'pk.eyJ1IjoiZ3JpZWdjb25uZWN0IiwiYSI6ImNrODdlM2xxNDAzMG4zZW02bWszaXlxOG8ifQ.HLuYGpum8T5N9iWvP6oJqA'

export const defaultBaseMaps: KMapLayer[] = [
  {
    id: 'darkgrieg',
    name: 'Grieg Dark',
    layerType: 'MVT',
    mvtConfig: {
      accessToken: mapBoxToken,
      styleUrl: 'mapbox://styles/griegconnect/cktjv86245gey17mo5i9mvx1v',
    },
    backgroundColor: '#272d32',
    colorPalette: 'dark',
    shared: true,
    default: true,
    opacity: 1
  },
  {
    id: 'lightgrieg',
    name: 'Grieg Light',
    layerType: 'MVT',
    mvtConfig: {
      accessToken: mapBoxToken,
      styleUrl: 'mapbox://styles/griegconnect/cktjvfxeh5gm917moeoil27f4',
    },
    backgroundColor: '#eff0f0',
    colorPalette: 'light',
    shared: true,
    default: true,
    opacity: 1
  },
]

export const defaultBackgroundLayer = (colorPalette: colorPalette) => {
  if (colorPalette === 'dark') {
    return defaultBaseMaps[0]
  } else {
    return defaultBaseMaps[1]
  }
}
