import { Box, Button, IconButton, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Page } from '../page'
import { Cross } from '@griegconnect/krakentools-react-icons'
import { Settings } from '@griegconnect/krakentools-react-icons'

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& svg': {
      fill: theme.palette.text.disabled,
    },
  },
  button: {
    margin: theme.spacing(0.75),
    marginRight: theme.spacing(1.5),
  },
  actionsContainer: {
    minHeight: theme.spacing(6),
  },
}))

export interface IWidgetCardProps {
  icon: React.ReactNode
  title: string
  description: string
  colorDescription?: string
  onDetails?: any
  detailsDisabled?: boolean
  editing?: boolean
  onRemove?: any
  onSettings?: any
  className?:string
}

export const WidgetCard: React.FC<React.PropsWithChildren<IWidgetCardProps>> = ({
  icon,
  title,
  description,
  children,
  colorDescription,
  onDetails,
  detailsDisabled,
  editing,
  onRemove,
  onSettings,
  className
}) => {
  const classes = useStyles()
  return (
    <Page.Paper padding={2} style={{ height: '100%' }} className={className}>
      <Box display="flex" flex={1} flexDirection="column" height="100%">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mb={2}
          className={classes.actionsContainer}
        >
          <div className={classes.iconWrapper}>{icon}</div>
          <Box display="flex" flex={1} flexDirection="column" justifyContent="center">
            <Typography variant="subtitle2">{title}</Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={colorDescription ? { color: colorDescription } : {}}
            >
              {description}
            </Typography>
          </Box>
          {!editing && onDetails && (
            <Button
              className={`nonDraggable ${classes.button}`}
              color={!detailsDisabled ? 'primary' : undefined}
              onClick={onDetails}
              disabled={detailsDisabled}
            >
              Details
            </Button>
          )}
          {editing && onSettings && (
            <IconButton onClick={onSettings} size="large">
              <Settings />
            </IconButton>
          )}
          {editing && onRemove && (
            <IconButton onClick={onRemove} size="large">
              <Cross />
            </IconButton>
          )}
        </Box>
        <Box display="flex" flex={1} flexDirection="column">
          {children}
        </Box>
      </Box>
    </Page.Paper>
  )
}

export default WidgetCard
