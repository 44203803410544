import React, { useEffect, useRef, useState } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Header } from './frames/Header'
import { LeftDrawer } from './frames/LeftDrawer'
import { Content } from './frames/Content'
import { Footer } from './frames/Footer'
import GlobalAlert from './ui/alerts/GlobalAlert'
import { useRecoilValue } from 'recoil'
import { globalAlertAtom } from './atoms/globalInfoAtom'

export type WrapperProps = {
  content?: React.ReactNode
  logoUrl?: string
  disableFooter?: boolean
  profileMenuChildren?: React.ReactNode
  /**
   * Only used to disable palette switch in order to default to a specific palette mode.
   * @deprecated
   */
  disablePaletteSwitch: boolean
}

type UseStylesProps = {}

const useStyles = makeStyles<Theme, UseStylesProps>((_theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      overscrollBehavior: 'none',
      [_theme.breakpoints.up('md')]: {
        overscrollBehavior: 'initial',
      },
    },
    applicationWrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'stretch',
      alignItems: 'stretch',
      overflow: 'hidden',
      overscrollBehavior: 'none',
      [_theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        overflow: 'inherit',
        overscrollBehavior: 'initial',
      },
    },
    content: {
      flexBasis: '100%',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      overflow: 'hidden',
      overscrollBehavior: 'none',
      flex: 1,
      [_theme.breakpoints.up('md')]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        alignContent: 'stretch',
        overflow: 'hidden',
        flex: 1,
      },
    },
    contentAndDrawer: {
      flexGrow: 1,
      flex: 1,
      width: '100%',
      // height will behave like min-height and fill available space when we have flex-grow: 1
      height: '80%',
      display: 'flex',
      [_theme.breakpoints.up('md')]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',
        alignContent: 'stretch',
        flexDirection: 'row',
        overflow: 'auto',
        flex: 1,
      },
    },
  })
)

export const FrameWrapper = (props: WrapperProps) => {
  const { content, disableFooter, profileMenuChildren, disablePaletteSwitch } = props
  const globalAlert = useRecoilValue(globalAlertAtom)
  const classes = useStyles()
  const theme = useTheme()
  const [height, setHeight] = useState<number>(0)
  const contentAndDrawerRef = useRef<HTMLDivElement>(null)
  const largeDevice = useMediaQuery(theme.breakpoints.up('md'))
  const smallDevice = !largeDevice

  useEffect(() => {
    if (contentAndDrawerRef.current) {
      setHeight(contentAndDrawerRef.current.clientHeight)
    }
    const listener = (ev: UIEvent) => {
      if (contentAndDrawerRef.current) {
        setHeight(contentAndDrawerRef.current.clientHeight)
      }
    }
    addEventListener('resize', listener)
    return () => {
      removeEventListener('resize', listener)
    }
  }, [globalAlert])

  return (
    <div className={classes.wrapper}>
      <GlobalAlert />
      <div className={classes.applicationWrapper}>
        {smallDevice && (
          <Header profileMenuChildren={profileMenuChildren} disablePaletteSwitch={disablePaletteSwitch} />
        )}
        {largeDevice && <LeftDrawer disableBottomSafeArea={!disableFooter} />}
        <div className={classes.content}>
          {largeDevice && (
            <Header profileMenuChildren={profileMenuChildren} disablePaletteSwitch={disablePaletteSwitch} />
          )}
          <div className={classes.contentAndDrawer} ref={contentAndDrawerRef}>
            {smallDevice && <LeftDrawer disableBottomSafeArea={!disableFooter} pixelsHeight={height} />}
            <Content component={content} disableBottomSafeArea={!disableFooter} />
            {/* <RightDrawer disableBottomSafeArea={!disableFooter} /> */}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default FrameWrapper
