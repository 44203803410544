import * as Sentry from '@sentry/react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import React, { createContext, useContext, useEffect, useMemo } from 'react'

import { useConfig } from './hooks/useConfig'
import { getEnvironmentName } from './utils/environment'
import ConfigError from './ui/ConfigError'
import { useAlerts } from './hooks/useAlerts'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

export type ErrorBoundaryContextType = {
  sessionErrorCode: string
  applicationInsightsInstance?: ApplicationInsights
}

export const ErrorBoundaryContext = createContext<ErrorBoundaryContextType | null>(null)
export const useErrorBoundary = () => useContext(ErrorBoundaryContext)!

const sessionErrorCode = Math.random().toString(36).substring(7)

const ErrorBoundaryProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const config = useConfig()
  const { setGlobal, closeGlobal } = useAlerts()

  useEffect(() => {
    const offlineHandler = () =>
      setGlobal(
        'The network/internet connection has been lost. This message will go away when the connection is working again.',
        'error',
        false
      )
    const onlineHandler = () => closeGlobal()

    window.addEventListener('offline', offlineHandler)
    window.addEventListener('online', onlineHandler)

    return () => {
      window.removeEventListener('offline', offlineHandler)
      window.removeEventListener('online', onlineHandler)
    }
  }, [])

  const insights = useMemo(() => {
    if (config.insights) {
      const ai = new ApplicationInsights({
        config: {
          ...config.insights,
        },
      })
      ai.loadAppInsights()
      ai.context.session.id = sessionErrorCode
      return ai
    }
  }, [config])

  useEffect(() => {
    const integrations = () => {
      const activatedIntegrations: Sentry.BrowserOptions['integrations'] = [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ]

      if (config.sentry?.replays) {
        activatedIntegrations.push(new Sentry.Replay())
      }

      return activatedIntegrations
    }
    if (config.sentry) {
      Sentry.init({
        dsn: config.sentry.dsn,
        integrations: integrations(),
        release: config.version,
        // We recommend adjusting this value in production
        tracesSampleRate: config.sentry.tracesSampleRate ?? 0.1,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: config.sentry?.replays ? config.sentry.replaysSessionSampleRate ?? 0.1 : 0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: config.sentry?.replays ? config.sentry.replaysOnErrorSampleRate ?? 1.0 : 0,
        environment: getEnvironmentName(config.environment),
        beforeSend: (event) => {
          event.tags = {
            ...event.tags,
            errorCode: sessionErrorCode,
          }
          return event
        },
      })
    }
  }, [])

  return (
    <ErrorBoundaryContext.Provider value={{ sessionErrorCode, applicationInsightsInstance: insights }}>
      {config.initError ? <ConfigError /> : children}
    </ErrorBoundaryContext.Provider>
  )
}

export default ErrorBoundaryProvider
