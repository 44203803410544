import { useEffect, useRef, useState } from 'react'
import { ConfigType } from '../atoms/configAtom'
import axios from 'axios'
import { useApplicationOptions } from '../ApplicationOptionsContext'

type UseConfigQueryOptions = {
  enabled?: boolean
}
export function useConfigQuery<T>(options?: UseConfigQueryOptions) {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isInit, setIsInit] = useState(false)
  const abortControllerRef = useRef<AbortController>()
  const { configurationFilePath } = useApplicationOptions()
  const [data, setData] = useState<ConfigType<T>>()

  const queryApi = async () => {
    setIsLoading(true)
    setIsError(false)
    const newAbortController = new AbortController()
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = newAbortController
      const config = await axios.get<ConfigType<T>>(configurationFilePath, {
        signal: abortControllerRef.current.signal,
      })
      setData(config.data)
    } catch (error) {
      if (error instanceof axios.Cancel === false) {
        if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
          console.error('[kraken-app::useConfigQuery]', error)
          setIsError(true)
        }
      }
    }
    if (
      abortControllerRef.current &&
      abortControllerRef.current === newAbortController &&
      !abortControllerRef.current.signal.aborted
    ) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isInit && (options?.enabled === undefined || options.enabled)) {
      setIsInit(true)
      queryApi()
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [options?.enabled])

  return { data, isLoading, isError, refetch: queryApi }
}
