import { atom } from 'recoil'

export type MenuItemBadgeType = {
  count: number
  variant?: 'dot' | 'standard'
}

export type MenuItemType = {
  name: string | React.ReactElement
  /**
   * @deprecated Only used by Port to translate menu items that will be referenced to from both AngularJS (does not support hooks) and React
   */
  displayName?: React.ReactNode
  badge?: MenuItemBadgeType
  absolutePath?: string
  activeOnExactMatch?: boolean
  /**
   * For apps that has legacy and React frontends we need to support external links.
   * Set `external: true` to handle menu item as standard link (not handled by react router)
   */
  external?: true

  /**
   * @deprecated Only used in Port to support global actions, e.g. such as new port call
   */
  callback?: () => void
}

export type MenuItemWithSubsType = MenuItemType & {
  icon?: React.ReactNode
  /**
   * If parent has items and enableLink is false, the whole menu item will toggle the sub items.
   *
   * @type {boolean}
   */
  disableLink?: boolean
  items?: MenuItemType[]
}

export type MenuItemsAtomType = MenuItemWithSubsType[]

export const menuItemsAtom = atom<MenuItemsAtomType>({
  key: 'menuItemsState',
  default: [],
})
