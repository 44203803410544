import { Feature } from 'ol'
import { LineString, Point, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { useMapContext } from './MapContext'

type MeasuringContext = {
  measuringLayer: VectorLayer<VectorSource<Feature<LineString | Point | Polygon>>>
}

export const MeasuringContext = createContext<MeasuringContext | null>(null)
export const useMeasuringContext = () => useContext(MeasuringContext)!

type MeasureProviderProps = {
  children?: ReactNode | ReactNode[]
  onClick?: (measureObjectId: string | null) => void
}

export const MeasureProvider: FC<MeasureProviderProps> = ({ children, onClick }) => {
  const { kmapInstance } = useMapContext()
  const [id] = useState(v4())

  const globalClickEventId = useMemo(() => {
    return onClick ? kmapInstance.on('backgroundLayer_click', () => onClick(null)) : null
  }, [])

  const setLayerClickEvent = (layer: VectorLayer<VectorSource<Feature<LineString>>>) => {
    const eventId = layer.get('clickEventId')
    if (eventId) {
      kmapInstance.un(eventId)
    }
    if (onClick) {
      layer.set(
        'clickEventId',
        kmapInstance.on('measure_layer_' + id + '_click', ({ feature }) => {
          onClick(feature.properties.measureId || null)
        })
      )
    }
  }
  const measuringLayer = useMemo(() => {
    const layer = new VectorLayer<VectorSource<Feature<LineString>>>({
      source: new VectorSource(),
      declutter: false,
      zIndex: 9000,
      style: [
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 4,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: '#3399CC',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
          }),
        }),
      ],
    })
    layer.set('type', 'measure_layer_' + id)
    kmapInstance.addOlLayer(layer)
    kmapInstance.enableSnap(true)
    setLayerClickEvent(layer)
    return layer
  }, [])

  useEffect(() => {
    setLayerClickEvent(measuringLayer)
  }, [onClick])

  const snapResetEventId = useMemo(
    () =>
      kmapInstance.on('snapCollection-reset', () => {
        measuringLayer
          .getSource()
          ?.getFeatures()
          .forEach((f) => {
            if (f.get('snappable')) kmapInstance.addToSnapCollection(f)
          })
      }),
    [measuringLayer]
  )

  useEffect(
    () => () => {
      kmapInstance.un(snapResetEventId)
    },
    [measuringLayer]
  )

  useEffect(
    () => () => {
      const eventId = measuringLayer.get('clickEventId')
      if (eventId) {
        kmapInstance.un(eventId)
      }
      if (globalClickEventId) {
        kmapInstance.un(globalClickEventId)
      }
      kmapInstance.removeOlLayer(measuringLayer)
    },
    []
  )

  return <MeasuringContext.Provider value={{ measuringLayer }}>{children}</MeasuringContext.Provider>
}
