import { FormHelperText } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

type Props = {
  errorMessage?: string | string[]
}

const useStyles = makeStyles(() =>
  createStyles({
    emptyBox: {
      visibility: `hidden`,
    },
  })
)

const FieldError = (props: Props) => {
  const { errorMessage } = props
  const classes = useStyles()

  return !!errorMessage ? (
    <FormHelperText error={!!errorMessage} variant="outlined">
      {errorMessage}
    </FormHelperText>
  ) : (
    <FormHelperText className={classes.emptyBox}> EMPTY </FormHelperText>
  )
}

export default FieldError
