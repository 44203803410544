import { useApplicationOptions } from '../ApplicationOptionsContext'
import { useUserInterface } from '../hooks'
import { getApplicationLogo } from '../utils/getApplicationLogo'
import { Box } from '@mui/material'

const ApplicationLogo = () => {
  const { appRef, tool } = useApplicationOptions()
  const { palette } = useUserInterface()

  return (
    <Box sx={{ height: { xs: 36, sm: 48 } }}>
      {getApplicationLogo({
        appRef: appRef ?? tool,
        palette,
        type: 'component',
        style: {
          width: 'auto',
          maxHeight: '100%',
        },
      })}
    </Box>
  )
}

export default ApplicationLogo
