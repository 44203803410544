import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#CBDD9B',
  light: '#ffffcc',
  dark: '#9aab6c',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#70991F',
}

export default Color
