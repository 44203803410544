import { StepLabelClasskey } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { StepLabelProps } from '@mui/material/StepLabel'
import StepLabel from '../step-label'
import clsx from 'clsx'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import VerticalStepOptional from './vertical-step-optional'

type StyleProps = {
  isActive: boolean
  nonLinear: boolean
}

const useStyles = makeStyles<Theme, StyleProps, StepLabelClasskey>((theme) =>
  createStyles<StepLabelClasskey, StyleProps>({
    root: ({ isActive, nonLinear }) => ({
      backgroundColor: theme.palette.mode == 'dark' ? theme.palette.grey[900] : theme.palette.background.paper,
      borderRadius: isActive ? `5px 5px 0px 0px` : `5px 5px 5px 5px`,
      padding: nonLinear ? `0 0 0 ${theme.spacing(2)}` : `12px 0 12px 8px`,
    }),
    error: {},
    active: {},
    alternativeLabel: {},
    completed: {},
    disabled: {},
    horizontal: {},
    iconContainer: {},
    label: {},
    labelContainer: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
    },
    vertical: {},
  })
)

type VerticalStepLabelProps = {
  isActive: boolean
  onToggle: () => void
  nonLinear?: boolean
} & StepLabelProps

function VerticalStepLabel(props: VerticalStepLabelProps) {
  const { classes, isActive, nonLinear = false, onToggle, ...other } = props
  const innerClasses = useStyles({ isActive, nonLinear })

  return (
    <StepLabel
      {...other}
      optional={<VerticalStepOptional nonLinear={nonLinear} isActive={isActive} onToggle={onToggle} />}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        labelContainer: clsx(innerClasses.labelContainer, classes?.labelContainer),
        label: clsx(innerClasses.label, classes?.label),
        iconContainer: clsx(innerClasses.iconContainer, classes?.iconContainer),
        horizontal: clsx(innerClasses.horizontal, classes?.horizontal),
        disabled: clsx(innerClasses.disabled, classes?.disabled),
        completed: clsx(innerClasses.completed, classes?.completed),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
        active: clsx(innerClasses.active, classes?.active),
        error: clsx(innerClasses.error, classes?.error),
      }}
    />
  )
}

export default VerticalStepLabel
