import { atom } from 'recoil'
import { AlertMessage } from '../hooks/useAlerts'

type GlobalAlertAtom = {
  type: GlobalAlertTypes
  message: AlertMessage
  closeable: boolean
  action?: React.ReactNode
}

export type GlobalAlertTypes = 'info' | 'error'

export const globalAlertAtom = atom<GlobalAlertAtom | null>({
  key: 'globalAlertAtomState',
  default: null,
})
