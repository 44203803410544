import React from 'react';

import styles from '../../grieg-onboarding.module.scss';

export type CarouselTextProps = {
  transitionTimingFunction: string;
  transitionTime: number;
  opacity: number;
  title: string;
  description: string;
};

export const CarouselText = (props: CarouselTextProps) => {
  const { transitionTimingFunction, transitionTime, opacity, title, description } = props;
  return (
    <div
      className={styles.carouselText}
      style={{
        opacity: opacity,
        transition: `opacity ${transitionTimingFunction} ${transitionTime}ms`,
      }}
    >
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};

export default CarouselText;
