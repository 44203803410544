import {
  Table,
  TableCellProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  useMediaQuery,
  SxProps,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import React, { useMemo, useState, useCallback, useEffect, PropsWithChildren } from 'react'

import { CheckboxCell } from './CheckboxCell'

export type StringKeyOf<T> = { [k in keyof T]: T[k] extends string | number ? k : never }[keyof T]

type Props<D> = {
  data: D[]
  columns: Column<D>[]
  loadingClean?: boolean
  loadingAppend?: boolean
  enableSelection?: boolean
  onSelect?: (selected: D[]) => void
  onHoverChange?: (hovered: D | null) => void
  onItemClick?: (clicked: D) => void
  dataKey: StringKeyOf<D>
  size?: 'medium' | 'small'
  className?: string
}

export type Column<D> = {
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  header: React.ReactNode
  align?: TableCellProps['align']
  sx?: SxProps<Theme>
  renderCell: (data: D) => React.ReactNode
}

type SelectedContextType = any[]

export const SelectedContext = React.createContext<SelectedContextType>([])

type StyleProps = {
  enableClickPointer: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    table: {
      height: '100%',
      width: '100%',
      overflow: 'auto',
    },
    rowHeader: {
      '& th:not(:first-child):not(:last-child)': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    rowBody: {
      cursor: ({ enableClickPointer }) => (enableClickPointer ? 'pointer' : 'auto'),
      '& td:not(:first-child):not(:last-child)': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    cell: {
      whiteSpace: 'nowrap',
    },
    rowCell: {
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    checkbox: {
      padding: theme.spacing(1.5),
    },
  })
)

export function KrakenTable<D>({
  columns,
  data,
  enableSelection,
  loadingClean,
  loadingAppend,
  onSelect,
  onHoverChange,
  onItemClick,
  dataKey,
  size,
  className,
}: PropsWithChildren<Props<D>>): React.ReactElement {
  const [selected, setSelected] = useState<D[]>([])
  const classes = useStyles({ enableClickPointer: onItemClick !== undefined })
  const theme = useTheme()
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'))
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    // If data has changed and a earlier selected items does not exist, remove it
    if (selected.length > 0) {
      const dataExists = data.filter((d) => selected.some((s) => d[dataKey] === s[dataKey]))
      if (dataExists.length !== selected.length) {
        setSelected(dataExists)
      }
    }
  }, [data])

  /**
   *
   * Memoizations
   *
   */

  const isAllSelected = useMemo(
    () => data.length > 0 && data.every((d) => selected.some((s) => s[dataKey] === d[dataKey])),
    [selected, data]
  )

  const toggleSelectAll = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSelected(selected.length > 0 ? [] : checked ? data : [])
    },
    [data, selected]
  )

  const toggleSelectRow = useCallback(
    (selected: D) => {
      setSelected((previousSelected) => {
        const exist = previousSelected.some((s) => s[dataKey] === selected[dataKey])
        if (exist) {
          return previousSelected.filter((s) => s[dataKey] !== selected[dataKey])
        } else {
          return previousSelected.concat(selected)
        }
      })
    },
    [data, setSelected]
  )

  const onRowClickHandler = useCallback(
    (data: D) => {
      return (_event: React.MouseEvent<HTMLTableRowElement>) => {
        onItemClick?.(data)
      }
    },
    [data, onItemClick]
  )

  const onMouseEnterHandler = useCallback((selected: D) => {
    onHoverChange?.(selected)
  }, [])

  const onMouseLeaveHandler = useCallback(() => {
    onHoverChange?.(null)
  }, [])

  const visibleColumns = useMemo(
    () =>
      columns.filter((c) => {
        switch (c.breakpoint) {
          case 'xs':
            return isXsUp
          case 'sm':
            return isSmUp
          case 'md':
            return isMdUp
          case 'lg':
            return isLgUp
          case 'xl':
            return isXlUp
          default:
            return false
        }
      }),
    [columns, isXsUp, isSmUp, isMdUp, isLgUp, isXlUp]
  )

  /**
   *
   * Effects
   *
   */

  useEffect(() => {
    onSelect?.(selected)
  }, [selected])

  /**
   *
   * Render methods
   *
   */

  const renderHeaderCells = useMemo(() => {
    let cells = visibleColumns.map((c, index) => (
      <TableCell
        key={`header-cell-${index}`}
        padding="normal"
        variant="head"
        //sx={c.sx}
        align={c.align}
        className={classes.cell}
      >
        {c.header}
      </TableCell>
    ))

    enableSelection &&
      cells.unshift(
        <TableCell key="select-all-cell" padding="checkbox" className={classes.cell}>
          <Checkbox
            onChange={toggleSelectAll}
            checked={isAllSelected}
            indeterminate={selected.length > 0 && !isAllSelected}
            className={classes.checkbox}
          />
        </TableCell>
      )

    return cells
  }, [toggleSelectAll, visibleColumns, isAllSelected, classes])

  const renderRowCells = useCallback<(rowData: D) => React.ReactNode>(
    (rowData) => {
      let cells = visibleColumns.map((c, index) => (
        <TableCell
          key={`cell-${rowData[dataKey]}-${index}`}
          padding={'normal'}
          align={c.align ?? 'inherit'}
          sx={c.sx}
          className={classes.rowCell}
        >
          {c.renderCell(rowData)}
        </TableCell>
      ))
      enableSelection &&
        cells.unshift(
          <TableCell
            key={`select-item-${rowData[dataKey]}`}
            padding={'checkbox'}
            align={'inherit'}
            className={classes.rowCell}
          >
            <CheckboxCell<D> data={rowData} onToggle={toggleSelectRow} dataKey={dataKey} />
          </TableCell>
        )
      return cells
    },
    [visibleColumns, classes]
  )

  const renderBodyRows = useMemo(
    () =>
      data.map((d) => (
        <TableRow
          hover={true}
          key={`row-${d[dataKey]}`}
          className={classes.rowBody}
          onMouseEnter={() => onMouseEnterHandler(d)}
          onMouseLeave={onMouseLeaveHandler}
          onClick={onRowClickHandler(d)}
        >
          {renderRowCells(d)}
        </TableRow>
      )),
    [visibleColumns, data, classes, renderRowCells, onMouseEnterHandler, onMouseLeaveHandler, onRowClickHandler]
  )

  const renderLoadingSkeleton = useMemo(
    () => (
      <TableRow key="skeleton-loader">
        {visibleColumns.map((_c, index) => (
          <TableCell key={`skeleton-loader-cell-${index}`}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ),
    [visibleColumns]
  )

  return (
    <SelectedContext.Provider value={selected}>
      <Table className={`${classes.table} ${className}`} stickyHeader size={size || 'medium'}>
        <TableHead>
          <TableRow className={classes.rowHeader}>{renderHeaderCells}</TableRow>
        </TableHead>
        <TableBody>
          {loadingClean ? renderLoadingSkeleton : renderBodyRows}
          {loadingAppend && renderLoadingSkeleton}
        </TableBody>
      </Table>
    </SelectedContext.Provider>
  )
}

export default KrakenTable
