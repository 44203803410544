import { StepContentProps } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles';
import { StepContentClasskey } from '@mui/material/StepContent'
import StepContent from '../step-content'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepContentClasskey>((theme) =>
  createStyles<StepContentClasskey, StyleProps>({
    root: {
      marginTop: `unset`,
      marginLeft: `unset`,
      borderLeft: `unset`,
      borderRadius: `0 0 5px 5px`,
      backgroundColor: theme.palette.mode == 'dark' ? theme.palette.grey[900] : theme.palette.background.paper,
    },
    last: {},
    transition: {},
  })
)

function VerticalStepContent(props: StepContentProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <StepContent
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        last: clsx(innerClasses.last, classes?.last),
        transition: clsx(innerClasses.transition, classes?.transition),
      }}
    />
  )
}

export default VerticalStepContent
