import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Collapse,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material'
import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon } from '@griegconnect/krakentools-react-icons'
import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { LayerListItem } from './LayerListItem'
import { allLayersVisibleSelector, availableLayersSelector } from '../atoms/mapConfigAtoms'
import { useMapContext } from '../MapContext'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingBottom: theme.spacing(1),
    },
    listHeader: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    button: {
      padding: theme.spacing(0.5),
    },
    actions: {
      right: theme.spacing(1),
    },
    listIcon: {
      minWidth: theme.spacing(3.5),
    },
  })
)

type LayerListProps = {
  title?: string
}

export const LayerList: React.FC<React.PropsWithChildren<LayerListProps>> = (props) => {
  const classes = useStyles()
  const { mapIdentifierSlug, kmapInstance: instance } = useMapContext()
  const [open, setOpen] = useState<boolean>(true)
  const layers = useRecoilValue(availableLayersSelector(mapIdentifierSlug))
  const [allVisible, setAllVisible] = useRecoilState(allLayersVisibleSelector(mapIdentifierSlug))
  const { t } = useTranslation('kmap')
  const onToggleList = () => {
    setOpen((prev) => !prev)
  }

  const onToggleVisibilityHandler = () => {
    setAllVisible((prev) => !prev)
  }

  const title = props.title || t('LayerDialog.title')

  return (
    <List disablePadding={true} className={classes.list} key="layer-list">
      <ListItem disableGutters={true} onClick={onToggleList} className={classes.listHeader} key="layer-list-item">
        <ListItemIcon className={classes.listIcon}>
          {open ? <ArrowUpIcon fontSize="small" /> : <ArrowDownIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2',
            color: 'textPrimary',
          }}
          primary={title}
        />
        <ListItemSecondaryAction className={classes.actions}>
          <IconButton className={classes.button} onClick={onToggleVisibilityHandler} size="large">
            {allVisible ? <VisibilityOutlinedIcon color="primary" /> : <VisibilityOffOutlinedIcon color="disabled" />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {layers.map((layer) => {
            return <LayerListItem key={`list-${layer.key}`} layer={layer} />
          })}
        </List>
      </Collapse>
    </List>
  )
}

export default LayerList
