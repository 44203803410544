import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import React from 'react'

export type SelectItemType = {
  id: string
  label: string
}

type SelectProps = Pick<MuiSelectProps, 'onChange' | 'value' | 'disabled' | 'className' | 'required'> &
  Pick<MuiFormControlProps, 'fullWidth' | 'size'> & {
    label?: string
    items: SelectItemType[]
  }

const Select = ({ fullWidth, label, items, size, className, required, ...selectProps }: SelectProps) => {
  return (
    <MuiFormControl variant="filled" fullWidth={fullWidth} size={size} required={required}>
      {label && <MuiInputLabel id={`gc-select-${label.replace(' ', '-')}`}>{label}</MuiInputLabel>}
      <MuiSelect className={className} {...selectProps} labelId={label && `gc-select-${label.replace(' ', '-')}`} required={required}>
        {items.map((item) => (
          <MuiMenuItem key={`select-item-${item.id}`} value={item.id}>
            {item.label}
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </MuiFormControl>
  )
}
export default Select
