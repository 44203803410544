import { AxiosInstance } from "axios"
import { Tenant } from '@griegconnect/krakentools-react-tenants'

export class TenantsApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  async tenants(abortSignal?: AbortSignal) {
    return (await this.httpClient.get<{ tenants: Tenant.Details[] }>('', { signal: abortSignal })).data.tenants
  }

  async tenant(id: string, abortSignal?: AbortSignal) {
    return (await this.httpClient.get<Tenant.Details>(`/${id}`, { signal: abortSignal })).data
  }
}