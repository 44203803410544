import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { boardColumnMinimumWidth } from './board-column'

const collapsedColumnWidth = 52

export type Props = {
  openColumnsCount: number
  columnsCount: number
  collapsedChildColumns?: number
  children?: React.ReactNode
  disableSpacing?: boolean
  isCollapsed: boolean
  className?: string
}

const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    columnRoot: {
      overflow: 'auto',
      margin: 0,
      minWidth: `${collapsedColumnWidth}px`,
      width: (props) => (props.openColumnsCount === 0 ? `${collapsedColumnWidth * props.columnsCount}px` : 'auto'),
      display: 'flex',
      flexDirection: 'column',
    },
    columnOpen: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flexGrow: (props: Props) => Math.max(1, props.openColumnsCount),
      flexBasis: (props: Props) => `${(props.collapsedChildColumns ?? 0) * collapsedColumnWidth}px`,
      minWidth: (props: Props) => boardColumnMinimumWidth(props.openColumnsCount),
    },
  })
)

export const ColumnLayout: React.FC<React.PropsWithChildren<Props>> = React.memo((props: Props) => {
  const classes = useStyles(props)

  return (
    <div className={`${classes.columnRoot} ${props.isCollapsed ? '' : classes.columnOpen} ${props.className}`}>
      {props.children}
    </div>
  )
})
