import { Upload } from '@mui/icons-material'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/assetupload'

export const routes = [
  {
    name: 'Asset Upload',
    page: React.createElement(React.lazy(() => import('./AssetUpload'))),
    menu: {
      icon: <Upload />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
]
