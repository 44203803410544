import { KMapLayer, colorPalette } from '@griegconnect/krakentools-kmap'
import { AxiosInstance } from 'axios'
import { MapView } from './types/MapView'
import { VesselList } from './types/VesselList'

export type MapLayerConfig = {
  id: KMapLayer['id']
  opacity: number
  ordering: number
}

export type MonitorMap = {
  id: string
  name: string
  layers: MapLayerConfig[]
  shared: boolean
  default: boolean
  backgroundColor?: string
  colorPalette?: colorPalette
}

export class MonitorApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  /*
   *
   * Maps
   *
   */

  async getMaps() {
    const maps = await this.httpClient.get<MonitorMap[]>('maps').then((response) => response.data)
    return maps
  }

  async createMap(map: Omit<MonitorMap, 'id'>) {
    return await this.httpClient.post('maps', map)
  }

  async updateMap(map: MonitorMap) {
    return await this.httpClient.put('maps/' + map.id, map)
  }

  async deleteMap(id: string) {
    return await this.httpClient.delete('maps/' + id)
  }

  /*
   *
   * Layers
   *
   */

  async getLayers() {
    return await this.httpClient.get<KMapLayer[]>('layers').then((response) => response.data)
  }

  async createLayer(layer: Omit<KMapLayer, KMapLayer['id']>) {
    return await this.httpClient.post('layers', { ...layer, shared: true }).then((response) => response.data)
  }

  async deleteLayer(id: string) {
    return await this.httpClient.delete('layers/' + id)
  }

  async updateLayer(layer: KMapLayer) {
    return await this.httpClient.put('layers/' + layer.id, layer)
  }

  /*
   *
   * MapViews
   *
   */

  async getMapViews(): Promise<MapView.Data[]> {
    const resp = await this.httpClient.get<MapView.DirtyData[]>(`/mapviews`)
    return resp.data.map((item) => ({
      ...item,
      bbox: JSON.parse(item.bbox),
    }))
  }

  async createMapView(mapView: MapView.Form) {
    await this.httpClient.post<MapView.Form, MapView.Form, MapView.DirtyForm>(`/mapviews`, {
      name: mapView.name,
      bbox: JSON.stringify(mapView.bbox),
      shared: mapView.shared,
      rotation: mapView.rotation
    })
  }

  async deleteMapView(id: string) {
    await this.httpClient.delete(`/mapviews/${id}`)
  }

  /*
   *
   * VesselLists
   *
   */

  async vesselLists(resolvedAisStatics?: boolean, excludeEmpty?: boolean) {
    const resp = await this.httpClient.get<VesselList.Data[]>(`/vessellists`, {
      params: { resolve: resolvedAisStatics },
    })
    const sortedVesselLists = resp.data.sort((a, b) => (a.name <= b.name ? -1 : 1))
    return excludeEmpty ? sortedVesselLists.filter((vesselsList) => vesselsList.list.length > 0) : sortedVesselLists
  }

  async createVesselList(form: VesselList.Form) {
    await this.httpClient.post<VesselList.Form, VesselList.Form, VesselList.PostForm>(`/vessellists`, {
      list: form.list.map((aisStatic) => aisStatic.id),
      name: form.name,
    })
  }

  async updateVesselList(formData: VesselList.Form) {
    const { id, ...form } = formData
    await this.httpClient.put<VesselList.Form, VesselList.Form, VesselList.PostForm>(`/vessellists/${id}`, {
      list: form.list.map((aisStatic) => aisStatic.id),
      name: form.name,
    })
  }

  async deleteVesselList(id: VesselList.Data['id']) {
    await this.httpClient.delete(`/vessellists/${id}`)
  }

}
