import { TextField, InputAdornment } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Search as SearchIcon } from '@griegconnect/krakentools-react-icons'
import React, { useEffect, useState } from 'react'

export type SearchFieldProps = {
  placeholder?: string
  onSearch: (searchText: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseInput: {
      backgroundColor: theme.palette.action.hover,
    },
    input: {
      padding: theme.spacing(1),
      '& :hover': {},
    },
    endAdornmentIcon: {
      color: theme.palette.text.secondary,
    },
  })
)

export const SearchField: React.FC<React.PropsWithChildren<SearchFieldProps>> = ({ onSearch, placeholder }) => {
  const [text, setText] = useState<string>('')
  const classes = useStyles()

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value ?? '')
  }

  useEffect(() => {
    onSearch(text)
  }, [text])

  return (
    <TextField
      variant="filled"
      onChange={onChangeHandler}
      value={text}
      autoFocus={true}
      inputProps={{ className: classes.input }}
      placeholder={placeholder ?? 'Search...'}
      InputProps={{
        className: classes.baseInput,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fontSize="small" className={classes.endAdornmentIcon} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchField
