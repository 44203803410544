import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#85d9ea',
  light: '#b9ffff',
  dark: '#51a7b8',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#2f8ea2',
}

export default Color
