import {
  Navigation,
  TenantContextWrapper,
  useAuth,
  configSelector,
  useTenants,
  useHttp,
} from '@griegconnect/krakentools-react-kraken-app'
import { useRecoilValue } from 'recoil'
import { useEffect } from 'react'
import { AssetsApi, AssetsAdminApi } from '@griegconnect/krakentools-react-kmap'
import { navigation } from './modules'
import React, { useMemo } from 'react'
import axios from 'axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { TenantsApi } from './api/TenantsApi'

const ApiContext = React.createContext<AssetsApi | null>(null)
const AdminApiContext = React.createContext<AssetsAdminApi | null>(null)
const TenantApiContext = React.createContext<TenantsApi | null>(null)
export const useAssetApi = () => React.useContext(ApiContext)
export const useAdminApi = () => React.useContext(AdminApiContext)
export const useTenantApi = () => React.useContext(TenantApiContext)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const AdminRouter = () => {
  const { getTokenSilently } = useAuth()
  const { isAuthenticated, loginWithRedirect, isInitializing } = useAuth()
  const config = useRecoilValue(configSelector)
  const { httpClient } = useHttp()
  const { activeTenant } = useTenants()

  const assetApi = useMemo(() => {
    const instance = axios.create({
      baseURL: config.api.assetApiBaseUrl + '/tenants/' + activeTenant?.ref + '/',
    })
    return new AssetsApi(instance, getTokenSilently)
  }, [config.api.assetApiBaseUrl, activeTenant, getTokenSilently])

  const adminApi =  new AssetsAdminApi(httpClient(config.api.assetApiBaseUrl + '/admin/'))
  const tenantsApi = new TenantsApi(httpClient(config.api.tenantApiUrl + '/management/tenants/'))

  useEffect(() => {
    if (!isInitializing && !isAuthenticated) {
      loginWithRedirect()
    }
  }, [isInitializing, isAuthenticated, loginWithRedirect])

  return (
    <QueryClientProvider client={queryClient}>
      <TenantContextWrapper>
        <TenantApiContext.Provider value={tenantsApi}>
          <ApiContext.Provider value={assetApi}>
            <AdminApiContext.Provider value={adminApi}>
              <Navigation navigation={navigation} />
            </AdminApiContext.Provider>
          </ApiContext.Provider>
        </TenantApiContext.Provider>
      </TenantContextWrapper>
    </QueryClientProvider>
  )
}
