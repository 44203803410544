import { atomFamily } from "recoil";
import { SearchResultItem } from "./types/SearchResultItem"
import { MapIdentifierType } from './types/MapIdentifierType'

export const searchStringAtom = atomFamily<string, MapIdentifierType>({
    key: 'searchStringState',
    default: (mapIdentifier: MapIdentifierType) => ''
})

export const searchConnectedAtom = atomFamily<boolean, MapIdentifierType>({
    key: 'searchConnected',
    default: (mapIdentifier: MapIdentifierType) => false
})

export const searchResultAtom = atomFamily<SearchResultItem[], MapIdentifierType>({
    key: 'searchResultState',
    default: (mapIdentifier: MapIdentifierType) => []
})

export const selectedSearchResultAtom = atomFamily<SearchResultItem | null, MapIdentifierType>({
    key: 'selectedSearchResultState',
    default: (mapIdentifier: MapIdentifierType) => null
})

// Maximum results
export const SEARCH_RESULT_MAX_LIMIT = 5