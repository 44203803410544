import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactElement, useEffect, useRef } from 'react'

const duration = 300
const minWidth = 37
const height = minWidth
const maxWidth = 250

const transitionProperties = (
  properties: string[],
  duration: number,
  easing: 'ease-out' | 'ease-in',
  delay: number = 0
) => {
  return properties.map((property) => `${property} ${duration}ms ${easing} ${delay}ms`).join(', ')
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapperDefaults: {
    width: minWidth,
    position: 'relative',
  },
  wrapperClosed: {
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(3.25),
    transition: [
      transitionProperties(['border-radius', 'width'], duration, 'ease-in'),
      transitionProperties(['background'], duration, 'ease-in'),
    ].join(', '),
  },
  wrapperOpen: {
    width: maxWidth,
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.spacing(0.5),
    transition: [
      transitionProperties(['border-radius'], duration, 'ease-out', duration * 0.75),
      transitionProperties(['background', 'width'], duration * 0.75, 'ease-out'),
    ].join(', '),
  },
  textfieldDefaults: {
    height: height,
  },
  textfieldClosed: {
    transition: transitionProperties(['width'], duration, 'ease-in'),
  },
  textfieldOpen: {
    transition: transitionProperties(['width'], duration, 'ease-out'),
  },
  inputDefaults: {
    height: height,
    paddingLeft: 0,
  },
  inputClosed: {
    opacity: 0,
    height: height,
    paddingLeft: 0,
    transition: transitionProperties(['opacity', 'padding-left'], duration / 2, 'ease-in'),
  },
  inputOpen: {
    opacity: 1,
    height: height,
    paddingLeft: theme.spacing(2),
    transition: transitionProperties(['opacity', 'padding-left'], duration, 'ease-out', duration),
    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
  },
  iconButton: {
    position: 'relative',
    top: 0,
    height: height,
    width: height,
  },
}))

type IconButtonInputProps = {
  value: string
  open: boolean
  placeholder?: string
  icon: ReactElement
  onOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
  onChange: (value: string) => void
  onSubmit?: () => void
  onBlur?: (event: React.FocusEvent<HTMLFormElement>) => void
  /**
   * Default true
   */
  autoFocus?: boolean
  className?: string
}

const IconButtonToInputField: React.FC<React.PropsWithChildren<IconButtonInputProps>> = ({
  value,
  open,
  placeholder,
  icon,
  onOpen,
  onBlur,
  onChange,
  onSubmit,
  autoFocus,
  className,
}) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (autoFocus && open) {
      inputRef!.current!.focus()
    }
  }, [autoFocus, open])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    onOpen(event)
    if (autoFocus) {
      inputRef!.current!.focus()
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    // Casting due to: https://stackoverflow.com/a/61164277
    if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget as Node)) {
      if (onBlur) {
        onBlur(event)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    onChange(newValue)
  }

  const handleSearch = (evt: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <form
      className={`${classes.wrapperDefaults} ${open ? classes.wrapperOpen : classes.wrapperClosed}`}
      onBlur={handleBlur}
      onSubmit={handleSearch}
    >
      <Input
        classes={{
          root: `${classes.textfieldDefaults} ${open ? classes.textfieldOpen : classes.textfieldClosed}`,
          input: open ? classes.inputOpen : classes.inputClosed,
        }}
        className={className}
        onChange={handleChange}
        fullWidth
        inputRef={inputRef}
        value={value}
        placeholder={placeholder}
        disableUnderline={true}
        type="search"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={open ? handleSearch : handleOpen}
              edge="start"
              disabled={open && value.length === 0}
              size="large"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        }
      />
    </form>
  )
}

IconButtonToInputField.defaultProps = {
  autoFocus: true,
}

export default IconButtonToInputField
