import { Page } from '@griegconnect/krakentools-react-ui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'

const PageUpgradeApp = () => {
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setTimeout(() => setFadeIn(true), 150)
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: (t) => t.zIndex.modal + 1000,
        backgroundColor: (t) => t.palette.action.disabled,
        left: 0,
        right: 0,
        bottom: 0,
        backdropFilter: 'blur(10px)',
        opacity: fadeIn ? 1 : 0,
        transition: '1s all',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Page.Wrapper title="Upgrade available!" maxWidth="sm">
        <Typography sx={{ marginBottom: (t) => t.spacing(3) }} component="div">
          This page requires a newer version than the one currently running in your browser. Please upgrade to access
          the latest features and ensure an uninterrupted experience.
        </Typography>
        <Button variant="contained" style={{ marginRight: 8 }} color="primary" onClick={() => window.location.reload()}>
          Upgrade now
        </Button>
      </Page.Wrapper>
    </Box>
  )
}

export default PageUpgradeApp
