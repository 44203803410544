import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import { Reload } from '@griegconnect/krakentools-react-icons'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer - 1,
      color: '#fff',
      flexDirection: 'column',
    },
    '@keyframes spinner': {
      from: {
        transform: 'rotateZ(0deg)',
      },
      to: {
        transform: 'rotateZ(360deg)',
      },
    },
    icon: {
      color: '#fff',
      animation: '$spinner 1s linear 0s infinite',
    },
    description: {
      color: '#fff',
    },
  })
)

let warnDeprecated = false
/**
 * @deprecated AppLoader is deprecated, please use skeleton loaders
 */
export type AppLoaderProps = {
  description?: string
  /**
   * @deprecated AppLoader is deprecated, please use skeleton loaders
   */
  className?: string
}

/**
 * @deprecated
 * ```markdown
 * # AppLoader is deprecated, please use skeleton loaders
 * ```
 */
export function AppLoader(props: AppLoaderProps) {
  const { className, description } = props
  const classes = useStyles()

  if (!warnDeprecated) {
    console.warn('[react-ui]: AppLoader is deprecated, please use skeleton loaders')
    warnDeprecated = true
  }

  return (
    <Backdrop className={`${classes.backdrop} ${className}`} open={true}>
      <div>
        <Reload className={classes.icon} />
      </div>
      {description && (
        <Typography className={classes.description} component="p">
          {description}
        </Typography>
      )}
    </Backdrop>
  )
}

export default AppLoader
