import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { useRecoilValue } from 'recoil'

import { footerAtom } from '../atoms/footerAtom'

export type FooterProps = {}

type useStylesProps = {}

const useStyles = makeStyles<Theme, useStylesProps>((theme: Theme) =>
  createStyles({
    footer: {
      paddingRight: 'env(safe-area-inset-right)',
      paddingBottom: 'env(safe-area-inset-bottom)',
      paddingLeft: 'env(safe-area-inset-left)',
      flexBasis: '64px',
    },
    bg: {
      backgroundColor: '#aeaeae',
    },
  })
)

export const Footer = (props: FooterProps) => {
  const classes = useStyles()

  const footerState = useRecoilValue(footerAtom)

  return footerState.hide ? null : <div className={clsx(classes.footer)}>Footer</div>
}

export default Footer
