import {
  transformStyle,
  IFeatureStyle,
  IPointerMoveEvent,
  IPointerClickEvent,
  colorPalette,
  KrakenMap,
  Feature,
  Draw,
} from '@griegconnect/krakentools-kmap'
import { unlistenByKey } from 'ol/events'
import { Extent } from 'ol/extent'
import { FeatureLike } from 'ol/Feature'
import { Geometry, MultiPoint, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Circle, Fill, Stroke, Style } from 'ol/style'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useMapContext } from './MapContext'
import OlFeature from 'ol/Feature'
import contains from '@turf/boolean-contains'
import { polygon } from '@turf/helpers'
import truncate from '@turf/truncate'

export type LayerStyle = {
  normal: IFeatureStyle
  hover: IFeatureStyle
  selected: IFeatureStyle
}

const verticeImageStyle = {
  image: new Circle({
    radius: 6,
    fill: new Fill({
      color: 'rgba(24, 160, 251, 1)',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 1,
    }),
  }),
}

const modifyStyle = new Style({
  ...verticeImageStyle,
  geometry: (feature) => {
    const f = feature as OlFeature
    const geom = f.getGeometry() as Polygon
    return new MultiPoint(geom.getCoordinates()[0])
  },
})

const inModifyStyle = new Style(verticeImageStyle)

const nullStyle = new Style()

type LayerProviderProps = {
  children?: React.ReactNode | React.ReactNode[]
  name: string
  style: LayerStyle
  zIndex?: number
  selectedFeatureId?: string
  hoveredFeatureId?: string
  tooltipPropertyPath?: string
  editedFeatureId?: string
  onGeometryChanged?: (newGeometry: Feature['geometry']) => void
  onFeatureHover?: (hit: IPointerMoveEvent | null) => void
  onFeatureClick?: (featureId: string) => void
  onDeselect?: () => void
  onColorPaletteChanged?: (colorPalette: colorPalette) => void

  /*
   * if set, sets nullstyle on polygons that have all its vertices outside the viewport.
   */
  hideOutsidePolygons?: boolean
}

type LayerContext = {
  layer: VectorLayer<VectorSource>
}

export const LayerContext = createContext<LayerContext | null>(null)
export const useLayerContext = () => useContext(LayerContext)!

export const LayerProvider: React.FC<LayerProviderProps> = ({
  name,
  children,
  style,
  zIndex,
  selectedFeatureId,
  hoveredFeatureId,
  hideOutsidePolygons,
  tooltipPropertyPath,
  editedFeatureId,
  onGeometryChanged: onFeatureChanged,
  onFeatureHover,
  onFeatureClick,
  onDeselect,
  onColorPaletteChanged,
}) => {
  const { kmapInstance } = useMapContext()

  const layer = useMemo(() => {
    const source = new VectorSource()
    const l = new VectorLayer({ source })
    l.set('name', name)
    l.set('type', name)
    l.setZIndex(zIndex ?? 1000)
    if (hideOutsidePolygons) {
      const eventKey = source.on('addfeature', (e) => {
        if (e.feature) {
          updateVisibility(kmapInstance, e.feature)
        }
      })
      l.set('kmapFeatureAddedEventKey', eventKey)
    }
    setTimeout(() => {
      onColorPaletteChanged?.(kmapInstance.getCurrentColorPalette())
    }, 0)
    return l
  }, [name, kmapInstance, hideOutsidePolygons])

  useEffect(() => {
    let feature: OlFeature<Geometry>
    const drawTool = new Draw(kmapInstance)
    if (layer && kmapInstance && editedFeatureId) {
      const f = layer.getSource()?.getFeatureById(editedFeatureId)
      if (f instanceof OlFeature) {
        feature = f
        drawTool.editFeature(feature, undefined, false, undefined, false, true, (newGeometry) => {
          const newGeom = truncate(
            kmapInstance.transformGeometry(newGeometry, 'EPSG:3857', 'EPSG:4326') as GeoJSON.Polygon,
            {
              precision: 11,
            }
          )
          onFeatureChanged?.(newGeom)
        })

        kmapInstance.enableSnap(true)
        const geom = feature.getGeometry()
        if (geom) {
          kmapInstance.getView().fit(geom.getExtent(), {
            size: kmapInstance.getSize(),
            padding: [200, 200 + 330, 200, 200],
            duration: 250,
          })
        }
      }
    } else {
      console.warn('LayerProvider: feature not found in useEffect')
    }
    return () => {
      if (drawTool) {
        drawTool.stopEditFeature(false)
        drawTool.destroy()
      }
      if (feature && kmapInstance) {
        kmapInstance.addToSnapCollection(feature)
      }
    }
  }, [layer, kmapInstance, editedFeatureId, selectedFeatureId])

  useEffect(() => {
    const normalStyle = transformStyle(style.normal)
    const selectedStyle = transformStyle(style.selected)
    const hoverStyle = transformStyle(style.hover)

    if (Array.isArray(normalStyle) && Array.isArray(selectedStyle) && Array.isArray(hoverStyle)) {
      layer.setStyle((feature: FeatureLike) => {
        const id = feature.getId()
        if (id === editedFeatureId) {
          return nullStyle
        }
        const outStyle = id === selectedFeatureId ? selectedStyle : id === hoveredFeatureId ? hoverStyle : normalStyle
        outStyle.forEach((s) => s.setZIndex(5000 - Number(feature.get('zIndex'))))
        return outStyle
      })
    } else {
      console.warn('LayerProvider: style(s) is not an array in useEffect')
    }
  }, [style, layer, selectedFeatureId, hoveredFeatureId, editedFeatureId])

  useEffect(() => {
    kmapInstance.getOlMap().addLayer(layer)
    return () => {
      const eventKey = layer.get('kmapFeatureAddedEventKey')
      if (eventKey) {
        unlistenByKey(eventKey)
      }
      kmapInstance.getOlMap().removeLayer(layer)
    }
  }, [layer])

  useEffect(() => {
    let moveEndId: string
    if (hideOutsidePolygons) {
      moveEndId = kmapInstance.on('moveend', () => {
        updateVisibilities(kmapInstance, layer)
      })
    }
    return () => {
      if (moveEndId) {
        kmapInstance.un(moveEndId)
      }
    }
  }, [layer, kmapInstance, hideOutsidePolygons, editedFeatureId])

  useEffect(() => {
    let pointerMoveEventId: string
    let pointerClickEventId: string
    let backgroundLayerClickEventId: string
    let colorPaletteEventId: string
    if (onFeatureHover && tooltipPropertyPath) {
      pointerMoveEventId = kmapInstance.on(name + '_pointermove', (event: IPointerMoveEvent) => {
        if (event.feature && event.feature.id !== editedFeatureId) {
          onFeatureHover(event)
          const tooltiptext = event.feature.properties?.[tooltipPropertyPath]
          if (tooltiptext) {
            kmapInstance.showTooltip(event.coordinate, tooltiptext)
          }
        } else {
          kmapInstance.showTooltip()
          onFeatureHover(null)
        }
      })
    }
    if (onFeatureClick) {
      pointerClickEventId = kmapInstance.on(name + '_click', (event: IPointerClickEvent) => {
        if (event.feature && !event.originaleEvent.altKey) {
          onFeatureClick(event.feature.properties?.id)
        }
      })
    }
    if (onDeselect) {
      backgroundLayerClickEventId = kmapInstance.on('backgroundLayer_click', (event: IPointerClickEvent) => {
        if (!event.originaleEvent.altKey) {
          onDeselect()
        }
      })
    }
    if (onColorPaletteChanged) {
      colorPaletteEventId = kmapInstance.on('colorPalette-changed', onColorPaletteChanged)
    }

    return () => {
      if (pointerMoveEventId) {
        kmapInstance.un(pointerMoveEventId)
      }
      if (pointerClickEventId) {
        kmapInstance.un(pointerClickEventId)
      }
      if (backgroundLayerClickEventId) {
        kmapInstance.un(backgroundLayerClickEventId)
      }
      if (colorPaletteEventId) {
        kmapInstance.un(colorPaletteEventId)
      }
    }
  }, [kmapInstance, onFeatureHover, onFeatureClick, editedFeatureId])

  const updateVisibility = (map: KrakenMap, feature: OlFeature, mapExtent?: Extent) => {
    if (feature.getId() === editedFeatureId) {
      return
    }
    const featureGeom = feature.getGeometry()
    if (featureGeom instanceof Polygon) {
      const polygonExtent = feature.getGeometry()?.getExtent()
      if (polygonExtent) {
        const extent = mapExtent || map.getView().calculateExtent(map.getOlMap().getSize())
        const extentPolygon = polygon([
          [
            [extent[0], extent[1]],
            [extent[0], extent[3]],
            [extent[2], extent[3]],
            [extent[2], extent[1]],
            [extent[0], extent[1]],
          ],
        ])

        const geom = kmapInstance.geometryToGeoJSON(featureGeom) as GeoJSON.Polygon
        const truncatedGeom = truncate(geom)
        const p = polygon(truncatedGeom.coordinates)
        if (contains(p, extentPolygon)) {
          feature.setStyle(nullStyle)
        } else {
          feature.setStyle(undefined)
        }
      }
    }
  }

  const updateVisibilities = (map: KrakenMap, l: VectorLayer<VectorSource>) => {
    const mapExtent = map.getView().calculateExtent(map.getOlMap().getSize())
    l.getSource()
      ?.getFeaturesInExtent(mapExtent)
      .forEach((feature) => {
        updateVisibility(map, feature, mapExtent)
      })
  }

  return <LayerContext.Provider value={{ layer }}>{children}</LayerContext.Provider>
}
