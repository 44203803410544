import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react'
import { Prettify } from '@griegconnect/core'
import { generatePath, Route as ReactRouterRoute, Routes } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useApplicationOptions } from './ApplicationOptionsContext'
import { menuItemsAtom, MenuItemWithSubsType } from './atoms/menuAtom'
import { activeTenantSelector } from './atoms/tenantsAtom'
import { NotAuthorizedPage } from './ui/NotAuthorizedPage'
import { NotFoundPage } from './ui/NotFoundPage'
import SuspenseLoader from './ui/SuspenseLoader'

export namespace Navigation {
  export type MenuOptions = Prettify<Pick<MenuItemWithSubsType, 'icon' | 'badge'>> | boolean
  //menu item
  export type Item<D = {}> = {
    name: string | React.ReactElement
    /**
     * @deprecated Only used by Port to translate menu items that will be referenced to from both AngularJS (does not support hooks) and React
     */
    displayName?: React.ReactNode
    path?: string
    search?: Record<string, string | string[] | number | number[] | boolean>
    public?: boolean
    /**
     * Menu options
     * @example
     * ```
     * menu: true
     * ```
     * @example
     * ```
     * menu: {
     *   icon: <GatePassings />
     * }
     * ```
     * @example
     * ```
     * menu: {
     *   badge: {
     *     count: 4,
     *     variant: 'dot|standard',
     *   }
     *   icon: <GatePassings />
     * }
     * ```
     *
     */
    menu?: Prettify<MenuOptions>
    subNavs?: Item<D>[]
    exact?: boolean
    page?: JSX.Element
    access?: ((dependencies: D) => boolean | Promise<boolean>) | boolean
  } & Pick<MenuItemWithSubsType, 'external' | 'callback'>

  export type Props<D = {}> = {
    navigation: Item<D>[]
    dependencies?: D
  }
}

type NavigationContextType<D = {}> = Navigation.Props<D>
function createGenericContext<D>() {
  return React.createContext<NavigationContextType<D>>(({} as unknown) as NavigationContextType<D>)
}
const NavigationContext = createGenericContext<{}>()

export function useNavigation<D>() {
  const context = useContext<NavigationContextType<D>>(
    (NavigationContext as unknown) as React.Context<NavigationContextType<D>>
  )
  return context
}

export function Navigation<D>(props: Navigation.Props<D>) {
  const activeTenant = useRecoilValue(activeTenantSelector)
  const setMenuItems = useSetRecoilState(menuItemsAtom)
  const [routes, setRoutes] = useState<Navigation.Item<D>[]>([])
  const { tenantsUrlMatchPathParam } = useApplicationOptions()

  const evalAccess = async (items: Navigation.Item<D>[], dependencies: D): Promise<Navigation.Item<D>[]> => {
    const res = items.map(
      async (n) =>
        ({
          ...n,
          access: typeof n.access === 'function' ? await n.access(dependencies) : n.access ?? true,
          subNavs: n.subNavs ? await evalAccess(n.subNavs, dependencies) : [],
        } as Navigation.Item<D>)
    )
    return await Promise.all(res)
  }

  const generateSearchQueryString = (search: Navigation.Item['search']) => {
    const searchParams = new URLSearchParams()
    if (search) {
      Object.entries(search).forEach(([key, value]) => {
        if (typeof value === 'string') {
          searchParams.set(key, value)
        } else if (typeof value === 'number') {
          searchParams.set(key, value.toString())
        } else if (typeof value === 'boolean') {
          searchParams.set(key, value.toString())
        } else if (typeof value === 'object' && Array.isArray(value)) {
          value.forEach((v: string | number) => {
            if (typeof v === 'string') {
              searchParams.append(key, v)
            } else {
              searchParams.append(key, v.toString())
            }
          })
        }
      })
    }
    return search ? '?' + searchParams.toString() : undefined
  }

  function generateAbsolutePathForMenu<T>(nav: Navigation.Item<D>) {
    if (!nav.path) {
      return nav.path
    }

    let isExternalOrigin = nav.external && nav.path.startsWith('http') ? true : false
    let prependAfterInterpolation: string = ''
    let onlyPath = nav.path

    // External navigation that has protocol (external origin) may contain path params
    // We need to strip origin before interpolation
    if (isExternalOrigin) {
      const url = new URL(nav.path)
      onlyPath = url.pathname
      prependAfterInterpolation = url.origin
    }

    const separator = '/:'
    const pathParts = onlyPath.split(separator)
    const strippedPath = pathParts.filter((_part, index) => index <= 1).join(separator)
    const interpolatedPath = activeTenant?.ref
      ? generatePath(strippedPath, {
          [tenantsUrlMatchPathParam]: activeTenant?.ref,
        })
      : onlyPath
    const queryString = generateSearchQueryString(nav.search) ?? ''
    return prependAfterInterpolation + interpolatedPath + queryString
  }

  const filterMenu = (items: Navigation.Item<D>[]): MenuItemWithSubsType[] => {
    return items
      .filter((n) => n.access && Boolean(n.menu))
      .map((n) => {
        const subNavs = n.subNavs && n.subNavs.length > 0 ? filterMenu(n.subNavs) : undefined
        return {
          name: n.name,
          displayName: n.displayName,
          absolutePath: generateAbsolutePathForMenu(n),
          activeOnExactMatch: n.exact ?? false,
          external: n.external,
          callback: n.callback,
          icon: n.menu && typeof n.menu !== 'boolean' ? n.menu.icon : n.menu,
          badge: n.menu && typeof n.menu !== 'boolean' ? n.menu.badge : undefined,
          disableLink: n.page ? false : true,
          items: subNavs && subNavs.length > 0 ? subNavs : undefined,
        }
      })
  }

  const getRoutes = (items: Navigation.Item<D>[]): Navigation.Item<D>[] => {
    return items
      .filter((n) => typeof n.page !== 'undefined' && typeof n.path !== 'undefined')
      .concat(items.flatMap((n) => (n.subNavs ? getRoutes(n.subNavs) : [])))
  }

  useEffect(() => {
    let cancelled = false
    const getAccessableItems = async () => {
      const evaluatedAccess = await evalAccess(props.navigation, props.dependencies ?? ({} as D))
      const availableRoutes = getRoutes(evaluatedAccess)
      const filteredMenu = filterMenu(evaluatedAccess)
      if (!cancelled) {
        setMenuItems(filteredMenu)
        setRoutes(availableRoutes)
      }
    }
    getAccessableItems()
    return () => {
      cancelled = true
    }
  }, [props.navigation, props.dependencies, activeTenant])

  const filteredRoutes = useMemo(
    () =>
      routes
        // Path and page need to be defined
        .filter((nav) => nav.path !== undefined && nav.page !== undefined),
    [routes]
  )

  // only used as dependency to update validRoutes on access change
  const authorizedRoutes = useMemo(() => filteredRoutes.filter((n) => n.access), [filteredRoutes])

  const validRoutes = useMemo(
    () =>
      filteredRoutes.map((nav) => (
        <ReactRouterRoute
          path={`${nav.path!}${nav.exact ? '' : nav.path!.length > 1 ? '/*' : '*'}`}
          element={nav.access ? nav.page : <NotAuthorizedPage />}
          key={`${nav.path}-${nav.name}`}
        />
      )),
    // recalculate only when neccessary, avoid rerender on badge count change
    [filteredRoutes.length, authorizedRoutes.length]
  )

  if (validRoutes.length === 0) {
    // If no routes exists yet, we will not render anything. This is to avoid rendering/flickering.
    return null
  } else {
    return (
      // Add default SuspenseLoader to avoid that other suspense fallbacks (in apps) are rendered
      <Suspense fallback={<SuspenseLoader />}>
        <NavigationContext.Provider value={{ ...((props as unknown) as NavigationContextType) }}>
          <Routes>
            {validRoutes}
            <ReactRouterRoute key="not-found-page" element={<NotFoundPage />} path="*" />
          </Routes>
        </NavigationContext.Provider>
      </Suspense>
    )
  }
}

export default Navigation
