import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#CB95E7',
  light: '#ffc6ff',
  dark: '#9966b5',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#9629CC',
}

export default Color
