import { atom, DefaultValue, selector } from 'recoil'

export declare namespace Tenant {
  export type IdentifierType = string

  export type Type = {
    id: IdentifierType
    name: string
    ref: string
    permissions: Permission[]
    companies: Omit<Type, 'companies'>[]
  }
  export type Permission = {
    domain: string
    name: string
  }
}

export const tenantsAtom = atom<Tenant.Type[]>({
  key: 'tenantsState',
  default: [],
})

export const activeTenantAtom = atom<Tenant.IdentifierType | null>({
  key: 'activeTenantState',
  default: null,
})

export const activeTenantSelector = selector<Tenant.Type | null>({
  key: 'activeTenantSelector',
  get: ({ get }) => {
    const activeTenant = get(activeTenantAtom)
    if (activeTenant) {
      return get(tenantsAtom).find((tenants) => tenants.id === activeTenant) ?? null
    } else {
      return null
    }
  },
  set: ({ get, set }, newActiveTenant) => {
    if (
      newActiveTenant &&
      !(newActiveTenant instanceof DefaultValue) &&
      get(tenantsAtom).find((tenants) => tenants.id === newActiveTenant.id)
    ) {
      set(activeTenantAtom, newActiveTenant.id)
    } else {
      set(activeTenantAtom, null)
    }
  },
})
