import React, { useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import makeStyles from '@mui/styles/makeStyles'
import { Button } from '@mui/material'
import Save from '@mui/icons-material/Save'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  cropperPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.black,
  },

  cropperContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  cropper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 600,

    [theme.breakpoints.down('md')]: {
      height: 300,
      width: '100%',
    },
  },

  buttons: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  cancelBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(2),
  },
  saveBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
  },
}))

interface CropperProps {
  onSave: (profileImage: string) => void
  onClose: () => void
  selectedImage: string
}

export const ProfileCropper: React.FC<React.PropsWithChildren<CropperProps>> = (props) => {
  const { onSave, onClose, selectedImage } = props

  const [cropper, setCropper] = useState<any>()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      onSave(cropper.getCroppedCanvas().toDataURL())
      enqueueSnackbar('Image updated!', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } else {
      enqueueSnackbar('Error', {
        variant: 'error',
      })
    }
  }

  return (
    <div className={classes.cropperPage}>
      <div className={classes.cropperContainer}>
        <Cropper
          className={classes.cropper}
          initialAspectRatio={1}
          alt="Image"
          src={selectedImage}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance: any) => {
            setCropper(instance)
          }}
        />
      </div>
      <div className={classes.buttons}>
        <Button className={classes.cancelBtn} size="small" variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className={classes.saveBtn}
          variant="contained"
          size="small"
          color="primary"
          startIcon={<Save />}
          onClick={() => getCropData()}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default ProfileCropper
