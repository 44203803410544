import React from 'react'
import { Button, Typography } from '@mui/material/'
import { useNavigate } from 'react-router-dom'
import { Page } from '@griegconnect/krakentools-react-ui'
import { useRecoilValue } from 'recoil'
import { activeTenantSelector } from '../atoms/tenantsAtom'
import { useTenantUrl } from '../hooks/useTenantUrl'

export const NotFoundPage = () => {
  const navigate = useNavigate()
  const activeTenant = useRecoilValue(activeTenantSelector)
  const { baseUrl: tenantBaseUrl } = useTenantUrl()

  const handleClickMainPage = () => {
    if (activeTenant) {
      navigate(tenantBaseUrl(activeTenant.ref))
    } else {
      document.location.href = process.env.PUBLIC_URL + '/'
    }
  }

  return (
    <Page.Wrapper title="Page not found!">
      <Typography style={{ marginBottom: 24 }}>
        The page you are looking for could not be found, and are probably moved or removed completely.
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        What to do next
      </Typography>
      <Button onClick={handleClickMainPage} variant="outlined" color="primary">
        Go to main page
      </Button>
    </Page.Wrapper>
  )
}
