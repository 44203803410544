import React from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

const StyledSignUpForm = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 560,
  '& .MuiTextField-root': {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
}))

export type SignUpFormStateField = {
  name: string
  value: string
  required: boolean
  display: boolean
  error: boolean
  helperText: string
  placeholder?: string
}

export type SignUpFormState = {
  email: SignUpFormStateField
  name: SignUpFormStateField
  password: SignUpFormStateField
  repeat_password: SignUpFormStateField
  phone_number: SignUpFormStateField
}

export type SignUpFormProps = {
  formState: SignUpFormState
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const SignUpForm = (props: SignUpFormProps) => {
  const { formState, onValueChange } = props

  return (
    <StyledSignUpForm>
      <Grid container={true}>
        {formState.email.display && (
          <Grid item={true} xs={12}>
            <TextField
              name={formState.email.name}
              fullWidth={true}
              margin="normal"
              required={formState.email.required}
              label="E-mail address"
              value={formState.email.value}
              error={formState.email.error}
              helperText={formState.email.helperText}
              placeholder={formState.email.placeholder}
              onChange={onValueChange}
            />
          </Grid>
        )}
        {formState.name.display && (
          <Grid item={true} xs={12}>
            <TextField
              name={formState.name.name}
              fullWidth={true}
              margin="normal"
              required={formState.name.required}
              label="Full name"
              value={formState.name.value}
              error={formState.name.error}
              helperText={formState.name.helperText}
              placeholder={formState.name.placeholder}
              onChange={onValueChange}
            />
          </Grid>
        )}
        {formState.password.display && (
          <Grid item={true} xs={12}>
            <TextField
              name={formState.password.name}
              type="password"
              fullWidth={true}
              margin="normal"
              required={formState.password.required}
              label="Password"
              value={formState.password.value}
              error={formState.password.error}
              helperText={formState.password.helperText}
              placeholder={formState.password.placeholder}
              onChange={onValueChange}
            />
          </Grid>
        )}
        {formState.repeat_password.display && (
          <Grid item={true} xs={12}>
            <TextField
              name={formState.repeat_password.name}
              type="password"
              fullWidth={true}
              margin="normal"
              required={formState.repeat_password.required}
              label="Repeat password"
              value={formState.repeat_password.value}
              error={formState.repeat_password.error}
              helperText={formState.repeat_password.helperText}
              placeholder={formState.repeat_password.placeholder}
              onChange={onValueChange}
            />
          </Grid>
        )}
        {formState.phone_number.display && (
          <Grid item={true} xs={12}>
            <TextField
              name={formState.phone_number.name}
              fullWidth={true}
              margin="normal"
              required={formState.phone_number.required}
              label="Phone number (mobile)"
              value={formState.phone_number.value}
              error={formState.phone_number.error}
              helperText={formState.phone_number.helperText}
              placeholder={formState.phone_number.placeholder}
              onChange={onValueChange}
            />
          </Grid>
        )}
      </Grid>
    </StyledSignUpForm>
  )
}

export default SignUpForm
