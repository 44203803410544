import { Stepper as MuiStepper, StepperClasskey, StepperProps } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps, StepperClasskey>(() =>
  createStyles<StepperClasskey, StyleProps>({
    root: {},
    horizontal: {},
    vertical: {},
    alternativeLabel: {},
  })
);

function Stepper(props: StepperProps) {
  const { classes, ...other } = props;
  const innerClasses = useStyles();
  return (
    <MuiStepper
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        horizontal: clsx(innerClasses.horizontal, classes?.horizontal),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
      }}
    />
  );
}

export default Stepper;
