import { useHttp } from '@griegconnect/krakentools-react-http'
import { IdentityApi } from '@griegconnect/krakentools-react-tenants'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'

export const useIdentityApi = () => {
  const { httpClient } = useHttp()
  const config = useRecoilValue(configSelector)

  const identityApi = useMemo(() => new IdentityApi(httpClient, config.api.tenantApiUrl), [httpClient, config])
  return identityApi
}
