import React from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Typography } from '@mui/material'

type Props = {
  avatarSrc?: string
  name: string
  className?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    name: {
      flex: '1 1 auto',
      marginRight: theme.spacing(1),
    },
    avatar: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      marginRight: theme.spacing(1),
    },
  })
)

const CommentAuthor = (props: Props) => {
  const { avatarSrc, name } = props
  const classes = useStyles()
  const matches = name.match(/\b(\w)/g)
  const acronym = matches ? matches.join('') : 'NA'
  return (
    <div className={`${classes.root} ${props.className}`}>
      <Avatar src={avatarSrc} alt={name} className={classes.avatar}>
        {!avatarSrc ? acronym : ''}{' '}
      </Avatar>{' '}
      <Typography variant="subtitle2" className={classes.name}>
        {name}
      </Typography>
    </div>
  )
}

export default CommentAuthor
