import { ChangeEvent, HTMLAttributes, ReactNode, SyntheticEvent, useEffect, useMemo } from 'react'

import { KeyboardBackspace } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Button,
  CircularProgress,
  FilterOptionsState,
  InputAdornment,
  ListItemText,
  TextField,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

import ReadOnlySelect from './ReadOnlySelect'
import StyledSelectPopper from './StyledPaper'
import React from 'react'

export type AutoCompleteSelectBaseValueType<ValueType extends OptionValuePropType> = {
  value: ValueType
  label?: string
}

export type OptionValuePropType = string | number | null | undefined

export type AutoCompleteSelectBaseProps<
  OptionValueType extends OptionValuePropType,
  OptionType extends AutoCompleteSelectBaseValueType<OptionValueType>
> = {
  label?: string
  backLabel?: string
  value: OptionValueType
  values: OptionType[]
  name?: string
  required?: boolean
  disabled?: boolean
  helperText?: string
  error?: boolean
  allowAnyValue?: boolean
  open?: boolean
  styles?: {
    root?: string
  }
  className?: string
  includeIdentifier?: boolean
  loading?: boolean
  readOnly?: boolean
  remoteErrorResult?: { error: boolean; helperText?: string | null }
  variant?: 'filled' | 'outlined' | 'standard'
  autoFocus?: boolean
  handleChange: (event: React.SyntheticEvent<Element, Event>, value: OptionValueType) => void
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  groupBy?: (option: OptionType) => string
  filterOptions?: (options: OptionType[], state: FilterOptionsState<OptionType>) => OptionType[]
  handleOpen?: (event: SyntheticEvent<Element, Event>) => void
  handleClose?: (event: SyntheticEvent<Element, Event>) => void
  getOptionsFromServer?: (value: string) => void
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: OptionType) => ReactNode | string
  startAdornment?: (option: OptionType) => ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    width: '20px !important',
    height: '20px !important',
    marginLeft: theme.spacing(2),
  },
  currentOption: {
    color: theme.palette.info.main,
  },
  identifier: {
    color: theme.palette.grey[400],
  },
}))

export default function AutoCompleteSelectBase<
  OptionValueType extends OptionValuePropType,
  OptionType extends AutoCompleteSelectBaseValueType<OptionValueType>
>({
  backLabel,
  disabled,
  helperText,
  label,
  name,
  required,
  value,
  values,
  error,
  allowAnyValue,
  open,
  loading,
  className,
  readOnly,
  remoteErrorResult,
  styles,
  autoFocus,
  variant = 'standard',
  includeIdentifier = true,
  getOptionsFromServer,
  handleChange,
  renderGroup,
  onBlur,
  handleOpen,
  handleClose,
  groupBy,
  filterOptions,
  renderOption,
  startAdornment,
}: AutoCompleteSelectBaseProps<OptionValueType, OptionType>) {
  const classes = useStyles()

  const currentOption = useMemo(() => values.find((v) => v.value === value), [value, values])

  const getValue = (value: OptionValueType) => values.find((o) => o.value === value) || null

  const getCaption = (option: OptionType | string) => {
    if (typeof option === 'string') return option

    if (option.value === 'resetCategory') return ''

    return option.label || (option.value as string)
  }

  const internalOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (getOptionsFromServer) getOptionsFromServer(event.target.value)

    if (allowAnyValue) handleChange(event, event.target.value as OptionValueType)
  }

  useEffect(() => {
    if (value && value !== currentOption?.value && values.length === 0 && getOptionsFromServer) {
      getOptionsFromServer(value.toString())
    }
  }, [currentOption?.value, getOptionsFromServer, value, values.length])

  const getStartAdornment = (params: AutocompleteRenderInputParams) => {
    if (!currentOption) return undefined

    const propsAdornment = startAdornment
    const inputAdornment = params.InputProps.startAdornment

    return !propsAdornment ? (
      inputAdornment
    ) : !inputAdornment ? (
      propsAdornment(currentOption)
    ) : (
      <>
        {propsAdornment(currentOption)}
        {inputAdornment}
      </>
    )
  }

  const optionRenderer = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) => {
    if (option.value === 'resetCategory')
      return (
        <Box component="li" {...props} key={option.value as string}>
          <>
            <KeyboardBackspace />
            <Button sx={{ justifyContent: 'flex-start' }} color="inherit" fullWidth>
              {backLabel ?? 'Back'}
            </Button>
          </>
        </Box>
      )

    return renderOption ? (
      renderOption(props, option)
    ) : (
      <Box component="li" {...props} key={option.value as string}>
        <ListItemText
          className={option.value === value ? classes.currentOption : undefined}
          primary={getCaption(option)}
        />
        {includeIdentifier && <Box className={classes.identifier}>{option.value}</Box>}
      </Box>
    )
  }

  if (readOnly) {
    return <ReadOnlySelect item={currentOption || null} label={currentOption?.label || '-'} />
  }

  return (
    <Autocomplete<OptionType, false, false, boolean>
      onBlur={onBlur}
      onOpen={handleOpen}
      onClose={handleClose}
      autoHighlight
      autoSelect
      PopperComponent={StyledSelectPopper}
      getOptionLabel={getCaption}
      freeSolo={allowAnyValue}
      disabled={disabled}
      options={values}
      onChange={(e, selectValue) => {
        handleChange(
          e,
          (selectValue as AutoCompleteSelectBaseValueType<OptionValueType>)?.value || (selectValue as OptionValueType)
        )
      }}
      open={open}
      groupBy={groupBy}
      renderGroup={renderGroup}
      classes={{
        root: clsx(styles?.root),
      }}
      value={getValue(value)}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={optionRenderer}
      renderInput={(params) => (
        <TextField
          required={required}
          name={name}
          helperText={helperText}
          className={className}
          label={label}
          autoFocus={autoFocus}
          variant={variant}
          onChange={getOptionsFromServer || allowAnyValue ? internalOnInputChange : undefined}
          error={error}
          {...remoteErrorResult}
          {...params}
          InputProps={
            loading
              ? {
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box>
                        <CircularProgress className={classes.spinner} />
                      </Box>
                    </InputAdornment>
                  ),
                }
              : { ...params.InputProps, startAdornment: getStartAdornment(params) }
          }
        />
      )}
    />
  )
}
