import {
  LiveView as LiveViewClass,
  LiveMessage,
  IViewParameters,
  WebSocketErrorTypes,
  aisShipType,
  Vessel,
  VesselModel,
} from '@griegconnect/krakentools-kmap'
import { Reload as ReloadIcon } from '@griegconnect/krakentools-react-icons'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Hidden from '@mui/material/Hidden'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useTheme } from '@mui/material/styles'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import { selectedSearchResultAtom } from '../atoms/controlAtoms'
import {
  activeLiveLayersSelector,
  followedVesselSelector,
  displayVesselTrailsAtom,
  selectedVesselSelector,
  allLiveViewLayersVisibleSelector,
  liveLayersAtom,
  vesselOpacityAtom,
  visibleVesselsAtom,
  vesselFilterAtom,
  enableLiveViewAtom,
} from '../atoms/liveViewAtoms'
import { mapCenterSelector, mapZoomLevelSelector } from '../atoms/mapConfigAtoms'
import { useMapContext } from '../MapContext'
import { aisCountryMap } from '../utils/aisCountryMap'
import { MapDialog } from '../dialogs/MapDialog'
import { MapDialogMobile } from '../dialogs/MapDialogMobile'
import { VesselInformation } from '../shared/VesselInformation'
import { VesselActions } from '../shared/VesselActions'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { aisNavStatuses } from '../utils/aisNavStatuses'
import MapSnackBarControl from '../shared/MapSnackBarControl'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { VesselImage } from '../shared/VesselImage'

export type LiveViewProps = {
  liveApiBaseUrl: string
  tenantId: string | null
  /**
   * Enable vessel dialogs on vessel click
   *
   * @type {boolean}
   */
  enableDialogs?: boolean
  vesselMMSI?: number
  followedVessel?: Vessel
  onFollowVessel?: (vessel: Vessel | null) => void
  defaultLayersToVisible?: boolean
  onVisibleVesselsUpdated?: (vesselList: Vessel[]) => void
  onViewPortChanged?: (newViewParameters: IViewParameters) => void
  onError?: (error: WebSocketErrorTypes) => void
  /**
   * Slot props for the error dialog and future sub/child components
   */
  slotProps?: {
    errorDialog?: {
      disableAutoFocus?: boolean
    }
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: '$spinner 1s linear 0s infinite',
      display: 'block',
      margin: '0 auto',
    },
    '@keyframes spinner': {
      from: {
        transform: 'rotateZ(0deg)',
      },
      to: {
        transform: 'rotateZ(360deg)',
      },
    },
    errorDialogAbsolute: {
      position: 'absolute',
    },
  })
)
/**
 * @param {LiveViewProps.liveApiBaseUrl} props.liveApiBaseUrl The hostname + path base of the live API (without protocol)
 */
export const LiveView = (props: LiveViewProps) => {
  const { mapIdentifierSlug, kmapInstance, getToken, notify } = useMapContext()
  const theme = useTheme()
  const { t } = useTranslation('kmap')
  const [enableOverlayAutoPan, setEnableOverlayAutoPan] = useState<boolean>(false)
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const [view, setView] = useState<LiveViewClass | null>(null)
  const enabled = useRecoilValue(enableLiveViewAtom(mapIdentifierSlug))
  const [isConnected, setIsConnected] = useState<boolean>(true)
  const [wsErrorMsg, setWsErrorMsg] = useState('')
  const setLiveLayers = useSetRecoilState(liveLayersAtom(mapIdentifierSlug))
  const resetLiveLayers = useResetRecoilState(liveLayersAtom(mapIdentifierSlug))
  const visibleLayers = useRecoilValue(activeLiveLayersSelector(mapIdentifierSlug))
  const setVisibleVessels = useSetRecoilState(visibleVesselsAtom(mapIdentifierSlug))
  const [allLiveLayersToVisible, setAllLiveLayersToVisible] = useRecoilState(
    allLiveViewLayersVisibleSelector(mapIdentifierSlug)
  )
  const [selectedSearchResult, setSelectedSearchResult] = useRecoilState(selectedSearchResultAtom(mapIdentifierSlug))
  const setCenter = useSetRecoilState(mapCenterSelector(mapIdentifierSlug))
  const setZoomLevel = useSetRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const [selectedVessel, setSelectedVessel] = useRecoilState(selectedVesselSelector(mapIdentifierSlug))
  const [followedVessel, setFollowedVessel] = useRecoilState(followedVesselSelector(mapIdentifierSlug))
  const resetSelectedVessel = useResetRecoilState(selectedVesselSelector(mapIdentifierSlug))
  const resetFollowedVessel = useResetRecoilState(followedVesselSelector(mapIdentifierSlug))
  const trails = useRecoilValue(displayVesselTrailsAtom(mapIdentifierSlug))
  const resetTrails = useResetRecoilState(displayVesselTrailsAtom(mapIdentifierSlug))
  const vesselOpacity = useRecoilValue(vesselOpacityAtom(mapIdentifierSlug))
  const vesselFilter = useRecoilValue(vesselFilterAtom(mapIdentifierSlug))
  const isMounted = useRef<boolean>(false)
  const vesselDialog = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  /**
   *
   * Mounting & initialization effects
   *
   */

  useEffect(() => {
    isMounted.current = true
    setLiveLayers([
      {
        key: 'krakenLiveView',
        name: t('LiveView.liveVessels'),
        hasLabels: false,
      },
    ])
    return () => {
      isMounted.current = false
      view?.destroy()
      resetSelectedVessel()
      resetFollowedVessel()
      resetTrails()
      resetLiveLayers()
    }
  }, [])

  useEffect(() => {
    let vesselListUpdatedEventId: string
    let viewPortEventId: string

    const vesselListInterval = setInterval(() => {
      if (view) {
        view.calculateVisibleVessels()
      }
    }, 10000)
    if (view) {
      vesselListUpdatedEventId = view.on('visible-vessels-updated', (event: Vessel[]) => {
        setVisibleVessels(event)
        if (props.onVisibleVesselsUpdated) {
          props.onVisibleVesselsUpdated(event)
        }
      })
    }
    if (view && props.onViewPortChanged) {
      viewPortEventId = view.on('viewport-changed', props.onViewPortChanged)
    }
    return () => {
      if (view) {
        view.un(vesselListUpdatedEventId)
        view.un(viewPortEventId)
      }
      clearInterval(vesselListInterval)
    }
  }, [view])

  useEffect(() => {
    if (props.defaultLayersToVisible) {
      setAllLiveLayersToVisible(true)
    }
  }, [props.defaultLayersToVisible])

  useEffect(() => {
    if (visibleLayers.length > 0 && enabled) {
      if (props.tenantId === null) {
        console.debug('LiveView: No active tenant, e.g. no tenant filtering enabled')
      }
      let initedView: LiveViewClass
      let reconnectInterval: number
      let reconnectCount = 1
      const handleWsError = (error: WebSocketErrorTypes) => {
        setIsConnected(false)
        setWsErrorMsg(error.message)
        if (props.onError) {
          props.onError(error)
        }
        if (reconnectInterval) {
          clearInterval(reconnectInterval)
        }
        reconnectInterval = window.setInterval(() => {
          initedView.positionApi.connect()
          // Retry connecting with increasing delay for up to max 30s between each try.
        }, 1000 * (reconnectCount++ < 15 ? reconnectCount : 30))
      }
      const handleWsConnected = () => {
        clearInterval(reconnectInterval)
        setIsConnected(true)
      }
      const initView = () =>
        new LiveViewClass(
          kmapInstance,
          getToken,
          props.liveApiBaseUrl,
          undefined,
          props.tenantId ?? undefined,
          undefined,
          undefined,
          handleWsError,
          handleWsConnected,
          true
        )
      initedView = initView()
      initedView.setOpacity(vesselOpacity)
      initedView.setVesselFilter(vesselFilter)
      setView(initedView)
      return () => {
        clearInterval(reconnectInterval)
        view?.destroy()
        initedView.destroy()
      }
    } else {
      view?.destroy()
      setView(null)
      return () => {
        view?.destroy()
      }
    }
  }, [kmapInstance, visibleLayers, props.liveApiBaseUrl, props.tenantId, enabled])

  /**
   *
   * Select response actions
   *
   */

  useEffect(() => {
    let ghostShipMMSI: number | null = null
    if (selectedSearchResult && (selectedSearchResult.type === 'vessel' || selectedSearchResult.type === 'port')) {
      if (selectedSearchResult.type === 'vessel') {
        if (view) {
          view
            .getLastKnownPosition(selectedSearchResult.value.payload.mmsi)
            .then((live) => {
              if (!live?.gpsPosition) {
                notify?.({
                  message: t('LiveView.noPositionMessage', { name: selectedSearchResult.value.name }),
                  type: 'error',
                })
                return
              } else {
                if (isMounted.current) {
                  kmapInstance.setCenter(
                    {
                      lat: live.gpsPosition.lat,
                      lon: live.gpsPosition.lon,
                    },
                    17,
                    { duration: 1000 }
                  )
                  const lastSeen = new Date().getTime() - new Date(live.time).getTime()
                  const isGhost = live.source === 'ais' ? lastSeen > 20 * 60 * 1000 : lastSeen > 8 * 60 * 60 * 1000
                  if (isGhost) {
                    ghostShipMMSI = live.mmsi
                    view.showGhostShip(live)
                    const vessel = {
                      id: live.mmsi,
                      gpsPosition: live.gpsPosition,
                      dimensions: {
                        bow: selectedSearchResult.value.payload.dims[0] || 0,
                        starboard: selectedSearchResult.value.payload.dims[1] || 0,
                        stern: selectedSearchResult.value.payload.dims[2] || 0,
                        port: selectedSearchResult.value.payload.dims[3] || 0,
                      },
                      name: selectedSearchResult.value.payload.name,
                      heading: live.heading,
                      cog: live.cog,
                      speed: live.speed,
                      time: live.time,
                      isGhost: true,
                      callsign: selectedSearchResult.value.payload.callsign,
                      draught: selectedSearchResult.value.payload.draught || null,
                      shiptype: selectedSearchResult.value.payload.shipType,
                      imo: selectedSearchResult.value.payload.imo || null,
                      mmsi: live.mmsi,
                      navStatus: live.navStatus,
                      source: live.source,
                      aisClass: null,
                      observers: live.observers,
                    }
                    setSelectedVessel(vessel)
                  } else {
                    view.handlePositionUpdate(live)
                    setTimeout(() => {
                      const vessel = view.getVessel(live.mmsi)
                      if (vessel) {
                        setSelectedVessel(vessel.exportModel())
                        setFollowedVessel(null)
                      }
                    }, 0)
                  }
                }
              }
            })
            .catch((e) => {
              console.error(e)
              notify?.({ message: t('LiveView.errorFetching', { error: e }), type: 'error' })
            })
        }
      } else if (selectedSearchResult.type === 'port') {
        setCenter({
          lat: selectedSearchResult.value.coordinate[1],
          lon: selectedSearchResult.value.coordinate[0],
        })
        setZoomLevel(14)
      }
    }
    return () => {
      if (view && ghostShipMMSI) {
        view.hideGhostShip(ghostShipMMSI)
      }
      if (view) {
        setSelectedSearchResult(null)
      }
    }
  }, [view, selectedSearchResult])

  /**
   *
   * Enable liveview on vessel-searched and selected
   *
   */

  useEffect(() => {
    if (selectedSearchResult && (selectedSearchResult.type === 'vessel' || selectedSearchResult.type === 'port')) {
      if (!allLiveLayersToVisible) {
        setAllLiveLayersToVisible(true)
      }
    }
  }, [selectedSearchResult])

  useEffect(() => {
    if (followedVessel) {
      props.onFollowVessel?.(followedVessel)
      setEnableOverlayAutoPan(false)
    } else {
      props.onFollowVessel?.(null)
      view?.unFollowVessel()
    }
  }, [view, followedVessel])

  useEffect(() => {
    let ghostShipMMSI: number | null = null
    let count = 0
    if (view && props.vesselMMSI) {
      const requestLastKnown = () => {
        if (view.positionApi.isOpen()) {
          view
            .getLastKnownPosition(props.vesselMMSI || 0)
            .then((live) => {
              if (live && isMounted.current) {
                setCenter({
                  lat: live.gpsPosition.lat,
                  lon: live.gpsPosition.lon,
                })
                setZoomLevel(16)
                const lastSeen = new Date().getTime() - new Date(live.time).getTime()
                const isGhost = live.source === 'ais' ? lastSeen > 20 * 60 * 1000 : lastSeen > 8 * 60 * 60 * 1000
                if (isGhost) {
                  ghostShipMMSI = live.mmsi
                  view.showGhostShip(live)
                }
              } else {
                notify?.({ message: t('LiveView.noPositionMessage', { name: props.vesselMMSI }), type: 'error' })
              }
            })
            .catch((e) => {
              console.error(e)
            })
          count = 20
        } else {
          if (count < 20) {
            setTimeout(() => requestLastKnown(), 200)
          }
        }
        count++
      }
      requestLastKnown()
    }
    return () => {
      if (view && ghostShipMMSI) {
        view.hideGhostShip(ghostShipMMSI)
      }
    }
  }, [view, props.vesselMMSI])

  /**
   *
   * Vessel & map interactions
   *
   */

  useEffect(() => {
    if (view) {
      const vesselClickListener = view.on('vessel-click', (data: Vessel) => {
        setEnableOverlayAutoPan(true)
        setSelectedVessel(data)
      })
      const mapClickListener = kmapInstance.on('map-click', (data: any) => {
        resetSelectedVessel()
      })
      return () => {
        kmapInstance.un(vesselClickListener)
        kmapInstance.un(mapClickListener)
      }
    }
  }, [view])

  useEffect(() => {
    if (
      view &&
      props.enableDialogs &&
      selectedVessel &&
      selectedVessel.mmsi &&
      vesselDialog.current &&
      !isMobileOrTablet
    ) {
      const position = 'bottom-center'
      const offset: [number, number] = [0, -35]
      const vesselDialogId = view.addVesselOverlay(
        selectedVessel.mmsi,
        vesselDialog.current,
        selectedVessel.gpsPosition,
        enableOverlayAutoPan,
        position,
        offset
      )
      const vesselUpdateListenerId = view.on('live-update:' + selectedVessel.mmsi, (event) => {
        if (selectedVessel.mmsi) {
          const vessel = view.getVessel(selectedVessel.mmsi)?.exportModel()
          if (vessel?.name) {
            setSelectedVessel({ ...vessel, ...event })
          }
        } else {
          setSelectedVessel({ ...selectedVessel, ...event })
        }
      })
      if (enableOverlayAutoPan) {
        setEnableOverlayAutoPan(false)
      }
      return () => {
        if (vesselDialogId) {
          view.removeVesselOverlay(vesselDialogId)
          if (selectedVessel.isGhost && selectedVessel.mmsi) {
            view.hideGhostShip(selectedVessel.mmsi)
          }
        }
        if (vesselUpdateListenerId) {
          view.un(vesselUpdateListenerId)
        }
      }
    }
  }, [view, props.enableDialogs, selectedVessel, isMobileOrTablet, enableOverlayAutoPan])

  useEffect(() => {
    if (view) {
      if (props.followedVessel?.mmsi) {
        setFollowedVessel(props.followedVessel)
        view.followVessel(props.followedVessel)
      } else {
        setFollowedVessel(null)
        view.unFollowVessel()
      }
    }
    return () => {
      if (view && props.followedVessel?.mmsi) {
        setFollowedVessel(null)
        view.unFollowVessel(props.followedVessel.mmsi)
      }
    }
  }, [view, props.followedVessel])

  useEffect(() => {
    if (view && trails.length > 0) {
      trails.forEach((vessel) => {
        if (vessel.mmsi) {
          view.showTrail(vessel.mmsi)
        }
      })
      return () => {
        trails.forEach((vessel) => {
          if (vessel.mmsi) {
            view.removeTrail(vessel.mmsi)
          }
        })
      }
    }
  }, [view, trails])

  useEffect(() => {
    if (view) {
      view.setOpacity(vesselOpacity)
    }
  }, [vesselOpacity])

  useEffect(() => {
    view?.setVesselFilter(vesselFilter)
  }, [vesselFilter, view])

  const country = selectedVessel ? aisCountryMap.get(Number(String(selectedVessel.mmsi).substring(0, 3))) : null
  const shipType = aisShipType(selectedVessel?.shiptype || 0) || ''
  const navStatusCode = selectedVessel?.navStatus ? aisNavStatuses.get(selectedVessel.navStatus)?.statusCode : ''
  const flag = country ? (
    <img
      width="20"
      height="15"
      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
      title={country.name}
    />
  ) : null
  const tranlsatedShipType = t('ShipTypes.' + shipType.replaceAll(' ', ''))
  const translatedNavStatus = t('AISStatuses.' + navStatusCode)
  const renderDialogs = () => {
    return selectedVessel && props.enableDialogs ? (
      <>
        <Box display="none">
          <MapDialog
            title={selectedVessel.name || selectedVessel.mmsi?.toString() || t('LiveView.unknownVessel')}
            subtitle={
              <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                {flag}
                <span>{tranlsatedShipType + (navStatusCode ? ', ' + translatedNavStatus : '')}</span>
              </Stack>
            }
            onClose={() => setSelectedVessel(null)}
            arrow="bottom"
            ref={vesselDialog}
            primary={
              <>
                <VesselImage imo={selectedVessel.imo} />
                <VesselInformation datatype="primary" vessel={selectedVessel} />
                <VesselInformation datatype="secondary" vessel={selectedVessel} />{' '}
              </>
            }
            actions={<VesselActions vessel={selectedVessel} />}
          ></MapDialog>
        </Box>
        <Hidden lgUp>
          <MapDialogMobile
            title={selectedVessel.name || selectedVessel.mmsi?.toString() || t('LiveView.unknownVessel')}
            subtitle={
              <Stack direction={'row'} alignItems={'baseline'} spacing={1}>
                {flag}
                <span>{tranlsatedShipType + ', ' + (navStatusCode ? translatedNavStatus : '')}</span>
              </Stack>
            }
            onClose={() => setSelectedVessel(null)}
            actions={<VesselActions vessel={selectedVessel} />}
            primary={
              <>
                <VesselImage imo={selectedVessel.imo} />
                <VesselInformation datatype="primary" vessel={selectedVessel} />
              </>
            }
            secondary={<VesselInformation datatype="secondary" vessel={selectedVessel} />}
          />
        </Hidden>
      </>
    ) : null
  }

  const snackbarControl = useMemo(() => <MapSnackBarControl />, [trails])
  return (
    <>
      {renderDialogs()}
      {snackbarControl}
      <Dialog
        open={!isConnected}
        sx={{ zIndex: 90 }}
        classes={{ root: classes.errorDialogAbsolute }}
        BackdropProps={{ classes: { root: classes.errorDialogAbsolute } }}
        container={() => document.getElementById(kmapInstance.mapElementId)}
        {...props.slotProps?.errorDialog}
      >
        <DialogTitle>{t('LiveView.errorTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{t('LiveView.connectivityProblems')}</Typography>
          {wsErrorMsg && wsErrorMsg.length > 0 && <Typography sx={{ color: 'error.main' }}>{wsErrorMsg}</Typography>}

          <br />
          <Typography sx={{ textAlign: 'center' }}>{t('LiveView.reconnecting')}</Typography>
          <ReloadIcon className={classes.rotateIcon} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LiveView
