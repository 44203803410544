import React, { useCallback, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTime } from 'luxon'
import { SxProps, TextFieldVariants, Theme } from '@mui/material'

const fontFamily = ['Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(',')

interface IDateSelectorProps {
  value: DateTime | null
  onChange: (value: DateTime | null) => void
  autoFocus?: boolean
  label?: string
  error?: boolean
  required?: boolean
  disabled?: boolean
  loading?: boolean
  maxDate?: DateTime
  minDate?: DateTime
  className?: string
  format?: string
  sx?: SxProps<Theme>
  variant?: TextFieldVariants
}

const DateSelector: React.FC<React.PropsWithChildren<IDateSelectorProps>> = ({
  value,
  onChange,
  autoFocus,
  label,
  required,
  error,
  loading,
  disabled,
  maxDate,
  minDate,
  className,
  format,
  sx,
  variant = 'filled',
}) => {
  const [dateTime, setDateTime] = useState<DateTime | null>(value)

  const handleOnChange = useCallback(
    (value: DateTime | null) => {
      setDateTime(value)
      onChange(value)
    },
    [onChange]
  )

  return (
    <DatePicker
      autoFocus={autoFocus}
      label={label}
      value={dateTime}
      onChange={handleOnChange}
      maxDate={maxDate}
      minDate={minDate}
      sx={Object.assign({ ...sx }, { fontFamily: fontFamily })}
      disabled={disabled}
      loading={loading}
      format={format}
      slotProps={{
        textField: {
          variant: variant,
          error: error,
          required: required,
          className: className,
        },
      }}
    />
  )
}

export default DateSelector
