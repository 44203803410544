import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Logo from './logo.svg';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      backgroundColor: '#005AA0',
      opacity: 1,
      color: '#fff',
    },
    logo: {
      maxWidth: '60%',
    },
  })
);

export const AppCurtain = () => {
  const classes = useStyles();

  return (
    <Backdrop transitionDuration={0} className={classes.backdrop} open={true}>
      <img src={Logo} className={classes.logo} />
    </Backdrop>
  );
};

export default AppCurtain;
