import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#90D0AE',
  light: '#c2ffe0',
  dark: '#609f7f',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#36B278',
}

export default Color
