type KrakenResponseHttpProblemItem = {
  field?: string
  path: string
  message: string
}

type KrakenReponseHttpProblem = {
  data: null | KrakenResponseHttpProblemItem[]
  detail: null | string
  status: number
  title: string
  type: string
}

export const httpErrorsAsString = (httpProblem: KrakenReponseHttpProblem): string | undefined => {
  const errors: string[] = []
  if (httpProblem) {
    if (httpProblem.data) {
      for (const problem of httpProblem.data) {
        if (problem.field) {
          errors.push(`${problem.message} (field: ${problem.field})`)
        } else {
          errors.push(`${problem.path} (${problem.message})`)
        }
      }
    }
    if (errors.length === 0 && httpProblem.detail) {
      errors.push(httpProblem.detail)
    }
    if (errors.length === 0 && httpProblem.title) {
      errors.push(httpProblem.title)
    }
    if (errors.length === 0) {
    }
  }
  return errors.length === 0 ? undefined : errors.join(', ')
}

type KrakenHttpProblem = { path: string; message: string, pathData: string }

export const httpErrors = (httpProblem: KrakenReponseHttpProblem, data?: any): Array<KrakenHttpProblem> | undefined => {
  const errors: Array<KrakenHttpProblem> = []
  if (httpProblem) {
    if (httpProblem.data) {
      for (const problem of httpProblem.data) {
        if (problem.field) {
          // errors.push(`${problem.message} (field: ${problem.field})`)
          errors.push({ path: problem.field, message: problem.message, pathData: problem.field })
        } else {
          errors.push({ path: problem.path, message: problem.message, pathData: getValueFromPointer(data, problem.path) })
        }
      }
    }
  }
  return errors.length === 0 ? undefined : errors
}

function getValueFromPointer(data: any, pointer: string): any {
  const parts = pointer.split('.')
  parts.shift()
  let currentValue = data

  for (const part of parts) {
    if (part.includes('[')) {
      // Handle array indexing
      const [key, dirtyIndex] = part.split('[')
      const arrayIndex = parseInt(dirtyIndex.slice(0, -1), 10)
      if (currentValue[key] && Array.isArray(currentValue[key])) {
        currentValue = currentValue[key][arrayIndex]
      } else {
        // Handle invalid pointer
        return undefined
      }
    } else {
      // Handle regular object key
      if (currentValue[part] !== undefined) {
        currentValue = currentValue[part]
      } else {
        // Handle invalid pointer
        return undefined
      }
    }
  }

  return currentValue
}