import { useAuth } from '@griegconnect/krakentools-react-auth'
import { useRecoilValue } from 'recoil'

import SplashLoader from './ui/SplashLoader'
import { applicationErrorAtom } from './atoms/applicationErrorAtom'
import { AppNewVersionNotifier } from './ui/AppNewVersionNotifier'

export type ApplicationWrapperType = {
  children?: React.ReactNode
}

export const ApplicationWrapper = (props: ApplicationWrapperType) => {
  const { children } = props
  const { isInitializing } = useAuth()
  const errors = useRecoilValue(applicationErrorAtom)

  if (errors.some((error) => error.severity === 'critical')) {
    throw Error(errors.find((error) => error.severity === 'critical')?.message)
  }

  return (
    <>
      {isInitializing ? <SplashLoader message="Loading application" /> : children}
      <AppNewVersionNotifier checkInterval={60} />
    </>
  )
}

export default ApplicationWrapper
