import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionProps as MuiAccordionProps,
  Box,
  Typography,
  TypographyProps,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowDown } from '@griegconnect/krakentools-react-icons'
import React from 'react'
import { FileDropZone } from '../file-drop-zone'

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))

const useAccordionStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))

const useAccordionSpaceBelowStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
}))

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'initial',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export type AccordionItemType = {
  id: number | string
  title: string
  titleProps?: {
    color: TypographyProps['color']
  }
  disabled?: boolean
  defaultExpanded?: boolean
  icon?: React.ReactNode
  content: React.ReactNode
  status?: React.ReactNode
  actions?: React.ReactNode
  onFilesDropped?: (files: FileList) => void
}

export type AccordionProps = Pick<MuiAccordionProps, 'className' | 'onChange' | 'elevation' | 'expanded'> & {
  items: AccordionItemType[]
  variant?: 'space-below'
}

const Accordion = (props: AccordionProps) => {
  const classes = useStyles()
  const accordionClasses = useAccordionStyles()
  const spaceBelowClasses = useAccordionSpaceBelowStyles()
  const renderAccordionsItem = (item: AccordionItemType) => (
    <MuiAccordion
      key={`accordion-${item.id}`}
      expanded={props.expanded}
      defaultExpanded={item.defaultExpanded}
      disabled={item.disabled}
      onChange={props.onChange}
      elevation={props.elevation}
      classes={props.variant === 'space-below' ? spaceBelowClasses : accordionClasses}
      className={props.className}
    >
      <AccordionSummary expandIcon={<ArrowDown />}>
        {item.icon && (
          <Box className={classes.margin} display="flex" justifyContent="flex-start" alignItems="center">
            {item.icon}
          </Box>
        )}
        <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">
          <Typography variant="subtitle2" color={item.titleProps?.color}>
            {item.title}
          </Typography>
        </Box>
        {item.status && (
          <Box className={classes.marginRight} display="flex" justifyContent="flex-end" alignItems="center">
            {item.status}
          </Box>
        )}
        {item.actions && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {item.actions}
          </Box>
        )}
      </AccordionSummary>
      <MuiAccordionDetails>{item.content}</MuiAccordionDetails>
    </MuiAccordion>
  )

  return (
    <div>
      {props.items.map((item) =>
        item.onFilesDropped ? (
          <FileDropZone key={`accordion-dropzone-${item.id}`} onFilesDropped={item.onFilesDropped} fullWidth>
            {renderAccordionsItem(item)}
          </FileDropZone>
        ) : (
          renderAccordionsItem(item)
        )
      )}
    </div>
  )
}

export default Accordion
