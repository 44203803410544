import { CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loader: {},
  })
)

export const SuspenseLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.loader} />
    </div>
  )
}

export default SuspenseLoader
