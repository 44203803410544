import { useHttp } from '@griegconnect/krakentools-react-http'
import { AuthConnectionsApi } from '@griegconnect/krakentools-react-tenants'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'

export const useAuthConnectionsApi = (useBearerToken?: boolean) => {
  const { httpClient } = useHttp()
  const config = useRecoilValue(configSelector)

  const useAuthConnectionsApi = useMemo(
    () => new AuthConnectionsApi(httpClient, config.api.tenantApiUrl, useBearerToken ?? true),
    [httpClient, config.api.tenantApiUrl, useBearerToken]
  )
  return useAuthConnectionsApi
}
