import { Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { headerTitleAtom } from '../atoms/headerTitleAtom'

export const TopBarTitle = () => {
  const titleState = useRecoilValue(headerTitleAtom)
  const { name } = useApplicationOptions()

  return titleState.hide ? null : (
    <Typography variant="h6" display="flex" alignItems="center" noWrap>
      {titleState.title ?? name}
    </Typography>
  )
}

export default TopBarTitle
