import { DynamicForm } from '@mui/icons-material'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/schemaordering'

export const routes = [
  {
    name: 'Schema Ordering',
    page: React.createElement(React.lazy(() => import('./SchemaOrdering'))),
    menu: {
      icon: <DynamicForm />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
]
