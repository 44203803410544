import { useAuth } from '@griegconnect/krakentools-react-auth'
import { InviteeInfo } from '@griegconnect/krakentools-react-tenants'
import { LanguageTag, Page, supportedLanguages } from '@griegconnect/krakentools-react-ui'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import countries, { Country } from 'country-list-with-dial-code-and-flag'
import { parsePhoneNumber } from 'awesome-phonenumber'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { useAlerts } from '../hooks'
import { useInviteApi } from '../hooks/useInviteApi'
import { httpErrorsAsString } from '../utils/krakenHttpProblem'
import SuspenseLoader from './SuspenseLoader'

const isUsernamePasswordUser = (connection: string): boolean => {
  return connection === 'Username-Password-Authentication'
}

export const schema = z
  .object({
    fullName: z.string({ description: 'Full name' }).min(3),
    password: z.string().min(8),
    confirmPassword: z.string(),
    dialCode: z.string().optional(),
    mobile: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password, dialCode, mobile }, ctx) => {
    const hasLowerCaseRule = /[a-z]/
    const hasUpperCaseRule = /[A-Z]/
    const hasNumberRule = /[0-9]/

    // Password policy need to follow Auth0 rules
    if (!hasLowerCaseRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one lower case letter (a-z)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (!hasUpperCaseRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one upper case letter (A-Z)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (!hasNumberRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one number (0-9)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (password !== confirmPassword) {
      ctx.addIssue({
        message: 'Password and confirm password need to be equal',
        code: 'custom',
        path: ['confirmPassword'],
      })
    }
    if (dialCode && dialCode.length > 0 && mobile && mobile.length !== 1 && mobile.length < 4) {
      ctx.addIssue({
        message: 'Please enter an valid mobile number (must contain at least 5 numbers)',
        code: 'custom',
        path: ['mobile'],
      })
    }
  })

type FormData = z.infer<typeof schema>

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

type ConfirmInvitationRedirectType = 'login' | 'external'

export type ConfirmInvitationProps = {
  redirectUrl?: string
  redirectType?: ConfirmInvitationRedirectType
  confirmBtnText?: string
}

const ConfirmInvitation = (props: ConfirmInvitationProps) => {
  const { i18n } = useApplicationOptions()
  const [inviteeInfo, setInviteeInfo] = useState<InviteeInfo>()
  const [data, setData] = useState<FormData>({
    fullName: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState<z.ZodFormattedError<FormData>>()
  const [token, setToken] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [userLanguage, setUserLanguage] = useState<LanguageTag | undefined>(
    i18n?.languages ? i18n.languages[0] : undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { loginWithRedirect } = useAuth()
  const inviteApi = useInviteApi()
  const query = useQuery()
  const { enqueue } = useAlerts()

  const handleLogin = async (connection: string = 'Username-Password-Authentication') => {
    const redirectUrl = props.redirectUrl ? props.redirectUrl : query.get('redirectUrl')
    if (props.redirectType && props.redirectType === 'external') {
      document.location.href = redirectUrl ? redirectUrl : '/'
    } else {
      const redirectOptions = {
        appState: {
          targetUrl: redirectUrl ? redirectUrl : '/',
        },
        connection,
      }
      await loginWithRedirect(redirectOptions)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const paramToken = query.get('token')
        const inviteData = await inviteApi.invitation(paramToken!)
        setToken(paramToken!)
        setInviteeInfo(inviteData)
        const phoneNumber = parsePhoneNumber(inviteData.invitee.mobile ?? '')
        setData((c) => ({
          ...c,
          fullName: inviteData.invitee.name ?? '',
          dialCode: phoneNumber.countryCode ? `+${phoneNumber.countryCode.toString()}` : undefined,
          mobile: phoneNumber.number ? phoneNumber.number?.significant : undefined,
        }))
        const expiredAt = new Date(inviteData.expires)
        setIsExpired(expiredAt < new Date())
        setIsLoading(!isUsernamePasswordUser(inviteData.authConnection.id))
      } catch (error) {
        console.error(`Expired token`, error)
        setIsError(true)
        setIsLoading(false)
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inviteeInfo && !isUsernamePasswordUser(inviteeInfo.authConnection.id)) {
      handleLogin(inviteeInfo.authConnection.id)
    }
  })

  const submitHandler = async () => {
    const validationResult = schema.safeParse(data)
    setErrors(undefined)
    if (validationResult.success) {
      try {
        const mobile = data.dialCode && data.mobile ? `${data.dialCode}${data.mobile}` : undefined
        await inviteApi.updateInvitation(
          token,
          data.fullName,
          data.password,
          mobile,
          userLanguage ? { locale: userLanguage } : undefined
        )
        handleLogin()
      } catch (error) {
        let errorMessage = 'Unknown error, please contact your administrator or support.'
        if (axios.isAxiosError(error)) {
          const serverError = error.response ? httpErrorsAsString(error.response.data) : undefined
          errorMessage =
            serverError && error.response
              ? `Server error: ${serverError} (${error.response.status})`
              : `Unknown server error: ${error.message}, please contact your administrator or support.`
        }
        enqueue(errorMessage, 'error')
      }
    } else {
      const formatted = validationResult.error.format()
      setErrors(formatted)
    }
  }

  if (isLoading) {
    return <SuspenseLoader />
  }

  if (isExpired) {
    return (
      <Page.Wrapper maxWidth="md" title="Invitation expired">
        <Typography gutterBottom>
          We're sorry, but this invitation has expired. Invitations are valid for a limited time only. If you completed
          the invitation earlier you should sign in. If you did not complete the invitation, you can try to reset your
          password from the sign in page. please contact your administrator or support.
        </Typography>
        <Typography color="text.hint">
          If you have any questions about this invitation, please contact support
          {inviteeInfo?.invitee.name && <> or the person that invited you, {inviteeInfo?.invitee.name}</>}.
        </Typography>
        <Box my={2}>
          <Button
            variant="outlined"
            color="primary"
            href="/"
            onClick={(e) => {
              e.preventDefault()
              handleLogin()
            }}
          >
            Sign in
          </Button>
        </Box>
      </Page.Wrapper>
    )
  }

  if (isError) {
    return (
      <Page.Wrapper maxWidth="md" title="Unknown error">
        <Box my={2}>
          <Typography gutterBottom>
            You may have completed the invitation earlier (making this invitation invalid) or some other issue have
            caused the error. If you completed the invitation earlier you should sign in. If you did not complete the
            invitation, please try again. If the error persists, please contact support.
          </Typography>
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            color="primary"
            href="#"
            sx={{ mr: 2 }}
            onClick={(e) => {
              e.preventDefault()
              window.location.reload()
            }}
          >
            Retry
          </Button>
          <Button
            variant="outlined"
            color="primary"
            href="/"
            onClick={(e) => {
              e.preventDefault()
              handleLogin()
            }}
          >
            Sign in
          </Button>
        </Box>
      </Page.Wrapper>
    )
  }

  return (
    <Page.Wrapper maxWidth="sm" title={`Welcome!`}>
      <Box mt={2}>
        <Typography>
          {inviteeInfo && inviteeInfo.inviter.name && `You have been invited by ${inviteeInfo.inviter.name}. `}
          To accept the invitation, fill out the registration form below.
        </Typography>
      </Box>
      <Box mt={2}>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Full name"
            placeholder="Ola Nordmann"
            value={data.fullName}
            onChange={(e) => setData((c) => ({ ...c, fullName: e.target.value }))}
            helperText={errors && errors.fullName?._errors ? errors.fullName?._errors.join('. ') : undefined}
            error={errors?.fullName?._errors ? true : false}
          />
          {/* <Stack direction="row" alignItems="stretch" spacing={2}> */}
          <CountrySelect
            value={data.dialCode}
            onChange={(_e, value) => setData((c) => ({ ...c, dialCode: value ? value.dialCode : undefined }))}
          />
          <TextField
            label="Mobile number"
            variant="filled"
            type="tel"
            sx={{ display: 'flex' }}
            fullWidth
            value={data.mobile ?? ''}
            onChange={(e) => setData((c) => ({ ...c, mobile: e.target.value.replace(/[^0-9]/g, '') }))}
            helperText={errors && errors.mobile?._errors ? errors.mobile?._errors.join('. ') : undefined}
            error={errors?.mobile?._errors ? true : false}
            disabled={data.dialCode === undefined}
          />
          {/* </Stack> */}
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Password"
            type="password"
            value={data.password}
            onChange={(e) => setData((c) => ({ ...c, password: e.target.value }))}
            helperText={
              errors && errors.password?._errors
                ? errors.password?._errors.map((error, index) => (
                    <span style={{ display: 'inline-block' }} key={index + '-' + error}>
                      {error}
                    </span>
                  ))
                : undefined
            }
            error={errors?.password?._errors ? true : false}
          />
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Confirm password"
            type="password"
            value={data.confirmPassword}
            onChange={(e) => setData((c) => ({ ...c, confirmPassword: e.target.value }))}
            helperText={
              errors && errors.confirmPassword?._errors ? errors.confirmPassword?._errors.join('. ') : undefined
            }
            error={errors?.confirmPassword?._errors ? true : false}
          />
          {i18n && (
            <FormControl required fullWidth variant="filled">
              <InputLabel id="select-language-label">Language</InputLabel>
              <Select
                labelId="select-language-label"
                id="select-language"
                label="Language"
                required
                value={userLanguage}
                onChange={(e) => setUserLanguage(e.target.value as LanguageTag)}
              >
                {i18n.languages &&
                  i18n.languages.map((lang) => (
                    <MenuItem value={lang} key={lang}>
                      {supportedLanguages[lang]}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                If the selected language is not supported by an application, english will be used.
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
        {errors && (
          <Box my={2}>
            <FormHelperText error={true}>Form contains errors, please fix errors before you proceed.</FormHelperText>
          </Box>
        )}
        <Box mt={1}>
          <Button variant="contained" color="primary" onClick={submitHandler}>
            {props.confirmBtnText ? props.confirmBtnText : 'Accept invitation'}
          </Button>
        </Box>
      </Box>
    </Page.Wrapper>
  )
}

type CountrySelectProps = {
  value?: string
  onChange: (event: React.SyntheticEvent<Element, Event>, value: Country | null) => void
}
const CountrySelect = (props: CountrySelectProps) => {
  const [initDefaultValue] = useState(props.value ? countries.findOneByDialCode(props.value) : undefined)

  return (
    <Autocomplete
      fullWidth
      defaultValue={initDefaultValue}
      options={countries.getAll()}
      onChange={props.onChange}
      isOptionEqualToValue={(option, value) => option && value && option.dialCode === value.dialCode}
      getOptionLabel={(option) => `${option.dialCode} (${option.name})`}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name} ({option.dialCode})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          label="Select country code"
          variant="filled"
        />
      )}
    />
  )
}

export default ConfirmInvitation
