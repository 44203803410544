import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import withStyles, { Styles } from '@mui/styles/withStyles'
import React from 'react'

type ButtonColors = MuiButtonProps['color'] | 'delete'

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  color?: ButtonColors
}

// const sharedStyles: { root: CSSProperties; startIcon: CSSProperties } = {
const sharedStartIconStyles: Styles<any, any, string> = {
  startIcon: {
    marginLeft: -6,
    marginRight: 6,
  },
}

const DefaultButton = withStyles(sharedStartIconStyles)(MuiButton)

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  ...sharedStartIconStyles,
}))(MuiButton)

const DeleteButtonContained = withStyles((theme) => ({
  root: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
  },
  ...sharedStartIconStyles,
}))(MuiButton)

const DeleteButtonOutlined = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
    },
  },
}))(MuiButton)

const DeleteButtonText = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  ...sharedStartIconStyles,
}))(MuiButton)

/**
 * There should only exist one "primary button" (`variant="contained"`) in a page, dialog/modal etc.
 *
 * **Primary** buttons should have `variant="contained" color="primary"`
 *
 * **Secondary** buttons should have `variant="outlined"` (please don't set color)
 *
 * **Delete** buttons should have `variant="contained" color="delete"`, delete buttons is often used as primary button in delete dialogs.
 */
export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, color, ...rest }) => {
  if (color === 'delete') {
    switch (rest.variant) {
      case 'contained':
        return <DeleteButtonContained {...rest}>{children}</DeleteButtonContained>
        break
      case 'outlined':
        return <DeleteButtonOutlined {...rest}>{children}</DeleteButtonOutlined>
        break
      case 'text':
        return <DeleteButtonText {...rest}>{children}</DeleteButtonText>
        break
      default:
        return <DeleteButton {...rest}>{children}</DeleteButton>
        break
    }
  } else {
    return (
      <DefaultButton color={color} {...rest}>
        {children}
      </DefaultButton>
    )
  }
}

export default Button
