import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#70991F',
  light: '#a2ca51',
  dark: '#406b00',
  contrastText: 'rgba(0, 0, 0, 0.87)',
  contrastPrimary: '#CBDD9B',
}

export default Color
