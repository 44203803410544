import { ClickAwayListener, Paper, Popper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useRecoilValue } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'

export type AppSwitcherPopperProps = {
  anchorEl: null | HTMLDivElement | HTMLElement
  onClickAway: (event: MouseEvent | TouchEvent) => void
  children?: React.ReactNode
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
)

const AppSwitcherPopper = (props: AppSwitcherPopperProps) => {
  const classes = useStyles()
  const leftDrawerState = useRecoilValue(leftDrawerAtom)

  return (
    <Popper
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      disablePortal={false}
      placement="bottom"
      className={classes.popper}
      modifiers={[
        {
          name: 'flip',
        },
        {
          name: 'preventOverflow',
        },
        {
          name: 'arrow',
        },
      ]}
    >
      <ClickAwayListener onClickAway={props.onClickAway}>
        <Paper elevation={8} style={{ padding: 16, width: 248 }}>
          <Typography variant="subtitle2" style={{ height: 32, marginBottom: 8 }}>
            Your applications
          </Typography>
          {props.children}
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export default AppSwitcherPopper
