import React from 'react'
import type { PaletteMode } from '@mui/material'
import type { Tool } from './createKrakenTheme'

type Theme = {
  tool: Tool
  paletteType: PaletteMode
  changeTool: (tool: Tool) => void
  changePaletteMode: (paletteType: PaletteMode) => void
}

export const GriegThemeContext = React.createContext<Theme | null>(null)
export const useGriegTheme = () => React.useContext(GriegThemeContext)!
