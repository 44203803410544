import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, FilledInput } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { activeTenantSelector, tenantsAtom } from '../atoms/tenantsAtom'
import { generatePath, useNavigate } from 'react-router-dom'
import { useApplicationOptions } from '../ApplicationOptionsContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginLeft: 24,
      maxWidth: '180px',
      minWidth: '180px',
    },
    input: {
      fontSize: theme.typography.body2.fontSize,
      padding: theme.spacing(1),
    },
    formControlLabel: {
      top: 0,
      left: 0,
      position: 'absolute',
      transform: `translate(${theme.spacing(1)}, ${theme.spacing(1.25)}) scale(1)`,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body2.fontSize,
    },
    selectIcon: {
      right: theme.spacing(0.5),
      fill: theme.palette.text.secondary,
    },
  })
)

export const TenantSelector = () => {
  const tenants = useRecoilValue(tenantsAtom)
  const activeTenant = useRecoilValue(activeTenantSelector)
  const { tenantsUrlMatchPathPrefix, tenantsUrlMatchPathParam, reloadOnTenantsChange } = useApplicationOptions()
  const navigate = useNavigate()
  const classes = useStyles()

  const onChangeHandler = (event: SelectChangeEvent<string>) => {
    const newActiveTenantId = event.target.value as string
    const newActiveTenant = tenants.find((tenant) => tenant.id === newActiveTenantId) ?? null
    const path = generatePath(tenantsUrlMatchPathPrefix, {
      [tenantsUrlMatchPathParam]: newActiveTenant?.ref,
    })

    if (reloadOnTenantsChange) {
      window.location.href = path
    } else {
      navigate(path)
    }
  }

  return (
    <FormControl fullWidth={true} variant="filled" className={classes.wrapper}>
      <InputLabel id="tenant-select-label" className={classes.formControlLabel}>
        {activeTenant ? '' : tenants.length === 0 ? 'No companies' : 'Select company'}
      </InputLabel>
      <Select
        labelId="tenant-select-label"
        id="tenant-select"
        classes={{ icon: classes.selectIcon }}
        value={activeTenant?.id ?? ''}
        disabled={tenants.length === 0}
        autoWidth={true}
        onChange={onChangeHandler}
        input={<FilledInput classes={{ input: classes.input }} />}
      >
        {!activeTenant && tenants.length < 0 && (
          <MenuItem value="" disabled>
            Select company
          </MenuItem>
        )}

        {tenants.map((tenant) => {
          return (
            <MenuItem key={tenant.id} value={tenant.id}>
              {tenant.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default TenantSelector
