import { Share } from '@mui/icons-material'
import React from 'react'

const tenantsUrlMatchPathPrefix = '/companies/:tenantRef'
export const ROUTEPATH = '/defaulttypes'

export const routes = [
  {
    name: 'Default Types',
    page: React.createElement(React.lazy(() => import('./DefaultTypes'))),
    menu: {
      icon: <Share />,
    },
    path: tenantsUrlMatchPathPrefix + ROUTEPATH,
  },
  {
    name: 'Edit Default Type',
    page: React.createElement(React.lazy(() => import('./DefaultType'))),
    path: tenantsUrlMatchPathPrefix + ROUTEPATH + '/:source/:typeName',
  },
]
