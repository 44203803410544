import makeStyles from '@mui/styles/makeStyles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'

export interface ISkeletonLoaderTableRowsProps {
  children?: never
  /**
   * Show skeleton for action column
   */
  actionMenu?: boolean
  columns: number
  rows?: number
  size?: TableCellProps['size']
  /** @deprecated */
  appendedActions?: actionTypes[]
  /** @deprecated */
  prependedActions?: actionTypes[]
  className?: string
}

type actionTypes = 'icon' | 'button'

const SkeletonLoaderTableRows: React.FC<React.PropsWithChildren<ISkeletonLoaderTableRowsProps>> = (
  props
): React.ReactElement | null => {
  const column = Array.from({ length: props.columns }, (_, i) => ++i)
  const rows = Array.from({ length: props.rows ? props.rows : 10 }, (_, i) => ++i)

  return (
    <>
      {rows.map((rowIndex) => (
        <TableRow key={rowIndex} data-testid="skeleton-loader" className={props.className}>
          <>
            {props.prependedActions && <SkeletonLoaderTableCellActions actions={props.prependedActions} align="left" />}
            {column.map((columnIndex) => (
              <TableCell key={`text${rowIndex}${columnIndex}`} size={props.size}>
                <Skeleton variant="text" animation="pulse" width={Math.random() * 60 + 40} height={26} />
              </TableCell>
            ))}
            {props.appendedActions && <SkeletonLoaderTableCellActions actions={props.appendedActions} align="right" />}
            {props.actionMenu && <SkeletonLoaderTableCellActions actions={['icon']} align="right" />}
          </>
        </TableRow>
      ))}
    </>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    borderRadius: 6,
  },
}))

const SkeletonLoaderTableCellActions: React.FC<
  React.PropsWithChildren<{
    actions: actionTypes[]
    align: 'left' | 'right'
    size?: TableCellProps['size']
  }>
> = (props): React.ReactElement | null => {
  const classes = useStyles()

  return (
    <TableCell align={props.align} size={props.size}>
      <div className={classes.wrapper} style={{ justifyContent: props.align === 'left' ? 'start' : 'end' }}>
        {props.actions.map((action, index) => (
          <React.Fragment key={action + index}>
            {action === 'button' ? (
              <Skeleton
                variant="rectangular"
                animation="pulse"
                width={80}
                height={36}
                className={classes.button}
                style={{ marginRight: index !== props.actions.length - 1 ? 12 : 0 }}
              />
            ) : (
              <Skeleton
                variant="circular"
                animation="pulse"
                width={24}
                height={24}
                style={{ marginRight: index !== props.actions.length - 1 ? 24 : 0 }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </TableCell>
  )
}

export default SkeletonLoaderTableRows
