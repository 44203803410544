import { ButtonProps, Link, LinkProps } from '@mui/material'
import React from 'react'

type ButtonLinkProps = {
  href: string
  sx?: LinkProps['sx']
  variant?: LinkProps['variant']
  children?: LinkProps['children']
  onClick?: ButtonProps['onClick']
}

/**
 *
 * Button that is styled as a Link.
 */
const ButtonLink = (props: ButtonLinkProps) => {
  const { children, ...linkProps } = props
  return (
    <Link component="button" {...linkProps}>
      {children}
    </Link>
  )
}

export default ButtonLink
