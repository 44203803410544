import { StepButton as MuiStepButton, StepButtonClasskey, StepButtonProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepButtonClasskey>(() =>
  createStyles<StepButtonClasskey, StyleProps>({
    root: {},
    vertical: {},
    touchRipple: {},
    horizontal: {},
  })
)

function StepButton(props: StepButtonProps) {
  const { classes, ...other } = props
  const innerClasses = useStyles()
  return (
    <MuiStepButton
      {...other}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        touchRipple: clsx(innerClasses.touchRipple, classes?.touchRipple),
      }}
    />
  )
}

export default StepButton
