import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import clsx from 'clsx'
import { useRecoilValue } from 'recoil'

import { headerAtom } from '../atoms/headerAtom'
import { TopBar } from '../ui/TopBar'

export type HeaderProps = {
  profileMenuChildren?: React.ReactNode
  /**
   * Only used to disable palette switch in order to default to a specific palette mode.
   * @deprecated
   */
  disablePaletteSwitch: boolean
}

type useStylesProps = {}

const useStyles = makeStyles<Theme, useStylesProps>((theme: Theme) =>
  createStyles({
    header: {
      zIndex: theme.zIndex.appBar,
      overscrollBehavior: 'contain',
      flexBasis: '64px',
      flexShrink: 0,
      flexGrow: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  })
)

export const Header = (props: HeaderProps) => {
  const { profileMenuChildren, disablePaletteSwitch } = props
  const classes = useStyles()

  const headerState = useRecoilValue(headerAtom)

  return headerState.hide ? null : (
    <div className={clsx(classes.header)}>
      <TopBar profileMenuChildren={profileMenuChildren} disablePaletteSwitch={disablePaletteSwitch} />
    </div>
  )
}

export default Header
