import { atom, DefaultValue, selector } from 'recoil'
import { PaletteMode } from '@mui/material'
import { getFromLocalStorage, removeFromLocalStorage, setToLocalStorage } from '../utils/localStorage'

export type ThemeConfigurationType = {
  palette: PaletteMode
}

const localStorageKey = 'themeConfig'

const getDefaultConfiguration = () => {
  const config = getFromLocalStorage<ThemeConfigurationType>(localStorageKey)
  return (
    config ?? {
      palette: 'light',
    }
  )
}

export const themeConfigurationAtom = atom<ThemeConfigurationType>({
  key: 'themeConfigurationState',
  default: getDefaultConfiguration(),
})

export const themeConfigurationSelector = selector<ThemeConfigurationType>({
  key: 'themeConfigurationSelector',
  get: ({ get }) => {
    const config = get(themeConfigurationAtom)
    return config
  },
  set: ({ set, reset }, updatedConfig) => {
    if (updatedConfig && !(updatedConfig instanceof DefaultValue)) {
      // Add to localstorage
      setToLocalStorage(localStorageKey, updatedConfig)
      set(themeConfigurationAtom, updatedConfig)
    } else {
      // Remove from localstorage
      removeFromLocalStorage(localStorageKey)
      reset(themeConfigurationAtom)
    }
  },
})
