import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#22AAFF',
  light: '#71dbff',
  dark: '#007bcb',
  contrastText: 'rgba(0, 0, 0, 0.87)',
}

export default Color
