import { Navigation, useActiveTenantUrl } from '@griegconnect/krakentools-react-kraken-app'
import { Navigate } from 'react-router-dom'
import { routes as styleRoutes } from './Styles'
import { routes as assetUploadRoutes } from './AssetUpload'
import { routes as schemaOrderingRoutes } from './SchemaOrdering'
import { routes as typeRoutes } from './Types'
import { routes as defaultStylesRoutes } from './DefaultStyles'
import { routes as defaultTypeRoutes } from './DefaultTypes'
import { routes as sfkbRoutes } from './SFKB'

const RedirectRoute = () => {
  const { forPath } = useActiveTenantUrl()
  const redirectPath = forPath('/styles')
  return <Navigate replace={true} to={redirectPath} />
}

export const navigation: Navigation.Item[] = [
  ...styleRoutes,
  ...defaultStylesRoutes,
  ...defaultTypeRoutes,
  ...assetUploadRoutes,
  ...schemaOrderingRoutes,
  ...typeRoutes,
  ...sfkbRoutes,
  {
    name: 'RedirectRoute',
    path: '/companies/:tenantRef',
    exact: false,
    page: <RedirectRoute />,
  },
]
