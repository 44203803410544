import React from 'react'
import { atom } from 'recoil'

export type HeaderTitleAtomType = {
  hide: boolean
  title?: string
  component?: React.ReactNode
}

export const headerTitleAtom = atom<HeaderTitleAtomType>({
  key: 'headerTitleState',
  default: {
    hide: false,
    title: undefined,
    component: undefined,
  },
})
