import { useHttp } from '@griegconnect/krakentools-react-http'
import { UsersApi } from '@griegconnect/krakentools-react-tenants'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'

export const useUsersApi = (useBearerToken?: boolean) => {
  const { httpClient } = useHttp()
  const config = useRecoilValue(configSelector)

  const usersApi = useMemo(
    () => new UsersApi(httpClient, config.api.tenantApiUrl, useBearerToken ?? true),
    [httpClient, config]
  )
  return usersApi
}
