import { LayerMenuItem } from "../atoms/types/LayerMenuItem"
import {KMapLayer} from '@griegconnect/krakentools-kmap'

const storagePrefix = "krakenmap-state-"

export type KrakenMapStorageState = {
    zoomLevel?: number
    rotation?: number
    center?: {
        lon: number
        lat: number
    }
    layers?: {
        assetView?: LayerMenuItem['key'][]
        liveView?: LayerMenuItem['key'][]
    }
    labels?: {
        assetView?: LayerMenuItem['key'][]
        liveView?: LayerMenuItem['key'][]
    }
    activeMapId?: KMapLayer['id']
}

export const setToLocalStorage = <T extends object | string>(key: string, value: T) => {
    // Add expiration method
    const serializedString = JSON.stringify(value)
    localStorage.setItem(`${storagePrefix}${key}`, serializedString)
}

export const getFromLocalStorage = <T extends object | string>(key: string): T | null => {
    // Add expiration method
    try {
        const unserializedString = localStorage.getItem(`${storagePrefix}${key}`)
        if (typeof unserializedString == "string") {
            return JSON.parse(unserializedString) as T
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}

export const removeFromLocalStorage = (key: string) => {
    // Add expiration method
    localStorage.removeItem(`${storagePrefix}${key}`)
}
