import React, { useState } from 'react'

import { DragDropContext, DragStart, DropResult } from '@hello-pangea/dnd'
import { BoardColumn, BoardColumnProps, DragProps } from './components/board-column'
import { ColumnsContainer } from './components/columns-container'
import { BoardTracks, BoardTracksProps } from './components/board-tracks'
import { DroppableIdProps } from './components/columns-droppable'

export type BoardProps = {
  columns: (BoardColumnProps | BoardTracksProps)[]
  isEnableDragAndDrop?: boolean
  handleDragAndDrop?: (
    dragId: string,
    sourceId: DroppableIdProps,
    destinationId: DroppableIdProps,
    destionationIndex: number
  ) => void
}

const getDroppableId = (id?: string): DroppableIdProps | undefined => {
  return id ? (JSON.parse(id) as DroppableIdProps) : undefined
}

export const Board = React.memo((props: BoardProps) => {
  const [draggingSource, setDraggingSource] = useState<DroppableIdProps | undefined>(undefined)
  const [draggingTarget, setDraggingTarget] = useState<DroppableIdProps | undefined>(undefined)

  const onDragStart = (start: DragStart) => {
    setDraggingSource(getDroppableId(start.source.droppableId))
  }

  const onDragUpdate = (result: DropResult) => {
    setDraggingTarget(getDroppableId(result.destination?.droppableId))
  }

  const onDragEnd = (result: DropResult) => {
    setDraggingSource(undefined)
    setDraggingTarget(undefined)

    if (!result.destination) {
      return
    }

    let dragId = result.draggableId

    let sourceIndex = result.source.index
    let destionationIndex = result.destination.index
    let sourceId = result.source.droppableId
    let destinationId = result.destination.droppableId

    if (sourceIndex === destionationIndex && sourceId === destinationId) {
      return
    }

    if (props.handleDragAndDrop) {
      const source = getDroppableId(sourceId)
      const destination = getDroppableId(destinationId)

      if (source && destination) {
        props.handleDragAndDrop!(dragId, source, destination, destionationIndex)
      }
    }
  }

  const isDragDisabled = !props.isEnableDragAndDrop

  const dragProps: DragProps = {
    isDragDisabled: isDragDisabled,
    draggingSource: draggingSource,
    draggingTarget: draggingTarget,
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <ColumnsContainer>
        {props.columns.map((column: BoardColumnProps | BoardTracksProps, index: number) => {
          const tracksProps = column as BoardTracksProps
          const tracksInfo = tracksProps?.tracks

          if (tracksInfo) {
            return <BoardTracks key={tracksProps.id} {...tracksProps} dragProps={dragProps} />
          }

          const col = column as BoardColumnProps
          return <BoardColumn key={col.id} {...col} index={index} dragProps={dragProps} />
        })}
      </ColumnsContainer>
    </DragDropContext>
  )
})

export default Board
