import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'

import makeStyles from '@mui/styles/makeStyles'

import { drawerWidthClosedDesktop } from '../config'

export type AppContentProps = {
  children?: React.ReactNode
  appBarTopMargin?: boolean
  appDrawerMargin?: boolean
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentWrapperWithDrawer: {
    paddingTop: `env(safe-area-inset-top)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidthClosedDesktop}px`,
    },
  },
  content: {
    width: '100%',
  },
}))

export const AppContent = (props: AppContentProps) => {
  const { children, appBarTopMargin, appDrawerMargin, className } = props

  const classes = useStyles()

  return (
    <div className={`${appDrawerMargin ? classes.contentWrapperWithDrawer : classes.contentWrapper} ${className}`}>
      {appBarTopMargin && <Toolbar />}
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default AppContent
