import { StepButtonClasskey, StepButtonProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import StepButton from '../step-button'

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps, StepButtonClasskey>(() =>
  createStyles<StepButtonClasskey, StyleProps>({
    root: {},
    vertical: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
    },
    horizontal: {},
    touchRipple: {},
  })
)

type VerticalStepButtonProps = {
  isActive: boolean
} & StepButtonProps

function VerticalStepButton(props: VerticalStepButtonProps) {
  const { classes, isActive, onClick, ...other } = props
  const innerClasses = useStyles()
  return (
    <StepButton
      {...other}
      onClick={onClick}
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        touchRipple: clsx(innerClasses.touchRipple, classes?.touchRipple),
      }}
    />
  )
}

export default VerticalStepButton
