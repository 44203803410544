import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

type LinkProps = Pick<MuiLinkProps, 'onClick' | 'href' | 'underline' | 'style' | 'className' | 'children'>

/**
 * Link component using react router
 */
const Link = React.forwardRef<any, LinkProps>((props, ref) => {
  const { children, href, underline = 'hover', ...linkProps } = props
  return (
    <MuiLink ref={ref} component={RouterLink} to={href ? href : '#'} underline={underline} {...linkProps}>
      {children}
    </MuiLink>
  )
})

export default Link
