import { SignOut } from '@griegconnect/krakentools-react-icons'
import { Modal, PaletteMode, Slide } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import MuiDialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { SnackbarProvider } from 'notistack'
import React, { useState } from 'react'
import ProfileForm, { ProfileFormProps } from './profile-form'
import ProfileIcon from './profile-icon'
import { LanguageTag, Profile } from './profile-menu'
import { Close as CloseIcon } from '@griegconnect/krakentools-react-icons'
import { ISelectTenantItem, TenantSelect } from '../tenant-selector'

const useStyles = makeStyles((theme: Theme) => ({
  edit: {
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  box: {
    padding: theme.spacing(1.5, 2, 1.5),
  },
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    outline: 'none',
  },
  modalBg: {
    width: '100%',
    height: '100%',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  switchRoot: {
    marginLeft: 0,
  },
  switchLabel: {
    marginRight: theme.spacing(1),
  },
  dialogPaper: {
    minWidth: 280,
  },
}))

const useDialogTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

type DialogTitleProps = {
  children?: React.ReactNode
  onClose: () => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  const classes = useDialogTitleStyles()
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6" component="span">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export type ProfileDialogProps = Pick<ProfileFormProps, 'onError'> & {
  profile: Profile
  /**
   * One or more languages to display in the language selector.
   */
  languages?: LanguageTag[]
  disableEditProfile?: boolean
  onProfileSave: (profile: Profile) => Promise<void>
  onProfileImageSave: (profileImage: string) => void
  onLogin?: () => void
  onLogout?: () => void
  paletteType?: PaletteMode
  onChangePaletteMode?: (paletteType: PaletteMode) => void
  showTenantSwitcher?: boolean
  tenants?: ISelectTenantItem[]
  activeTenantRef?: string
  onChangeTenant?: (ref: string) => void
  className?: string
  initOpenProfileForm: boolean
}

const ProfileDialog: React.FC<React.PropsWithChildren<ProfileDialogProps>> = (props) => {
  const {
    profile,
    disableEditProfile,
    onProfileSave,
    onProfileImageSave,
    onLogout,
    paletteType: theme,
    onChangePaletteMode,
    children,
    showTenantSwitcher,
    tenants,
    activeTenantRef,
    onChangeTenant,
    languages,
    className,
  } = props

  const classes = useStyles()
  const [openProfileForm, setOpenProfileForm] = useState(props.initOpenProfileForm || false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onChangeTenantHandler = (ref: string) => {
    onChangeTenant!(ref)
    setOpen(false)
  }

  const handleOpenProfileForm = () => {
    setOpenProfileForm(true)
    setOpen(false)
  }

  const changePaletteModeHandler = (darkmode: boolean) => onChangePaletteMode?.(darkmode ? 'dark' : 'light')
  const logoutHandler = () => onLogout?.()

  const getName = (() => {
    if (profile) {
      if (profile.fullName) {
        return profile.fullName
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  })()

  return (
    <>
      {profile && (
        <>
          <ProfileIcon onClick={handleOpen} fullName={getName} imageUrl={profile.picture} />
          <Dialog open={open} maxWidth="xs" classes={{ paper: classes.dialogPaper }} className={className}>
            <DialogTitle onClose={handleClose} />
            <ProfileIcon fullName={getName} imageUrl={profile.picture} size="large" style={{ marginTop: 32 }} />
            {(getName || profile.email) && (
              <>
                <div className={classes.box}>
                  {getName && <Typography variant="body1">{getName}</Typography>}
                  {profile.email && (
                    <Typography variant="body2" gutterBottom>
                      {profile.email !== profile.phone + '@grieg.io' ? profile.email : profile.phone}
                    </Typography>
                  )}
                </div>
              </>
            )}
            {showTenantSwitcher && tenants && onChangeTenant && (
              <>
                <div className={classes.box}>
                  <TenantSelect activeRef={activeTenantRef} onChange={onChangeTenantHandler} tenants={tenants} />
                </div>
              </>
            )}
            {theme && (
              <>
                <Divider />
                <div className={classes.box}>
                  <FormControlLabel
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        checked={theme === 'dark'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          changePaletteModeHandler(event.target.checked)
                        }
                        style={{ marginLeft: 0 }}
                        name="darkmode"
                        color="primary"
                      />
                    }
                    label="Dark mode"
                  />
                </div>
                <Divider />
              </>
            )}
            <div className={classes.box}>
              {children}
              {disableEditProfile !== true && (
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth={true}
                  disableElevation={true}
                  onClick={handleOpenProfileForm}
                  className={classes.edit}
                >
                  Edit profile
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                disableElevation={true}
                endIcon={<SignOut />}
                onClick={logoutHandler}
              >
                Sign out
              </Button>
            </div>
          </Dialog>
        </>
      )}
      <SnackbarProvider
        style={{ width: 180 }}
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Modal
          className={classes.modal}
          open={openProfileForm}
          onClose={() => setOpenProfileForm(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Slide in={openProfileForm} timeout={300} direction="up">
            <div className={classes.modalBg}>
              <ProfileForm
                open={openProfileForm}
                onClose={() => setOpenProfileForm(false)}
                profile={profile}
                onProfileImgSave={onProfileImageSave}
                onFormSave={async (form) => {
                  await onProfileSave(form)
                  setOpenProfileForm(false)
                }}
                languages={languages}
                onError={props.onError}
              />
            </div>
          </Slide>
        </Modal>
      </SnackbarProvider>
    </>
  )
}

export default ProfileDialog
