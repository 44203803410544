import {
  DialogTitle as MuiDialogTitle,
  DialogTitleClassKey as MuiDialogTitleClassKey,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton } from '../icon-button'
import { Cancel } from '@griegconnect/krakentools-react-icons'
import clsx from 'clsx'

export type DialogTitleClassKey = 'closeButton' | ('title' & MuiDialogTitleClassKey)

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: () => void
  classes?: {
    root?: string
    closeButton?: string
  }
  children?: React.ReactNode
  className?: string
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      margin: 0,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
)

function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, classes, className, ...other } = props
  const innerClasses = useStyles()

  return (
    <MuiDialogTitle className={clsx(innerClasses.root, classes?.root, className)} {...other}>
      {children}
      {onClose && (
        <IconButton className={clsx(innerClasses.closeButton, classes?.closeButton)} onClick={onClose} size="large">
          <Cancel />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
