import { Splash } from '@griegconnect/krakentools-react-ui'
import { useRecoilValue } from 'recoil'
import { ApplicationRef, useApplicationOptions } from '../ApplicationOptionsContext'

import { configSelector } from '../atoms/configAtom'

export type SplashLoaderProps = {
  message?: string
}

type AssetsBundle = Record<
  ApplicationRef,
  {
    applicationImageUrl: string
    applicationLogoDarkUrl: string
    applicationLogoLightUrl: string
  }
>
export const CDN_CACHE_KILLER = '6' // change this value if you need to kill cache
const coreSplashUrl = `/design/default/bundle/splash/Core@2x.png?c=${CDN_CACHE_KILLER}`
const exporeSplashUrl = `/design/default/bundle/splash/Explore@2x.png?c=${CDN_CACHE_KILLER}`
const portSplashUrl = `/design/default/bundle/splash/Port@2x.png?c=${CDN_CACHE_KILLER}`
const terminalSplashUrl = `/design/default/bundle/splash/Terminal@2x.png?c=${CDN_CACHE_KILLER}`
export const assetsBundle: AssetsBundle = {
  admin: {
    applicationImageUrl: coreSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Admin/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Admin/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  explore: {
    applicationImageUrl: exporeSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Explore/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Explore/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  go: {
    applicationImageUrl: portSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Port Go/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Port Go/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  haulier: {
    applicationImageUrl: terminalSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Haulier/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Haulier/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  // TODO: remove
  kraken: {
    applicationImageUrl: coreSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Grieg Connect/White.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Grieg Connect/Black.svg?c=${CDN_CACHE_KILLER}`,
  },
  manage: {
    applicationImageUrl: coreSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Manage/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Manage/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  monitor: {
    applicationImageUrl: portSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Monitor/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Monitor/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  placeholder: {
    applicationImageUrl: coreSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Placeholder/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Placeholder/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  plan: {
    applicationImageUrl: `/design/default/bundle/splash/Plan@2x.png?c=${CDN_CACHE_KILLER}`,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Plan/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Plan/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  port: {
    applicationImageUrl: portSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Port/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Port/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  predict: {
    applicationImageUrl: `/design/default/bundle/splash/Predict@2x.png?c=${CDN_CACHE_KILLER}`,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Predict/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Predict/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  security: {
    applicationImageUrl: `/design/default/bundle/splash/Security@2x.png?c=${CDN_CACHE_KILLER}`,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Security/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Security/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  shiplog: {
    applicationImageUrl: `/design/default/bundle/splash/Monitor@2x.png?c=${CDN_CACHE_KILLER}`,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Shiplog/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Shiplog/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  terminal: {
    applicationImageUrl: terminalSplashUrl,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Terminal/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Terminal/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
  traffic: {
    applicationImageUrl: `/design/default/bundle/splash/Traffic@2x.png?c=${CDN_CACHE_KILLER}`,
    applicationLogoDarkUrl: `/design/default/bundle/logo/Traffic/Dark/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
    applicationLogoLightUrl: `/design/default/bundle/logo/Traffic/Light/Horizontal 1.svg?c=${CDN_CACHE_KILLER}`,
  },
}

export const SplashLoader = (props: SplashLoaderProps) => {
  const { tool, appRef, name } = useApplicationOptions()
  const config = useRecoilValue(configSelector)
  const bundle = appRef ?? tool

  return (
    <Splash
      applicationImageUrl={config.cdn.designHostUrl + assetsBundle[tool ?? 'kraken'].applicationImageUrl}
      applicationLogoDarkUrl={config.cdn.designHostUrl + assetsBundle[bundle ?? 'kraken'].applicationLogoDarkUrl}
      applicationLogoLightUrl={config.cdn.designHostUrl + assetsBundle[bundle ?? 'kraken'].applicationLogoLightUrl}
      griegConnectLogoDarkUrl={config.cdn.designHostUrl + assetsBundle.kraken.applicationLogoDarkUrl}
      griegConnectLogoLightUrl={config.cdn.designHostUrl + assetsBundle.kraken.applicationLogoLightUrl}
      applicationName={name}
      applicationTool={tool ?? 'kraken'}
      message={props.message ?? `Loading ${name}...`}
      version={config.version}
      progressType="indeterminate"
    />
  )
}

export default SplashLoader
