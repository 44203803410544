import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#CC2954',
  light: '#ff6180',
  dark: '#95002c',
  contrastText: 'rgba(255, 255, 255, 0.87)',
  contrastPrimary: '#EF8FA8',
}

export default Color
