import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#ff5722',
  light: '#ff8a50',
  dark: '#c41c00',
  contrastText: 'rgba(255, 255, 255, 0.87)',
}

export default Color
