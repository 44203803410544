import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgVessel = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M13 4h5a1 1 0 0 1 1 1v5.28l2.316.771a1 1 0 0 1 .665 1.145l-1 5-.002.008-.143.716a6.53 6.53 0 0 1-1.926-.57l.936-4.68-2.14-.714a1.134 1.134 0 0 1-.044-.015L12 10.054l-5.662 1.887-.044.015-2.14.713.936 4.681a6.53 6.53 0 0 1-1.926.57l-.144-.722v-.002l-1-5a1 1 0 0 1 .664-1.145L5 10.28V5a1 1 0 0 1 1-1h5V3a1 1 0 1 1 2 0v1ZM7 9.613l4.684-1.562a1 1 0 0 1 .632 0L17 9.613V6H7v3.613Z"
      clipRule="evenodd"
    />,
    <path d="M8.889 18.541a1 1 0 0 0-1.591-.253l-.005.005-.045.04c-.045.04-.12.102-.223.18a6.393 6.393 0 0 1-.972.593C5.175 19.544 3.827 20 2 20a1 1 0 1 0 0 2c2.173 0 3.825-.544 4.947-1.106.341-.17.632-.341.874-.5.124.127.267.257.43.387C9.04 21.413 10.256 22 12 22c1.743 0 2.96-.587 3.75-1.22.162-.129.305-.26.43-.385.24.157.532.33.873.5C18.175 21.454 19.827 22 22 22a1 1 0 1 0 0-2c-1.827 0-3.175-.456-4.053-.894a6.39 6.39 0 0 1-.972-.594 3.815 3.815 0 0 1-.268-.22l-.005-.004a1 1 0 0 0-1.59.253l-.001.002-.002.002-.005.01-.008.012c-.017.03-.05.08-.1.145-.098.132-.26.318-.496.507-.46.368-1.243.781-2.5.781s-2.04-.413-2.5-.78a2.836 2.836 0 0 1-.497-.508 1.83 1.83 0 0 1-.114-.17Z" />
  )
export default SvgVessel
