import React from 'react';

import styles from '../../grieg-onboarding.module.scss';

export type CarouselComponentProps = {
  component: React.ReactNode;
  transitionTimingFunction: string;
  transitionTime: number;
  opacity: number;
  top: string;
  left: string;
};

export const CarouselComponent = (props: CarouselComponentProps) => {
  const { component, transitionTimingFunction, transitionTime, opacity, top, left } = props;

  return (
    <div
      style={{
        top: top,
        left: left,
        opacity: opacity,
        transition: `left ${transitionTimingFunction} ${transitionTime}ms,
					top ${transitionTimingFunction} ${transitionTime}ms,
					opacity ${transitionTimingFunction} ${transitionTime}ms`,
      }}
      className={styles.carouselComponent}
    >
      {component}
    </div>
  );
};

export default CarouselComponent;
