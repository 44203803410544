import { PaperProps, StandardProps, Stepper as MuiStepper, StepperClasskey } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

type StyleProps = {};

type VerticalStepperClasskey = 'root' | 'vertical' | 'alternativeLabel';

const useStyles = makeStyles<Theme, StyleProps, VerticalStepperClasskey>(() =>
  createStyles<VerticalStepperClasskey, StyleProps>({
    root: {},
    vertical: {},
    alternativeLabel: {},
  })
);

interface Props extends StandardProps<PaperProps, StepperClasskey> {
  activeStep?: number;
  alternativeLabel?: boolean;
  children: React.ReactNode;
  connector?: React.ReactElement<any, any>;
  nonLinear?: boolean;
}
function VerticalStepper(props: Props) {
  const { classes, ...other } = props;
  const innerClasses = useStyles();
  return (
    <MuiStepper
      {...other}
      orientation="vertical"
      classes={{
        root: clsx(innerClasses.root, classes?.root),
        vertical: clsx(innerClasses.vertical, classes?.vertical),
        alternativeLabel: clsx(innerClasses.alternativeLabel, classes?.alternativeLabel),
      }}
    />
  );
}

export default VerticalStepper;
