import type { SimplePaletteColorOptions } from '@mui/material'

const Color: SimplePaletteColorOptions = {
  main: '#296ACC',
  light: '#6a97ff',
  dark: '#00409a',
  contrastText: 'rgba(255, 255, 255, 0.87)',
  contrastPrimary: '#94CEFF',
}

export default Color
