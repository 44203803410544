import { Page } from '@griegconnect/krakentools-react-ui'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { Component, ErrorInfo, ReactNode, useEffect, useState } from 'react'
import { Location, useLocation } from 'react-router-dom'
import { useErrorBoundary } from './ErrorBoundaryProvider'
import { useConfig } from './hooks/useConfig'
import * as Sentry from '@sentry/react'
import { useConfigQuery } from './hooks/useConfigQuery'
import PageUpgradeApp from './ui/PageUpgradeApp'
import SuspenseLoader from './ui/SuspenseLoader'

type ErrorBoundaryProps = Pick<
  ErrorBoundaryUiProps,
  'fallback' | 'reloadPage' | 'resetOnNavigation' | 'margins' | 'size'
>

const ErrorBoundary: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  fallback,
  margins,
  size,
  reloadPage,
  resetOnNavigation,
}) => {
  const config = useConfig()
  const { sessionErrorCode: errorCode } = useErrorBoundary()
  const location = useLocation()

  if (config.sentry) {
    return (
      <Sentry.ErrorBoundary
        fallback={({ resetError, componentStack, error }) => (
          <div data-sentry-unblock data-sentry-unmask style={{ width: '100%' }}>
            <ErrorBoundaryUi
              size={size}
              margins={margins}
              errorCode={config.sentry ? errorCode : undefined}
              errorStackTrace={componentStack}
              errorName={error.name}
              errorMessage={error.message}
              fallback={fallback}
              resetOnNavigation
              resetError={resetError}
              reloadPage={reloadPage}
            />
          </div>
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    )
  }

  return (
    <ErrorBoundaryCatch
      location={location}
      errorBoundaryUiProps={{
        fallback: fallback,
        resetOnNavigation,
        reloadPage,
        errorCode,
        margins: margins,
        size: size,
      }}
    >
      {children}
    </ErrorBoundaryCatch>
  )
}

interface ErrorBoundaryCatchProps {
  location: Location
  children: ReactNode
  errorBoundaryUiProps: Omit<ErrorBoundaryUiProps, 'errorStackTrace' | 'errorName' | 'errorMessage' | 'resetError'>
}

type ErrorBoundaryCatchState = Pick<ErrorBoundaryUiProps, 'errorStackTrace' | 'errorName' | 'errorMessage'> & {
  hasError: boolean
  error?: Error
}

const defaultErrorState: ErrorBoundaryCatchState = {
  hasError: false,
  error: undefined,
  errorName: '',
  errorMessage: '',
  errorStackTrace: '',
}

class ErrorBoundaryCatch extends Component<ErrorBoundaryCatchProps, ErrorBoundaryCatchState> {
  constructor(props: ErrorBoundaryCatchProps) {
    super(props)
    this.state = { ...defaultErrorState }
  }

  public componentDidUpdate(prevProps: ErrorBoundaryCatchProps, prevState: ErrorBoundaryCatchState) {
    // Reset error on navigation
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ ...defaultErrorState })
    }
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryCatchState {
    // Triggers a new render after thrown error
    return { hasError: true, errorName: error.name, errorMessage: error.message, errorStackTrace: error.stack }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catches any error thrown by descendant components
    this.setState({
      error: error,
      errorName: error.name,
      errorMessage: error.message,
      errorStackTrace: errorInfo.componentStack,
      hasError: true,
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryUi
          {...this.props.errorBoundaryUiProps}
          errorName={this.state.errorName}
          errorMessage={this.state.errorMessage}
          errorStackTrace={this.state.errorStackTrace}
          resetError={() => this.setState({ ...defaultErrorState })}
        />
      )
    }

    return this.props.children
  }
}

type ErrorBoundaryUiProps = {
  /**
   * When set, the supplied component will be rendered instead of the default error ui
   */
  fallback?: (errorData: {
    // error: Error
    componentStack?: string
    // eventId: string
    resetError: () => void
  }) => React.ReactElement
  errorCode?: string
  /**
   * Add spacing around the error ui
   */
  margins?: boolean
  size?: 'lg' | 'sm'
  /**
   * Display a reload page button
   */
  reloadPage?: boolean
  errorStackTrace?: string
  errorName: string
  errorMessage: string
  resetError: () => void
  resetOnNavigation?: boolean
}

const ErrorBoundaryUi = ({
  errorCode,
  fallback: FallbackComponent,
  margins,
  size,
  reloadPage,
  errorName,
  errorMessage,
  errorStackTrace,
  resetOnNavigation,
  resetError,
}: ErrorBoundaryUiProps) => {
  const mainpagePath = `${process.env.PUBLIC_URL}/`
  const isMainPagePath = mainpagePath === document.location.pathname
  const location = useLocation()
  const { environment, version } = useConfig()
  const [initialized, setInitialized] = useState(false)
  const chunkLoadError =
    (errorName === 'ChunkLoadError' && errorMessage.startsWith('Loading chunk')) ||
    (errorName === 'TypeError' && errorMessage.startsWith('Failed to fetch dynamically imported module'))
  const { data, isError, isLoading } = useConfigQuery({ enabled: chunkLoadError })

  console.log('errorName', errorName)
  console.log('errorMessage', errorMessage)

  useEffect(() => {
    if (resetOnNavigation && resetError && initialized) {
      resetError()
    }
    setInitialized(true)
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  if (chunkLoadError && isLoading) {
    return <SuspenseLoader />
  }

  const hasNewVersion = chunkLoadError && !isError && !isLoading && data && data.version !== version

  if (FallbackComponent) {
    return <FallbackComponent componentStack={errorStackTrace} resetError={resetError} />
  }

  return (
    <>
      {hasNewVersion && <PageUpgradeApp />}
      {!hasNewVersion && (
        <Page.Wrapper title="Sorry.. there was an error">
          <Typography variant={size === 'sm' ? 'body2' : 'body1'} component="div">
            {errorCode ? (
              <>
                <p>
                  An unknown error occurred. If this error reoccurs, please don't hesitate to reach out and provide the
                  code:{' '}
                  <Typography
                    component="span"
                    variant={size === 'sm' ? 'body2' : 'body1'}
                    style={{ fontFamily: 'Roboto Mono' }}
                  >
                    {errorCode}
                  </Typography>
                </p>
              </>
            ) : (
              <p>An unknown error occurred, please try again, or contact your administrator.</p>
            )}
            <Typography variant="body2" style={{ fontFamily: 'Roboto Mono' }}>
              {errorName}: {errorMessage}
            </Typography>
          </Typography>

          {environment && environment !== 'prod' && errorStackTrace && (
            <Typography variant="body2" sx={{ fontFamily: `'Roboto Mono', monospace`, marginBottom: 3 }}>
              {errorStackTrace.split('\n').map((stackTracePart, index) => (
                <React.Fragment key={stackTracePart + index.toString()}>
                  {stackTracePart}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          )}

          {reloadPage && (
            <>
              <Button
                variant="outlined"
                style={{ marginRight: 8 }}
                color="primary"
                onClick={() => window.location.reload()}
              >
                Reload page
              </Button>
              {/* document.location.pathname */}
              {!isMainPagePath && (
                <Button variant="outlined" color="primary" onClick={() => (document.location.href = mainpagePath)}>
                  Go to main page
                </Button>
              )}
            </>
          )}
        </Page.Wrapper>
      )}
    </>
  )
}

export default ErrorBoundary
