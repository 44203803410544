import { Paper, alpha, PaperProps as MuiPaperProps, styled, CSSObject } from '@mui/material'
import { useMapContext } from '../MapContext'
import { translucentPaperBackgroundColor } from '../utils/colors'
import React from 'react'

type StyledPaperProps = {
  /**
   * Specifies the visual style of the MapTranslucentPaper component.
   *
   * This prop determines the appearance of the component, particularly how it handles
   * transparency and hover effects.
   *
   * - 'default': The component will exhibit standard behavior where the translucent effect
   *   becomes more pronounced when hovered over. This is the typical behavior where the
   *   component's transparency changes upon user interaction. Should be used on buttons.
   *
   * - 'static': The component will use hover style permanently, regardless of
   *   mouse hover state. This setting makes the hover style the default appearance,
   *   providing a consistent look without interaction-based changes. Should be used on
   *   panels like dialogs, drawers.
   *
   * @default 'default'
   */
  styleVariant?: 'default' | 'static'
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'styleVariant',
})<StyledPaperProps>(({ theme, styleVariant }) => {

  
  const defaultStyle: CSSObject = {
    boxShadow: 'none',
    color: alpha(theme.palette.text.primary, 0.7),
    backgroundColor: translucentPaperBackgroundColor({elevation: 24, opacity: 0.65, theme}),
    backdropFilter: 'blur(5px)',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
  }

  const hoverStyle: CSSObject = {
    boxShadow: 'none',
    color: theme.palette.text.primary,
    backgroundColor: translucentPaperBackgroundColor({elevation: 24, opacity: 0.85, theme}),
    backdropFilter: 'blur(5px)',
  }

  if (styleVariant === 'static') {
    return hoverStyle
  }
  
  return {
    ...defaultStyle,
    '&:hover, &:focus-within, &:active': {
      ...hoverStyle
    },
  } satisfies CSSObject
})

type MapTranslucentPaperProps = StyledPaperProps & MuiPaperProps

export const MapTranslucentPaper = React.forwardRef<any, MapTranslucentPaperProps>(({
  children,
  ...muiPaperProps
}, ref) => {
  return (
    <StyledPaper ref={ref} {...muiPaperProps} elevation={24}>
      {children}
    </StyledPaper>
  )
})
