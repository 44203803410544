import React from 'react'

import styles from '../../grieg-onboarding.module.scss'
import { CarouselElement } from './carousel'

export type CarouselNavigationProps = {
  onSlideChange: (index: number) => void
  activeIndex: number
  elements: CarouselElement[]
}

export const CarouselNavigation = (props: CarouselNavigationProps) => {
  const { onSlideChange, activeIndex, elements } = props

  const renderButtons = elements.map((_el: CarouselElement, index: number) => {
    if (elements.length === 1) {
      return null
    }
    if (index === activeIndex) {
      return (
        <span key={index} onClick={() => onSlideChange(index)}>
          ●
        </span>
      )
    } else {
      return (
        <span key={index} onClick={() => onSlideChange(index)}>
          ○
        </span>
      )
    }
  })

  return <div className={styles.carouselNavigation}>{renderButtons}</div>
}

export default CarouselNavigation
