import { StandardProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import React, { ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

export type MobileStepperHeaderClassKey = 'root'

type Props = {
  content: string | ReactNode
  className?: string
} & StandardProps<React.HTMLAttributes<HTMLDivElement>, MobileStepperHeaderClassKey>

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: `100%`,
      padding: theme.spacing(1),
    },
  })
)

const MobileStepperHeader = (props: Props) => {
  const { content, classes, ref, ...other } = props
  const innerClasses = useStyles()
  return (
    <div className={clsx(innerClasses.root, classes?.root, props.className)} {...other}>
      {content}
    </div>
  )
}

export default MobileStepperHeader
